<div class="col-12">
    <div class="logo margin-top-30 center">
        <img src="assets/images/ChatstasyCorpLogoAndSlogan-June2023-1.png" style="height: 30%;width: 18%;align-content: center;"
            alt="Chatstasy" />
    </div>
    <div class="box-register panel panel-white">
        <form class="form-register">
            <fieldset>
                <h3>
                    Business Information
                </h3>
                <hr>
                <div class="row">
                    <div class="form-group col-md-6">
                        <label>Business Name (Formal)</label>
                        <input type="text" class="form-control border-black" name="businessName"
                            placeholder=" Enter Business Name">
                    </div>
                    <div class="form-group col-md-6">
                        <label>Business Short Name (Portal Name)</label>
                        <input type="text" class="form-control" name="businessShortName"
                            placeholder=" Enter Business Short Name">
                    </div>
                </div>
                <div class="row">
                    <div class="form-group col-md-12">
                        <label>Business Address</label>
                        <input type="text" class="form-control" name="businessAddress"
                            placeholder=" Enter Business Address">
                    </div>
                </div>
                <div class="row">
                    <div class="form-group col-md-6">
                        <label>Business Country</label>
                        <input type="text" class="form-control" name="businessCountry"
                            placeholder=" Enter Business Country">
                    </div>
                    <div class="form-group col-md-6">
                        <label>Business Phone Number</label>
                        <input type="text" class="form-control" name="businessPhoneNo"
                            placeholder=" Enter Business Phone Number">
                    </div>
                </div>
                <div class="row">
                    <div class="form-group col-md-6">
                        <label>Business Fax Number</label>
                        <input type="text" class="form-control" name="businessFax" placeholder=" Enter Business Fax No">
                    </div>
                    <div class="form-group col-md-6">
                        <label>Business Logo</label>
                        <input type="text" class="form-control" name="full_name" placeholder=" Enter Business Logo">
                    </div>
                </div>
                <div class="row">
                    <div class="form-group col-md-6">
                        <label>Business Website</label>
                        <input type="text" class="form-control" name="businessWebsite"
                            placeholder=" Enter Business Website">
                    </div>
                    <div class="form-group col-md-6">
                        <label>Business EIN Number</label>
                        <input type="text" class="form-control" name="businessEinNo"
                            placeholder=" Enter Business EIN No.">
                    </div>
                </div>
                <div class="row">
                    <div class="form-group col-md-6">
                        <label>Business Type</label>
                        <select class="custom-select" name="businessType" id="businessType">
                            <option value="">Select Business Type</option>
                            <option value="1">Volvo</option>
                            <option value="2">Saab</option>
                            <option value="3">Mercedes</option>
                            <option value="4">Audi</option>
                            <option value="5">Others</option>
                        </select>
                    </div>
                    <div class="form-group col-md-6">
                        <label>Others</label>
                        <input type="text" class="form-control" name="full_name" placeholder="">
                    </div>
                </div>
                <h3 class="mt-2">Admin User Information</h3>
                <hr>
                <div class="row">
                    <div class="form-group col-md-6">
                        <label>Admin Name</label>
                        <input type="text" class="form-control" name="adminName" placeholder=" Enter Admin Name">
                    </div>
                    <div class="form-group col-md-6">
                        <label>Admin Email ID</label>
                        <input type="text" class="form-control" name="adminEmail" placeholder=" Enter Admin email Id">
                    </div>
                </div>
                <div class="row">
                    <div class="form-group col-md-6">
                        <label>Admin Phone Number</label>
                        <input type="text" class="form-control" name="adminPhoneNo" placeholder=" Enter Admin Phone No">
                    </div>
                </div>
                <div class="form-actions">
                    <p>
                        Already have an account?
                        <a href="/">
                            Log-in
                        </a>
                    </p>
                    <div class="center">
                        <button type="submit" class="btn btn-primary    ">
                            Submit
                            <i class="fa fa-arrow-circle-right"></i>
                        </button>
                    </div>
                </div>
            </fieldset>
        </form>
    </div>
</div>