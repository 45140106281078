import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { finalize, map, timeout } from 'rxjs/operators';
import { FileUpload } from 'src/app/models/FileUpload';
import { Room } from 'src/app/models/room.model';
import { Thread } from 'src/app/models/thread.model';
import { CommonFunctions } from 'src/app/Utilities/CommonFunctions';
import { environment } from 'src/environments/environment.prod';


@Injectable({
  providedIn: 'root'
})
export class ClientcommunicationService {
  private dbPath = 'business_admin/chatrooms/' + CommonFunctions.getBusinessInfo().businessId;
  private storageBasePath = '/business_admin/chatrooms';
  rooms?: Observable<Room[]>;
  roomRef: AngularFirestoreCollection<Room>;

  // 1. Storing the route param value, room/:id -> id value
  private pathParamState = new BehaviorSubject<string>('');
  pathParam: Observable<string>;
  // private node:Subject<Node> = new BehaviorSubject<Node>([]);

  constructor(
    private http: HttpClient,
    private db: AngularFirestore,
    private storage: AngularFireStorage
  ) {
    this.pathParam = this.pathParamState.asObservable();
    this.roomRef = this.db.collection<Room>(this.dbPath)
  }



  // get node$(){
  //   return this.node.asObservable().filter(node => !!node);
  // }

  // addNode(data:Node) {
  //   this.node.next(data);
  // }

  updatePathParamState(newPathParam: string): void {
    this.pathParamState.next(newPathParam);
  }

  getAllRooms(): AngularFirestoreCollection<Room> {
    let itemsCollection = this.db.collection<Room>(this.dbPath, ref => ref.orderBy('timestamp', 'desc'));
    return itemsCollection;
  }

  getRoomData(doc_id: any): AngularFirestoreDocument<Room> {
    let itemsCollection = this.roomRef.doc(doc_id);
    return itemsCollection;
  }


  createRoom(room: Room): any {
    return this.roomRef.add({ ...room });
  }

  updateRoom(id: any, text: any, timestamp: any, read_status: string): Promise<void> {
    return this.roomRef.doc(id).update({ last_message: text, timestamp: timestamp, read_status: read_status });
  }

  updateRoomForTypingOrRead(id: any, object: any): Promise<void> {
    return this.db.collection(this.dbPath).doc(id).update(object);
  }

  // checkUserIsTyping(id: any): AngularFirestoreDocument<Room> {
  //   return this.roomRef.doc(id).
  // }

  checkRoomExist(id: any): AngularFirestoreCollection<Room> {
    let itemsCollection = this.db.collection<Room>(this.dbPath, ref => ref.where('recepient_id', "==", id));
    return itemsCollection;
  }

  checkRoomExistForSupport(id: any): AngularFirestoreCollection<Room> {
    let itemsCollection = this.db.collection<Room>(this.dbPath, ref => ref.where('business_id', "==", id));
    return itemsCollection;
  }

  isUserOnline(id: boolean) {
    let itemsCollection = this.db.collection<Room>(this.dbPath, ref => ref.where('is_user_online', "==", id));
    return itemsCollection;
  }


  addMeassage(id: any, message: Thread) {
    return this.roomRef.doc(id).collection('messages').add({
      ...message
    });
  }

  getAllMessages(doc_id: any): AngularFirestoreCollection<Thread> {
    let itemsCollection = this.roomRef.doc(doc_id).collection('messages', ref => ref.orderBy('timestamp', 'asc'));
    return itemsCollection;
  }

  getAllMessagesDesc(doc_id: any): AngularFirestoreCollection<Thread> {
    let itemsCollection = this.roomRef.doc(doc_id).collection('messages', ref => ref.orderBy('timestamp', 'desc'));
    return itemsCollection;
  }

  setDbRef(from: string) {
    if (from == 'client') {
      this.dbPath = 'business_admin/chatrooms/' + CommonFunctions.getBusinessInfo().businessId;
    } else {
      this.dbPath = 'system_admin/chatrooms/SYSADMIN01';
    }
    this.roomRef = this.db.collection<Room>(this.dbPath)
  }
  ///////firebase storage///////////

  pushFileToStorage(fileUpload: FileUpload): any {
    const filePath = `${this.storageBasePath}/${fileUpload.file.name}`;
    const storageRef = this.storage.ref(filePath);
    const uploadTask = this.storage.upload(filePath, fileUpload.file);

    return { uploadTask, storageRef };
  }

  // private saveFileData(fileUpload: FileUpload): void {
  //   this.db.list(this.storageBasePath).push(fileUpload);
  // }

  // getFiles(numberItems: number): AngularFirestoreCollection<FileUpload> {
  //   return this.db.list(this.storageBasePath, ref =>
  //     ref.limitToLast(numberItems));
  // }

  // deleteFile(fileUpload: FileUpload): void {
  //   this.deleteFileDatabase(fileUpload.key)
  //     .then(() => {
  //       this.deleteFileStorage(fileUpload.name);
  //     })
  //     .catch(error => console.log(error));
  // }

  // private deleteFileDatabase(key: string): Promise<void> {
  //   return this.db.list(this.storageBasePath).remove(key);
  // }

  private deleteFileStorage(name: string): void {
    const storageRef = this.storage.ref(this.storageBasePath);
    storageRef.child(name).delete();
  }

  /////chatstasy server api call


  sendSMS(smsBody: any, channel_id: any) {
    console.log('smsBody Service Data ---> ' + JSON.stringify(smsBody));
    const url = environment.API_URL + '/api/anonymous/twilio/send-message/' + channel_id;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'BusinessId': CommonFunctions.getBusinessId().toString()
      }),
    };
    return this.http.post(url, smsBody, httpOptions).pipe(timeout(60000));
  }

  /////////////////////////Broadcast Api's///////////////////////////////////////////
  sendBulkSMS(smsBody: any, clientIds: any) {
    const url = environment.API_URL + '/api/clientCommunication/bulk?clientIds=' + clientIds;
    const TOKEN = CommonFunctions.getToken();
    console.log('Biz id:', CommonFunctions.getBusinessId().toString())
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + TOKEN,
        'Content-Type': 'application/json',
        'BusinessId': CommonFunctions.getBusinessId().toString()
      }),
    };
    return this.http.post(url, smsBody, httpOptions).pipe(timeout(60000));
  }

  getAllDocByBusinessId(){
    // let itemsCollection = this.roomRef.valueChanges().subscribe((datas) => {  datas },(err)=>{ console.log("probleme : ", err) });
    return this.db.collection<Room>(this.dbPath);
  }

}


