<div class="sidebar app-aside" id="sidebar" style="transition: all 0.2s ease-in-out;" [@widthGrow]="state"
    [ngClass]="from=='mobile'?'mobile_view':''">
    <div class="sidebar-container perfect-scrollbar">
        <p-scrollPanel [style]="{width: '100%', height: '95vh','padding-bottom':'40px'}" #sc>
            <nav>
                <p-panelMenu [model]="items" (click)="activeMenu($event)"></p-panelMenu>
            </nav>
        </p-scrollPanel>
    </div>
</div>
<!-- style="transition: all 0.2s ease-in-out;" [@widthGrow]="state" -->