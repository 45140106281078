import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { timeout } from 'rxjs/operators';
import { environment } from 'src/environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class RegistrationService {

  constructor(private http: HttpClient) { }

  checkBusinessShortname(shortname: any) {
    let url = environment.API_URL + '/api/anonymous/business/checkBusinessShortname?shortName=' + shortname;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };
    return this.http.post(url, null, httpOptions).pipe(timeout(60000));
  }

  checkDuplicateUser(mobileNo: any, email: any, businessShortName: any) {
    let params = new HttpParams()
    if (mobileNo != '') {
      params = params.set('mobileNo', mobileNo);
    }
    if (email != '') {
      params = params.set('email', email);
    }
    if (businessShortName != '') {
      params = params.set('businessShortName', businessShortName);
    }
    let url = environment.API_URL + '/api/anonymous/user/checkDuplicate';
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      params: params
    };
    return this.http.post(url, null, httpOptions).pipe(timeout(60000));
  }

  sendOTP(email: any) {
    let url = environment.API_URL + '/api/anonymous/util/sendOTP?email=' + email;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };
    return this.http.post(url, null, httpOptions).pipe(timeout(60000));
  }

  registerBusiness(body: any) {
    let url = environment.API_URL + '/api/anonymous/business/register';
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };
    return this.http.post(url, body, httpOptions).pipe(timeout(60000));
  }

  uploadBusinessLogo(business_id: string, file: any) {
    const url = environment.API_URL + '/api/anonymous/business/' + business_id + '/upload';
    // console.log('Profile Service Data ---> ' + JSON.stringify(file));
    const formData = new FormData();
    formData.append('file', file, file.name);
    return this.http.post(url, formData).pipe(timeout(60000));
  }

  getCountries() {
    const url = environment.API_URL + '/api/anonymous/country';
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };
    return this.http.get(url, httpOptions).pipe(timeout(60000));
  }

  getBusinessType() {
    const url = environment.API_URL + '/api/anonymous/businessType/list';
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };
    return this.http.get(url, httpOptions).pipe(timeout(60000));
  }

  getTimezone() {
    const url = environment.API_URL + '/api/anonymous/timezones';
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };
    return this.http.get(url, httpOptions).pipe(timeout(60000));
  }

  validateBusiness(data: any) {
    const url = environment.API_URL + '/api/anonymous/business/validate';
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };
    return this.http.post(url, data, httpOptions).pipe(timeout(60000));
  }

  validateUser(data: any) {
    const url = environment.API_URL + '/api/anonymous/user/validate';
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };
    return this.http.post(url, data, httpOptions).pipe(timeout(60000));
  }

}
