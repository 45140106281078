<div class="p-d-flex">
    <div style="width: 30%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;">
        <div style=" border-right: 1px solid #dee2e6; height: 100%;">
            <div class="header">
                <div class="sidebar_search p-fluid">
                    <div class="p-d-flex p-ai-center p-mr-2">
                        <p-checkbox *ngIf="!filterToggle" [(ngModel)]="selectAll" [binary]="true" inputId="binary"
                            (onChange)="selectOrDeselectAll($event)"></p-checkbox>
                        
                        <p-checkbox *ngIf="filterToggle" [(ngModel)]="segmentSelectAll" [binary]="true" inputId="binary"
                            (onChange)="segmentSelectOrDeselectAll($event)"></p-checkbox>
                    </div>
                    <div class="p-d-flex">
                            <div *ngIf="broadcastLoading" class="progressLayout">
                                <div class="progressContainer">
                                    <p-progressSpinner [style]="{ width: '40px', height: '40px' }" strokeWidth="3">
                                    </p-progressSpinner>
                                </div>
                            </div>
                        <span class="p-input-icon-right ">
                            <i *ngIf="searchText !== ''" class="pi pi-times" style="cursor: pointer;"></i>
                            <input *ngIf="!filterToggle" #searchInput pInputText type="text" [(ngModel)]="searchText"
                                placeholder="Search client" />

                            <input *ngIf="filterToggle" #searchInput pInputText type="text" [(ngModel)]="searchText"
                                placeholder="Search segment" />
                        </span>
                        <div>
                            <button *ngIf="!filterToggle" pButton pRipple type="button" icon="pi pi-search"
                                [style]="{width:'2.1rem',height:'2.1rem'}"
                                class="p-button-rounded p-button-raised p-button-sm p-ml-2"></button>

                            <button *ngIf="!filterToggle" pButton pRipple type="button" icon="pi pi-filter" [style]="{width:'2.1rem',height:'2.1rem'}"  class="p-button-rounded p-button-raised p-button-sm p-ml-2" (click)="handleFilter()"></button>

                            <button *ngIf="filterToggle" pButton pRipple type="button" icon="pi pi-check" [style]="{width:'2.1rem',height:'2.1rem'}" class="p-button-rounded p-button-raised p-button-sm p-ml-2 p-button-success" (click)="filterClientBySegment()"></button>

                            <button *ngIf="filterToggle" pButton pRipple type="button" icon="pi pi-times" [style]="{width:'2.1rem',height:'2.1rem'}"  class="p-button-rounded p-button-raised p-button-sm p-ml-2 p-button-danger" (click)="cancelFilter()"></button>
                        </div>
                    </div>
                </div>
            </div>
            <p-scrollPanel [style]="{width: '100%', height: '385px'}" #sc>
                <div *ngIf="!filterToggle">
                    <ul class="sidebar_ul">
                        <li class="list_sidebar" *ngFor="let client of clients | filter: searchText">
                            <a class="sidebar_container" (click)="addToBroadcastList(client)">
                                <img *ngIf="client.userType == 'chatstasy'" src="assets/images/chatstasy_circle.png"
                                    class="sidebar_profile_img" alt="">
                                <img *ngIf="client.userType != 'chatstasy'" src="assets/images/SMS_Logo.png"
                                class="sidebar_profile_img" style="width: 32px;height: 32px;" alt="">
                                <!-- [label]="client.firstName!.charAt(0)+ client.lastName!.charAt(0)" -->
                                <div class="sidebar_info">
                                    <div class="sidebar_info_title">{{client.firstName}} {{client.middleName}}
                                        {{client.lastName}}</div>
                                </div>
                            </a>
                        </li>
                        <p-messages *ngIf="clients?.length == 0 && !isLoading" key="errMsg" class="alignCenter">
                            <ng-template pTemplate>
                                <i class="pi pi-copy" style="font-size: 2rem"></i>
                                <div class="p-ml-2">NO CLIENTS FOUND</div>
                            </ng-template>
                        </p-messages>
                    </ul>
                </div>

                <div *ngIf="filterToggle">
                    <ul class="sidebar_ul">
                        <li class="list_sidebar" *ngFor="let segment of clientSegmentationList | clientFilter: searchText">
                            <a class="sidebar_container">
                                <div class="sidebar_info">
                                    <p-checkbox name="groupname" value="{{segment.id}}" [(ngModel)]="selectedclientSegmentation" label="{{segment.name}}"></p-checkbox>
                                </div>
                            </a>
                        </li>
                        <p-messages *ngIf="clientSegmentationList?.length == 0 && !isLoading" key="errMsg" class="alignCenter">
                            <ng-template pTemplate>
                                <i class="pi pi-copy" style="font-size: 2rem"></i>
                                <div class="p-ml-2">NO SEGMENT FOUND</div>
                            </ng-template>
                        </p-messages>
                    </ul>
                </div>

            </p-scrollPanel>
        </div>
    </div>
    <div style="width: 70%;">
        <div style="width: 100%;">
            <p-scrollPanel [style]="{width: '100%', height: '387px'}" #sc>
                <div *ngIf="filterUserType('chatstasy', broadcastList).length !=0">
                    <span style="color: #919191;flex:1">
                        Chatstasy:
                    </span>
                    <div class="p-grid p-m-2">
                        <div style="padding: 1px;" *ngFor="let broad of broadcastList; let i=index"
                            [@animation]="'visible'">
                            <p-chip *ngIf="broad.userType=='chatstasy'"
                                [label]="broad.firstName +' '+(broad.middleName == null? '':broad.middleName)+' '+broad.lastName"
                                [icon]="broad.userType=='nonchatstasy'? 'pi pi-user':''"
                                [image]="broad.userType=='chatstasy'? 'assets/images/chatstasy_circle.png':''"
                                [removable]="true" (onRemove)="removeToBroadcastList(broad)">
                            </p-chip>
                        </div>
                    </div>
                </div>

                <div *ngIf="filterUserType('nonchatstasy',broadcastList).length !=0">
                    <div style="flex:1;border-top: 1px solid #eee;"></div>
                    <span class="p-pt-2" style="color: #919191;">
                        Non Chatstasy:
                    </span>
                    <div class="p-grid p-p-2">
                        <div style="padding: 1px;" *ngFor="let broad of broadcastList; let i=index"
                            [@animation]="'visible'">
                            <p-chip *ngIf="broad.userType=='nonchatstasy'"
                                [label]="broad.firstName +' '+(broad.middleName == null? '':broad.middleName)+' '+broad.lastName"
                                [icon]="broad.userType=='nonchatstasy'? 'pi pi-user':''"
                                [image]="broad.userType=='chatstasy'? 'assets/images/chatstasy_circle.png':''"
                                [removable]="true" (onRemove)="removeToBroadcastList(broad)">
                            </p-chip>
                        </div>
                    </div>
                </div>
                <div *ngIf="broadcastList?.length == 0" class="alignCenter" style="height: 100%;">
                    <h1 style="font-size: 18px;">NO CLIENT SELECTED</h1>
                </div>
                <div *ngIf="isLoading" class="progressLayout">
                    <div class="progressContainer">
                        <p-progressSpinner [style]="{ width: '40px', height: '40px' }" strokeWidth="3">
                        </p-progressSpinner>
                    </div>
                </div>
            </p-scrollPanel>
            <div class="footer p-p-2">
                <p-messages key="errMsg" [style]="{
                    'font-size': '12px'
                  }"></p-messages>
                <div class="p-d-flex p-jc-evenly p-ai-center ">
                    <i class="pi pi-bars" style="font-size: 1rem;padding: 0.4375rem 0.875rem; cursor: pointer;" (click)="showToolBar()"></i>
                    <!-- <i class="pi pi-download" style="font-size: 1.2rem"></i> -->
                    <!-- <textarea class="p-mr-2 p-ml-2" style="width: 100%;max-height: 66px;" placeholder="Type a message"
                        [rows]="1" pInputTextarea [(ngModel)]="msgText" [disabled]="disabled"></textarea> -->
                        <div id="chatArea" style="border-top: 1px solid #ccc;width: 40em;">
                            <textarea id="broadcastEditor" name="broadcastEditor"></textarea>
                        </div>
                        <button pButton pRipple type="button" label="Send" class="p-button-raised" style="font-size: 12px;"
                        (click)="prepareMessageBeforeSending()" [disabled]="disabled || bussinesInfo.state=='offline'">
                    </button>
                </div>

            </div>
        </div>

    </div>
</div>

<!-- ADD ClientSegmentation MODAL -->
<!-- <p-dialog header="Add Chat" [(visible)]="displayClientSegmentModal" [modal]="true" [baseZIndex]="10000" [draggable]="false" [resizable]="true" [maximizable]="true" styleClass="p-fluid" (onHide)="clearFormData()"
    (onShow)="showDialogMaximized($event,addDialog)" #addDialog>
    <app-client-segmentation-filter></app-client-segmentation-filter>
</p-dialog> -->


<!-- ADD CHAT MODAL -->
<!-- <p-dialog header="Add Chat" [(visible)]="displayClientSegmentModal" [modal]="true" [baseZIndex]="10000" [draggable]="false" [resizable]="true" [maximizable]="true" styleClass="p-fluid" (onHide)="clearFormData()"
    (onShow)="showDialogMaximized($event,addDialog)" #addDialog>
    dfbdfgdf
</p-dialog> -->