import { Component, OnInit, ViewChild } from '@angular/core';
import { AppSettings } from '../Constant/AppSettings';
import { environment } from 'src/environments/environment.prod';
import { AuthenticationService } from '../services/authentication/authentication.service';
import { MessageService, ConfirmationService } from 'primeng/api';
import { CommonFunctions } from '../Utilities/CommonFunctions';
import { LocationStrategy } from '@angular/common';
import { Router } from '@angular/router';
import { AppointmentService } from '../services/appointment/appointment.service';

@Component({
  selector: 'app-business-calendar-action',
  templateUrl: './business-calendar-action.component.html',
  styleUrls: ['./business-calendar-action.component.css']
})
export class BusinessCalendarActionComponent implements OnInit {

  showBusinessName: boolean = false;
  businessUrl = '';
  baseUrl = '';
  disabled: boolean = true;
  isLoading: boolean = false;
  serviceTypeImages: any[] = [];
  BaseURL:string = environment.IMAGE_BASE_URL;
  @ViewChild('largeModal') largeModal:any;
  selectImage:string = '';
  serviceName:string = '';
  appointmentID:any = '';
  action:any = '';
  appointmentDetails:any;
  actionType:string = '';

  constructor(private authenticationService: AuthenticationService,
    private messageService: MessageService,
    private locationStrategy: LocationStrategy,
    private router: Router,
    private appointmentService: AppointmentService,
    private confirmationService: ConfirmationService
    ) { }

  ngOnInit(): void {
    this.baseUrl = CommonFunctions.replaceAll(this.locationStrategy.getBaseHref(), '/', '')
    this.appointmentID = this.router.url.split('?')[0].split('/').pop();
    let getaction: any = this.router.url.split('?')[1].split('/').pop();
    CommonFunctions.setBaseUrl('');
    if (this.baseUrl == '' || this.baseUrl == 'login') {
      this.showBusinessName = true;
    } else {
      this.showBusinessName = false;
      this.getBusinessInfoByShortname()
    }
    // alert(this.appointmentID);
    getaction = getaction.replace('action=','');
    if(getaction == 'addcalendar') {
      this.action = 'Add Calendar';
      this.getAppointment();
    }else if(getaction == 'updatecalendar') {
      this.action = 'Update Calendar';
      this.getAppointment();
    }else if(getaction == 'removecalendar') {
      this.action = 'Remove Calendar';
      this.getAppointment();
    }else if(getaction == 'cancelappointment') {
      this.action = 'Cancel Appointment';
      this.getAppointment();
    }else if(getaction == 'changeappointment') {
      this.action = 'Change Appointment';
      this.getAppointment();
    }else if(getaction == 'confirmappointment') {
      this.action = 'Confirm Appointment';
      this.getAppointment();
    }
    // this.getSingleServiceType(this.router.url.split('?')[0].split('/').pop());
  }

  getAppointment(){

    console.log('Appointment ID --->> ' + JSON.stringify(this.appointmentID));
    this.appointmentService.getAppointmentDetailsByID(this.appointmentID).subscribe(
      (response) => {
        var json = JSON.parse(JSON.stringify(response));
        console.log('Add Appointment Post Response --->> ' + JSON.stringify(json));
        console.log('Status --->> ' + json.response.status);
        if (json.response.status == 'SUCCESS') {
          this.appointmentDetails = json.data;
        } else {
          this.showErrorToastMsg(json.response.displayMessage);
          this.showErrorMessage(json.response.displayMessage);
        }
        this.hideProgressBar();
      },
      (error) => {
        console.log(error);
        this.showErrorMessage(AppSettings.ERROR_MSG)
        this.hideProgressBar();
      }
    );
  }

  getBusinessInfoByShortname() {
    this.showProgressBar()
    let shortName = '';
    if (this.showBusinessName) {
      shortName = this.businessUrl
    } else {
      shortName = this.baseUrl
    }

    this.authenticationService.getBusinessInfoByShortname(shortName).subscribe(
      (response) => {
        var json = JSON.parse(JSON.stringify(response));
        console.log('Get Business Info Response --->> ' + JSON.stringify(json));
        if (json.response.status == 'SUCCESS') {
          if (json.data != '' && json.data != 'null' && json.data != null) {
            CommonFunctions.setBusinessInfo(json.data)
            this.disabled = false;
          } else {
            this.disabled = true;
          }
        }
        this.hideProgressBar()
      },
      (error) => {
        console.log('There was an error');
        console.log(error);
        this.hideProgressBar();
      }
    );
  }

 showErrorMessage(msg: string) {
    this.messageService.add({
      key: 'errMsg',
      severity: 'error',
      detail: msg,
    });
  }

  showSuccessMessage(msg: string) {
    this.messageService.add({
      key: 'errMsg',
      severity: 'success',
      detail: msg,
    });
  }

  showProgressBar() {
    this.messageService.clear('errMsg');
    this.isLoading = true;
    this.disabled = true;
  }

  hideProgressBar() {
    this.isLoading = false;
    this.disabled = false;
  }

  confirm(action:any) {
    var config = {};
    if(action == 'Add Calendar') {
      config = {
        message: 'Do you want to add this appointment your calendar?',
        accept: () => {
            this.showSuccessMessage("Successfully added calendar");
        }
      }
    }else if(action == 'Update Calendar') {
      config = {
        message: 'Do you want to update this appointment your calendar?',
        accept: () => {
          this.showSuccessMessage("Successfully update calendar");
        }
      }
    }else if(action == 'Remove Calendar') {
      config = {
        message: 'Do you want to remove this appointment your calendar?',
        accept: () => {
          this.showSuccessMessage("Successfully remove calendar");
        }
      }
    }else if(action == 'Cancel Appointment') {
      config = {
        message: 'Do you want to cancel this appointment your calendar?',
        accept: () => {
          this.cancelAppointment();
        }
      }
    }else if(action == 'Change Appointment') {
      config = {
        message: 'Do you want to change this appointment your calendar?',
        accept: () => {
          this.router.navigate(['/login']);
        }
      }
    }else if(action == 'Confirm Appointment') {
      config = {
        message: 'Do you want to confirm this appointment your calendar?',
        accept: () => {
          this.clientConfirmAppointment();
        }
      }
    }
    this.confirmationService.confirm(config);
  }

  RedirectToBaseURL(){
    this.router.navigate(['/login']);
  }

  cancelAppointment() {
    this.showProgressBar()
    this.appointmentService.cancelAppointment(this.appointmentDetails.id, true).subscribe(
      (response) => {
        var json = JSON.parse(JSON.stringify(response));
        if (json.response.status == 'SUCCESS') {
          this.showSuccessMessage(json.response.message)
        } else {
          this.showErrorToastMsg(json.response.message)
        }
        this.hideProgressBar()
        console.log('Cancel Appointment Response --->> ' + JSON.stringify(json));
      },
      (error) => {
        console.log(error);
        this.showErrorToastMsg(AppSettings.ERROR_MSG)
      }
    );
  }

  showErrorToastMsg(msg: string) {
    this.messageService.add({
      key: 'br',
      severity: 'error',
      detail: msg,
    });
  }

  clientConfirmAppointment() {
    this.showProgressBar()
    this.appointmentService.confirmAppointment(this.appointmentDetails.id, true, 'confirmation').subscribe(
      (response) => {
        var json = JSON.parse(JSON.stringify(response));
        if (json.response.status == 'SUCCESS') {
          this.showSuccessMessage(json.response.message)
        } else {
          this.showErrorToastMsg(json.response.message)
        }
        this.hideProgressBar()
        console.log('Cancel Appointment Response --->> ' + JSON.stringify(json));
      },
      (error) => {
        console.log(error);
        this.showErrorToastMsg(AppSettings.ERROR_MSG)
      }
    );
  }

}
