<!-- <footer data-ng-include=" 'assets/views/partials/footer.html' " class="hidden-print" style="">
  <div class="footer-inner">
    <div class="pull-left">
      © Copyright 2020 <span class="text-bold text-uppercase">Chatstasy, LLC</span>. <span
        translate="footer.copyright.MESSAGE" class="">All rights reserved</span>
    </div>
    <div class="pull-right">
      <span class="go-top" ng-click="toTheTop()"><i class="ti-angle-up"></i></span>
    </div>
  </div>
</footer> -->

<footer [ngClass]="state=='open' ? 'footer_open' : ''" class="footer">
  <div style="padding: 0 20px;
  line-height: 50px;">
    <div class="pull-left">
      © Copyright <span class="current-year">2021</span>
      <span class="text-bold text-uppercase"> Chatstasy, LLC</span>.
      <span>All rights reserved</span>
    </div>
    <div class="pull-right">
      <span class="go-top"><i class="ti-angle-up"></i></span>
    </div>
  </div>
</footer>