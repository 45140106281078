import * as moment from "moment";
import { AuthenticationService } from "../services/authentication/authentication.service";

export class CommonFunctions {
    public static sum(a: number, b: number): number {
        return a + b;
    }

    public static base64ToFile(data: any, filename: any) {

        const arr = data.split(',');
        const mime = arr[0].match(/:(.*?);/)[1];
        const bstr = atob(arr[1]);
        let n = bstr.length;
        let u8arr = new Uint8Array(n);

        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }

        return new File([u8arr], filename, { type: mime });
    }

    public static getPrivileges() {
        return JSON.parse(localStorage.getItem("biz_privileges") || '[]');
    }

    public static setPrivileges(privileges: any) {
        localStorage.setItem('biz_privileges', JSON.stringify(privileges));
    }

    public static getUserId(): string {
        const json = JSON.parse(localStorage.getItem('biz_user') || '{}');
        return json.id;
    }

    public static getUser() {
        return JSON.parse(localStorage.getItem('biz_user') || '{}');
    }

    public static getToken() {
        return localStorage.getItem("biz_token") || '';
    }

    public static setUser(user: any) {
        localStorage.setItem('biz_user', JSON.stringify(user));
    }

    public static setToken(token: any) {
        localStorage.setItem('biz_token', token);
    }

    public static removeToken() {
        localStorage.removeItem('biz_token');
    }

    public static removeUser() {
        localStorage.removeItem('biz_user');
    }

    public static removeBusinessInfo() {
        localStorage.removeItem('biz_info');
    }

    public static setBusinessInfo(info: any) {
        localStorage.setItem('biz_info', JSON.stringify(info));
    }

    public static getBusinessInfo() {
        return JSON.parse(localStorage.getItem('biz_info') || '{}');
    }

    public static getBusinessInfoId() {
        let json = JSON.parse(localStorage.getItem('biz_info') || '{}');
        return json.businessId;
    }

    public static getBusinessId() {
        let json = JSON.parse(localStorage.getItem('biz_info') || '{}');
        return json.id;
    }

    public static isSubscribed(): boolean {
        const json = JSON.parse(localStorage.getItem('biz_info') || '{}');
        return json.isSubscribed || false;
    }

    public static getTimeAndDateTag(timestamp: any, from: string) {
        let timeTag = '';
        if (this.isToday(new Date(timestamp?.toDate()))) {
            timeTag = from == 'sidebar' ? moment(timestamp?.toDate()).format("hh:mm A") : 'Today';
        } else if (this.isYesterday(new Date(timestamp?.toDate()))) {
            timeTag = 'Yesterday';
        } else {
            timeTag = from == 'sidebar' ? moment(timestamp?.toDate()).format("MM/DD/YYYY") : moment(timestamp?.toDate()).format('dddd, MMM DD, YYYY');
        }
        return timeTag;
    }

    public static isToday(someDate: any) {
        const today = new Date()
        return someDate.getDate() == today.getDate() &&
            someDate.getMonth() == today.getMonth() &&
            someDate.getFullYear() == today.getFullYear()
    }


    public static isYesterday(someDate: any) {
        const today = new Date()
        return someDate.getDate() == today.getDate() - 1 &&
            someDate.getMonth() == today.getMonth() &&
            someDate.getFullYear() == today.getFullYear()
    }

    public static formatBytes(bytes: any, decimals = 2) {
        if (bytes === 0) return '0 Bytes';

        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

        const i = Math.floor(Math.log(bytes) / Math.log(k));

        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }

    public static setBaseUrl(info: any) {
        localStorage.setItem('biz_redirect_url', info);
    }

    public static getBaseUrl() {
        return localStorage.getItem('biz_redirect_url') || '';
    }


    public static getBaseLocation() {
        let baseUrl = localStorage.getItem('biz_redirect_url') || '';
        let paths: string[] = location.pathname.split('/').splice(1, 1);
        let basePath: string = ''
        if (paths[0] == 'registration' || paths[1] == 'registration') {
            basePath = '';
        }
        // else if (baseUrl != '') {
        //     basePath = baseUrl + (paths && paths[0]) || '';
        // }
        else {
            basePath = (paths && paths[0]) || ''; // Default: my-account
        }
        console.log('basePath:', paths && paths[0])

        return '/' + basePath;
    }

    public static escapeRegExp(string: any) {
        return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
    }

    /* Define functin to find and replace specified term with replacement string */
    public static replaceAll(str: any, term: any, replacement: any) {
        return str.replace(new RegExp(this.escapeRegExp(term), 'g'), replacement);
    }

    public static validateFax(checkField: any) {
        if (checkField.length > 0) {
            var faxRegEx = /^\+?[0-9]{8,10}$/;
            if (!checkField.match(faxRegEx)) {
                return false;
            }
        }
        return true;
    }
    public static validateEin(checkField: any) {
        if (checkField.length > 0) {
            var faxRegEx = /^[0-9]{9,9}$/;
            if (!checkField.match(faxRegEx)) {
                return false;
            }
        }
        return true;
    }

    public static validURL(str: any) {
        var pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
            '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
            '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
        return !!pattern.test(str);
    }

    public static validSecureURL(str: any) {
        var res = str.match(/^((?:http:\/\/)|(?:https:\/\/))(www.)?((?:[a-zA-Z0-9]+\.[a-z]{3})|(?:\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}(?::\d+)?))([\/a-zA-Z0-9\.]*)$/gm);
        if(res == null)
            return false;
        else
            return true;
    }

    public static setClientChatList(info: any) {
        localStorage.setItem('client_list_info', JSON.stringify(info));
    }

    public static getClientChatList() {
        return JSON.parse(localStorage.getItem("client_list_info") || '{}');
    }

    public static removeClientChatList() {
        localStorage.removeItem('client_list_info');
    }

    public static forbiddenCharsValidator(address: any) {
        const forbiddenChars = /[@&#]/; // Regular expression to match "&", "@", and "#"
        if (forbiddenChars.test(address)) {
            return true; // Validation failed
        }
        return false; // Validation passed
      }


    public static filterClientSegmentBySegmentId(clientList: any, segmentIdList: any){
        let ary: any = [];
        clientList.forEach((element:any) => {
            if(element.clientSegments != "" && element.clientSegments !=null ) {
                
                // Check if any element from ary_two exists in ary_one
                const exists = segmentIdList.some((item: any) => element.clientSegments.includes(item));
                if(exists){
                    ary.push(element);
                }
            }
        });
        return ary;

    }
}