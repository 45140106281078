import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { timeout } from 'rxjs/operators';
import { CommonFunctions } from 'src/app/Utilities/CommonFunctions';
import { environment } from 'src/environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class BusinessHoursService {

  constructor(
    private http: HttpClient
  ) { }

  getListBusinessHours() {
    const url = environment.API_URL + '/api/businessHour/list';
    const TOKEN = CommonFunctions.getToken();
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + TOKEN,
        'Content-Type': 'application/json',
        'BusinessId': CommonFunctions.getBusinessId().toString()
      }),
    };
    return this.http.get(url, httpOptions).pipe(timeout(60000));
  }

  editBusinessHours(businessHour: any) {
    const url = environment.API_URL + '/api/businessHour/edit';
    // console.log('Business Hours edit Service Data ---> ' + JSON.stringify(businessHour));
    const TOKEN = CommonFunctions.getToken();
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + TOKEN,
        'Content-Type': 'application/json',
        'BusinessId': CommonFunctions.getBusinessId().toString()
      }),
    };
    return this.http.post(url, businessHour, httpOptions).pipe(timeout(60000));
  }

  getBusinessHoursTimeSlots(startTime: string, endTime: string) {
    const url = environment.API_URL + '/api/businessHour/timeslots?startTime=' + startTime + '&endTime=' + endTime;
    const TOKEN = CommonFunctions.getToken();
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + TOKEN,
        'Content-Type': 'application/json',
        'BusinessId': CommonFunctions.getBusinessId().toString()
      }),
    };
    return this.http.post(url, null, httpOptions).pipe(timeout(60000));
  }

}
