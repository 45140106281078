import { Component, OnInit, ViewChild, } from "@angular/core";
import { MessageService } from "primeng/api";
// import { RenewModalComponent } from "../custom/renew-modal/renew-modal.component";
import { SubscriptionService } from "../services/subscription/subscription.service";
import { UtilsService } from "../services/utils/utils.service";
import { CommonFunctions } from "../Utilities/CommonFunctions";
import * as ChartDataLabels from 'chartjs-plugin-datalabels';
import { DashboardService } from "../services/dashboard/dashboard.service";
import { BusinessInfoService } from "../services/business-info/business-info.service";
import { SubscriptionAndPaymentsService } from "../services/subscription-and-payments/subscription-and-payments.service";
import { AppSettings } from "../Constant/AppSettings";

@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.css"],
})
export class DashboardComponent implements OnInit {
  message: any = {};
  isLoadingClientChannel: boolean = false
  isLoadingClientChannelAdoption: boolean = false
  isLoadingMessageUsage: boolean = false

  // Dialog display varaible
  displayRenewModal: boolean = false;
  //Chart
  clientChannelData: any;
  clientChannelAdoptionData: any;
  messageUsageData: any;
  //
  noClientChannelData: boolean = false;
  noClientChannelAdoptionData: boolean = false;
  noMessageUsageData: boolean = false;
  //
  options: any;
  basicOptions: any;
  plugin: any[] = [];
  userId: string = CommonFunctions.getUserId();

  // @ViewChild('renew') renew?: RenewModalComponent;
  constructor(
    public utils: UtilsService,
    public subscriptionService: SubscriptionService,
    private businessInfoService: BusinessInfoService,
    private dashboardService: DashboardService,
    private messageService: MessageService,
    private subscriptionAndPaymentsService: SubscriptionAndPaymentsService
  ) {
    this.plugin.push(ChartDataLabels);
    // this.options = {
    //   plugins: {
    //     datalabels: {
    //       /* show value in percents */
    //       formatter: (value: any, ctx: any) => {
    //         let sum = 0;
    //         const dataArr = ctx.chart.data.datasets[0].data;
    //         dataArr.map((data: any) => {
    //           sum += data;
    //         });
    //         const percentage = (value * 100 / sum);
    //         return percentage !== 0 ? percentage.toFixed(2) + '%' : '';
    //       },
    //       color: '#fff',
    //     }
    //   }
    // }
    this.options = {
      //display labels on data elements in graph
      plugins: {
        datalabels: {
          formatter: (value: any, ctx: any) => {
            return ctx.chart.data.displayData[ctx.dataIndex];
          },
          align: 'center',
          anchor: 'center',
          borderRadius: 4,
          backgroundColor: 'transparent',
          color: ['white', '#8E8E93'],
          font: {
            weight: 'bold',
          },
        },
      },
    };


    this.basicOptions = {
      plugins: {
        datalabels: {
          formatter: (value: any, ctx: any) => {
            return ctx.chart.data.displayData[ctx.dataIndex];
          },
          align: 'center',
          anchor: 'center',
          borderRadius: 4,
          backgroundColor: 'transparent',
          color: '#8E8E93',
          font: {
            weight: 'bold',
          },
        },
      },
      legend: {
        labels: {
          fontColor: '#495057'
        }
      },
      scales: {
        xAxes: [{
          ticks: {
            fontColor: '#495057'
          }
        }],
        yAxes: [{
          ticks: {
            fontColor: '#495057',
            min: 0,
            max: 100
          }
        }]
      }
    };
  }

  ngOnInit(): void {
    this.getClientChannel()
    this.getClientChannelAdoption()
    this.getMessageUsage()
  }

  ngAfterViewInit() {
    setTimeout(() => {
      if (this.subscriptionService.isPaymentSuccessful) {
        this.showSuccessMessage(
          "Thank You for your Payment. Your Subscription is now Active"
        );
      }
      if (CommonFunctions.isSubscribed() == false) {
        this.showHideModalDialog('renew_subscription');
      }
      console.log('Dash respo:', CommonFunctions.isSubscribed())
    }, 1);
  }


  getClientChannel() {
    this.isLoadingClientChannel = true
    this.dashboardService.getClientChannel().subscribe(
      (response) => {
        var json = JSON.parse(JSON.stringify(response));
        console.log("Client Channel Response --->> " + JSON.stringify(json));
        if (json.response.status == 'SUCCESS') {
          this.noClientChannelData = json.data.every((data: any) => {
            return data.data == '0';
          });
          this.clientChannelData = {
            labels: this.getLabels(json.data),
            displayData: this.getDisplayData(json.data),
            datasets: [
              {
                data: this.getData(json.data),
                backgroundColor: [
                  "#3979FA",
                  "#DEE2E6"
                ],
                hoverBackgroundColor: [
                  "#3979FA",
                  "#DEE2E6"
                ]
              }]
          };
        }
        this.isLoadingClientChannel = false;
      },
      (error) => {
        console.log(error);
        this.isLoadingClientChannel = false;
      }
    );
  }

  getClientChannelAdoption() {
    this.isLoadingClientChannelAdoption = true
    this.dashboardService.getClientChannelAdoption().subscribe(
      (response) => {
        var json = JSON.parse(JSON.stringify(response));
        console.log("Client Channel Adoption Response --->> " + JSON.stringify(json));
        if (json.response.status == 'SUCCESS') {
          this.noClientChannelAdoptionData = json.data.every((data: any) => {
            return data.data == '0';
          });
          this.clientChannelAdoptionData = {
            labels: this.getLabels(json.data),
            displayData: this.getDisplayData(json.data),
            datasets: [
              {
                data: this.getData(json.data),
                backgroundColor: [
                  "#3979FA",
                  "#DEE2E6"
                ],
                hoverBackgroundColor: [
                  "#3979FA",
                  "#DEE2E6"
                ]
              }]
          };

        }
        this.isLoadingClientChannelAdoption = false
      },
      (error) => {
        this.isLoadingClientChannelAdoption = false
        console.log(error);
      }
    );
  }

  getMessageUsage() {
    this.isLoadingMessageUsage = true
    this.dashboardService.getMessageUsage().subscribe(
      (response) => {
        var json = JSON.parse(JSON.stringify(response));
        console.log("Message Usage Response --->> " + JSON.stringify(json));
        if (json.response.status == 'SUCCESS') {
          this.noMessageUsageData = json.data.every((data: any) => {
            return data.data == '0';
          });
          this.messageUsageData = {
            labels: this.getBarLabels(json.data),
            displayData: this.getDisplayData(json.data),
            datasets: [
              {
                label: 'SMS',
                backgroundColor: '#DEE2E6',
                data: this.getData(json.data)
              }
            ]
          };
        }
        this.isLoadingMessageUsage = false
      },
      (error) => {
        console.log(error);
        this.isLoadingMessageUsage = false
      }
    );
  }

  getBusinessInfo() {
    this.businessInfoService.getBusinessInfo(CommonFunctions.getBusinessId()).subscribe(
      (response) => {
        var json = JSON.parse(JSON.stringify(response));
        console.log('Business info response --> ' + JSON.stringify(json));
        if (json.response.status == 'SUCCESS') {
          if (json.data != '' && json.data != 'null' && json.data != null) {
            CommonFunctions.setBusinessInfo(json.data)
          }
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }

  getLabels(data: any) {
    return data.map(function (val: any) {
      return val.datasetName;
    });
  }

  getData(data: any) {
    return data.map(function (val: any) {
      return parseInt(val.data);
    });
  }

  getBarLabels(data: any) {
    return data.map(function (val: any) {
      return val.xAxis;
    });
  }

  getDisplayData(data: any) {
    return data.map(function (val: any) {
      return val.displayData;
    });
  }

  showSuccessMessage(msg: string) {
    this.messageService.add({
      key: "successMsg",
      severity: "success",
      detail: msg,
    });
  }

  showHideModalDialog(type: string) {
    switch (type) {
      case 'renew_subscription':
        this.displayRenewModal = !this.displayRenewModal;
        break;
    }
  }

  hideModalRenewDialog(displayRenewModal: any) {
    if (displayRenewModal !== undefined) {
      this.displayRenewModal = displayRenewModal;
    }
  }

  isSuccess(isSuccess: any) {
    console.log(isSuccess);
    if (isSuccess !== undefined) {
      // if (isSuccess == 'SUCCESS') {

      this.subscriptionAndPaymentsService.paymentSuccess(this.userId, "home", isSuccess)
      .subscribe(
        (response) => {
          var json = JSON.parse(JSON.stringify(response));
          console.log("Payment success Response --->> " + JSON.stringify(json));
          if (json.response.status == 'SUCCESS') {
            this.showSuccessMessage(json.response.message)
            this.displayRenewModal = false;
            window.location.reload();
            // this.clearFormData();
            // if(this.from == "subs&pay" || this.from == "message_credit"){
            //   this.isSuccess.emit('SUCCESS');
            // }
            //  this.isSuccess.emit('SUCCESS')
          } else {
            this.showErrorMessage(json.response.displayMessage)
          }

          // this.hideProgressBar();
        },
        (error) => {
          console.log(error);
          this.showErrorMessage(AppSettings.ERROR_MSG)
          // this.hideProgressBar();
        }
      );
        this.getBusinessInfo();
      // }
    }
  }

  get state(): string {
    return this.utils.state;
  }

  showErrorMessage(msg: string) {
    this.messageService.add({
      key: 'br',
      severity: 'error',
      detail: msg,
    });
  }
}
