<!-- <p-dialog #renewDialog [(visible)]="displayRenewModal" [modal]="true" [baseZIndex]="10000" [draggable]="false"
    [resizable]="true" [maximizable]="true" (onHide)="clearFormData()" [closable]="from!='home'"
    [contentStyle]="{'overflow':'hidden'}" (onShow)="showDialogMaximized($event,renewDialog)">
    <ng-template pTemplate="header">
        <div style="width: 100%;">
            <div class="text-center">
                <h1 class="display-4" style="font-size: 2rem;margin:1rem 0 1rem 0;">{{titleText}}</h1>
            </div>
        </div>
    </ng-template>
    <p-messages key="errMsg" [style]="{
      'font-size': '12px'
    }"></p-messages>
    <div style="min-width: 70vw;height: 70vh;">
        <div [ngClass]="currentPosition=='package'?'show':'hide'">
            <section class="pricing py-2">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-4" *ngFor="let subs of subscriptionPackageList;let i = index" #parent>
                            <div class="card mb-2 mb-lg-0" [ngClass]="packageId==subs.id?'active':''"
                                (click)="onPackageSelection(subs,'renew')" style="cursor: pointer;height: 100%;">

                                <div class="card-body">
                                    <h5 class="card-title text-muted text-uppercase text-center">{{subs.name}}</h5>
                                    <h6 class="card-price text-center">
                                        ${{stateValue == 'monthly'?subs.monthlyPrice:subs.yearlyPrice}}<span
                                            class="period">/{{stateValue == 'monthly'?
                                            'month':'year'}}</span>
                                    </h6>
                                    <p class="hidden">{{subs.id}}</p>
                                    <hr>
                                    <ul class="fa-ul">
                                        <li *ngFor="let feature of subs.features">
                                            <span class="fa-li">
                                                <i class="pi pi-check"></i>
                                            </span>
                                            <strong
                                                *ngIf="feature.name == 'basic_monthly_credits_for_clients_on_non_chatstasy_app' || feature.name == 'standard_monthly_credits_for_clients_on_non_chatstasy_app'">{{stateValue
                                                == 'monthly'?feature.amount + ' Monthly' :(feature.amount*12) +
                                                ' Yearly'}}</strong>
                                            <strong
                                                *ngIf="feature.name == 'free_monthly_chatstasy_message_credits' || feature.name == 'free_monthly_credits_for_clients_on_chatstasy_app'
                                                || feature.name == 'basic_monthly_chatstasy_message_credits' || feature.name == 'basic_monthly_credits_for_clients_on_chatstasy_app'
                                                ||feature.name == 'standard_monthly_chatstasy_message_credits' || feature.name == 'standard_monthly_credits_for_clients_on_chatstasy_app'"
                                                class="period">{{stateValue == 'monthly'?
                                                'Monthly':'Yearly'}}</strong>
                                            <strong
                                                *ngIf="feature.name != 'basic_monthly_credits_for_clients_on_non_chatstasy_app' && feature.name != 'standard_monthly_credits_for_clients_on_non_chatstasy_app'">{{feature.amount}}</strong>
                                            {{feature.description}}
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </section>
        </div>
        <div [ngClass]="currentPosition=='message_credit'?'show':'hide'">
            <div class="p-d-flex p-flex-column" style="padding:1rem;margin-left: 2rem;">
                <div *ngFor="let credit of messageCreditList" class="p-field-checkbox">
                    <p-radioButton [inputId]="credit.id" name="credit" [value]="credit"
                        [(ngModel)]="selectedMessageCredit"></p-radioButton>
                    <label class="p-mt-1" *ngIf="credit.id == 'default'"
                        [for]="credit.id">{{credit.description}}</label>
                    <label class="p-mt-1" *ngIf="credit.id != 'default'" [for]="credit.id">{{credit.description}} -
                        $ {{credit.price.toFixed(2)}} (${{credit.perMsgCost}}/message)</label>
                </div>
            </div>
        </div>
        <div [ngClass]="currentPosition=='review_pay'?'show':'hide'">
            <div class="p-grid p-d-flex p-jc-center p-ai-center invoice_content" style="margin:0px;">
                <div style="padding: 1rem;">
                    <div class="invoice-box">
                        <table>
                            <tr class="heading">
                                <td>Item</td>
                                <td>Price</td>
                            </tr>
                            <tr *ngFor="let item of calculateInvoice" class="item"
                                [ngClass]="item.name == 'Total Amount'?'last':''">
                                <td *ngIf="item.name != 'Total Amount'">{{item.name}}</td>
                                <td *ngIf="item.name != 'Total Amount'">$ {{ twoDigitDecimal(item.value)}}</td>

                                <td *ngIf="item.name == 'Total Amount'"></td>
                                <td *ngIf="item.name == 'Total Amount'">Total: $ {{twoDigitDecimal(item.value)}}
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>
                <div class="paypal_container">
                    <div *ngIf="currentPosition!='review_pay' || amount=='0.0'; else paypal" style="display: contents;">
                        <button pButton pRipple type="button" label="Proceed" class="p-button-rounded p-button-lg"
                            style="font-size: 18px;" (click)="callPaymentSuccess('null')"></button>
                    </div>
                    <ng-template #paypal>
                        <ngx-paypal [config]="payPalConfig"></ngx-paypal>
                    </ng-template>
                </div>

            </div>
        </div>
    </div>
    <ng-template pTemplate="footer">
        <div *ngIf="isLoading" class="progressLayout">
            <div class="progressContainer">
                <p-progressSpinner [style]="{ width: '40px', height: '40px' }" strokeWidth="3">
                </p-progressSpinner>
            </div>
        </div>
        <div [ngClass]="currentPosition=='package'?'show':'hide'">
            <div class="p-p-2" style="font-weight: bold;"
                [ngClass]="packageText.status=='UPGRADE'?'text-warning':'text-danger'">
                {{packageText.message}}
            </div>
            <div class="p-grid">
                <div class="p-col">
                    <p-selectButton [options]="stateOptions" [(ngModel)]="stateValue" optionLabel="label"
                        optionValue="value">
                    </p-selectButton>
                </div>
                <div class="p-col">
                    <p-button *ngIf="from !='subs&pay'" [label]="packageBtnText"
                        (click)="goToNextAndPrevious('message_credit')" [disabled]="disabled">
                    </p-button>
                    <p-button *ngIf="from =='subs&pay'" [label]="packageBtnText"
                        (click)="goToNextAndPrevious('review_pay')" [disabled]="disabled">
                    </p-button>
                </div>
                <div class="p-col"></div>
            </div>
        </div>
        <div class="p-p-1" [ngClass]="currentPosition=='message_credit'?'show':'hide'">
            <div class="p-grid">
                <div class="p-col p-d-flex p-ai-end" style="text-align: start;">
                    <button *ngIf="from!='message_credit'" pButton pRipple type="button" icon="pi pi-angle-left"
                        label="Select package" class="p-button-secondary p-button-text"
                        (click)="goToNextAndPrevious('package')"></button>
                </div>

                <div class="p-col p-d-flex p-jc-end">
                    <button pButton pRipple type="button" icon="pi pi-angle-right" iconPos="right" label="Next"
                        class="p-button-secondary p-button-text" (click)="goToNextAndPrevious('review_pay')"></button>
                </div>
            </div>
        </div>
        <div class="p-p-1" [ngClass]="currentPosition=='review_pay'?'show':'hide'">
            <div class="p-grid">
                <div class="p-col p-d-flex p-ai-end" style="text-align: start;">
                    <button *ngIf="from =='subs&pay'" pButton pRipple type="button" icon="pi pi-angle-left" label="Back"
                        class="p-button-secondary p-button-text" (click)="goToNextAndPrevious('package')"></button>
                    <button *ngIf="from !='subs&pay'" pButton pRipple type="button" icon="pi pi-angle-left" label="Back"
                        class="p-button-secondary p-button-text"
                        (click)="goToNextAndPrevious('message_credit')"></button>
                </div>
                <div class="p-col p-pt-2 p-pb-0">
                </div>
                <div class="p-col"></div>
            </div>
        </div>
    </ng-template>

</p-dialog>

<p-toast position="bottom-right" key="br"></p-toast> -->




<p-dialog #renewDialog [(visible)]="displayRenewModal" [modal]="true" [baseZIndex]="10000" [draggable]="false"
    [resizable]="true" [maximizable]="true" (onHide)="clearFormData()" [contentStyle]="{'overflow':'hidden'}" (onShow)="showDialogMaximized($event,renewDialog)">
    <ng-template pTemplate="header">
        <div style="width: 100%;">
            <div class="text-center">
                <h1 class="display-4" style="font-size: 2rem;margin:1rem 0 1rem 0; font-variant: lining-nums;">{{titleText}}</h1>
            </div>
        </div>
    </ng-template>
    <p-messages key="errMsg" [style]="{
        'font-size': '12px'
      }"></p-messages>
    <div style="min-width: 70vw;height: 70vh;">
        <div>
            <section class="pricing py-2">
                <div class="container">
                    <div class="row">
                        <div class="col-sm-12 col-sm-offset-1 p-grid p-ai-center">
                            <div class="col-sm-11 col-sm-offset-1 p-grid p-ai-center" *ngIf="currentPosition != 'review_pay'" >
                                <div class="p-col-5  col-sm-offset-1" *ngIf="from!='message_credit'">
                                    <div class="row form-group" style="margin-bottom: 10px;">
                                        <label class="col-sm-12 control-label" style=" font-size: 1.5rem; ">Subscription Plan</label>
                                        <div class="col-sm-12">
                                            <select [(ngModel)]="subscriptionPlan" (change)="onPackageSelection($event,'renew')" name="SubscriptionPlan" class="form-control" style="height: 34px;" required>
                                                <option *ngFor="let subs of subscriptionPackageList; let i = index" value={{i}}> {{ subs.name }} <span *ngIf="from == 'home'"> ( {{ subs.info }} ) </span></option>
                                            </select>
                                            <br>
                                            <select [(ngModel)]="stateValue" name="statevalue" class="form-control" style="height: 34px;" (change)="calculateSubscription()">
                                                <option value="yearly">Annual</option>
                                                <option value="monthly">Monthly</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>

                                <div class="p-col-5  col-sm-offset-1 text-right" *ngIf="from!='message_credit'">
                                    <span class="card-price" *ngFor="let cal of calculateInvoice">
                                        <span *ngIf="cal.name == 'Basic Package Amount'">
                                            $ {{twoDigitDecimal(cal.value)}}
                                        </span>
                                    </span>
                                </div>

                                <div class="p-col-5  col-sm-offset-1" *ngIf="from!='subs&pay'">
                                    <div class="row form-group" style="margin-bottom: 10px;">
                                        <label class="col-sm-12 control-label" style=" font-size: 1.5rem; ">Additional SMS Message Credits</label>
                                        <div class="col-sm-12">
                                            <select [(ngModel)]="selectedMessageCredit" name="selectedMessageCredit" class="form-control"  style="height: 34px;" required (change)="calculateSubscription()">
                                            <option [ngValue]="messageCreditList[0]" selected>{{messageCreditList[0]?.description}}</option>
                                            <option *ngFor="let credit of messageCreditList | slice:1" [ngValue]="credit">
                                                    {{credit.description}} - $ {{credit.price.toFixed(2)}} (${{credit.perMsgCost}}/message)
                                            </option>
                                            </select>
                                        </div>
                                    </div>
                                </div>

                                <div class="p-col-5  col-sm-offset-1 text-right" *ngIf="from!='subs&pay'">
                                    <span *ngIf="calculateInvoice.length == 6" class="card-price">
                                        $ {{twoDigitDecimal("0")}}
                                    </span>
                                    <span *ngIf="calculateInvoice.length == 7" class="card-price">
                                        $ {{twoDigitDecimal(calculateInvoice[4]?.value)}}
                                    </span>
                                </div>

                                <div class="p-col-5  col-sm-offset-1" *ngIf="from!='message_credit'">
                                    <label class="control-label" style=" font-size: 1.5rem; ">Setup fees</label>
                                </div>

                                <div class="p-col-5  col-sm-offset-1 text-right" *ngIf="from!='message_credit'">
                                    <span class="card-price" *ngFor="let cal of calculateInvoice">
                                        <span *ngIf="cal.name == 'Setup Fee'">
                                            $ {{twoDigitDecimal(cal.value)}}
                                        </span>
                                    </span>
                                </div>

                                <div class="p-col-5  col-sm-offset-1" *ngIf="from!='message_credit'">
                                    <div class="row form-group" style="margin-bottom: 10px;" *ngIf="from =='home'">
                                        <label class="col-sm-12 control-label" style=" font-size: 1.5rem; ">Discount/Coupons</label>
                                        <div class="col-sm-12">
                                            <div class="d-table-cell w-100">
                                                <input type="text" name="coupon" class="form-control" placeholder="Enter Discount/Coupons" [(ngModel)]="coupon" value="{{ coupon | uppercase }}" autocomplete="off" [disabled]="discountValid"/>
                                            </div>
                                             <div class="d-table-cell align-middle">
                                                <button class="btn btn-primary" (click)="calculateSubscription()" *ngIf="!discountValid">Apply</button>
                                                <button class="btn btn-danger" (click)="removeCouponCode()" *ngIf="discountValid">Remove</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="p-col-5  col-sm-offset-1 text-right" *ngIf="from!='message_credit'">
                                    <span *ngIf="from =='home'">
                                        <span class="card-price" *ngFor="let cal of calculateInvoice">
                                            <span *ngIf="cal.name == 'Discount Amount' && cal.valid == 'success'">
                                               - $ {{twoDigitDecimal(cal.value)}}
                                            </span>
                                        </span>
                                    </span>
                                </div>

                                <div class="p-col-5  col-sm-offset-1">
                                    <label class="control-label" style=" font-size: 1.5rem; ">Total</label>
                                </div>

                                <div class="p-col-5  col-sm-offset-1 text-right">
                                    <span class="card-price" *ngFor="let cal of calculateInvoice">
                                        <span *ngIf="cal.name == 'Total Amount'">
                                            $ {{twoDigitDecimal(cal.value)}}
                                        </span>
                                    </span>
                                </div>
                            </div>
                            <div class="p-col-11 col-sm-offset-1">
                                
                                <div class="p-col-12 text-center" *ngIf="currentPosition != 'review_pay' && amount != '0.0'">
                                    <div class="p-col-12 text-center">
                                        <span style="font-size: 1.5rem; ">{{titleText}}</span>
                                    </div>
                                    <img class="rounded p-col-3" src="assets/images/PayPal_Payment_Button.jpg" (click)="ProcessshowPaymentGatway('paypal')" style="width: 20%; cursor: pointer;">
                                    <img class="rounded p-col-3" src="assets/images/CC_Payment_Button.jpg" (click)="ProcessshowPaymentGatway('squareupcard')" style="width: 20%; cursor: pointer;">
                                    <img class="rounded p-col-3" src="assets/images/Other_Payment_Button.jpg" (click)="ProcessshowPaymentGatway('squareupother')" style="width: 20%; cursor: pointer;">
                                    <!-- <button pButton pRipple type="button" label="Proceed" class="p-button-rounded p-button-lg" style="font-size: 18px;" (click)="ProcessshowPaymentGatway()"></button> -->
                                </div>

                                <div class="p-col-12 text-center" *ngIf="currentPosition != 'review_pay' && amount == '0.0' && validateUpgradePackageError">
                                    <button pButton pRipple type="button" label="Proceed" class="p-button-rounded p-button-lg" style="font-size: 18px;" (click)="callPaymentSuccess('null','null')"></button>
                                </div>
                                
                                <!-- <div class="p-col-12 text-center" *ngIf="currentPosition == 'review_pay'">
                                    <span style="font-size: 1.5rem; ">Please select payment</span>
                                </div> -->
                                <div class="p-col-12 center">
                                    <div *ngIf="currentPosition == 'review_pay' && SelectedpaymentGateWay == 'paypal' && validateUpgradePackageError">
                                        <div *ngIf="currentPosition!='review_pay' || amount=='0.0'; else paypal" >
                                            <button pButton pRipple type="button" label="Proceed" class="p-button-rounded p-button-lg"
                                                style="font-size: 18px;" (click)="callPaymentSuccess('null','null')"></button>
                                        </div>
                                        <ng-template #paypal>
                                            <ngx-paypal [config]="payPalConfig"></ngx-paypal>
                                        </ng-template>
                                    </div>
                                    <div *ngIf="currentPosition == 'review_pay' && SelectedpaymentGateWay == 'squareupcard' ">
                                        <div *ngIf="currentPosition!='review_pay' || amount=='0.0'; else squareupcard">
                                        </div>
                                        <ng-template #squareupcard>
                                            <form id="payment-form" style=" padding-top: 10%; ">
                                                <div class="form-group">
                                                    <div id="card-container"></div>
                                                    <button style="cursor: pointer;background-color: black;color: white;" type="button" id="card-button" class="btn center btn-lg btn-block"><i class="fa fa-credit-card" style=" padding-right: 5px; "></i>Proceed with card</button>
                                                </div>
                                            </form>
                                        </ng-template>                          
                                    </div>

                                    <div *ngIf="currentPosition == 'review_pay' && SelectedpaymentGateWay == 'squareupother' ">
                                        <div *ngIf="currentPosition!='review_pay' || amount=='0.0'; else squareupother">
                                        </div>
                                        <ng-template #squareupother>
                                            <form id="payment-form"  style=" padding-top: 10%; ">
                                                <div class="row p-col-12">
                                                    <div class="p-col-4">
                                                        <img src="assets/images/GooglePay.jpg" style=" height: 25%; ">
                                                        <div id="google-pay-button" style=" height: 52px; "></div>
                                                    </div>
                                                    <!--  <div class="p-col-4">
                                                        <div id="apple-pay-button"></div>
                                                    </div> -->
                                                    <div  class="p-col-4 text-center">
                                                        <img src="assets/images/Bank_ACH_Icon.jpg" style=" height: 25%; ">
                                                        <button class="btn" id="ach-button" style="height: 52px;padding-left: 35px;padding-right: 35px;font-size: large;background-color: black;color: white;"><i class="fa fa-bank" style=" padding-right: 5px; "></i>Pay ACH Bank Account</button>
                                                    </div>
                                                    <div  class="p-col-4">
                                                        <img src="assets/images/CashApp_Icon.jpg" style=" height: 25%; ">
                                                        <div id="cash-app-pay" style=" height: 44px; "></div>
                                                    </div>
                                                </div>
                                            </form>
                                        </ng-template>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div>
    <ng-template pTemplate="footer">
        <div *ngIf="isLoading" class="progressLayout">
            <div class="progressContainer">
                <p-progressSpinner [style]="{ width: '40px', height: '40px' }" strokeWidth="3">
                </p-progressSpinner>
            </div>
        </div>
        <div class="p-p-1" [ngClass]="currentPosition=='review_pay'?'show':'hide'">
            <div class="p-grid">
                <div class="p-col p-d-flex p-ai-end" style="text-align: start;">
                    <button *ngIf="from =='subs&pay'" pButton pRipple type="button" icon="pi pi-angle-left" label="Back"
                        class="p-button-secondary p-button-text" (click)="goToNextAndPrevious('package')"></button>
                    <button *ngIf="from !='subs&pay'" pButton pRipple type="button" icon="pi pi-angle-left" label="Back"
                        class="p-button-secondary p-button-text"
                        (click)="backToReview()"></button>
                </div>
                <div class="p-col p-pt-2 p-pb-0">
                </div>
                <div class="p-col"></div>
            </div>
        </div>
    </ng-template>
</p-dialog>