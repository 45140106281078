import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { ICreateOrderRequest, IPayPalConfig } from "ngx-paypal";
import { SubscriptionService } from "../services/subscription/subscription.service";

@Component({
  selector: "app-subscription",
  templateUrl: "./subscription.component.html",
  styleUrls: ["./subscription.component.css"],
})
export class SubscriptionComponent implements OnInit {
  subscriptionList: any[] = [];
  isLoading: boolean = false;
  disabled: boolean = false;
  constructor(
    public router: Router,
    public subscriptionService: SubscriptionService
  ) { }

  ngOnInit(): void {
    this.isLoading = true;
    this.listSubscription();
    this.isLoading = false;
  }

  listSubscription() {
    this.subscriptionService.listSubscription().subscribe(
      (response) => {
        var json = JSON.parse(JSON.stringify(response));
        console.log('Subscription list response -->' + JSON.stringify(response))
        this.subscriptionList = json.data;
      },
      (error) => { }
    );
  }

  onClickBuy(amount: string, name: string, subscriptionId: string) {
    this.subscriptionService.amount = amount;
    this.subscriptionService.subscriptionType = name;
    this.subscriptionService.subscriptionId = subscriptionId;
    this.router.navigate(["/paypal"]);
  }
}
