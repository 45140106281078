import { Component, HostListener, OnInit } from '@angular/core';
import { ConfirmationService, MenuItem, MessageService } from 'primeng/api';
import { AppSettings } from '../Constant/AppSettings';
import { BusinessHoursService } from '../services/business-hours/business-hours.service';
import { UtilsService } from '../services/utils/utils.service';
import { CommonFunctions } from '../Utilities/CommonFunctions';
import { BusinessHolidaysService } from '../services/business-holidays/business-holidays.service';
import { Dialog } from 'primeng/dialog';
import { DatePipe } from '@angular/common';


interface Data {
  name: string,
  code: string
}

interface YearLabel {
  label: string;
}

@Component({
  selector: 'app-business-hours',
  templateUrl: './business-hours.component.html',
  styleUrls: ['./business-hours.component.css'],
})
export class BusinessHoursComponent implements OnInit {
  timeoutError = '';
  isLoading: boolean = false;
  disabled: boolean = false;
  isFormValid = false;
  items: MenuItem[] = [{ label: 'Business Hours & Holidays' }];
  home: MenuItem = { icon: 'pi pi-home', routerLink: '/home' };
  businessHoursList: any[] = [];
  selectedValues: string[] = [];
  timeList: Data[] = [];
  selectedTime?: Data;
  activeTab: number = 0;
  uniqueYears:any = [];
  selectedYear!: { year: number; };
  businessHolidayList:any = [];
  businessHolidayFilter:any = [];
  selectedHolidaysValues: string[] = [];
  unSelectedHolidaysValues: string[] = [];
  allCheckboxValues: string[] = [];
  detectChanges:Boolean = false;
  displayAddEditModal: boolean = false;
  id: any = '';
  holidayName: string = '';
  holidayDate!: Date;
  roleDialogMax: string = '278px';
  isMaximizeAddRole: boolean = false;
  public innerWidth: any;
  invalidDates: any[] = [];

  constructor(
    public utils: UtilsService,
    private messageService: MessageService,
    private businessHoursService: BusinessHoursService,
    public businessHolidaysService: BusinessHolidaysService,
    private confirmationService: ConfirmationService,
    private datePipe: DatePipe
  ) { }

  ngOnInit(): void {
    this.timeList = [
      { name: '12:00 AM', code: '00:00' },
      { name: '01:00 AM', code: '01:00' },
      { name: '02:00 AM', code: '02:00' },
      { name: '03:00 AM', code: '03:00' },
      { name: '04:00 AM', code: '04:00' },
      { name: '05:00 AM', code: '05:00' },
      { name: '06:00 AM', code: '06:00' },
      { name: '07:00 AM', code: '07:00' },
      { name: '08:00 AM', code: '08:00' },
      { name: '09:00 AM', code: '09:00' },
      { name: '10:00 AM', code: '10:00' },
      { name: '11:00 AM', code: '11:00' },
      { name: '12:00 PM', code: '12:00' },
      { name: '01:00 PM', code: '13:00' },
      { name: '02:00 PM', code: '14:00' },
      { name: '03:00 PM', code: '15:00' },
      { name: '04:00 PM', code: '16:00' },
      { name: '05:00 PM', code: '17:00' },
      { name: '06:00 PM', code: '18:00' },
      { name: '07:00 PM', code: '19:00' },
      { name: '08:00 PM', code: '20:00' },
      { name: '09:00 PM', code: '21:00' },
      { name: '10:00 PM', code: '22:00' },
      { name: '11:00 PM', code: '23:00' },
    ];
    this.getBusinessHoursList();
    // this.editBusinessHoursList();
    this.getBusinessHolidaysList();
  }

  onActionChange(event: any, id: string) {
    console.log('event:' + JSON.stringify(event) + ', id:' + id)
    var elementPos = this.businessHoursList.map(
      function (x) {
        return x.name;
      }).indexOf(id);
    var objectFound = this.businessHoursList[elementPos];
    // console.log(this.businessHoursList[elementPos].startTime, this.businessHoursList[elementPos].endTime)
    this.getBusinessHourTimeSlots(elementPos, this.businessHoursList[elementPos].startTime, this.businessHoursList[elementPos].endTime)
  }

  getPositionOfTime(id: string) {
    var elementPos = this.timeList.map(
      function (x) {
        return x.code;
      }).indexOf(id);
    return elementPos
  }

  getBusinessHoursList() {
    this.showProgressBar();
    this.businessHoursService.getListBusinessHours().subscribe(
      (response) => {
        var json = JSON.parse(JSON.stringify(response));
        console.log('Business Hours response --> ' + JSON.stringify(json));
        if (json.response.status == 'SUCCESS') {
          let i = 0
          json.data.forEach((element: any) => {
            let possibleTimeslotsTempList: any[] = []
            element.possibleTimeslots.forEach((element: any) => {
              possibleTimeslotsTempList.push({ name: element.startTimeSlotDisplay + ' - ' + element.endTimeSlotDisplay, code: element.startTimeSlot + '-' + element.endTimeSlot });
            });
            let offHourTemp: any[] = [];
            element.offHour.forEach((j: any) => {
              offHourTemp.push({ name: j.startTimeDisplay + ' - ' + j.endTimeDisplay, code: j.startTime + '-' + j.endTime })
            });
            this.businessHoursList.push(
              {
                id: element.id,
                name: element.nameOfDay,
                possibleTimeslots: possibleTimeslotsTempList,
                selectedOffHour: offHourTemp,
                startTime: this.timeList[this.getPositionOfTime(element.startTime)],
                endTime: this.timeList[this.getPositionOfTime(element.endTime)],
                hasOffHour: element.hasOffHour,
                isActive: element.isActive
              }
            );
            // this.getBusinessHourTimeSlots(i, this.timeList[this.getPositionOfTime(element.startTime)], this.timeList[this.getPositionOfTime(element.endTime)])
            i = i + 1;
          });
          console.log('data:' + JSON.stringify(this.businessHoursList))
        } else {
          this.showErrorMessage(json.response.displayMessage)
        }
        this.hideProgressBar()
      }, (error) => {
        console.log(error);
        this.showErrorMessage(AppSettings.ERROR_MSG)
        this.hideProgressBar();
      })
  }

  editBusinessHoursList() {
    this.showProgressBar();
    let temp: any[] = []
    this.businessHoursList.forEach(element => {
      let offHourTemp: any[] = [];
      element.selectedOffHour.forEach((j: any) => {
        offHourTemp.push({
          businessId: CommonFunctions.getBusinessId(),
          startTime: j.code?.split('-')[0],
          endTime: j.code?.split('-')[1]
        })
      });

      temp.push({
        id: element.id,
        name: element.name,
        offHour: offHourTemp,
        startTime: element.startTime.code,
        endTime: element.endTime.code,
        hasOffHour: element.hasOffHour,
        isActive: element.isActive
      })
    });
    var data = { data: JSON.parse(JSON.stringify(temp)) };
    console.log('Business Hours data --> ' + JSON.stringify(temp));
    this.businessHoursService.editBusinessHours(data).subscribe(
      (response) => {
        var json = JSON.parse(JSON.stringify(response));
        console.log('Business Hours response --> ' + JSON.stringify(json));
        if (json.response.status == 'SUCCESS') {
          this.showSuccessMessage(json.response.displayMessage)
        } else {
          this.showErrorMessage(json.response.displayMessage)
        }
        this.hideProgressBar();
      }, (error) => {
        console.log(error);
        this.showErrorMessage(AppSettings.ERROR_MSG)
        this.hideProgressBar();
      })
  }

  getBusinessHourTimeSlots(position: number, startTime: any, endTime: any) {
    this.showProgressBar();
    this.businessHoursService.getBusinessHoursTimeSlots(startTime.code, endTime.code).subscribe(
      (response) => {
        var json = JSON.parse(JSON.stringify(response));
        console.log('Business Hours response --> ' + JSON.stringify(json));
        if (json.response.status == 'SUCCESS') {
          // this.businessHoursList = json.data;
          let tempList: any[] = []
          json.data.forEach((element: any) => {
            tempList.push({ name: element.startTimeSlotDisplay + ' - ' + element.endTimeSlotDisplay, code: element.startTimeSlot + '-' + element.endTimeSlot });
          });
          this.businessHoursList[position].possibleTimeslots = tempList;
        } else {
          this.showErrorMessage(json.response.displayMessage)
        }
        this.hideProgressBar();
      }, (error) => {
        console.log(error);
        this.showErrorMessage(AppSettings.ERROR_MSG)
        this.hideProgressBar();
      })
  }

  showErrorMessage(msg: string) {
    this.messageService.add({
      key: 'br',
      severity: 'error',
      detail: msg,
    });
  }

  showSuccessMessage(msg: string) {
    this.messageService.add({
      key: 'br',
      severity: 'success',
      detail: msg,
    });
  }

  hideProgressBar() {
    this.isLoading = false;
    this.disabled = false;
  }

  showProgressBar() {
    this.messageService.clear();
    this.isLoading = true;
    this.disabled = true;
  }
  get state(): string {
    return this.utils.state;
  }

  customGraphicTypeChangeEvent(type: any){
    this.activeTab = type.index;
  }

  getBusinessHolidaysList() {
    this.businessHolidaysService.getListBusinessHolidays().subscribe(
      (response) => {
        var json = JSON.parse(JSON.stringify(response));
        console.log('Business Holidays response --> ' + JSON.stringify(json));
        if (json.response.status == 'SUCCESS') {
          // json.data.forEach((ele:any)=>{
          //   const date = new Date(ele.date + 'T00:00:00Z'); 
          //   ele.date = this.datePipe.transform(date, 'yyyy-MM-dd HH:mm:ss', 'UTC');
          // });
          this.uniqueYears = this.extractUniqueYears(json.data);
          this.businessHolidayList = json.data;
          this.filterDatesByYear(json.data, this.uniqueYears[0].year);
        }
    });
  }

  extractUniqueYears(dates: any[]): YearLabel[] {
    const uniqueYearsSet = new Set<number>();
    dates.forEach(dateObj => {
      const year = new Date(dateObj.date).getFullYear();
      uniqueYearsSet.add(year);
    });

    // Create an array of objects containing year and label
    return Array.from(uniqueYearsSet).map(year => ({ year, label: year.toString() }));
  }

  filterDatesByYear(dates: any[], year: number) {
    if(this.detectChanges){
      this.confirmDialogYearChange(dates,year);
      return;
    }
    this.businessHolidayFilter = dates.filter(dateObj => new Date(dateObj.date).getFullYear() === year);
    this.businessHolidayFilter.forEach((element:any) => {
      this.allCheckboxValues.push(String(element.id));
    });
    this.checkHolidaysConflict();
  }

  confirmDialogYearChange(dates: any[], year: number) {
    this.messageService.clear();
    this.confirmationService.confirm({
      message: 'Changes are detected please save before changing the years.',
      header: 'Unsave Data',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.detectChanges = false;
        this.filterDatesByYear(dates,year);
      }
    });
  }

  checkHolidaysConflict(){
    this.businessHolidaysService.getApprovedHolidaysList().subscribe(
      (response) => {
        var json = JSON.parse(JSON.stringify(response));
        console.log('Business Holidays response --> ' + JSON.stringify(json));
        if (json.response.status == 'SUCCESS') {
          this.selectedHolidaysValues = this.businessHolidayFilter.map((holiday: { id: any; }) => String(holiday.id));
          if(json.data != null){
            json.data.forEach((element:any) => {
              this.selectedHolidaysValues = this.selectedHolidaysValues.filter(item => item !== element.holidayId.toString());
              this.unSelectedHolidaysValues.push(element.holidayId);
            });
          }       
        }
        console.log(this.selectedHolidaysValues);
    });
  }

  confimSaveUnCheckData(){
    this.messageService.clear();
    this.confirmationService.confirm({
      message: 'Do you want to save this changes?',
      header: 'Save Confirmation',
      icon: 'pi pi-save',
      accept: () => {
        this.detectChanges = false;
        this.saveUnCheckData();
      }
    });
  }

  saveUnCheckData(){
    this.showProgressBar();
    this.unSelectedHolidaysValues = Array.from(new Set(this.unSelectedHolidaysValues));
    this.businessHolidaysService.UpdateBusinessHolidays(this.unSelectedHolidaysValues.toString() ? this.unSelectedHolidaysValues.toString() : null).subscribe(
      (response) => {
        var json = JSON.parse(JSON.stringify(response));
        console.log('Business holidays saved response --> ' + JSON.stringify(json));
        if (json.response.status == 'SUCCESS') {
          this.showSuccessMessage(json.response.message)
        }else{
          this.showErrorMessage(json.response.displayMessage)
        }
        this.hideProgressBar();
    });
  }

  confirmDeleteDialog(customHolidayId: any) {
    this.messageService.clear();
    this.confirmationService.confirm({
      message: 'Do you want to delete this custom holiday?',
      header: 'Delete Confirmation',
      icon: 'pi pi-trash',
      accept: () => {
        this.removeCustomHolidays(customHolidayId);
      }
    });
  }

  removeCustomHolidays(customHolidayId:any){
    this.businessHolidaysService.removeCustomHolidays(customHolidayId).subscribe(
      (response) => {
        var json = JSON.parse(JSON.stringify(response));
        if (json.response.status == 'SUCCESS') {
          this.showSuccessMessage(json.response.message);
          this.getBusinessHolidaysList();

          this.filterDatesByYear(this.businessHolidayList, this.uniqueYears[0].year);
        } else {
          this.showErrorMessage(json.response.displayMessage)
        }
        this.hideProgressBar();
      },
      (error) => {
        this.showErrorMessage(AppSettings.ERROR_MSG)
        this.hideProgressBar();
      }); 
  }

  onCheckboxChange(event: any, id: any){
    this.detectChanges = true;
    if(!event.checked){      
      this.unSelectedHolidaysValues.push(id.toString());
    }
    const uncheckedValues = this.allCheckboxValues.filter(value => !this.selectedHolidaysValues.includes(value));    

    this.unSelectedHolidaysValues = uncheckedValues;
  }

  showHideModalDialog(type: string) {
    switch (type) {
      case 'edit_role':
        this.clearFormData();
        this.displayAddEditModal = !this.displayAddEditModal;
        break;
      case 'add_holiday':
        this.clearFormData();
        this.displayAddEditModal = !this.displayAddEditModal;
        break;
    }
  }

  clearFormData() {
    this.messageService.clear('errMsg');
  }

  addHoliday(){
    this.messageService.clear();    
    if(this.holidayName == '' || this.holidayName == undefined){
      this.showErrorMessage("Name is required.");
      return;
    }

    this.showProgressBar();
    var Data = {
      name: this.holidayName,
      date: this.datePipe.transform(this.holidayDate, 'yyyy-MM-ddT00:00:00')
    }

    var data = { data: JSON.parse(JSON.stringify(Data)) };
    this.businessHolidaysService.addCustomHolidays(data).subscribe(
      (response) => {
        var json = JSON.parse(JSON.stringify(response));
        if (json.response.status == 'SUCCESS') {
          this.showSuccessMessage(json.response.message);
          this.displayAddEditModal = !this.displayAddEditModal;
          this.getBusinessHolidaysList();
        } else {
          this.showErrorMessage(json.response.displayMessage)
        }
        this.hideProgressBar();
      },
      (error) => {
        this.showErrorMessage(AppSettings.ERROR_MSG)
        this.hideProgressBar();
      }
      );    
  }

  onDateChange(event: any, type: string) {

  }

   // Only AlphaNumeric with Some Characters [-_ ]
   keyPressAlphaNumericWithCharacters(event: any) {

    var inp = String.fromCharCode(event.keyCode);
    // Allow numbers, alpahbets, space, underscore
    if (/^[a-zA-Z0-9 ]$/.test(inp)) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.innerWidth = window.innerWidth;
  }

  showDialogMaximized(event: any, dialog: Dialog) {
    if (this.innerWidth <= 640) {
      dialog.maximized = true;
    }
  }
}
