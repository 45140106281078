import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { timeout } from 'rxjs/operators';
import { CommonFunctions } from 'src/app/Utilities/CommonFunctions';
import { environment } from 'src/environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class ContentService {
  constructor(
    private http: HttpClient
  ) { }

  listContent(page_number: string, contentType: string, keyName: string) {
    const url = environment.ADMIN_API_PORTAL_URL + '/api/anonymous/content?page=' + page_number + '&contentType=' + contentType + "&search=" +
      keyName;
    const TOKEN = CommonFunctions.getToken();
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + TOKEN,
        'Content-Type': 'application/json',
      }),
    };
    return this.http.get(url, httpOptions).pipe(timeout(60000));
  }


  getContent(content_id: string) {
    const url = environment.ADMIN_API_PORTAL_URL + '/api/anonymous/content/' + content_id;
    const TOKEN = CommonFunctions.getToken();
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + TOKEN,
        'Content-Type': 'application/json',
      }),
    };
    return this.http.get(url, httpOptions).pipe(timeout(60000));
  }


  addContent(data: any) {
    // console.log('Edit Message Service Data ---> ' + JSON.stringify(data));
    const url = environment.ADMIN_API_PORTAL_URL + '/api/anonymous/content/add';
    const TOKEN = CommonFunctions.getToken();
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + TOKEN,
        'Content-Type': 'application/json',
      }),
    };
    return this.http.post(url, data, httpOptions).pipe(timeout(60000));
  }

  editContent(data: any, content_id: string) {
    // console.log('Edit Message Service Data ---> ' + JSON.stringify(data));
    const url = environment.ADMIN_API_PORTAL_URL + '/api/anonymous/content/' + content_id + '/update';
    const TOKEN = CommonFunctions.getToken();
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + TOKEN,
        'Content-Type': 'application/json',
      }),
    };
    return this.http.post(url, data, httpOptions).pipe(timeout(60000));
  }

  deleteContent(content_id: string) {
    const url = environment.ADMIN_API_PORTAL_URL + '/api/anonymous/content/' + content_id + '/remove';
    const TOKEN = CommonFunctions.getToken();
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + TOKEN,
        'Content-Type': 'application/json',
      }),
    };
    return this.http.post(url, null, httpOptions).pipe(timeout(60000));
  }
}
