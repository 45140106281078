<!-- <div class="p-d-flex p-ai-center">
            <a href="javascript:void(0)" (click)="changeState()">
                <i class="ti-align-justify" style="font-size: 17px;margin-left: 1rem; color: #007aff;"></i>
            </a>
            <a [routerLink]="['/home']" routerLinkActive="router-link-active"><img
                    src="assets/images/acme-industries-logo.png" class="image_layout" alt="Chatstasy" /> </a>
            <p-messages *ngIf="noInternetConnection" class="alignCenter">
                <ng-template pTemplate>
                    <i class="pi pi-info-circle" style="font-size: 2rem;color: red;"></i>
                    <div class="p-ml-2" style="color: red;">No Internet Connection</div>
                </ng-template>
            </p-messages>

        </div> -->

<header class="navbar navbar-expand-lg navbar-light navbar-static-top">
    <div class="navbar-header nav_bar">
        <a href="javascript:void(0)" (click)="changeMobileMenuState()"
            class="sidebar-mobile-toggler float-left d-lg-none" style="color: #0b7ad1;"><i class="ti-align-justify"></i>
        </a>
        <a class="navbar-brand d-none d-lg-block d-xl-block" href="javascript:void(0)" style="padding: 0 0 0 15px;">
            <div class="p-d-flex flex-row align-items-center">
                <img [src]="businessLogoUrl" style="height: 55px;border-radius: 4px;max-width: 100px;
                object-fit: contain;margin-top: 6px;" alt="Chatstasy" (error)="fileFailedToLoadBusinessLogo($event)">
                <!-- <span class="badge_status badge-success p-ml-2">Online</span> -->
                <div class="p-d-flex p-flex-column p-ai-center">
                    <!-- <span class="badge_status p-mt-1 p-ml-2" style="font-weight: 600;white-space: break-spaces;
                    max-width: 85px;color: #6c757d;">{{ bussinesInfo?.name | uppercase }}</span> -->
                    <span *ngIf="businessState=='offline'" class="badge_status badge-danger p-ml-2">Offline</span>
                    <span class="badge_status p-mt-1 p-ml-2" style="font-weight: 600;white-space: break-spaces;
                    max-width: 85px;color: #6c757d;">{{bussinesInfo?.subscriptionType}}</span>
                    
                    <span *ngIf="!bussinesInfo?.operatingStatus" class="badge_status p-mt-1 p-ml-2" style="font-weight: 600;white-space: break-spaces;max-width: 85px;color: #ffffff;background-color: #dc3545;">CLOSED</span>
                    
                    <span *ngIf="bussinesInfo?.operatingStatus" class="badge_status p-mt-1 p-ml-2" style="font-weight: 600;white-space: break-spaces; max-width: 85px;color: #ffffff; background-color: #689F38;">OPEN</span>
                </div>
            </div>


        </a>
        <a href="javascript:void(0)" (click)="changeState()"
            class="sidebar-toggler float-right d-none d-lg-block d-xl-block" style="color: #0b7ad1;"><i
                class="ti-align-justify"></i>
        </a>

    </div>
    <div class="container-navbar-right" style="padding-right: 10px;">
        <ul class="navbar-right">
            <li class="dropdown d-none d-lg-block d-xl-block">
                <!-- *ngIf="noInternetConnection" -->
                <p-messages *ngIf="noInternetConnection" class="alignCenter">
                    <ng-template pTemplate>
                        <i class="pi pi-info-circle" style="color: #dc3545;"></i>
                        <div class="p-ml-2 d-none d-md-block d-lg-block d-xl-block" style="color: #dc3545;">No Internet
                            Connection</div>
                    </ng-template>
                </p-messages>
            </li>
            <li [ngStyle]="{'width': '430px', 'font-size': bussinesInfo?.name.length < 38 ? '20px': '15px', 'color': '#0b7ad1', 'margin-top' : '15px'}" class="text-center d-none d-sm-block d-sm-none d-md-block d-md-none d-lg-block">
                <span>
                    {{ bussinesInfo?.name | uppercase }}
                </span>
            </li>
            <li class="dropdown d-none d-lg-block d-xl-block">
            </li>
            <li class="dropdown">
                <a href="javascript:void(0)" class="dropdown-toggle message_credit"
                    (click)="changeCreditMsgState('show')">
                    <span style="font-size: 17px;"
                        [style.color]="messageCreditCount <= 10 ? '#dc3545':''">{{messageCreditCount}}</span>
                    <span class="text-bold d-none d-md-block d-lg-block d-xl-block">Message credits</span>
                </a>
                <!-- <div role="menu" class="dropdown-menu dropdown-menu-right dropdown-light" [ngClass]="getCreditState()">
                    <button type="button" class="close" style="padding: 0px 10px;"
                        (click)="changeCreditMsgState('hide')"><span>×</span></button>
                    <div class="p-p-3">
                        You have no message credit left.
                        <button pButton label="Buy Now" class="p-button-link p-button-sm"></button>
                    </div>
                </div> -->
            </li>
            <li class="dropdown">
                <a href="javascript:void(0)" class="dropdown-toggle" style="opacity: 1;"
                    (click)="openClientCommunication()">
                    <!-- <span class="dot-badge partition-red animated infinite pulse"></span> -->
                    <span *ngIf="messageCount != '0'" class="badge badge-pill badge-danger">{{messageCount}}</span>
                    <i style="color: #0b7ad1;" class="ti-comment animated bounce"></i>
                    <span class="d-none d-md-block d-lg-block d-xl-block">MESSAGES</span>
                </a>
            </li>
            <li class="dropdown">
                <a href="javascript:void(0)" (click)="setIsVisibleSidebar(true)" class="dropdown-toggle"
                    style="opacity: 1;">
                    <!-- <span class="dot-badge partition-red animated infinite pulse"></span> -->
                    <span *ngIf="notificationCount != '0'"
                        class="badge badge-pill badge-danger">{{notificationCount}}</span>
                    <i style="color: #0b7ad1;" class="ti-bell animated bounce"></i>
                    <span class="d-none d-md-block d-lg-block d-xl-block">Notification</span>
                </a>
            </li>
            <li class="dropdown current-user ">
                <a href="javascript:void(0)" (click)="menu.toggle($event)" class="dropdown-toggle"
                    data-toggle="dropdown">
                    <img [src]="profileUrl" [alt]="avatarName" (error)="fileFailedToLoad($event)">
                    <!-- <p-avatar *ngIf="imageInputError" class="avatar_style" [label]="avatarName">
                    </p-avatar> -->
                    <span class="username">{{firstName}} {{lastName}} <i class="ti-angle-down"></i></span>
                </a>
            </li>
        </ul>
    </div>

</header>
<!-- Menu -->
<p-menu #menu [popup]="true" [model]="items" [style]="{'width':'230px'}" styleClass="dark-panel">
</p-menu>
<!-- Message Credit -->
<app-renew-modal #renew [displayRenewModal]="displayRenewModal" from="message_credit"
    (hideModalRenewDialog)="hideModalRenewDialog($event)" (isSuccess)='isSuccess($event)'>
</app-renew-modal>

<p-dialog header="Upcoming Plans" [(visible)]="displayModalUpcoming" [modal]="true" [baseZIndex]="10000" [draggable]="false" [resizable]="true" [maximizable]="true" styleClass="p-fluid" [style]="{width: '70vw'}">
    <p-table [columns]="cols" [value]="subscriptionList"styleClass="p-datatable-sm p-datatable-gridlines p-datatable-striped " [autoLayout]='true' [responsive]='true' [rowHover]="true">
        <ng-template pTemplate="header" let-columns>
            <tr style="font-size: small;">
                <th *ngFor="let col of columns">
                    {{col.header}}
                </th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-subs let-columns="columns">
            <tr style="font-size: smaller;">
                <td *ngFor="let col of columns">
                    {{subs[col.field]}}
                </td>
            </tr>
        </ng-template>
    </p-table>
</p-dialog>