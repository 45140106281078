import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ResizeEvent } from 'angular-resizable-element';
import { ConfirmationService, MenuItem, MessageService } from 'primeng/api';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment.prod';
import { AppSettings } from '../Constant/AppSettings';
import { ImageSnippet } from '../Constant/ImageSnippet';
import { ChatRoomService } from '../services/chat-room/chat-room.service';
import { UtilsService } from '../services/utils/utils.service';
import { PhonePreviewComponent } from '../phone-preview/phone-preview.component';

@Component({
  selector: 'app-chat-room-configuration',
  templateUrl: './chat-room-configuration.component.html',
  styleUrls: ['./chat-room-configuration.component.css']
})
export class ChatRoomConfigurationComponent implements OnInit {
  items: MenuItem[] = [{ label: 'Chat Room Configuration' }];
  home: MenuItem = { icon: 'pi pi-home', routerLink: '/home' };
  businessName: string = '';
  phone: string = '';
  backgroundColor: string = '';
  textColor: string = 'whitesmoke';
  isLoading: boolean = false;
  isLoadingUrl: boolean = false;
  disabled: boolean = false;
  disabledPublish: boolean = false
  style: any = {};
  //File upload
  avatarName = '';
  imageInputError: boolean = false;
  profileUrl = '';
  //image cropper
  croppedImageUrl: any = '';
  //Crop Dialog
  displayCropModal: boolean = false
  @ViewChild(PhonePreviewComponent) phonePreviewComponent: PhonePreviewComponent | undefined;
  imageChangedEvent: any = '';
  @ViewChild('imageInput') imageInput!: ElementRef;
  maintainAspectRatio: boolean = false;
  imageFile: any = undefined;
  cropedImage = '';
  displayCropModal2: boolean = false;
  innerWidth: any;
  profileUrlNew = '';
  innerHeight: any;
  customGraphicType: boolean = true;
  headerShow: string = '';
  aspectRatio: number = 4/1;
  currentPosition: string = 'page_one';
  text: string = '';
  
  alignment: string = 'left';
  size: number = 14;
  bold: boolean = false;
  italic: boolean = false;
  font: string = 'Arial';
  underline: boolean = false;

  phonealignment: string = 'left';
  phonesize: number = 14;
  phonebold: boolean = false;
  phoneitalic: boolean = false;
  phonefont: string = 'Arial';
  phoneunderline: boolean = false;
  
  activeTab: number = 0;
  secondLine: string = "";
  secondLineColor: string = '#495057';

  checkCustomType: boolean = true;
  tempImage: string = '';
  croppedImageWidht: number = 0;
  resizeToWidth: number = 0; 
  resizeToHeight: number = 0; 

  constructor(
    private utils: UtilsService,
    private messageService: MessageService,
    private chatRoomService: ChatRoomService,
    private confirmationService: ConfirmationService
  ) { }

  ngOnInit(): void {
    this.getChatRoom()
    this.innerWidth = window.innerWidth;
    this.innerHeight = window.innerHeight+'px';
    // this.headerShow = 'liveChange';
  }

  ngAfterViewInit() {
    this.phonePreviewComponent?.childFunction("NEW_APPOINTMENT");
  }

  editChatRoom(businessInfoForm: NgForm) {
    console.log(this.backgroundColor)
    this.showProgressBar();
    if (businessInfoForm.value.color === '' || businessInfoForm.value.color === 'null') {
      this.showErrorBannerMsg('Backgroud color Field is empty.')
      this.hideProgressBar();
      return;
    }

    console.log(this.croppedImageUrl);
    console.log(this.croppedImageUrl == '');
    console.log(this.customGraphicType);

    if(this.croppedImageUrl == '' && this.customGraphicType == false){
      this.showErrorBannerMsg('Image is empty.')
      this.hideProgressBar();
      return;
    }


    let setNameCustomGraphics = null;
    let setNumberCustomGraphics = null;
    
    if(this.customGraphicType == true){
      setNameCustomGraphics = {};
      setNameCustomGraphics = {
        alignment: this.alignment,
        size: this.size,
        bold: this.bold,
        italic: this.italic,
        font: this.font,
        underline: this.underline
      }

      setNameCustomGraphics = JSON.stringify(setNameCustomGraphics)
      
      setNumberCustomGraphics = {};
      setNumberCustomGraphics = {
        alignment: this.phonealignment,
        size: this.phonesize,
        bold: this.phonebold,
        italic: this.phoneitalic,
        font: this.phonefont,
        color: this.secondLineColor,
        underline:this.phoneunderline
      }

      setNumberCustomGraphics = JSON.stringify(setNumberCustomGraphics)
    }

    let gType = true;
    if(this.customGraphicType == false){
      gType = false;
    }
    let mob = '+1' + this.phone;
    const Data = {
      name: this.businessName,
      phoneNumber: mob,
      backgroundColor: this.backgroundColor,
      textColor: this.textColor,
      width: this.style.width,
      height: this.style.height,
      customGraphicType: gType,
      businessNameGraphicProperty: setNameCustomGraphics,
      businessNumberGraphicProperty: setNumberCustomGraphics,
      secondLine: this.secondLine
    }

    var data = { data: JSON.parse(JSON.stringify(Data)) };
    console.log('Data --->> ' + JSON.stringify(data));
    this.chatRoomService.editChatRoom(data).subscribe(
      (response) => {
        var json = JSON.parse(JSON.stringify(response));
        console.log('Edit Chat room Post Response --->> ' + JSON.stringify(json));
        console.log('Status --->> ' + json.response.status);
        if (json.response.status == 'SUCCESS') {
          this.showSuccessMessage(json.response.status, json.response.message)
          // if (this.croppedImageUrl != '' && (this.croppedImageWidht == 237 && !this.customGraphicType) || (this.croppedImageWidht != 237 && this.customGraphicType)) {
            if(this.croppedImageUrl != '' && this.profileUrl != this.tempImage){
              this.uploadChatButtonLogo();
            }
          // }
          this.disabledPublish = json.data.isPublished
          this.currentPosition = "page_one";
          this.checkCustomType = gType;
        } else {
          this.showErrorMessage(json.response.displayMessage)
        }
        this.hideProgressBar();
      },
      (error) => {
        console.log(error);
        this.showErrorMessage(AppSettings.ERROR_MSG)
        this.hideProgressBar();
      }
    );
  }

  uploadChatButtonLogo() {
    this.showProgressBar()
    this.chatRoomService.uploadChatRoomLogo(this.croppedImageUrl).subscribe(
      (res) => {
        console.log(res);
        var json = JSON.parse(JSON.stringify(res));
        if (json.response.status == 'SUCCESS') {
          this.imageInputError = false;
          this.showSuccessMessage(json.response.status, json.response.message)
          this.getChatRoom();
        } else {
          this.showErrorMessage(json.response.displayMessage)
        }
        this.hideProgressBar()
      },
      (err) => {
        console.log(err);
        this.showErrorMessage(AppSettings.ERROR_MSG)
      }
    );
  }

  getChatRoom() {
    this.showProgressBar()
    this.chatRoomService.getChatRoom().subscribe(
      (response) => {
        var json = JSON.parse(JSON.stringify(response));
        console.log('Get chat room response --> ' + JSON.stringify(json.data));
        if (json.response.status == 'SUCCESS') {
          this.setData(json.data)
        } else {
          this.showErrorMessage(json.response.displayMessage)
        }
        this.hideProgressBar()
      },
      (error) => {
        console.log(error);
        this.showErrorMessage(AppSettings.ERROR_MSG)
        this.hideProgressBar();
      }
    );
  }

  resetChatRoom() {
    this.ngOnInit();
    // this.showProgressBar()
    // this.chatRoomService.resetChatRoom().subscribe(
    //   (response) => {
    //     var json = JSON.parse(JSON.stringify(response));
    //     console.log('Reset Chat Room response --> ' + JSON.stringify(json));
    //     if (json.response.status == 'SUCCESS') {
    //       this.showSuccessMessage(json.response.status, json.response.displayMessage)
    //       this.setData(json.data)
    //       this.currentPosition = "page_one";
    //     } else {
    //       this.showErrorMessage(json.response.displayMessage)
    //     }
    //     this.hideProgressBar()
    //   },
    //   (error) => {
    //     console.log(error);
    //     this.showErrorMessage(AppSettings.ERROR_MSG)
    //     this.hideProgressBar();
    //   }
    // );
  }

  deleteImageLogo() {
    this.showProgressBar()
    this.chatRoomService.deleteImageLogo().subscribe(
      (response) => {
        var json = JSON.parse(JSON.stringify(response));
        console.log('Delete Chat Button Logo response --> ' + JSON.stringify(json));
        if (json.response.status == 'SUCCESS') {
          this.showSuccessMessage(json.response.status, json.response.displayMessage)
          this.getChatRoom()
        } else {
          this.showErrorMessage(json.response.displayMessage)
        }
        this.hideProgressBar()
      },
      (error) => {
        console.log(error);
        this.showErrorMessage(AppSettings.ERROR_MSG)
        this.hideProgressBar();
      }
    );
  }

  publishChatRoom() {
    this.showProgressBar()
    this.chatRoomService.publishChatRoom().subscribe(
      (response) => {
        var json = JSON.parse(JSON.stringify(response));
        console.log('Publish Chat Room response --> ' + JSON.stringify(json));
        if (json.response.status == 'SUCCESS') {
          this.showSuccessMessage(json.response.status, json.response.displayMessage)
          this.getChatRoom()
        } else {
          this.showErrorMessage(json.response.displayMessage)
        }
        this.hideProgressBar()
      },
      (error) => {
        console.log(error);
        this.showErrorMessage(AppSettings.ERROR_MSG)
        this.hideProgressBar();
      }
    );
  }

  confirmPublishDialog() {
    this.messageService.clear();
    this.confirmationService.confirm({
      message: 'Are you sure you want to publish your changes ? Your changes will be visible to your customers in Chatstasy App.',
      header: 'Publish Confirmation',
      icon: 'pi pi-upload',
      accept: () => {
        this.publishChatRoom()
      }
    });
  }

  confirmDeleteDialog() {
    this.messageService.clear();
    this.confirmationService.confirm({
      message: 'Do you want to delete this image ?',
      header: 'Delete Confirmation',
      icon: 'pi pi-trash',
      accept: () => {
        this.fileFailedToLoad({}),
        this.deleteImageLogo();
      }
    });
  }

  setData(data: any) {
    this.businessName = data.name;
    this.phone = data.phoneNumber.replace('+1', '');
    this.imageInputError = false
    this.profileUrl = environment.IMAGE_BASE_URL + data.image;
    this.tempImage = environment.IMAGE_BASE_URL + data.image;
    this.backgroundColor = data.backgroundColor;
    this.textColor = data.textColor;
    this.disabledPublish = data.isPublished
    // this.avatarName = data!.name!.split(' ')[0]!.charAt(0) + json!.data!.name!.split(' ')[1]!.charAt(0);
    this.style.width = data.width;
    this.style.height = data.height;
    if(data.customGraphicType != null){

      this.customGraphicType = data.customGraphicType;
      this.checkCustomType = data.customGraphicType;
      if(this.customGraphicType == false) {
        this.headerShow = 'cropImage';     
        this.activeTab = 0;   
      }else{
        this.headerShow = 'liveChange';
        this.activeTab = 1;
      }
      
    }else{
      this.customGraphicType = false;
      this.headerShow = 'cropImage';
      this.activeTab = 0;
    }
    if(data.businessNameGraphicProperty != null){
      let businessNameProp = JSON.parse(data.businessNameGraphicProperty);
      this.alignment = businessNameProp.alignment;
      this.size = businessNameProp.size;
      this.bold = businessNameProp.bold;
      this.italic = businessNameProp.italic;
      this.font = businessNameProp.font;
      this.underline = businessNameProp.underline;
    }else{
      this.alignment = "left";
      this.size = 14;
      this.bold = false;
      this.italic = false;
      this.font = 'Arial';
      this.underline = false;
    }

    if(data.businessNumberGraphicProperty != null){
      let businessNumberProp = JSON.parse(data.businessNumberGraphicProperty);
      this.phonealignment = businessNumberProp.alignment;
      this.phonesize = businessNumberProp.size;
      this.phonebold = businessNumberProp.bold;
      this.phoneitalic = businessNumberProp.italic;
      this.phonefont = businessNumberProp.font;
      this.secondLineColor = businessNumberProp.color;
      this.phoneunderline = businessNumberProp.underline
    }else{
      this.phonealignment = "left";
      this.phonesize = 14;
      this.phonebold = false;
      this.phoneitalic = false;
      this.phonefont = 'Arial';
      this.secondLineColor = "#495057";
      this.phoneunderline = false;
    }
    this.secondLine = data.secondLine;
  }

  fileFailedToLoad(event: any) {
    console.log(event)
    this.imageInputError = true;
    this.profileUrl = 'assets/images/picture.svg';
    this.profileUrlNew = 'assets/images/picture.svg';
    this.croppedImageUrl = "";
  }

  hideCropModal(displayCropModal: any) {
    if (displayCropModal !== undefined) {
      this.displayCropModal = displayCropModal;
    }

    this.displayCropModal2 = false;
  }

  croppedImage(imageFile: any) {
    if (imageFile !== undefined) {
      this.croppedImageUrl = imageFile?.croppedImageUrl
      this.profileUrl = imageFile?.base64Value;
      this.imageInputError = false;
      this.croppedImageWidht = imageFile.width;
    }
  }

  validate(event: ResizeEvent): boolean {
    const MIN_DIMENSIONS_PX: number = 34;
    if (
      event.rectangle.width &&
      event.rectangle.height &&
      (event.rectangle.width < MIN_DIMENSIONS_PX ||
        event.rectangle.height < MIN_DIMENSIONS_PX)
    ) {
      return false;
    }
    return true;
  }

  onResizeEnd(event: ResizeEvent): void {
    this.style = {
      width: `${event.rectangle.width}px`,
      height: `${event.rectangle.height}px`
    };
  }

  validateDigitOnly(event: any) {
    var inp = String.fromCharCode(event.keyCode);
    if (/[0-9]/.test(inp)) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  }

  keyPressAlphaNumericWithCharacters(event: any) {
    var inp = String.fromCharCode(event.keyCode);
    // Allow numbers, alpahbets, space, underscore
    if (/[a-zA-Z0-9]/.test(inp)) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  }

  showErrorBannerMsg(msg: string) {
    this.messageService.add({
      key: 'errMsg',
      severity: 'error',
      detail: msg,
    });
  }

  showErrorMessage(msg: string) {
    this.messageService.add({
      key: 'br',
      severity: 'error',
      detail: msg,
    });
  }

  showSuccessMessage(status: string, msg: string) {
    this.messageService.add({
      key: 'br',
      severity: 'success',
      summary: status,
      detail: msg,
    });
  }

  hideProgressBar() {
    this.isLoading = false;
    this.disabled = false;
  }

  showProgressBar() {
    this.messageService.clear('errMsg');
    this.isLoading = true;
    this.disabled = true;
  }

  get state(): string {
    return this.utils.state;
  }

  // selectFile(imageInput: any): void {
  //   this.messageService.clear('errMsgCropper');
  //   if (this.isFileValid(imageInput)) {
  //     this.imageChangedEvent = imageInput;
  //     this.disabled = false;
  //   } else {
  //     this.disabled = true;
  //     this.imageInput!.nativeElement.value = "";
  //   }
  // }

  // isFileValid(fileInput: any) {
  //   const file = fileInput.target.files[0];
  //   var size = file.size;
  //   var name = file.name;
  //   var extension = name.substring(name.lastIndexOf('.') + 1);
  //   if (extension == 'jpg' || extension == 'jpeg' || extension == 'png') {
  //     if (size > 2000000) {
  //       let finalName = name;
  //       if (name.length > 20) {
  //         finalName = name.substring(0, 20) + '...' + extension;
  //       }
  //       this.showErrorMessage(finalName + ' File should not be more than 2 MB!')
  //       return false
  //     } else {
  //       return true
  //     }
  //   } else {
  //     this.showErrorMessage('Invalid file, please select only jpeg, jpg, png')
  //     return false
  //   }
  // }

  // imageLoaded() {
  //   // show cropper
  // }
  // cropperReady() {
  //   // cropper ready
  // }
  // loadImageFailed() {
  //   // show message
  // }

  // imageCropped(event: ImageCroppedEvent) {
  //   this.croppedImageUrl = CommonFunctions.base64ToFile(
  //     event.base64,
  //     this.imageChangedEvent.target.files[0].name,
  //   )
  //   this.cropedImage = event.base64 || '';
  // }

  clearFormData() {
    this.messageService.clear('errMsgCropper');
    this.imageChangedEvent = null
    this.imageInput!.nativeElement.value = "";
    this.cropedImage = '';
  }

  // setImageToProfile() {
  //   if (this.cropedImage !== undefined) {
  //     this.croppedImageUrl = this.cropedImage;
  //     this.profileUrlNew = this.cropedImage;
  //     this.imageInputError = false;
  //     this.displayCropModal2 = false;
  //   }
  // }

  // showDialogMaximized(event: any, dialog: Dialog) {
  //   if (this.innerWidth <= 640) {
  //     dialog.maximized = true;
  //   }
  // }

  customGraphicTypeChangeEvent(type: any){
    let tabView = false;
    if(type.index == 1){
      tabView = true;
    }
    this.activeTab = type.index;

    this.customGraphicType = tabView;
    if(tabView == true){
      this.headerShow = 'liveChange';
      this.aspectRatio = 0;
    }else if(tabView == false){
      this.headerShow = 'cropImage';
      this.aspectRatio = 4 / 1;
    }
    
    if(this.checkCustomType == false && tabView){
      this.profileUrl = '';
    }else if(this.checkCustomType == false && !tabView){
      this.profileUrl = this.tempImage;
    }else if(this.checkCustomType == true && tabView){
      this.profileUrl = this.tempImage;
    }else if(this.checkCustomType == true && !tabView){
      this.profileUrl = '';
    }
  }

  async goToNextAndPrevious(value: string) {
    switch (value) {
      case 'page_one':
        this.currentPosition = value;
        break;
      case 'page_two':
        this.currentPosition = value;
        break;
      case 'page_three':
        this.currentPosition = value;
        break;
    }
  }

  onBoldChange() {
    this.bold = !this.bold;
  }

  onItalicChange() {
    this.italic = !this.italic;
  }

  onBoldChangePhone() {
    this.phonebold = !this.phonebold;
  }

  onItalicChangePhone() {
    this.phoneitalic = !this.phoneitalic;
  }

  onSizeChange() {
    if (this.size != null) {
      // Convert the number to a string and check its length
      const sizeString = this.size.toString();
      
      // Adjust the maximum length as needed
      const maxLength = 5; // for example
      
      if (sizeString.length > maxLength) {
        // Truncate the input if it exceeds the maximum length
        this.size = +sizeString.slice(0, maxLength);
      }
    }
  }
}
