<div *ngIf="isLoading" class="progressLayout">
    <div class="progressContainer">
        <p-progressSpinner [style]="{ width: '40px', height: '40px' }" strokeWidth="3"></p-progressSpinner>
    </div>
</div>
<div class="container">
    <div class="row">
        <div class="col-md-12">
            <div>
                <div class="p-d-flex p-jc-center p-ai-center p-mb-4">
                    <img src="assets/images/ChatstasyCorpLogoAndSlogan-June2023-1.png" style="width: 30%;margin-top: 15px;" alt="Chatstasy" />
                </div>
            </div>
        </div>
    </div>
      
    <div class="row" style="overflow:auto; height:400px;">
        <div style="width: 100%;">
            <p-card>
                <ng-template pTemplate="header">
                    <div class="text-center" style=" text-transform: uppercase;font-size: 20px;padding-top: 15px; ">
                        {{ getDynamicHeading() }}
                    </div>
                </ng-template>
                <div class="row">
                    <div style=" width: 100%; ">
                        <h2 *ngIf="statusInfo == 'pending'" style=" text-align: center;">Please wait...</h2>
                        <h2 *ngIf="statusInfo == 'success'" style=" text-align: center;">Thank you for confirming</h2>
                        <h2 *ngIf="statusInfo == 'error'" style=" text-align: center;">Unexpected Error Occured. Please Try Again Later</h2>
                    </div>
                </div>
                <div class="row justify-content-center" *ngIf="actionType == 'cancellation'">
                    <div class="col-auto">
                      <button pButton pRipple type="button" label="Cancel Appointment" (click)="cancelAppointmentConfirmDialog()" class="p-button-raised" style="font-size: 12px;"></button>
                    </div>
                    <div class="col-auto">
                      <button pButton pRipple type="button" label="Exit" class="p-button-raised p-button-danger" style="font-size: 12px;"></button>
                    </div>
                </div>                  
                <ng-template pTemplate="footer">
                    <div class="copy_right" style=" text-align: center; ">
                        &copy;
                        <span class="current-year"></span>
                        <span style="font-weight: bold !important;text-transform: uppercase !important;"> Chatstasy</span>.
                        <span>All rights reserved</span>
                    </div>
                </ng-template>
            </p-card>
        </div>
    </div>
</div>
<p-toast position="bottom-right" key="errMsg" [baseZIndex]="10000"></p-toast>
<p-toast position="bottom-right" key="br"></p-toast>

<!-- REMOVE MODAL -->
<p-confirmDialog [baseZIndex]="10000" rejectButtonStyleClass="p-button-text" [style]="{width:'45vw'}">
</p-confirmDialog>