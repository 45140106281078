<div [ngClass]="state=='open' ? 'main_content_open' : 'main_content'">
    <p-scrollPanel [style]="{width: '100%', height: '95vh','margin-top':'7px','padding-bottom':'70px'}" #sc>
        <app-message-credit-warning></app-message-credit-warning>
        <app-twillio-message></app-twillio-message>
        <div class="p-d-flex p-jc-between p-ai-center p-pt-3 p-pb-3">
            <div class="page_title">
                Client Data Management
            </div>
            <div>
                <p-breadcrumb [model]="items" [home]="home"></p-breadcrumb>
            </div>
        </div>
        <div class="p-mb-5">
            <p-card>
                <div class="p-mb-3 toolbar">
                    <div class="p-grid p-ai-center">
                        <div class="p-col p-md-3 p-lg-3 p-fluid" style="padding: 0.5rem !important;">
                            <div class="p-d-flex">
                                <span class="p-input-icon-right">
                                    <i *ngIf="searchInputText !== ''" class="pi pi-times" style="cursor: pointer;"
                                        (click)="resetUserList()"></i>
                                    <input #searchInput pInputText type="text" [(ngModel)]="searchInputText"
                                        placeholder="Search Client"
                                        (input)="onEnterSearchUser($event,searchInput.value)" />
                                </span>
                                <div>
                                    <button pButton pRipple type="button" icon="pi pi-search"
                                        class="p-button-rounded p-button-raised p-button-sm p-ml-2"
                                        [style]="{width:'2.1rem',height:'2.1rem'}"
                                        (click)="listClients(1,searchInput.value)"></button>
                                </div>

                            </div>
                        </div>
                        <div class="p-col p-md-3 p-lg-3 p-fluid" style="padding: 0.5rem !important;">
                            <p-dropdown [options]="clientType" [(ngModel)]="selectedClientType" optionLabel="name"
                                optionValue="value" [showClear]="true" placeholder="Select Client Type"
                                (onChange)="onFilterValueChange($event)">
                            </p-dropdown>
                        </div>
                        <div class="p-col p-md-2 p-lg-2 p-fluid" style="padding: 0.5rem !important;">
                            <p-dropdown [options]="sortBy" [(ngModel)]="selectedSortBy" optionLabel="name"
                                optionValue="value" [showClear]="true" placeholder="Sort By"
                                (onChange)="onFilterValueChange($event)">
                            </p-dropdown>
                        </div>
                        <div class="p-col p-md-4 p-lg-4 p-jc-end d-flex d-lg-flex d-xl-flex"
                            style="padding: 0.5rem !important;">
                            <button *ngIf="getPrivilege('customer_add')" pButton pRipple type="button"
                                label="Add Client" class="p-button-sm p-button-raised"
                                style="font-size: 12px;margin-right: 0.5rem;" icon="pi pi-plus"
                                (click)="showHideModalDialog('add_client')"></button>
                            <button *ngIf="getPrivilege('customer_add')" pButton pRipple type="button"
                                label="Import Client Data" class="p-button-sm p-button-raised" style="font-size: 12px;margin-right: 0.5rem;"
                                icon="pi pi-file-excel" (click)="showHideModalDialog('import_client')"></button>
                                <button *ngIf="getPrivilege('customer_add')" pButton pRipple type="button"
                                label="Export" class="p-button-sm p-button-raised"
                                style="font-size: 12px;" icon="pi pi-file-excel"
                                (click)="exportExcel()"></button>
                        </div>
                    </div>
                </div>
                <p-table #dt1 [columns]="cols" [value]="clients"
                    styleClass="p-datatable-sm p-datatable-gridlines p-datatable-striped " [autoLayout]='true'
                    [responsive]='true' [rowHover]="true">
                    <!-- [globalFilterFields]="['firstName','lastName','mobile','email','country']" -->
                    <ng-template pTemplate="header" let-columns>
                        <tr style="font-size: small;">
                            <th *ngFor="let col of columns">
                                {{col.header}}
                            </th>
                            <th>
                                Client Segments
                            </th>
                            <th *ngIf="getPrivilege('customer_management')">
                                Action
                            </th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-client let-columns="columns">
                        <tr style="font-size: smaller;">
                            <!-- <td *ngFor="let col of columns">
                                        <span class="p-column-title">{{col.header}}</span>
                                        <p-avatar *ngIf="col.header == 'User Type'; else avatar"
                                            [label]="client[col.field].charAt(0)" shape="circle">
                                        </p-avatar>
                                        <ng-template #avatar>
    
                                            {{client[col.field]}}
                                        </ng-template>
                                    </td> -->


                            <td *ngFor="let col of columns">
                                <span class="p-column-title">{{col.header}}</span>

                                <div *ngIf="col.header == 'User Type'; else otherDetails">
                                    <img *ngIf="client[col.field] == 'chatstasy'"
                                        src="assets/images/chatstasy_circle.png" style="width: 22px;height: 22px;"
                                        alt="">
                                    <img *ngIf="client[col.field] == 'nonchatstasy' && client.isSubscribed == true" src="assets/images/SMS_Logo.png" style="width: 22px;height: 22px;"
                                    alt="">

                                    <img *ngIf="client[col.field] == 'nonchatstasy' && client.isSubscribed == false" src="assets/images/QuestionMark.png" style="width: 22px;height: 22px;"
                                    alt="">
                                    <!-- <p-avatar *ngIf="client[col.field] == 'nonchatstasy' && client.isSubscribed == true" label="SMS" shape="circle"
                                        [style]="{width: '22px',height: '22px','font-size':'9px'}">
                                    </p-avatar> -->
                                    <!-- <p-avatar *ngIf="client[col.field] == 'nonchatstasy' && client.isSubscribed == false" label="SMS" shape="circle"
                                        [style]="{width: '22px',height: '22px','font-size':'9px', 'background-color': '#dee2e6', background: 'linear-gradient(to top left, rgba(0,0,0,0) 0%, rgba(0,0,0,0) calc(50% - 0.8px), rgba(0,0,0,1) 50%, rgba(0,0,0,0) calc(50% + 0.8px), rgba(0,0,0,0) 100%), linear-gradient(to top right, rgba(0,0,0,0) 0%, rgba(0,0,0,0) calc(50% - 0.8px), rgba(0,0,0,1) 50%, rgba(0,0,0,0) calc(50% + 0.8px), rgba(0,0,0,0) 100%)'}">
                                    </p-avatar> -->
                                </div>
                                <ng-template #otherDetails>
                                    <div [ngClass]="{'testusermode': col.header == 'User Mode' && client[col.field] == 'TEST', 'liveusermode': col.header == 'User Mode' && client[col.field] == 'LIVE'}">
                                        {{client[col.field]}}
                                    </div>
                                </ng-template>
                            </td>


                            <!-- <td *ngFor="let col of columns">
    
                                    </td> -->

                            <td style="text-align:center">
                                <a [ngClass]="{'' : (client['clientSegments'] != null && client['clientSegments'] != '') == true , disabledClass : (client['clientSegments'] != null && client['clientSegments'] != '') == false}" href="javascript:void(0);" data-toggle="modal" (click)="ShowClientSegmentModal(client['clientSegments'])">
                                    <i class="fa fa-th-large" style="font-size: 0.8rem"></i>
                                </a>
                            </td>
                            <td *ngIf="getPrivilege('customer_management')" class="last_border">
                                <span class="p-column-title">Action</span>
                                <a *ngIf="getPrivilege('customer_management'); else canEdit" href="javascript:void(0);"
                                    data-toggle="modal" (click)="getClient(client, 'edit_client')">
                                    <i class="pi pi-pencil" style="font-size: 0.8rem;color:#007AFF"></i>
                                </a>
                                <ng-template #canEdit style="display:inline-block;">
                                    <div *ngIf="getPrivilege('customer_management')"
                                        pTooltip="You cannot edit this client" tooltipPosition="left"
                                        style="display:inline-block;">
                                        <a href="javascript:void(0);" data-toggle="modal" class="disabledClass">
                                            <i class="pi pi-pencil" style="font-size: 0.8rem"></i>
                                        </a>
                                    </div>
                                </ng-template>
                                &nbsp;
                                <a *ngIf="getPrivilege('customer_management') && client.canDelete; else canDelete"
                                    href="javascript:void(0);" data-toggle="modal"
                                    (click)="confirmDeleteDialog(client)">
                                    <i class="pi pi-trash" style="font-size: 0.8rem;color: #dc3545;"></i>
                                </a>
                                <ng-template #canDelete style="display:inline-block;">
                                    <div *ngIf="getPrivilege('customer_management')"
                                        pTooltip="You cannot delete this client" tooltipPosition="left"
                                        style="display:inline-block;">
                                        <a href="javascript:void(0);" data-toggle="modal" class="disabledClass">
                                            <i class="pi pi-trash" style="font-size: 0.8rem"></i>
                                        </a>
                                    </div>
                                </ng-template>
                            </td>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="emptymessage" let-columns>
                        <tr>
                            <td [attr.colspan]="columns.length+2">
                                <div *ngIf="isLoading" class="alignCenter">
                                    <p-progressSpinner [style]="{ width: '40px', height: '40px' }" strokeWidth="3">
                                    </p-progressSpinner>
                                </div>
                                <p-messages *ngIf="clients?.length == 0 && !isLoading" key="errMsg" class="alignCenter">
                                    <ng-template pTemplate>
                                        <i class="pi pi-copy" style="font-size: 2rem"></i>
                                        <div class="p-ml-2">NO DATA FOUND</div>
                                    </ng-template>
                                </p-messages>
                            </td>
                        </tr>
                    </ng-template>
                </p-table>
                <p-paginator #p [rows]="pageSize" [totalRecords]="totalCount" (onPageChange)="paginate($event)">
                </p-paginator>
            </p-card>
        </div>

    </p-scrollPanel>
</div>

<!-- IMPORT CLIENT MODAL -->
<p-dialog #inserClient header="Upload Clients" [(visible)]="displayImportModal" [modal]="true" [baseZIndex]="10000"
    [draggable]="false" [resizable]="true" [maximizable]="true" (onHide)="clearFormData()"
    (onShow)="showDialogMaximized($event,inserClient)">
    <form style="min-width: 60vw;max-width: 90vw;" role="form" #importClientForm="ngForm">
        <div class="file_upload_container">
            <div class="p-d-flex p-jc-between">
                <div class="p-d-flex">
                    <input type="file" #fileInput class="file-input" accept=".csv, .xls, .xlsx, .xlsm"
                        (change)="addFiles($event)" />
                    <button pButton pRipple type="button" icon="pi pi-upload"
                        class="p-button-rounded p-button-raised p-ml-2" (click)="importExcelPreview(fileInput)"
                        [disabled]="uploadedFiles.length == 0"></button>
                </div>

                <p-button label="Download template" styleClass="p-button-link p-ml-2" (click)="downloadTemplate()">
                </p-button>
            </div>
            <div class="p-pt-1">Upload csv, xls, xlsx ,xlsm only</div>
        </div>
        <div class="file_upload_content">
            <p-progressBar *ngIf="isLoading" mode="indeterminate" [style]="{'height': '6px'}"></p-progressBar>
            <ul *ngIf="uploadedFiles.length != 0" class="p-mt-2">
                <li *ngFor="let file of uploadedFiles">{{file.name}} - {{file.size}} bytes</li>
            </ul>
            <p-messages key="errMsg" [style]="{
                'font-size': '12px'
              }"></p-messages>
            <p-table *ngIf="errorDataList.length != 0" #dt1 [value]="errorDataList"
                styleClass="p-datatable-sm p-datatable-gridlines p-datatable-striped " [responsive]='true'
                [rowHover]="true" [scrollable]="true" scrollHeight="155px" [autoLayout]='true'>
                <ng-template pTemplate="header">
                    <tr>
                        <th style="width:50px;">Row</th>
                        <th>First Name</th>
                        <th>Middle Name</th>
                        <th>Last Name</th>
                        <th style="width: 120px;">Phone </th>
                        <th>Email </th>
                        <th>User Mode </th>
                        <th>Client Segments</th>
                        <th *ngIf="numErrorRecords != 0" style="width:60px;">Result</th>
                        <th *ngIf="numErrorRecords != 0">Result Message</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-data>
                    <tr>
                        <td style="width:50px;">{{data.row}}</td>
                        <td style="overflow: hidden;text-overflow: ellipsis;">{{data.firstName}}</td>
                        <td style="overflow: hidden;text-overflow: ellipsis;">{{data.middleName}}</td>
                        <td style="overflow: hidden;text-overflow: ellipsis;">{{data.lastName}}</td>
                        <td style="width: 120px;overflow: hidden;text-overflow: ellipsis;">{{data.mobileNo}}</td>
                        <td style="overflow: hidden;text-overflow: ellipsis;">{{data.email}}</td>
                        <td style="overflow: hidden;text-overflow: ellipsis;">{{data.userMode}}</td>
                        <td style="overflow: hidden;text-overflow: ellipsis;">{{data.clientSegments}}</td>
                        <td *ngIf="numErrorRecords != 0" style="width:60px;"
                            [ngClass]="data.result=='success'?'success_style':'error_style'">
                            {{data.result}}</td>
                        <td *ngIf="numErrorRecords != 0">{{data.resultMessage}}</td>
                    </tr>
                </ng-template>
            </p-table>
            <div *ngIf="noOfData > 25 && errorFileName != null" class="p-d-flex p-jc-end">
                <p-button [label]="'...'+ (noOfData - 25) +' more'" styleClass="p-button-link p-button-sm p-ml-2"
                    (click)="downloadErrorFile()">
                </p-button>
            </div>

        </div>

    </form>
    <ng-template pTemplate="footer">
        <p-button icon="pi pi-times" (click)="displayImportModal = false" label="Cancel"
            styleClass="p-button-danger p-button-text"></p-button>
        <p-button icon="pi pi-save" (click)="importClients()" label="Save" [disabled]="importDisabled">
        </p-button>
    </ng-template>
    <div *ngIf="isLoading && errorDataList.length != 0" class="progressLayout">
        <div class="progressContainer">
            <p-progressSpinner [style]="{ width: '40px', height: '40px' }" strokeWidth="3"></p-progressSpinner>
        </div>
    </div>
</p-dialog>

<!-- ADD/EDIT CLIENT MODAL -->
<p-dialog #clientDialog [header]="headerName" [(visible)]="displayAddEditModal" [modal]="true" [baseZIndex]="10000"
    [draggable]="false" [resizable]="true" [maximizable]="true" (onHide)="clearFormData()"
    (onShow)="showDialogMaximized($event,clientDialog)" styleClass="p-fluid" [contentStyle]="{'overflow':'visible'}">
    <p-messages key="errMsg" [style]="{'font-size': '12px'}"></p-messages>
    <form style="width: 60vw;" role="form" #clientForm="ngForm">
        <div class="row">
            <div class="form-group col-md-4">
                <label>First Name<span class="symbol required"></span></label>
                <input type="text" class="form-control border-black" name="firstName" placeholder="Enter First Name"
                    [(ngModel)]="firstName" required />
            </div>
            <div class="form-group col-md-4">
                <label>Middle Name</label>
                <input type="text" class="form-control" name="middleName" placeholder=" Enter Middle Name"
                    [(ngModel)]="middleName" required />
            </div>
            <div class="form-group col-md-4">
                <label>Last Name<span class="symbol required"></span></label>
                <input type="text" class="form-control" name="lastName" placeholder=" Enter Last Name"
                    [(ngModel)]="lastName" required />
            </div>
        </div>
        <div class="row">
            <div class="form-group col-md-4">
                <label class="control-label">Mobile<span class="symbol required"></span></label>
                <span class="input-icon"><input type="text" class="form-control" placeholder="Enter Mobile"
                        name="mobile" [(ngModel)]="mobile" [disabled]="disabled" maxlength="10"
                        (keypress)="validateDigitOnly($event)">
                    <span class="number">+1</span></span>
            </div>
            <div class="form-group col-md-4">
                <label>Email</label>
                <input type="email" class="form-control" name="AddUserEmail" placeholder=" Enter email"
                    [(ngModel)]="email" required />
            </div>
            <div class="form-group col-md-4">
                <label class="control-label">Country<span class="symbol required"></span></label>
                <select [(ngModel)]="country" class="form-control" name="country" style="height: 34px;" required>
                    <option [value]="null" disabled>Select Country</option>
                    <option *ngFor="let country of countries" [value]="country.isoCode">
                        {{ country.name }}
                    </option>
                </select>
            </div>
            <div class="form-group col-md-4">
                <label class="control-label">User Mode<span class="symbol required"></span></label>
                <select [(ngModel)]="userMode" class="form-control" name="userMode" style="height: 34px;" required>
                    <option [value]="null" disabled >Select User Mode</option>
                    <option *ngFor="let list of userModeList" [value]="list.value">
                        {{ list.name }}
                    </option>
                </select>
            </div>

            <div class="form-group col-md-4" [ngClass]="bussinesInfo?.subscriptionType != 'STANDARD' ?'disabledbutton':'' ">
                <label class="control-label" for="clientSegments">Client Segments</label>
                    <p-multiSelect [options]="clientSegmentationList" [ngModelOptions]="{standalone: true}" [(ngModel)]="clientSegments" optionLabel="name" placeholder="Client Segments" [autoZIndex]="true" name="clientSegments"></p-multiSelect>
            </div>
        </div>
        <!-- <div class="row">
            <div class="form-group col-md-4">
                <label class="control-label">User Type</label>
                <select [(ngModel)]="userTypeValue" class="form-control " style="height: 34px;" name="userTypeValue">
                    <option [value]="null" disabled>Select a user type</option>
                    <option *ngFor="let type of usersType" [value]="type.value">
                        {{ type.name }}
                    </option>
                </select>
            </div>
        </div> -->
    </form>
    <ng-template pTemplate="footer">
        <p-button icon="pi pi-times" (click)="displayAddEditModal = false" label="Cancel"
            styleClass="p-button-danger p-button-text"></p-button>
        <p-button *ngIf="headerName == 'Edit Client'" icon="pi pi-save" label="Save" (click)="editClient(clientForm)"
            [disabled]="disabled">
        </p-button>
        <p-button *ngIf="headerName == 'Add Client'" icon="pi pi-plus" label="Add" (click)="addClient(clientForm)"
            [disabled]="disabled">
        </p-button>
    </ng-template>
    <div *ngIf="isLoading" class="progressLayout">
        <div class="progressContainer">
            <p-progressSpinner [style]="{ width: '40px', height: '40px' }" strokeWidth="3"></p-progressSpinner>
        </div>
    </div>
</p-dialog>


<!-- ADD/EDIT CLIENT MODAL -->
<p-dialog #clientSegment [header]="headerName" [(visible)]="clientSegmentModal" [modal]="true" [baseZIndex]="10000" [draggable]="false" [resizable]="true" [maximizable]="true" (onHide)="clearFormData()" (onShow)="showDialogMaximized($event,clientDialog)" [style]="{width: '35vw'}">
    <p-table [value]="clientSegmentArray" styleClass="p-datatable-sm p-datatable-gridlines p-datatable-striped " [autoLayout]='true'
    [responsive]='true' [rowHover]="true">>
        <ng-template pTemplate="header">
            <tr>
                <th style="text-align:center">Segment Name</th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-segment>
            <tr>
                <td style="text-align:center">{{segment}}</td>
            </tr>
        </ng-template>
    </p-table>
    
    <div *ngIf="isLoading" class="progressLayout">
        <div class="progressContainer">
            <p-progressSpinner [style]="{ width: '40px', height: '40px' }" strokeWidth="3"></p-progressSpinner>
        </div>
    </div>
</p-dialog>

<!-- REMOVE USER MODAL -->
<p-confirmDialog [baseZIndex]="10000" rejectButtonStyleClass="p-button-text" [style]="{width:'45vw'}">
</p-confirmDialog>

<!-- TOAST -->
<p-toast position="bottom-right" key="br"></p-toast>