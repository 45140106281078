import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ConfirmationService, MenuItem, MessageService } from 'primeng/api';
import { Dialog } from 'primeng/dialog';
import { Paginator } from 'primeng/paginator';
import { AppSettings } from '../Constant/AppSettings';
import { UtilsService } from '../services/utils/utils.service';
import Quill from 'quill';
import ImageResize from 'quill-image-resize-module';

import CustomImage from '../Constant/CustomImage';
import { ContentService } from '../services/content/content.service';


Quill.register(CustomImage, true);
// Quill.register('modules/imageResize', ImageResize);
Quill.register({
  // ... other formats
  'formats/image': CustomImage
});


@Component({
  selector: 'app-frequently-asked-questions',
  templateUrl: './frequently-asked-questions.component.html',
  styleUrls: ['./frequently-asked-questions.component.css']
})
export class FrequentlyAskedQuestionsComponent implements OnInit {
  items: MenuItem[] = [{ label: 'Frequently Asked Questions' }];
  home: MenuItem = { icon: 'pi pi-home', routerLink: '/home' };
  @ViewChild('p', { static: false }) paginator?: Paginator;
  menuItems: MenuItem[] = [];
  faqId = '';
  cols: any[] = [];
  faqsList: any[] = [];
  disabled: boolean = false;
  isLoading: boolean = false;
  innerWidth: any;
  modules: any = {};
  //Paginator variable
  timer: any;
  pageSize: any = 0;
  currentPage: any = 1;
  totalCount: any = 0;
  searchInputText: string = '';
  //Dialog
  questionText: string = '';
  answerText: any = '';
  headerName: string = '';
  displayAddEditModal: boolean = false
  displayViewModal: boolean = false
  constructor(
    public utils: UtilsService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private contentService: ContentService
  ) { }

  ngOnInit(): void {
    this.innerWidth = window.innerWidth;
    this.cols = [
      { field: 'question', header: 'Question' },
    ]
    this.modules = {
      imageResize: { modules: ['Resize', 'DisplaySize'] },
    }
    this.listFaqs(this.currentPage, this.searchInputText);
  }


  listFaqs(page_number: string, searchText: string) {
    this.showProgressBar()
    this.faqsList = []
    this.contentService.listContent(page_number, 'FAQ', searchText).subscribe(
      (response) => {
        var json = JSON.parse(JSON.stringify(response));
        console.log('List Faqs Response --->> ' + JSON.stringify(json));
        if (json.response.status == 'SUCCESS') {
          this.faqsList = json.data;
          this.pageSize = json.additionalData.pageSize;
          this.totalCount = json.additionalData.totalCount;
        } else {
          this.showErrorToastMsg(json.response.displayMessage)
        }
        this.hideProgressBar()
      },
      (error) => {
        console.log(error);
        this.showErrorToastMsg(AppSettings.ERROR_MSG)
        this.hideProgressBar()
      }
    );
  }

  addFaq() {
    this.showProgressBar()
    if (this.questionText == '') {
      this.showErrorMessage('Question field is empty.')
      this.hideProgressBar();
      return;
    }
    if (this.answerText == '') {
      this.showErrorMessage('Answer field is empty.')
      this.hideProgressBar();
      return;
    }
    const Data = {
      data: {
        question: this.questionText,
        answer: this.answerText,
        contentType: "FAQ"
      }
    };
    console.log("add data:", JSON.stringify(Data));
    this.contentService.addContent(Data).subscribe(
      (response) => {
        var json = JSON.parse(JSON.stringify(response));
        console.log('Add Faq Post Response --->> ' + JSON.stringify(json));
        if (json.response.status == 'SUCCESS') {
          this.showSuccessMessage(json.response.status, json.response.displayMessage)
          this.showHideModalDialog('add_faq')
          this.listFaqs(this.currentPage, this.searchInputText)
        } else {
          this.showErrorMessage(json.response.displayMessage)
        }
        this.hideProgressBar()
      },
      (error) => {
        console.log(error);
        this.showErrorMessage(AppSettings.ERROR_MSG)
        this.hideProgressBar()
      }
    );
  }

  editFaq() {
    this.showProgressBar()
    if (this.questionText == '') {
      this.showErrorMessage('Question field is empty.')
      this.hideProgressBar();
      return;
    }
    if (this.answerText == '') {
      this.showErrorMessage('Answer field is empty.')
      this.hideProgressBar();
      return;
    }
    const Data = {
      data: {
        question: this.questionText,
        answer: this.answerText,
        contentType: "FAQ"
      }
    };
    console.log("add data:", JSON.stringify(Data));
    this.contentService.editContent(Data, this.faqId).subscribe(
      (response) => {
        var json = JSON.parse(JSON.stringify(response));
        console.log('Edit Faq Post Response --->> ' + JSON.stringify(json));
        if (json.response.status == 'SUCCESS') {
          this.showSuccessMessage(json.response.status, json.response.displayMessage)
          this.showHideModalDialog('edit_faq')
          this.listFaqs(this.currentPage, this.searchInputText)
        } else {
          this.showErrorMessage(json.response.displayMessage)
        }
        this.hideProgressBar()
      },
      (error) => {
        console.log(error);
        this.showErrorMessage(AppSettings.ERROR_MSG)
        this.hideProgressBar()
      }
    );
  }

  deleteFaq(item: any) {
    // console.log('id:' + item + ', event:' + JSON.stringify(e))
    this.showProgressBar()
    this.contentService.deleteContent(item.id).subscribe(
      (response) => {
        var json = JSON.parse(JSON.stringify(response));
        console.log('Delete Faq Response --->> ' + JSON.stringify(json));
        if (json.response.status == 'SUCCESS') {
          this.showSuccessMessage(json.response.status, json.response.displayMessage)
          this.listFaqs(this.currentPage, this.searchInputText)
        } else {
          this.showErrorToastMsg(json.response.displayMessage)
        }
        this.hideProgressBar()
      },
      (error) => {
        console.log(error);
        this.showErrorToastMsg(AppSettings.ERROR_MSG)
        this.hideProgressBar()
      }
    );
  }


  getFaq(type: any, item: any) {
    switch (type) {
      case 'edit_faq':
        this.showHideModalDialog('edit_faq')
        break;
      case 'view_faq':
        this.showHideModalDialog('view_faq')
        break;
    }

    this.faqId = item.id;
    this.showProgressBar()
    this.contentService.getContent(item.id).subscribe(
      (response) => {
        var json = JSON.parse(JSON.stringify(response));
        console.log('Get Faq Response --->> ' + JSON.stringify(json));
        if (json.response.status == 'SUCCESS') {
          this.questionText = json.data.question
          this.answerText = json.data.answer
        } else {
          this.showErrorMessage(json.response.displayMessage)
        }
        this.hideProgressBar()
      },
      (error) => {
        console.log(error);
        this.showErrorToastMsg(AppSettings.ERROR_MSG)
        this.hideProgressBar()
      }
    );
  }

  confirmDeleteDialog(item: any) {
    this.messageService.clear();
    this.confirmationService.confirm({
      message: 'Do you want to delete this FAQ ?',
      header: 'Delete Confirmation',
      icon: 'pi pi-trash',
      accept: () => {
        this.deleteFaq(item);
      },
    });
  }


  showHideModalDialog(type: string) {
    switch (type) {
      case 'edit_faq':
        this.headerName = 'Edit FAQ';
        this.displayAddEditModal = !this.displayAddEditModal;
        break;
      case 'add_faq':
        this.clearFormData();
        this.headerName = 'Add FAQ';
        this.displayAddEditModal = !this.displayAddEditModal;
        break;
      case 'view_faq':
        this.clearFormData();
        this.headerName = 'View FAQ';
        this.displayViewModal = !this.displayViewModal;
        break;
    }
  }

  getMenuItemsForItem(item: any): MenuItem[] {
    const context = item;
    return [
      {
        label: 'Options',
        items: [{
          label: 'Edit',
          icon: 'pi pi-pencil',
          command: e => this.getFaq('edit_faq', context)
        },
        {
          label: 'Delete',
          icon: 'pi pi-trash',
          command: e => this.confirmDeleteDialog(context)
        },
        {
          label: 'View',
          icon: 'pi pi-eye',
          command: e => this.getFaq('view_faq', context)
        }
        ]
      },
    ]
  }

  clearFormData() {
    this.messageService.clear('errMsg');
    this.questionText = ''
    this.answerText = ''
  }

  resetFaqsList() {
    this.searchInputText = '';
    this.currentPage = 1;
    this.paginator?.changePage(0);
    this.listFaqs(this.currentPage, this.searchInputText);
  }

  onEnterSearchFaqs(event: any, searchValue: string) {
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      this.currentPage = 1;
      this.paginator?.changePage(0);
      this.listFaqs(this.currentPage, searchValue)
    }, 900);
  }

  showErrorMessage(msg: string) {
    this.messageService.add({
      key: 'errMsg',
      severity: 'error',
      detail: msg,
    });
  }

  showErrorToastMsg(msg: string) {
    this.messageService.add({
      key: 'br',
      severity: 'error',
      detail: msg,
    });
  }

  showSuccessMessage(status: string, msg: string) {
    this.messageService.add({
      key: 'br',
      severity: 'success',
      summary: status,
      detail: msg,
    });
  }

  hideProgressBar() {
    this.isLoading = false;
    this.disabled = false;
  }

  showProgressBar() {
    this.messageService.clear('errMsg');
    this.isLoading = true;
    this.disabled = true;
  }

  showDialogMaximized(event: any, dialog: Dialog) {
    if (this.innerWidth <= 640) {
      dialog.maximized = true;
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.innerWidth = window.innerWidth;
  }

  paginate(event: any) {
    console.log(event)
    this.listFaqs(event.page + 1, this.searchInputText)
  }

  get state(): string {
    return this.utils.state;
  }
}
