import { ChangeDetectorRef, Component, forwardRef, HostListener, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CalendarOptions, DateSelectArg, EventClickArg, EventApi, EventInput, FullCalendarComponent, CalendarApi, EventContentArg, EventHoveringArg } from '@fullcalendar/angular';
import * as moment from 'moment';
import { ConfirmationService, MenuItem, MessageService } from 'primeng/api';
import { ConfirmDialog } from 'primeng/confirmdialog';
import { ContextMenu } from 'primeng/contextmenu';
import { Dialog } from 'primeng/dialog';
import { Menu } from 'primeng/menu';
import tippy from 'tippy.js';
import { AppSettings } from '../Constant/AppSettings';
import { AppointmentService } from '../services/appointment/appointment.service';
import { ClientDataService } from '../services/client-data/client-data.service';
import { PrivilegeService } from '../services/privilege/privilege.service';
import { ServiceProviderService } from '../services/service-provider/service-provider.service';
import { ServiceTypeService } from '../services/service-type/service-type.service';
import { UtilsService } from '../services/utils/utils.service';
import { CommonFunctions } from '../Utilities/CommonFunctions';
import { BusinessHolidaysService } from '../services/business-holidays/business-holidays.service';

@Component({
  selector: 'app-business-calendar',
  templateUrl: './business-calendar.component.html',
  styleUrls: ['./business-calendar.component.css'],
})
export class BusinessCalendarComponent implements OnInit {
  @ViewChild('calendar') calendarComponent!: FullCalendarComponent;
  @ViewChild('cd') cd!: ConfirmDialog;
  menuTopLeftPosition = { x: '0', y: '0' }

  @ViewChild('menu', { static: false }) menu!: Menu;
  @ViewChild('contextMenu') contextMenu: any;
  items: MenuItem[] = [{ label: 'Business Calendar' }];
  home: MenuItem = { icon: 'pi pi-home', routerLink: '/home' };
  menuItems: MenuItem[] = [];
  origin: any = {};
  //form variable
  appointmentId: any = '';
  appointmentNumber: any = '';
  title: string = '';
  description: string = '';
  isCalendarLoading: boolean = false;
  isLoading: boolean = false;
  appointmentStartDateTime: any = '';
  appointmentEndDateTime: any = '';
  serviceProviderList: any[] = [];
  serviceProvider: string = 'null';
  serviceProviderName: string = '';
  serviceTypeList: any[] = [];
  serviceType: string = "null";
  serviceTypeName: string = '';
  clients: any[] = [];
  client: string = 'null';
  singleClientData: any = {};
  specialInstruction = '';
  sendNotification: boolean = true;
  disabledSendNotification: boolean = false;
  isReminder: boolean = false;
  //Filter variable
  serviceProviderFilterList: any[] = [];
  selectedServiceProvider: any;
  serviceTypeFilterList: any[] = [];
  selectedServiceType: any;
  //calendar variable
  private month: number = new Date().getMonth() + 1;
  public year = new Date().getFullYear();
  selectInfo?: DateSelectArg;
  clickInfo?: EventClickArg;
  TODAY_STR = new Date().toISOString().replace(/T.*$/, ''); // YYYY-MM-DD of today
  INITIAL_EVENTS: EventInput[] = [];
  calendarVisible = true;
  events: EventInput[] = [];
  holidays: any[] = [];
  invalidDates: any[] = [];
  calendarOptions: CalendarOptions = {
    headerToolbar: {
      start: 'today prev,next',
      center: 'title',
      right: 'dayGridMonth,timeGridWeek,timeGridDay'
    },
    // timeZone: 'America/New_York',
    customButtons: {
      today: {
        text: 'Today',
        click: this.getEventsByToday.bind(this)
      },
      prev: {
        text: '<',
        click: this.getEventsByMonthBefore.bind(this)
      },
      next: {
        text: '>',
        click: this.getEventsByMonthAfter.bind(this)
      },
      dayGridMonth: {
        text: 'Month',
        click: this.getEventsByMonth.bind(this)
      },
      timeGridWeek: {
        text: 'Week',
        click: this.getEventsByWeek.bind(this)
      },
      timeGridDay: {
        text: 'Day',
        click: this.getEventsByDay.bind(this)
      }
    },
    initialView: 'timeGridWeek',
    initialEvents: this.INITIAL_EVENTS,
    weekends: true,
    editable: false,
    selectable: true,
    selectMirror: true,
    dayMaxEvents: true,
    contentHeight: 'auto',

    select: this.handleDateSelect.bind(this),
    eventClick: this.handleEventClick.bind(this),
    eventsSet: this.handleEvents.bind(this),
    eventContent: this.renderEventContent.bind(this),
    eventMouseEnter: this.handleEventMouseEnter.bind(this),
    dayCellDidMount: this.handleEventRenderService.bind(this),
    // viewDidMount: this.handleEventRenderService.bind(this)
  };
  currentEvents: EventApi[] = [];
  //Sidebar variable
  visibleViewAppointment: boolean = false;
  visibleTestAppointment: boolean = false;
  view_disabled: boolean = false;
  // Dialog display varaible
  blockId = '';
  serviceDuration: any = null;
  blockComment: string = '';
  headerName = '';
  isUserNonchatstasy: boolean = false;
  twillioNumber = CommonFunctions.getBusinessInfo().twilioNumber
  slotDisabled: boolean = false
  displayAddEditModal: boolean = false;
  displayBlockModal: boolean = false;
  disabled: boolean = false;
  checkDisabled: boolean = false
  innerWidth: any;
  bussinesInfo: any = {};
  appointmentList: any[] = [];
  checked: boolean = true;


  constructor(
    public utils: UtilsService,
    private appointmentService: AppointmentService,
    private clientDataService: ClientDataService,
    private serviceTypeService: ServiceTypeService,
    private serviceProviderService: ServiceProviderService,
    private privilegeService: PrivilegeService,
    private router: Router,
    private cdRef: ChangeDetectorRef,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    public businessHolidaysService: BusinessHolidaysService,
  ) {
    let notificationData = this.router.getCurrentNavigation()?.extras.state?.notification;
    if (notificationData != undefined) {
      this.showHideModalDialog('view_appointment');
      this.getAppointment(notificationData)
    }
    this.utils.notificationData.subscribe(
      (notification: any) => {
        if (notification != undefined) {
          this.showHideModalDialog('view_appointment');
          this.getAppointment(notification)
          utils.onClickNotification(undefined)
        }
      }
    )
    console.log('notiData:', this.router.getCurrentNavigation()?.extras.state);
  }

  ngOnInit(): void {
    this.innerWidth = window.innerWidth;
    this.bussinesInfo = CommonFunctions.getBusinessInfo();
    console.log(history.state);
    this.getServiceTypeList();
    this.listClient()
    this.getAppointmentFilterOptions();
    if (this.twillioNumber == null) {
      this.sendNotification = false
      this.disabledSendNotification = true
    } else {
      this.sendNotification = true
      this.disabledSendNotification = false
    }
    this.menuItems = [{
      label: 'File',
      items: [
        { label: 'New', icon: 'pi pi-fw pi-plus' },
        { label: 'Download', icon: 'pi pi-fw pi-download' }
      ]
    },
    {
      label: 'Edit',
      items: [
        { label: 'Add User', icon: 'pi pi-fw pi-user-plus' },
        { label: 'Remove User', icon: 'pi pi-fw pi-user-minus' }
      ]
    }];

  }

  ngAfterViewInit() {
    this.listAppointment();
  }

  ngAfterViewChecked() {
    this.cdRef.detectChanges();
  }


  toggleMenu(command: string) {
    this.contextMenu.nativeElement.style.display = command === "show" ? "block" : "none";
  };

  setPosition(data: any) {
    this.contextMenu.nativeElement.style.left = `${data.left}px`;
    this.contextMenu.nativeElement.style.top = `${data.top}px`;
    this.toggleMenu('show');
  };

  @HostListener('document:mousedown', ['$event'])
  onGlobalClick(event: any): void {
    if (!this.contextMenu.nativeElement.contains(event.target)) {
      // clicked outside => close dropdown list
      this.toggleMenu('hide')
    }
  }

  handleEventRenderService(e: any) {
    console.log(e)
    // e.el.addEventListener('contextmenu', this.handleContextMenu.bind(this));
    if (this.isHoliday(e.date.toISOString().split('T')[0])) {
      e.el.firstChild.classList.add('fc-non-business');
    }
  }

  // handleContextMenu(ev: any) {
  //   ev.preventDefault();
  //   this.origin = {
  //     left: ev.pageX,
  //     top: ev.pageY
  //   };

  //   this.setPosition(this.origin);
  // }

  handleDateSelect(selectInfo: DateSelectArg) {
    this.clearFormData();
    const calendarApi = this.calendarComponent.getApi();
    this.appointmentStartDateTime = new Date(selectInfo.startStr);
    this.appointmentEndDateTime = new Date(selectInfo.endStr);
    const origin = {
      left: selectInfo.jsEvent?.pageX,
      top: selectInfo.jsEvent?.pageY
    };
    var now = new Date();
    if (this.appointmentStartDateTime < now) {
      console.log("Selected date is in the past");
      calendarApi?.unselect();
    } else {
      console.log("Selected date is NOT in the past");
      if (this.getPrivilege('manage_appointment')) {
        if (this.bussinesInfo.state != 'offline') {
          this.setPosition(origin);
        } else {
          calendarApi?.unselect();
        }
      } else {
        calendarApi?.unselect();
      }
    }
    // calendarApi?.unselect(); // clear date selection
    console.log('Clicked on: ' + selectInfo.allDay);
    console.log('Coordinates: ' + selectInfo.jsEvent?.pageX + ',' + selectInfo.jsEvent?.pageY);
    console.log('Current view: ' + selectInfo.view.type);
    if (selectInfo.jsEvent?.button === 2) {
      console.log('setting...', selectInfo)
      // this.currentPasteData = info
    }
    const selectedDate = selectInfo.startStr;
    if (this.isHoliday(selectedDate)) {
      console.log(selectedDate);
      alert('This date is disabled.');
      this.toggleMenu('hide');
      return;
    }
  }

  isHoliday(date: string): boolean {
    return this.holidays.includes(date);
  }

  openDialogFromMenu(type: string) {
    switch (type) {
      case 'add_appointment':
        this.headerName = 'Add Appointment';
        this.displayAddEditModal = !this.displayAddEditModal;
        this.checkConflict(type)
        break;
      case 'add_block_slots':
        this.headerName = 'Add Block Slots';
        this.displayBlockModal = !this.displayBlockModal
        this.checkConflict(type)
        break;
    }
  }


  handleEventClick(clickInfo: EventClickArg) {
    this.clickInfo = clickInfo;
    if(clickInfo.event.backgroundColor == "#ccc"){
      const eventStart = clickInfo.event?.start;
      if (eventStart) {
        const eventDate = eventStart.toISOString().split('T')[0];
        if (this.isHoliday(eventDate)) {
          alert('This date is disabled.');
          return;
        }
      }
    }
    if (clickInfo.event.extendedProps.isBlocked) {
      this.showHideModalDialog('view_block_slots');
      this.getBlockSlot(clickInfo.event);
      this.slotDisabled = true;
    } else {
      this.showHideModalDialog('view_appointment');
      this.getAppointment(clickInfo.event)
    }
  }

  handleEvents(events: EventApi[]) {
    this.currentEvents = events;
  }


  getEventsByToday() {
    this.calendarComponent.getApi().today();
    this.listAppointment();
  }

  getEventsByMonth() {
    const cbm: any = {
      year: this.year,
      month: this.month
    };
    this.calendarComponent.getApi().changeView('dayGridMonth');
    this.listAppointment();
  }

  getEventsByWeek() {
    this.calendarComponent.getApi().changeView('timeGridWeek');
    this.listAppointment();
  }

  getEventsByDay() {
    this.calendarComponent.getApi().changeView('timeGridDay');
    this.listAppointment();
  }


  getEventsByMonthBefore() {
    this.month--;
    if (this.month == -1) {
      this.month = 11;
      this.year--;
    }
    const monthString = this.month > 9 ? this.month.toString() : '0' + this.month.toString();
    const cbm: any = {
      year: this.year,
      month: this.month
    };
    this.calendarComponent.getApi().prev();
    this.listAppointment();
  }
  getEventsByMonthAfter() {
    this.month++;
    if (this.month == 12) {
      this.month = 0;
      this.year++;
    }
    const monthString = this.month > 9 ? this.month.toString() : '0' + this.month.toString();
    const cbm: any = {
      year: this.year,
      month: this.month
    };
    this.calendarComponent.getApi().next();
    this.listAppointment();
  }

  renderEventContent(eventInfo: EventClickArg, createElement: any) {
    var innerHtml;
    if (eventInfo.view.type == 'dayGridMonth') {
      if (eventInfo.event.extendedProps.isBlocked) {
        if(eventInfo.event.backgroundColor == "#ccc"){
          innerHtml =
          '<div class="fc-daygrid-event-dot" style="border:4px solid ' + eventInfo.event.backgroundColor + ';"></div>' +
          '<div class="fc-event-title">' + eventInfo.event.title + '</div>'
        }else{
          innerHtml =
            '<div class="fc-daygrid-event-dot" style="border:4px solid ' + eventInfo.event.backgroundColor + ';"></div>' +
            '<div class="fc-event-time">' + moment(eventInfo.event.start).format("h:mm a") + '</div>' +
            '<div class="fc-event-title">' + eventInfo.event.title + '</div>'
        }
      } else {
        innerHtml =
          '<div class="fc-daygrid-event-dot"></div>' +
          '<div class="fc-event-time">' + moment(eventInfo.event.start).format("h:mm a") + '</div>' +
          '<div class="fc-event-title">' + eventInfo.event.extendedProps.clientName + '</div>'
      }
    } else {
      if (eventInfo.event.title != '') {
        if (eventInfo.event.extendedProps.isBlocked) {
          innerHtml =
            '<div class="fc-event-main-frame" style="background: #607D8B;border: 1px solid #607D8B;">' +
            '<div class="fc-event-title-container">' +
            '<div class="fc-event-time" style="font-size: 1em;font-weight: bold;white-space: pre-line;">' +
            '<div>' + eventInfo.event.title + '</div>' +
            '</div>' +
            '<div class="fc-event-title">' + moment(eventInfo.event.start).format("h:mm A") + ' - ' + (eventInfo.event.end ? moment(eventInfo.event.end).format("h:mm A") : moment(eventInfo.event.start).add(23, 'hours').add(59, 'minutes').format("h:mm A")) + '</div>' +
            '</div>' +
            '</div>'
        } else {
          innerHtml =
            '<div class="fc-event-main-frame">' +
            '<div class="fc-event-title-container">' +
            '<div class="fc-event-time" style="font-size: 1em;font-weight: bold;white-space: pre-line;">' +
            eventInfo.event.extendedProps.clientName + '<br>' +
            eventInfo.event.title +
            '<div style="font-weight: normal;"> Provided By ' + eventInfo.event.extendedProps.serviceProvider + '</div>' +
            '</div>' +
            '<div class="fc-event-title">' + moment(eventInfo.event.start).format("h:mm A") + ' - ' + moment(eventInfo.event.end).format("h:mm A") + '</div>' +
            '</div>' +
            '</div>'
        }
      } else {
        innerHtml =
          '<div class="fc-event-title">' + moment(eventInfo.event.start).format("h:mm A") + ' - ' + moment(eventInfo.event.end).format("h:mm A") + '</div>'
      }
    }
    return createElement = { html: innerHtml }
  }

  handleEventMouseEnter(eventInfo: EventHoveringArg) {
    if (!eventInfo.event.extendedProps.isBlocked) {
      tippy(eventInfo.el, {
        allowHTML: true,
        content:
          '<div>' +
          '<strong>' + eventInfo.event.extendedProps.clientName + '</strong> <br>' +
          '<strong>' + eventInfo.event.title + '</strong>' +
          '<header> Provided By ' + eventInfo.event.extendedProps.serviceProvider + '</header>' +
          '<footer>' + moment(eventInfo.event.start).format("h:mm A") + ' - ' + moment(eventInfo.event.end).format("h:mm A") + '</footer>' +
          '</div>',
      });
    }
    console.log(eventInfo.event.title)
  }


  addAppointment() {
    this.showProgressBar();
    if (this.serviceType == '' || this.serviceType == 'null') {
      this.showErrorMessage('Service Type field is empty.')
      this.hideProgressBar();
      return;
    } if (this.serviceProvider == '' || this.serviceProvider == 'null') {
      this.showErrorMessage('Service Provider field is empty.')
      this.hideProgressBar();
      return;
    } if (this.client == '' || this.client == 'null') {
      this.showErrorMessage('Client field is empty.')
      this.hideProgressBar();
      return;
    } if (this.appointmentStartDateTime == '') {
      this.showErrorMessage('Appointment start date/time field is empty.')
      this.hideProgressBar();
      return;
    } if (this.appointmentEndDateTime == '') {
      this.showErrorMessage('Appointment end date/time field is empty.')
      this.hideProgressBar();
      return;
    }
    var isoDateString = new Date(this.appointmentStartDateTime).toISOString();
    console.log(isoDateString);
    const appointmentData = {
      title: "Test 1",
      description: "Apppointment 1",
      scheduledOnFrom: this.toISOStringLocal(this.appointmentStartDateTime),
      scheduledOnTo: this.toISOStringLocal(this.appointmentEndDateTime),
      specialInstructions: this.specialInstruction,
      serviceType: {
        id: this.serviceType
      },
      serviceProvider: {
        id: this.serviceProvider
      },
      clientData: {
        id: this.client
      }
    }
    var data = { data: JSON.parse(JSON.stringify(appointmentData)) };
    console.log('Add Appointment Form Data --->> ' + JSON.stringify(data));
    this.appointmentService.addAppointment(data, this.sendNotification).subscribe(
      (response) => {
        var json = JSON.parse(JSON.stringify(response));
        console.log('Add Appointment Post Response --->> ' + JSON.stringify(json));
        console.log('Status --->> ' + json.response.status);
        if (json.response.status == 'SUCCESS') {
          this.showHideModalDialog('add_appointment');
          this.showSuccessMessage(json.response.status, json.response.message)
          this.listAppointment();
        } else {
          this.showErrorMessage(json.response.displayMessage)
        }
        this.hideProgressBar();
      },
      (error) => {
        console.log(error);
        this.showErrorMessage(AppSettings.ERROR_MSG)
        this.hideProgressBar();
      }
    );
  }

  editAppointment() {
    this.showProgressBar();
    if (this.serviceType == '' || this.serviceType == 'null') {
      this.showErrorMessage('Service Type field is empty.')
      this.hideProgressBar();
      return;
    } if (this.serviceProvider == '' || this.serviceProvider == 'null') {
      this.showErrorMessage('Service Provider field is empty.')
      this.hideProgressBar();
      return;
    } if (this.client == '' || this.client == 'null') {
      this.showErrorMessage('Client field is empty.')
      this.hideProgressBar();
      return;
    } if (this.appointmentStartDateTime == '') {
      this.showErrorMessage('Appointment start date/time field is empty.')
      this.hideProgressBar();
      return;
    } if (this.appointmentEndDateTime == '') {
      this.showErrorMessage('Appointment end date/time field is empty.')
      this.hideProgressBar();
      return;
    }
    const appointmentData = {
      title: "Test 2",
      description: "Apppointment 2",
      scheduledOnFrom: this.toISOStringLocal(this.appointmentStartDateTime),
      scheduledOnTo: this.toISOStringLocal(this.appointmentEndDateTime),
      specialInstructions: this.specialInstruction,
      serviceType: {
        id: this.serviceType
      },
      serviceProvider: {
        id: this.serviceProvider
      },
      clientData: {
        id: this.client
      }
    }
    var data = { data: JSON.parse(JSON.stringify(appointmentData)) };
    console.log('Edit Appointment Form Data --->> ' + JSON.stringify(data));
    this.appointmentService.editAppointment(this.appointmentId, data, this.sendNotification).subscribe(
      (response) => {
        var json = JSON.parse(JSON.stringify(response));
        console.log('Edit Apoointment Post Response --->> ' + JSON.stringify(json));
        console.log('Status --->> ' + json.response.status);
        if (json.response.status == 'SUCCESS') {
          this.showHideModalDialog('edit_appointment');
          if (this.visibleViewAppointment) {
            this.headerName = 'Cancel Appointment';
          }
          this.showSuccessMessage(json.response.status, json.response.message)
          this.getAppointment(json.data)
          this.listAppointment();
        } else {
          this.showErrorMessage(json.response.displayMessage)
        }
        this.hideProgressBar();
      },
      (error) => {
        console.log(error);
        this.showErrorMessage(AppSettings.ERROR_MSG)
        this.hideProgressBar();
      }
    );
  }

  cancelAppointment() {
    this.showProgressBar()
    this.appointmentService.cancelAppointment(this.appointmentId, this.sendNotification).subscribe(
      (response) => {
        var json = JSON.parse(JSON.stringify(response));
        if (json.response.status == 'SUCCESS') {
          this.showHideModalDialog('view_appointment')
          this.showSuccessMessage(json.response.status, json.response.message)
          this.listAppointment();
        } else {
          this.showErrorToastMsg(json.response.message)
        }
        this.hideProgressBar()
        console.log('Cancel Appointment Response --->> ' + JSON.stringify(json));
      },
      (error) => {
        console.log(error);
        this.showErrorToastMsg(AppSettings.ERROR_MSG)
      }
    );
  }

  listAppointment() {
    this.isCalendarLoading = true;
    var beginOfWeek = new Date(this.calendarComponent.getApi().view.currentStart);
    var endOfWeek = new Date(this.calendarComponent.getApi().view.currentEnd);
    console.log("range:" + beginOfWeek.toISOString() + '-' + endOfWeek.toISOString())
    this.appointmentService.listAppointment(this.toISOStringLocal(beginOfWeek), this.toISOStringLocal(endOfWeek), this.selectedServiceType, this.selectedServiceProvider).subscribe(
      (response) => {
        var json = JSON.parse(JSON.stringify(response));
        console.log('Get Appointment List Response --->> ' + JSON.stringify(json));
        if (json.response.status == 'SUCCESS') {
          this.INITIAL_EVENTS = []
          json.data.forEach((element: any) => {
            this.INITIAL_EVENTS.push({
              id: element.id,
              clientName: element.clientData?.firstName?.charAt(0) + ' ' + element.clientData?.lastName,
              title: element.serviceType ? element.serviceType?.name : '',
              start: element.scheduledOnFrom,
              end: element.scheduledOnTo,
              serviceProvider: element.serviceProvider ? element.serviceProvider?.name : '',
              isBlocked: false,
              backgroundColor: element.clientConfirm ? "#009945":"",
              borderColor: element.clientConfirm ? "#009945":"",
              color: element.clientConfirm ? "#009945":"",
            })
          });
          this.listBlockSlots();
        } else {
          this.showErrorMessage(json.response.displayMessage)
        }
        this.isCalendarLoading = false;
      },
      (error) => {
        console.log(error);
        this.showErrorToastMsg(AppSettings.ERROR_MSG)
        this.isCalendarLoading = false;
      }
    );
  }

  listClient() {
    this.showProgressBar();
    let type = null;
    if(this.bussinesInfo.state == "offline"){
      type = "test";
    }
    this.clientDataService.getAllClientList(type).subscribe(
      (response) => {
        var json = JSON.parse(JSON.stringify(response));
        console.log('Get Client List Response --->> ' + JSON.stringify(json));
        if (json.response.status == 'SUCCESS') {
          this.clients = [];
          json.data.forEach((element: any) => {
            this.clients.push({ name: element.firstName + ' ' + element.middleName + ' ' + element.lastName, value: element.id, userType: element.userType })
          });
        } else {
          this.showErrorMessage(json.response.displayMessage)
        }
        this.hideProgressBar();
      },
      (error) => {
        console.log(error);
        this.showErrorToastMsg(AppSettings.ERROR_MSG)
        this.hideProgressBar();
      }
    );
  }

  getAppointmentFilterOptions() {
    this.showProgressBar();
    this.appointmentService.getAppointmentFilterOptions().subscribe(
      (response) => {
        var json = JSON.parse(JSON.stringify(response));
        console.log('Get Appointment Filter Options Response --->> ' + JSON.stringify(json));
        if (json.response.status == 'SUCCESS') {
          this.serviceTypeFilterList = json.data.serviceType.options;
          this.serviceProviderFilterList = json.data.serviceProvider.options
        } else {
          this.showErrorMessage(json.response.displayMessage)
        }
        this.hideProgressBar();
      },
      (error) => {
        console.log(error);
        this.showErrorToastMsg(AppSettings.ERROR_MSG)
        this.hideProgressBar();
      }
    );
  }

  getAppointment(appointment: any) {
    this.showProgressBar();
    console.log("id:" + appointment.id)
    this.appointmentService.getAppointment(appointment.id).subscribe(
      (response) => {
        var json = JSON.parse(JSON.stringify(response));
        console.log('Get Appointment Response --->> ' + JSON.stringify(json));
        if (json.response.status == 'SUCCESS') {
          this.getServiceProviderByServiceTypeList(json.data.serviceType?.id)
          this.appointmentId = json.data.id
          this.appointmentNumber = json.data.appointmentId;
          this.appointmentStartDateTime = new Date(json.data.scheduledOnFrom);
          this.appointmentEndDateTime = new Date(json.data.scheduledOnTo);
          this.serviceType = json.data.serviceType?.id || '';
          this.serviceTypeName = json.data.serviceType?.name || '';
          this.serviceProvider = json.data.serviceProvider?.id || '';
          this.serviceProviderName = json.data.serviceProvider?.name || '';
          this.specialInstruction = json.data.specialInstructions || '';
          this.client = json.data.clientData?.id || '';
          this.singleClientData = json.data.clientData || '';
          this.checkUserType(json.data.clientData?.userType || '')
        } else {
          this.showErrorMessage(json.response.displayMessage)
        }
        this.hideProgressBar();
        var now = new Date();
        if (this.appointmentStartDateTime < now) {
          this.view_disabled = true;
        } else {
          this.view_disabled = false
        }
      },
      (error) => {
        console.log(error);
        this.showErrorMessage(AppSettings.ERROR_MSG)
        this.hideProgressBar();
        var now = new Date();
        if (this.appointmentStartDateTime < now) {
          this.view_disabled = true;
        } else {
          this.view_disabled = false
        }
      }
    );
  }

  checkConflict(type: string) {
    console.log('date:' + this.appointmentEndDateTime)
    this.showProgressBar()
    this.messageService.clear('checkMsg');
    this.checkDisabled = true
    if (this.appointmentStartDateTime == '' || this.appointmentStartDateTime === undefined) {
      this.hideProgressBar();
      return;
    } if (this.appointmentEndDateTime == '' || this.appointmentEndDateTime === undefined) {
      this.hideProgressBar();
      return;
    }
    if (this.serviceType == '' || this.serviceType == 'null') {
      this.hideProgressBar();
      return;
    } if (this.serviceProvider == '' || this.serviceProvider == 'null') {
      this.hideProgressBar();
      return;
    }

    this.appointmentService.checkConflict(this.toISOStringLocal(this.appointmentStartDateTime), this.toISOStringLocal(this.appointmentEndDateTime), this.serviceType, this.serviceProvider).subscribe(
      (response) => {
        var json = JSON.parse(JSON.stringify(response));
        console.log('Check Conflict Response --->> ' + JSON.stringify(json));
        if (json.response.status == 'SUCCESS') {
          if (type == 'add_appointment') {
            if (json.data.appointments > 0 && json.data.blockSlots > 0) {
              this.showCheckMessage('warn', json.data.appointments + ' Appointments found in the selected slot')
              this.showCheckMessage('error', json.data.blockSlots + ' Blocked slots found in the selected slot')
              this.checkDisabled = true;
            } else if (json.data.appointments > 0) {
              this.showCheckMessage('warn', json.data.appointments + ' Appointments found in the selected slot')
              this.checkDisabled = false;
            } else if (json.data.blockSlots > 0) {
              this.showCheckMessage('error', json.data.blockSlots + ' Blocked slots found in the selected slot')
              this.checkDisabled = true;
            } else if (json.data.appointments == 0 && json.data.blockSlots == 0) {
              this.checkDisabled = false;
            }
          } else {
            if (json.data.appointments > 0 && json.data.blockSlots > 0) {
              this.showCheckMessage('error', json.data.appointments + ' Appointments found in the selected slot')
              this.showCheckMessage('error', json.data.blockSlots + ' Blocked slots found in the selected slot')
              this.checkDisabled = true;
            } else if (json.data.appointments > 0) {
              this.showCheckMessage('error', json.data.appointments + ' Appointments found in the selected slot')
              this.confirmCancelDialog('cancel_all')
              this.checkDisabled = true;
            } else if (json.data.blockSlots > 0) {
              this.showCheckMessage('error', json.data.blockSlots + ' Blocked slots found in the selected slot')
              this.checkDisabled = true;
            } else if (json.data.appointments == 0 && json.data.blockSlots == 0) {
              this.checkDisabled = false;
            }
          }
        } else {
          this.showErrorMessage(json.response.displayMessage)
        }
        this.hideProgressBar()
      },
      (error) => {
        console.log(error);
        this.showErrorMessage(AppSettings.ERROR_MSG)
        this.hideProgressBar();
      }
    );
  }


  addBlockSlot() {
    this.showProgressBar();
    if (this.appointmentStartDateTime == '' || this.appointmentStartDateTime == 'null') {
      this.showErrorMessage('Start date/time field is empty.')
      this.hideProgressBar();
      return;
    } if (this.appointmentEndDateTime.toString().trim() == '' || this.appointmentEndDateTime == 'null') {
      this.showErrorMessage('End date/time field is empty.')
      this.hideProgressBar();
      return;
    } if (this.blockComment == '' || this.blockComment == 'null') {
      this.showErrorMessage('Comments field is empty.')
      this.hideProgressBar();
      return;
    }
    const blockData = {
      startDate: this.toISOStringLocal(this.appointmentStartDateTime),
      endDate: this.toISOStringLocal(this.appointmentEndDateTime),
      comments: this.blockComment
    }
    var data = { data: JSON.parse(JSON.stringify(blockData)) };
    console.log('Add Block Form Data --->> ' + JSON.stringify(data));
    this.appointmentService.addBlockSlot(data).subscribe(
      (response) => {
        var json = JSON.parse(JSON.stringify(response));
        console.log('Add Block Post Response --->> ' + JSON.stringify(json));
        console.log('Status --->> ' + json.response.status);
        if (json.response.status == 'SUCCESS') {
          this.showHideModalDialog('add_block_slots');
          this.showSuccessMessage(json.response.status, json.response.message)
          this.listAppointment();
        } else {
          this.showErrorMessage(json.response.displayMessage)
        }
        this.hideProgressBar();
      },
      (error) => {
        console.log(error);
        this.showErrorMessage(AppSettings.ERROR_MSG)
        this.hideProgressBar();
      }
    );
  }


  cancelAllAppointment() {
    this.showProgressBar();
    this.appointmentService.cancelAllAppointment(this.toISOStringLocal(this.appointmentStartDateTime), this.toISOStringLocal(this.appointmentEndDateTime), this.sendNotification).subscribe(
      (response) => {
        var json = JSON.parse(JSON.stringify(response));
        console.log('Check Appointment Response --->> ' + JSON.stringify(json));
        if (json.response.status == 'SUCCESS') {
          this.showSuccessMessage(json.response.status, json.response.displayMessage)
          this.checkConflict('add_block_slots')
          this.addBlockSlot();
        } else {
          this.showErrorMessage(json.response.displayMessage)

          this.hideProgressBar();
        }
      },
      (error) => {
        console.log(error);
        this.showErrorMessage(AppSettings.ERROR_MSG)
        this.hideProgressBar();
      }
    );
  }



  getBlockSlot(slot: any) {
    this.showProgressBar();
    this.blockId = slot.id;
    console.log("id:" + slot.id)
    this.appointmentService.getBlockSlot(slot.id).subscribe(
      (response) => {
        var json = JSON.parse(JSON.stringify(response));
        console.log('Get Single Block Response --->> ' + JSON.stringify(json));
        if (json.response.status == 'SUCCESS') {
          this.appointmentStartDateTime = new Date(json.data.startDate);
          this.appointmentEndDateTime = new Date(json.data.endDate);
          this.blockComment = json.data.comments;
        } else {
          this.showErrorMessage(json.response.displayMessage)
        }
        this.hideProgressBar();
      },
      (error) => {
        console.log(error);
        this.showErrorMessage(AppSettings.ERROR_MSG)
        this.hideProgressBar();
      }
    );
  }


  unlockSlot() {
    this.showProgressBar();
    this.appointmentService.unblockSlot(this.blockId).subscribe(
      (response) => {
        var json = JSON.parse(JSON.stringify(response));
        console.log('Unblock Response --->> ' + JSON.stringify(json));
        if (json.response.status == 'SUCCESS') {
          this.showHideModalDialog('view_block_slots');
          this.showSuccessMessage(json.response.status, json.response.displayMessage)
          this.listAppointment()
        } else {
          this.showErrorMessage(json.response.displayMessage)
        }
        this.hideProgressBar();
      },
      (error) => {
        console.log(error);
        this.showErrorMessage(AppSettings.ERROR_MSG)
        this.hideProgressBar();
      }
    );
  }

  listBlockSlots() {
    this.isCalendarLoading = true;
    this.appointmentService.listBlockedSlots().subscribe(
      (response) => {
        var json = JSON.parse(JSON.stringify(response));
        console.log('Block List Response --->> ' + JSON.stringify(json));
        if (json.response.status == 'SUCCESS') {
          json.data.forEach((element: any) => {
            this.INITIAL_EVENTS.push({
              id: element.id,
              title: element.comments,
              start: element.startDate,
              end: element.endDate,
              backgroundColor: '#607D8B',
              borderColor: '#607D8B',
              color: '#607D8B',
              isBlocked: true
            })
          });
          // this.calendarComponent!.options!.events = this.INITIAL_EVENTS;
          this.checkHolidaysConflict();
        } else {
          this.showErrorMessage(json.response.displayMessage)
        }
        this.isCalendarLoading = false;
      },
      (error) => {
        console.log(error);
        this.showErrorToastMsg(AppSettings.ERROR_MSG)
        this.isCalendarLoading = false;
      }
    );
  }

  sendReminder() {
    this.showProgressBar()
    this.appointmentService.sendReminder(this.appointmentId).subscribe(
      (response) => {
        var json = JSON.parse(JSON.stringify(response));
        console.log('Send reminder response --->> ' + JSON.stringify(json));
        if (json.response.status == 'SUCCESS') {
          this.isReminder = false
          this.showSuccessMessage(json.response.status, json.response.displayMessage)
        } else {
          this.showErrorMessage(json.response.displayMessage)
        }
        this.hideProgressBar()
      },
      (error) => {
        console.log(error);
        this.showErrorToastMsg(AppSettings.ERROR_MSG)
        this.hideProgressBar()
      }
    );
  }

  getServiceTypeList() {
    this.showProgressBar();
    this.serviceTypeService.serviceTypeList().subscribe(
      (response) => {
        var json = JSON.parse(JSON.stringify(response));
        console.log('Service type response --> ' + JSON.stringify(json));
        if (json.response.status == 'SUCCESS') {
          this.serviceTypeList = []
          json.data.forEach((element: any) => {
            this.serviceTypeList.push({ name: element.name, value: element.id, serviceDuration: element.serviceDuration, specialInstructions: element.specialInstructions })
          });

        } else {
          this.showErrorMessage(json.response.displayMessage)
        }
        this.hideProgressBar()
      }, (error) => {
        console.log(error);
        this.showErrorMessage(AppSettings.ERROR_MSG)
        this.hideProgressBar();
      })
  }

  getServiceProviderByServiceTypeList(value: any) {
    this.showProgressBar();
    const serviceData = {
      id: value
    }
    var data = { data: JSON.parse(JSON.stringify(serviceData)) };
    this.serviceProviderService.getServiceProviderByServicetypeList(data).subscribe(
      (response) => {
        var json = JSON.parse(JSON.stringify(response));
        console.log('Service provider response --> ' + JSON.stringify(json));
        if (json.response.status == 'SUCCESS') {
          this.serviceProviderList = []
          json.data.forEach((element: any) => {
            this.serviceProviderList.push({ name: element.name, value: element.id })
          });
        } else {
          this.showErrorMessage(json.response.displayMessage)
        }
        this.hideProgressBar()
      }, (error) => {
        console.log(error);
        this.showErrorMessage(AppSettings.ERROR_MSG)
        this.hideProgressBar();
      })
  }

  confirmAddEditDialog(type: any) {
    this.messageService.clear('errMsg');
    if (this.serviceType == '' || this.serviceType == 'null') {
      this.showErrorMessage('Service Type field is empty.')
      return;
    } if (this.serviceProvider == '' || this.serviceProvider == 'null') {
      this.showErrorMessage('Service Provider field is empty.')
      return;
    } if (this.client == '' || this.client == 'null') {
      this.showErrorMessage('Client field is empty.')
      return;
    } if (this.appointmentStartDateTime == '') {
      this.showErrorMessage('Appointment start date/time field is empty.')
      return;
    } if (this.appointmentEndDateTime == '') {
      this.showErrorMessage('Appointment end date/time field is empty.')
      return;
    }
    var selectedClient = this.clients.find(x => x.value == this.client).userType;
    this.checkUserType(selectedClient || '')
    this.confirmationService.confirm({
      message: type == 'add_appointment' ? 'Do you want to add this appointment ?' : 'Do you want to edit this appointment ?',
      header: type == 'add_appointment' ? 'Add Confirmation' : 'Edit Confirmation',
      icon: type == 'add_appointment' ? 'pi pi-calendar-plus' : 'pi pi-calendar-minus',
      accept: () => {
        if (type == 'add_appointment') {
          this.addAppointment()
        } else {
          this.editAppointment()
        }
      }
    });
  }

  confirmCancelDialog(type: string) {
    let messageTxt = '';
    let headerTxt = '';
    let iconValue = '';
    if (type == 'cancel_appointment') {
      headerTxt = 'Cancel Confirmation';
      messageTxt = 'Do you want to cancel this appointment ?';
      iconValue = 'pi pi-calendar-times';
    } else if (type == 'send_reminder') {
      this.isReminder = true;
      headerTxt = 'Send Reminder Confirmation';
      messageTxt = 'Do you want to send reminder ?';
      iconValue = 'pi pi-bell';
    } else {
      headerTxt = 'Cancel All Confirmation';
      messageTxt = 'There are existing appointments in the slot. Do you want to cancel the appointment ?';
      iconValue = 'pi pi-calendar-times';
    }
    this.confirmationService.confirm({
      message: messageTxt,
      header: headerTxt,
      icon: iconValue,
      accept: () => {
        // this.clickInfo?.event.remove();
        if (type == 'cancel_appointment') {
          this.cancelAppointment();
        } else if (type == 'send_reminder') {
          this.sendReminder();
        } else {
          this.cancelAllAppointment();
        }

      }, reject: () => {
        if (type == 'send_reminder') {
          this.isReminder = false;
        }
      }
    });
  }

  async showHideModalDialog(type: string) {
    switch (type) {
      case 'edit_appointment':
        this.headerName = 'Edit Appointment';
        this.displayAddEditModal = !this.displayAddEditModal;
        break;
      case 'add_appointment':
        this.clearFormData();
        this.headerName = 'Add Appointment';
        this.displayAddEditModal = !this.displayAddEditModal;
        break;
      case 'view_appointment':
        // this.clearFormData();

        this.headerName = 'View Appointment';
        this.visibleViewAppointment = !this.visibleViewAppointment
        break;
      case 'add_block_slots':
        this.clearFormData();
        this.headerName = 'Add Block Slots';
        this.displayBlockModal = !this.displayBlockModal
        break;
      case 'view_block_slots':
        this.headerName = 'View Block Slots';
        this.displayBlockModal = !this.displayBlockModal
        break;
      case 'send_reminder':
        this.headerName = 'View Appointment';
        this.confirmCancelDialog(type)
        break;
      case 'cancel_appointment':
        this.headerName = 'View Appointment';
        this.confirmCancelDialog(type)
        break;
      case 'remove_test_appointment':
        await this.getTestAppointment();
        this.headerName = 'Test Appointment';
        this.visibleTestAppointment = !this.visibleTestAppointment
        break;
    }
  }

  clearFormData() {
    this.messageService.clear('checkMsg');
    this.messageService.clear('errMsg');
    this.appointmentStartDateTime = '';
    this.appointmentEndDateTime = '';
    this.serviceType = 'null';
    this.serviceProvider = 'null';
    this.client = 'null';
    this.specialInstruction = '';
    this.serviceDuration = null;
    if (this.twillioNumber == null) {
      this.sendNotification = false
      this.disabledSendNotification = true
    } else {
      this.sendNotification = true
      this.disabledSendNotification = false
    }
    this.blockComment = '';
    this.slotDisabled = false;
  }


  showErrorMessage(msg: string) {
    this.messageService.add({
      key: 'errMsg',
      severity: 'error',
      detail: msg,
    });
  }

  showCheckMessage(status: string, msg: string) {
    this.messageService.add({
      key: 'checkMsg',
      severity: status,
      detail: msg,
    });
  }

  showErrorToastMsg(msg: string) {
    this.messageService.add({
      key: 'br',
      severity: 'error',
      detail: msg,
    });
  }

  showSuccessMessage(status: string, msg: string) {
    this.messageService.add({
      key: 'br',
      severity: 'success',
      summary: status,
      detail: msg,
    });
  }

  hideProgressBar() {
    this.isLoading = false;
    this.disabled = false;
  }

  showProgressBar() {
    this.messageService.clear('errMsg');
    this.isLoading = true;
    this.disabled = true;
  }

  showDialogMaximized(event: any, dialog: Dialog) {
    if (this.innerWidth <= 640) {
      dialog.maximized = true;
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.innerWidth = window.innerWidth;
  }

  checkUserType(type: string) {
    if (type == 'nonchatstasy') {
      this.isUserNonchatstasy = true
    } else {
      this.isUserNonchatstasy = false
    }

  }

  setEndDateTime() {
    if (this.appointmentStartDateTime != '') {
      let startDateTime = new Date(this.appointmentStartDateTime)
      if (this.serviceDuration) {
        let minute = (this.serviceDuration.split(':')[0] * 60) + parseInt(this.serviceDuration.split(':')[1])
        this.appointmentEndDateTime = this.dateAdd(startDateTime, 'minute', minute);
        console.log('minute:', minute, ',date:', this.dateAdd(startDateTime, 'minute', minute))
      }
    }
  }

  onServiceTypeChange(event: any, from: string) {
    // console.log(event.target.value);
    console.log('event:' + JSON.stringify(event))
    this.serviceDuration = this.serviceTypeList.find(obj => obj.value == event.value).serviceDuration
    this.setEndDateTime()
    this.specialInstruction = this.serviceTypeList.find(obj => obj.value == event.value).specialInstructions || ''
    this.getServiceProviderByServiceTypeList(event.value)
    this.serviceProvider = '';
    this.checkConflict('add_appointment');
  }

  onServiceProviderChange(event: any){
    this.checkConflict('add_appointment');
  }

  onDateChange(event: any, type: string) {
    this.setEndDateTime()
    this.checkConflict(type);
  }

  onFilterValueChange(event: any) {
    this.listAppointment();
  }

  dateAdd(date: any, interval: any, units: any) {
    if (!(date instanceof Date))
      return undefined;
    var ret: any = new Date(date); //don't change original date
    var checkRollover = function () { if (ret.getDate() != date.getDate()) ret.setDate(0); };
    switch (String(interval).toLowerCase()) {
      case 'year': ret.setFullYear(ret.getFullYear() + units); checkRollover(); break;
      case 'quarter': ret.setMonth(ret.getMonth() + 3 * units); checkRollover(); break;
      case 'month': ret.setMonth(ret.getMonth() + units); checkRollover(); break;
      case 'week': ret.setDate(ret.getDate() + 7 * units); break;
      case 'day': ret.setDate(ret.getDate() + units); break;
      case 'hour': ret.setTime(ret.getTime() + units * 3600000); break;
      case 'minute': ret.setTime(ret.getTime() + units * 60000); break;
      case 'second': ret.setTime(ret.getTime() + units * 1000); break;
      default: ret = undefined; break;
    }
    return ret;
  }

  toISOStringLocal(d: any) {
    function z(n: any) { return (n < 10 ? '0' : '') + n }
    return d.getFullYear() + '-' + z(d.getMonth() + 1) + '-' +
      z(d.getDate()) + 'T' + z(d.getHours()) + ':' +
      z(d.getMinutes()) + ':' + z(d.getSeconds())
  }

  getPrivilege(id: any) {
    return this.privilegeService.getPrivileges(id);
  }

  get state(): string {
    return this.utils.state;
  }

  getTestAppointment(){
    this.appointmentService.ListTestAppointment().subscribe(
      (response) => {
        var json = JSON.parse(JSON.stringify(response));
        console.log('List Test Appointment Response --->> ' + JSON.stringify(json));
        this.appointmentList = json.data;
      },
      (error) => {
        console.log(error);
        this.showErrorMessage(AppSettings.ERROR_MSG)
        this.hideProgressBar();
      });    
  }

  removeTestAppointment(){
    this.showProgressBar();
    this.appointmentService.removeTestAppointment().subscribe(
      (response) =>{
        var json = JSON.parse(JSON.stringify(response));
        console.log('Remove Test Appointment Response --->> ' + JSON.stringify(json));
        this.hideProgressBar();
        this.visibleTestAppointment = !this.visibleTestAppointment;
        this.showSuccessMessage(json.response.status, json.response.message);
        this.listAppointment();
      },
      (error) => {
        console.log(error);
        this.showErrorMessage(AppSettings.ERROR_MSG)
        this.hideProgressBar();
      }
    )
  }

  checkHolidaysConflict(){
    this.isCalendarLoading = true;
    this.businessHolidaysService.getConfirmHolidaysList().subscribe(
      (response) => {
        var json = JSON.parse(JSON.stringify(response));
        console.log('Business Holidays response --> ' + JSON.stringify(json));
        if (json.response.status == 'SUCCESS') {
          if(json.data != null){
            this.holidays = json.data.map((holiday:any) => holiday.date);
            this.invalidDates = json.data.map((dateString:any) => new Date(dateString.date));
            json.data.forEach((element:any) => {              
              this.INITIAL_EVENTS.push({
                id: element.id,
                title: element.name,
                clientName: element.name,
                start: element.date,
                end: element.date,
                allDay: true,
                backgroundColor: "#ccc",
                borderColor: "#ccc",
                color: "#ccc",
                isBlocked: true,
              });
            });
            console.log(this.INITIAL_EVENTS);
            this.calendarComponent!.options!.events = this.INITIAL_EVENTS;
          }       
        } else {
          this.showErrorMessage(json.response.displayMessage)
        }
        this.isCalendarLoading = false;
    },
    (error) => {
      console.log(error);
      this.showErrorToastMsg(AppSettings.ERROR_MSG)
      this.isCalendarLoading = false;
    });
  }
}
