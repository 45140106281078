<div [ngClass]="state=='open' ? 'main_content_open' : 'main_content'">
    <p-scrollPanel [style]="{width: '100%',height: '95vh','margin-top':'7px','padding-bottom':'70px'}" #sc>
        <app-message-credit-warning></app-message-credit-warning>
        <app-twillio-message></app-twillio-message>
        <div class="p-d-flex p-jc-between p-ai-center p-pt-3 p-pb-3">
            <div class="page_title">
                Chat Room Configuration
            </div>
            <div>
                <p-breadcrumb [model]="items" [home]="home"></p-breadcrumb>
            </div>
        </div>
        <div class="p-mb-5">
            <p-card>
                <div class="container-fluid bg-white">
                    <div class="row">
                        <div class="col-md-6">
                            <fieldset>
                                <legend>Change Chat Room</legend>
                                <p-messages key="errMsg" [style]="{ 'font-size': '12px' }"></p-messages>
                                <form role="form" class="form-horizontal" (ngSubmit)="editChatRoom(chatButtonForm)"
                                    #chatButtonForm="ngForm">
                                    <p-tabView dynamic="true" (onChange)="customGraphicTypeChangeEvent($event)" [activeIndex]="activeTab">
                                        <p-tabPanel header="Default Banner Image" [headerStyle]="{'width': '50%'}"> 
                                            <div class="fileinput fileinput-new text-center" style="margin-bottom: 1rem;"
                                            data-provides="fileinput">
                                            <div class="user-image mt-2">
                                                    <strong>Business Banner: &nbsp; &nbsp; </strong>
                                                    <div class="fileinput-new img-thumbnail">
                                                        <img style="width: 100px;height: 100px;object-fit: contain;"
                                                            [src]="profileUrl" alt="" (error)="fileFailedToLoad($event)" />
                                                    </div>
                                                    <div class=" user-image-buttons">
                                                        <span class="btn btn-azure btn-file btn-sm"
                                                            (click)="displayCropModal2 = true">
                                                            <span class="fileinput-new">
                                                                <i class="fa fa-pencil"></i>
                                                            </span>
                                                        </span>
                                                        <a *ngIf="!imageInputError" href="javascript:void(0)"
                                                            (click)="confirmDeleteDialog()"
                                                            class="btn fileinput-exists btn-red btn-sm">
                                                            <i class="fa fa-times"></i>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="form-group center mt-2">
                                                <p-progressSpinner *ngIf="isLoading" [style]="{ width: '40px', height: '40px' }"
                                                    strokeWidth="3">
                                                </p-progressSpinner>
                                                <button pButton pRipple type="button" label="Reset"
                                                    class="p-button-text p-button-plain p-ml-2 p-mr-4"
                                                    (click)="resetChatRoom()"></button>
        
                                                <button pButton pRipple type="button" class="p-button-raised "
                                                    (click)="editChatRoom(chatButtonForm)" label="Save"
                                                    [disabled]="disabled"></button>
                                            </div>

                                        </p-tabPanel>
                                        <p-tabPanel header="Customize Banner Image" [headerStyle]="{'width': '50%'}">

                                            <div class="fileinput fileinput-new text-center" style="margin-bottom: 1rem;" data-provides="fileinput">
                                                <div class="user-image mt-2">
                                                    <strong>Business Logo: &nbsp; &nbsp; </strong>
                                                    <div class="fileinput-new img-thumbnail">
                                                        <img style="width: 100px;height: 100px;object-fit: contain;"
                                                            [src]="profileUrl" alt="" (error)="fileFailedToLoad($event)" />
                                                    </div>
                                                    <div class=" user-image-buttons">
                                                        <span class="btn btn-azure btn-file btn-sm"
                                                            (click)="displayCropModal = true">
                                                            <span class="fileinput-new">
                                                                <i class="fa fa-pencil"></i>
                                                            </span>
                                                        </span>
                                                        <a *ngIf="!imageInputError" href="javascript:void(0)"
                                                            (click)="confirmDeleteDialog()"
                                                            class="btn fileinput-exists btn-red btn-sm">
                                                            <i class="fa fa-times"></i>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="row form-group">
                                                <label class="col-sm-4 control-label">First Line </label>
                                                <div class="col-sm-8">
                                                    <input type="text" name="businessName" class="form-control"
                                                        placeholder="Enter Business Name" [(ngModel)]="businessName"
                                                        [disabled]="disabled" required />
                                                </div>
                                            </div>

                                            <div class="form-group">
                                                <label class="col-sm-4 control-label">Text Color</label>
                                                <div class="col-sm-2">
                                                    <p-colorPicker name="textColor" [(ngModel)]="textColor"
                                                        [disabled]="disabled">
                                                    </p-colorPicker>
                                                </div>
                                                <label class="col-sm-4 control-label">Background Color</label>
                                                <div class="col-sm-2">
                                                    <p-colorPicker name="backgroundColor" [(ngModel)]="backgroundColor"
                                                        [disabled]="disabled">
                                                    </p-colorPicker>
                                                </div>
                                            </div>

                                            <div class="row form-group">
                                                <label class="col-sm-2 control-label">Font</label>
                                                <div class="col-sm-4">
                                                    <select [(ngModel)]="font" name="font"
                                                        class="form-control" style="height: 34px;">
                                                      <option value="Arial">Arial</option>
                                                      <option value="Verdana">Verdana</option>
                                                      <option value="Tahoma">Tahoma</option>
                                                      <option value="Trebuchet">Trebuchet</option>
                                                      <option value="Times New Roman">Times New Roman</option>
                                                      <option value="Georgia">Georgia</option>
                                                      <option value="Garamond">Garamond</option>
                                                      <option value="Courier New">Courier New</option>
                                                      <option value="Helvetica">Helvetica</option>
                                                    </select>
                                                </div>

                                                <label class="col-sm-2 control-label">Alignment</label>
                                                <div class="col-sm-4">
                                                    <select [(ngModel)]="alignment" name="alignment"
                                                        class="form-control" style="height: 34px;">
                                                      <option value="left">Left</option>
                                                      <option value="center">Middle</option>
                                                      <option value="right">Right</option>
                                                    </select>
                                                </div>
                                            </div>
                                              
                                            <div class="row form-group">
                                                <label class="col-sm-2 control-label">Size</label>
                                                <div class="col-sm-2">
                                                    <input name= "size" type="number" [(ngModel)]="size" class="form-control" (ngModelChange)="onSizeChange()"/>
                                                </div>
                                                
                                                <div class="col-sm-2" style="margin-top: 7px;">
                                                    <label class="form-check-label" for="inlineCheckbox1">
                                                        <span style=" margin-right: 5px; ">Bold</span>
                                                        <input name="bold" class="form-check-input" [(ngModel)]="bold" (change)="onBoldChange()" type="checkbox" id="inlineCheckbox1">
                                                    </label>
                                                    
                                                </div>

                                                <div class="col-sm-2" style="margin-top: 7px;">
                                                    <label class="form-check-label" for="inlineCheckbox2">
                                                        <span style=" margin-right: 5px; ">Italic</span>
                                                        <input name="italic" class="form-check-input" [(ngModel)]="italic" type="checkbox" id="inlineCheckbox2">
                                                    </label>
                                                </div>

                                                <div class="col-sm-3" style="margin-top: 7px;">
                                                    <label class="form-check-label" for="inlineCheckbox3">
                                                        <span style=" margin-right: 5px; ">Underline</span>
                                                        <input name="underline" class="form-check-input" [(ngModel)]="underline" type="checkbox" id="inlineCheckbox3">
                                                    </label>
                                                </div>
                                                
                                                <!-- <div class="checkbox-inline">
                                                    <div class="form-check form-check-inline">
                                                        <input name="bold" class="form-check-input" [(ngModel)]="bold" (onChange)="onBoldChange()" type="checkbox" id="inlineCheckbox1">
                                                        <label class="form-check-label col-sm-4" for="inlineCheckbox1">Bold</label>
                                                      </div>
                                                      <div class="form-check form-check-inline">
                                                        <input name="italic" class="form-check-input" [(ngModel)]="italic" type="checkbox" id="inlineCheckbox2">
                                                        <label class="form-check-label col-sm-4" for="inlineCheckbox2">Italic</label>
                                                      </div>
                                                      <div class="form-check form-check-inline">
                                                        <input name="underline" class="form-check-input" [(ngModel)]="underline" type="checkbox" id="inlineCheckbox3">
                                                        <label class="form-check-label col-sm-4" for="inlineCheckbox3">Underline</label>
                                                      </div>
                                                </div> -->
                                                
                                            </div>


                                            <div class="row form-group">
                                                <label class="col-sm-4 control-label">Second Line </label>
                                                <div class="col-sm-8">
                                                    <input type="text" name="secondLine" class="form-control" [(ngModel)]="secondLine"
                                                        [disabled]="disabled" required  autocomplete="off"/>
                                                </div>
                                            </div>

                                            <div class="form-group">
                                                <label class="col-sm-4 control-label">Text Color</label>
                                                <div class="col-sm-2">
                                                    <p-colorPicker name="secondLineColor" [(ngModel)]="secondLineColor"
                                                        [disabled]="disabled">
                                                    </p-colorPicker>
                                                </div>
                                            </div>
                                            
                                            <div class="row form-group">
                                                <label class="col-sm-2 control-label">Font</label>
                                                <div class="col-sm-4">
                                                    <select [(ngModel)]="phonefont" name="phonefont"
                                                        class="form-control" style="height: 34px;">
                                                    <option value="Arial">Arial</option>
                                                    <option value="Verdana">Verdana</option>
                                                    <option value="Tahoma">Tahoma</option>
                                                    <option value="Trebuchet">Trebuchet</option>
                                                    <option value="Times New Roman">Times New Roman</option>
                                                    <option value="Georgia">Georgia</option>
                                                    <option value="Garamond">Garamond</option>
                                                    <option value="Courier New">Courier New</option>
                                                    <option value="Helvetica">Helvetica</option>
                                                    </select>
                                                </div>

                                                <label class="col-sm-2 control-label">Alignment</label>
                                                <div class="col-sm-4">
                                                    <select [(ngModel)]="phonealignment" name="phonealignment"
                                                        class="form-control" style="height: 34px;">
                                                      <option value="left">Left</option>
                                                      <option value="center">Middle</option>
                                                      <option value="right">Right</option>
                                                    </select>
                                                </div>
                                            </div>

                                            <div class="row form-group">
                                                <label class="col-sm-2 control-label">Size</label>
                                                <div class="col-sm-2">
                                                    <input name= "phonesize" type="number" [(ngModel)]="phonesize" class="form-control" maxlength="25"/>
                                                </div>

                                                <div class="col-sm-2" style="margin-top: 7px;">
                                                    <label class="form-check-label" for="inlineCheckbox4">
                                                        <span style=" margin-right: 5px; ">Bold</span>
                                                        <input name="phonebold" class="form-check-input" [(ngModel)]="phonebold" (onChange)="onBoldChange()" type="checkbox" id="inlineCheckbox4">
                                                    </label>
                                                </div>

                                                <div class="col-sm-2" style="margin-top: 7px;">
                                                    <label class="form-check-label" for="inlineCheckbox5">
                                                        <span style=" margin-right: 5px; ">Italic</span>
                                                        <input name="phoneitalic" class="form-check-input" [(ngModel)]="phoneitalic" type="checkbox" id="inlineCheckbox5">
                                                    </label>
                                                </div>

                                                <div class="col-sm-3" style="margin-top: 7px;">
                                                    <label class="form-check-label" for="inlineCheckbox6">
                                                        <span style=" margin-right: 5px; ">Underline</span>
                                                        <input name="phoneunderline" class="form-check-input" [(ngModel)]="phoneunderline" type="checkbox" id="inlineCheckbox6">
                                                    </label>
                                                </div>

                                                <!-- <div class="checkbox-inline">
                                                    <div class="form-check form-check-inline">
                                                        <input name="phonebold" class="form-check-input" [(ngModel)]="phonebold" (onChange)="onBoldChange()" type="checkbox" id="inlineCheckbox4">
                                                        <label class="form-check-label col-sm-4" for="inlineCheckbox4">Bold</label>
                                                      </div>
                                                      <div class="form-check form-check-inline">
                                                        <input name="phoneitalic" class="form-check-input" [(ngModel)]="phoneitalic" type="checkbox" id="inlineCheckbox5" value="option2">
                                                        <label class="form-check-label col-sm-4" for="inlineCheckbox5">Italic</label>
                                                      </div>
                                                      <div class="form-check form-check-inline">
                                                        <input name="phoneunderline" class="form-check-input" [(ngModel)]="phoneunderline" type="checkbox" id="inlineCheckbox6" value="option3">
                                                        <label class="form-check-label col-sm-4" for="inlineCheckbox6">Underline</label>
                                                      </div>
                                                </div> -->
                                                
                                            </div>

                                            <div class="form-group center mt-2">
                                                <p-progressSpinner *ngIf="isLoading" [style]="{ width: '40px', height: '40px' }"
                                                    strokeWidth="3">
                                                </p-progressSpinner>
                                                <button pButton pRipple type="button" label="Reset"
                                                    class="p-button-text p-button-plain p-ml-2 p-mr-4"
                                                    (click)="resetChatRoom()"></button>
        
                                                <button pButton pRipple type="button" class="p-button-raised "
                                                    (click)="editChatRoom(chatButtonForm)" label="Save"
                                                    [disabled]="disabled"></button>
                                            </div>
                                        </p-tabPanel>
                                    </p-tabView>


                                    <!-- <div [ngClass]="currentPosition=='page_one'?'show':'hide'">
                                        <div class="fileinput fileinput-new text-center" style="margin-bottom: 1rem;"
                                            data-provides="fileinput">
                                            <div class="user-image mt-2">
                                                <div class="fileinput-new img-thumbnail">
                                                    <img style="width: 100px;height: 100px;object-fit: contain;"
                                                        [src]="profileUrl" alt="" (error)="fileFailedToLoad($event)" />
                                                </div>
                                                <div class=" user-image-buttons">
                                                    <span *ngIf="customGraphicType == 'false' " class="btn btn-azure btn-file btn-sm"
                                                        (click)="displayCropModal = true">
                                                        <span class="fileinput-new">
                                                            <i class="fa fa-pencil"></i>
                                                        </span>
                                                    </span>
                                                    <span *ngIf="customGraphicType == 'true' " class="btn btn-azure btn-file btn-sm"
                                                        (click)="displayCropModal2 = true">
                                                        <span class="fileinput-new">
                                                            <i class="fa fa-pencil"></i>
                                                        </span>
                                                    </span>
                                                    <a *ngIf="!imageInputError" href="javascript:void(0)"
                                                        (click)="confirmDeleteDialog()"
                                                        class="btn fileinput-exists btn-red btn-sm">
                                                        <i class="fa fa-times"></i>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
    
                                        <div class="row form-group">
                                            <div class="col-sm-8">
                                                <div class="col-sm-6">
                                                    <div class="p-field-radiobutton">
                                                        <p-radioButton name="customGraphicType" value="false" [(ngModel)]="customGraphicType" inputId="customGraphicType1" (click)="customGraphicTypeChangeEvent('false')"></p-radioButton>
                                                        <label for="customGraphicType1">Defult</label>
                                                    </div>
                                                    
                                                </div>
                                                <div class="col-sm-6">
                                                    <div class="p-field-radiobutton">
                                                        <p-radioButton name="customGraphicType" value="true" [(ngModel)]="customGraphicType" inputId="customGraphicType2" (click)="customGraphicTypeChangeEvent('true')"></p-radioButton>
                                                        <label for="customGraphicType2">Custom</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
    
                                        <div class="row form-group" *ngIf="customGraphicType == 'false' ">
                                            <label class="col-sm-4 control-label">Business Name </label>
                                            <div class="col-sm-8">
                                                <input type="text" name="businessName" class="form-control"
                                                    placeholder="Enter Business Name" [(ngModel)]="businessName"
                                                    [disabled]="disabled" required />
                                            </div>
                                        </div>
    
                                        <div class="row form-group" *ngIf="customGraphicType == 'false' ">
                                            <label class="col-sm-4 control-label">Business Phone</label>
                                            <div class="col-sm-8">
                                                <span class="input-icon"><input type="text" class="form-control"
                                                        placeholder="Enter Phone" name="phone" [(ngModel)]="phone"
                                                        [disabled]="disabled" maxlength="10"
                                                        (keypress)="validateDigitOnly($event)">
                                                    <span class="number">+1</span></span>
                                            </div>
                                        </div>
                                        <div class="row form-group" *ngIf="customGraphicType == 'false' ">
                                            <label class="col-sm-4 control-label">Text Color</label>
                                            <div class="col-sm-8">
                                                <p-colorPicker name="textColor" [(ngModel)]="textColor"
                                                    [disabled]="disabled">
                                                </p-colorPicker>
                                            </div>
                                        </div>
                                        <div class="row form-group" *ngIf="customGraphicType == 'false' ">
                                            <label class="col-sm-4 control-label">Background Color</label>
                                            <div class="col-sm-8">
                                                <p-colorPicker name="backgroundColor" [(ngModel)]="backgroundColor"
                                                    [disabled]="disabled">
                                                </p-colorPicker>
                                            </div>
                                        </div>
                                        <div class="form-group center mt-2" *ngIf="customGraphicType == 'true' ">
                                            <p-progressSpinner *ngIf="isLoading" [style]="{ width: '40px', height: '40px' }"
                                                strokeWidth="3">
                                            </p-progressSpinner>
                                            <button pButton pRipple type="button" label="Reset"
                                                class="p-button-text p-button-plain p-ml-2 p-mr-4"
                                                (click)="resetChatRoom()"></button>
    
                                            <button pButton pRipple type="button" class="p-button-raised "
                                                (click)="editChatRoom(chatButtonForm)" label="Save"
                                                [disabled]="disabled"></button>
                                        </div>   
                                        
                                        <div class="p-grid" *ngIf="customGraphicType == 'false' ">
                                            <div class="p-col p-d-flex p-jc-end" style="padding: 0;">
                                                <button pButton pRipple type="button" icon="pi pi-angle-right" iconPos="right"
                                                    label="Next" class="p-button-secondary p-button-text"
                                                    (click)="goToNextAndPrevious('page_two')"></button>
                                            </div>
                                        </div>

                                    </div>


                                    <div [ngClass]="currentPosition=='page_two'?'show':'hide'">
                                        <h4 class="center">Design Business Name</h4>

                                        <div class="row form-group">
                                            <label class="col-sm-4 control-label">Alignment</label>
                                            <div class="col-sm-8">
                                                <select [(ngModel)]="alignment" name="alignment"
                                                    class="form-control" style="height: 34px;">
                                                  <option value="left">Left</option>
                                                  <option value="center">Middle</option>
                                                  <option value="right">Right</option>
                                                </select>
                                            </div>
                                        </div>
                                          
                                        <div class="row form-group">
                                            <label class="col-sm-4 control-label">Size</label>
                                            <div class="col-sm-8">
                                                <input name= "size" type="number" [(ngModel)]="size" class="form-control" (ngModelChange)="onSizeChange()"/>
                                            </div>
                                        </div>
                                          
                                        <div class="row form-group">
                                            <label class="col-sm-4 control-label">Bold</label>
                                            <div class="col-sm-8">
                                                <input name="bold" type="checkbox" [(ngModel)]="bold" (onChange)="onBoldChange()"/>
                                            </div>
                                        </div>
                                          
                                        <div class="row form-group">
                                            <label class="col-sm-4 control-label">Italics</label>
                                            <div class="col-sm-8">
                                                <input name="italic" type="checkbox" [(ngModel)]="italic" (onChange)="onItalicChange()"/>
                                            </div>
                                        </div>
                                          
                                        <div class="row form-group">
                                            <label class="col-sm-4 control-label">Font</label>
                                            <div class="col-sm-8">
                                                <select [(ngModel)]="font" name="font"
                                                    class="form-control" style="height: 34px;">
                                                  <option value="Arial">Arial</option>
                                                  <option value="Verdana">Verdana</option>
                                                  <option value="Tahoma">Tahoma</option>
                                                  <option value="Trebuchet">Trebuchet</option>
                                                  <option value="Times New Roman">Times New Roman</option>
                                                  <option value="Georgia">Georgia</option>
                                                  <option value="Garamond">Garamond</option>
                                                  <option value="Courier New">Courier New</option>
                                                  <option value="Helvetica">Helvetica</option>
                                                </select>
                                            </div>
                                        </div>


                                        <div class="p-grid">
                                            <div class="p-col p-d-flex p-ai-end" style="text-align: start;padding: 0;">
                                                <button pButton pRipple type="button" icon="pi pi-angle-left" label="Back"
                                                    class="p-button-secondary p-button-text"
                                                    (click)="goToNextAndPrevious('page_one')"></button>
                                            </div>
                                            <div class="p-col p-d-flex p-jc-end" style="padding: 0;">
                                                <button pButton pRipple type="button" icon="pi pi-angle-right" iconPos="right"
                                                    label="Next" class="p-button-secondary p-button-text"
                                                    (click)="goToNextAndPrevious('page_three')"></button>
                                            </div>
                                        </div>
                                    </div>


                                    <div [ngClass]="currentPosition=='page_three'?'show':'hide'">
                                        <h4 class="center">Design Business Number</h4>

                                        <div class="row form-group">
                                            <label class="col-sm-4 control-label">Alignment</label>
                                            <div class="col-sm-8">
                                                <select [(ngModel)]="phonealignment" name="phonealignment"
                                                    class="form-control" style="height: 34px;">
                                                  <option value="left">Left</option>
                                                  <option value="center">Middle</option>
                                                  <option value="right">Right</option>
                                                </select>
                                            </div>
                                        </div>
                                          
                                        <div class="row form-group">
                                            <label class="col-sm-4 control-label">Size</label>
                                            <div class="col-sm-8">
                                                <input name= "phonesize" type="number" [(ngModel)]="phonesize" class="form-control" maxlength="25"/>
                                            </div>
                                        </div>
                                          
                                        <div class="row form-group">
                                            <label class="col-sm-4 control-label">Bold</label>
                                            <div class="col-sm-8">
                                                <input name="phonebold" type="checkbox" [(ngModel)]="phonebold" (onChange)="onBoldChangePhone()"/>
                                            </div>
                                        </div>
                                          
                                        <div class="row form-group">
                                            <label class="col-sm-4 control-label">Italics</label>
                                            <div class="col-sm-8">
                                                <input name="phoneitalic" type="checkbox" [(ngModel)]="phoneitalic" (onChange)="onItalicChangePhone()"/>
                                            </div>
                                        </div>
                                          
                                        <div class="row form-group">
                                            <label class="col-sm-4 control-label">Font</label>
                                            <div class="col-sm-8">
                                                <select [(ngModel)]="phonefont" name="phonefont"
                                                    class="form-control" style="height: 34px;">
                                                  <option value="Arial">Arial</option>
                                                  <option value="Verdana">Verdana</option>
                                                  <option value="Tahoma">Tahoma</option>
                                                  <option value="Trebuchet">Trebuchet</option>
                                                  <option value="Times New Roman">Times New Roman</option>
                                                  <option value="Georgia">Georgia</option>
                                                  <option value="Garamond">Garamond</option>
                                                  <option value="Courier New">Courier New</option>
                                                  <option value="Helvetica">Helvetica</option>
                                                </select>
                                            </div>
                                        </div>

                                        <div class="form-group center mt-2" *ngIf="customGraphicType == 'false' ">
                                            <p-progressSpinner *ngIf="isLoading" [style]="{ width: '40px', height: '40px' }"
                                                strokeWidth="3">
                                            </p-progressSpinner>
                                            <button pButton pRipple type="button" label="Reset"
                                                class="p-button-text p-button-plain p-ml-2 p-mr-4"
                                                (click)="resetChatRoom()"></button>
    
                                            <button pButton pRipple type="button" class="p-button-raised "
                                                (click)="editChatRoom(chatButtonForm)" label="Save"
                                                [disabled]="disabled"></button>
                                        </div>


                                        <div class="p-grid">
                                            <div class="p-col p-d-flex p-ai-end" style="text-align: start;padding: 0;">
                                                <button pButton pRipple type="button" icon="pi pi-angle-left" label="Back"
                                                    class="p-button-secondary p-button-text"
                                                    (click)="goToNextAndPrevious('page_two')"></button>
                                            </div>
                                        </div>
                                    </div> -->

                                    
                                </form>
                            </fieldset>
                        </div>
                        <div class="col-md-6 nopadding">
                            <fieldset>
                                <legend>Preview</legend>
                                <p-messages key="tr" [style]="{ 'font-size': '12px' }"></p-messages>
                                <!-- <div class="form-horizontal"> -->
                                    <div class="col-md-10 offset-md-2">
                                        <app-phone-preview [messageType]="'NEW_APPOINTMENT'" [headerShow]= headerShow [cropImage] = profileUrl [getBackgroundColor] = backgroundColor [getBusinessName] = businessName [getPhone] = secondLine [phoneNumberColor] = secondLineColor [getTextColor] = textColor [businessNameAlignment]="alignment" [businessNameSize]="size" [businessNameBold]="bold" [businessNameItalic]="italic"  [businessNameFont]="font" [phoneNumberAlignment]="phonealignment" [phoneNumberSize]="phonesize" [phoneNumberBold]="phonebold" [phoneNumberItalic]="phoneitalic"  [phoneNumberFont]="phonefont" [businessNameUnderline] = underline [phoneNumberUnderline] = phoneunderline></app-phone-preview>
                                        
                                        <!-- <div class="p-d-flex p-jc-between p-ai-center p-p-2"
                                            style="background: #2196F3;border-radius: 4px 4px 0px 0px;">
                                            <i class="pi pi-arrow-circle-left"
                                                style="font-size: 2rem;color: white;"></i>
                                            <div style="font-size: 1.3rem;color: white;font-weight: bold;">
                                                Chatstasy
                                            </div>
                                            <i class="pi pi-home" style="font-size: 2rem;color: white;"></i>
                                        </div>
                                        <div class="p-d-flex p-ai-center p-p-2"
                                            [ngStyle]="{'background-color': backgroundColor}">
                                            <div class="rectangle" [ngStyle]="style" mwlResizable
                                                [validateResize]="validate" [enableGhostResize]="true"
                                                [resizeSnapGrid]="{ left: 1, right: 1 }"
                                                (resizeEnd)="onResizeEnd($event)">
                                                <img *ngIf="profileUrl != 'assets/images/picture.svg'"
                                                    [src]="profileUrl"
                                                    style="width: 100%;height: 100%;border-radius: 3px;object-fit: cover;"
                                                    alt="" />
                                                <div class="resize-handle-right" mwlResizeHandle
                                                    [resizeEdges]="{ right: true }"></div>
                                            </div>
                                            <div class="p-d-flex p-flex-column p-ml-3"
                                                style="font-weight: bolder;text-align: center;"
                                                [ngStyle]="{'color': textColor}">
                                                <div style="font-size: 18px;">
                                                    {{businessName}}
                                                </div>
                                                <div *ngIf="phone != ''" style="font-size: 14px;">+1 {{phone}}
                                                </div>
                                            </div>

                                        </div> -->
                                    </div>
                                    <div class="form-group center mt-2">
                                        <p-progressSpinner *ngIf="isLoading" [style]="{ width: '40px', height: '40px' }"
                                            strokeWidth="3">
                                        </p-progressSpinner>
                                        <div class="col-sm-10 offset-sm-2" style=" padding-top: 10px; ">
                                            <button pButton pRipple type="button" label="Publish"
                                                class="p-button-raised p-mb-2 offset-md-2" (click)="confirmPublishDialog()"
                                                [disabled]="disabledPublish"></button>
                                        
                                            <small *ngIf="!disabledPublish" class="col-sm-12" style="color: #858585;">
                                                Publish your changes to customers in Chatstasy App.
                                            </small>
                                        </div>
                                    </div>
                                <!-- </div> -->
                            </fieldset>
                        </div>

                    </div>

                </div>
            </p-card>

            <!-- <p-card [style]="{ 'margin-top': '2rem' }" header="Create New">
                <div class="container-fluid bg-white">
                    <div class="row">
                        <div class="col-md-6">
                            <fieldset>
                                <legend>Change Chat Room</legend>
                                <p-messages key="errMsg" [style]="{ 'font-size': '12px' }"></p-messages>
                                <form role="form" class="form-horizontal" (ngSubmit)="editChatRoom(chatButtonForm)"
                                    #chatButtonForm="ngForm">
                                    <div class="fileinput fileinput-new text-center" style="margin-bottom: 1rem;"
                                        data-provides="fileinput">
                                        <div class="user-image mt-2">
                                            <div class="fileinput-new img-thumbnail">
                                                <img style="width: 100px;height: 100px;object-fit: contain;"
                                                    [src]="profileUrlNew" alt="" (error)="fileFailedToLoad($event)" />
                                            </div>
                                            <div class=" user-image-buttons">
                                                <span class="btn btn-azure btn-file btn-sm"
                                                    (click)="displayCropModal2 = true">
                                                    <span class="fileinput-new">
                                                        <i class="fa fa-pencil"></i>
                                                    </span>
                                                </span>
                                                <a *ngIf="!imageInputError" href="javascript:void(0)"
                                                    (click)="confirmDeleteDialog()"
                                                    class="btn fileinput-exists btn-red btn-sm">
                                                    <i class="fa fa-times"></i>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row form-group">
                                        <label class="col-sm-4 control-label">Business Name </label>
                                        <div class="col-sm-8">
                                            <input type="text" name="businessName" class="form-control"
                                                placeholder="Enter Business Name" [(ngModel)]="businessName"
                                                [disabled]="disabled" required />
                                        </div>
                                    </div>

                                    <div class="row form-group">
                                        <label class="col-sm-4 control-label">Business Phone</label>
                                        <div class="col-sm-8">
                                            <span class="input-icon"><input type="text" class="form-control"
                                                    placeholder="Enter Phone" name="phone" [(ngModel)]="phone"
                                                    [disabled]="disabled" maxlength="10"
                                                    (keypress)="validateDigitOnly($event)">
                                                <span class="number">+1</span></span>
                                        </div>
                                    </div>
                                    <div class="row form-group">
                                        <label class="col-sm-4 control-label">Text Color</label>
                                        <div class="col-sm-8">
                                            <p-colorPicker name="textColor" [(ngModel)]="textColor"
                                                [disabled]="disabled">
                                            </p-colorPicker>
                                        </div>
                                    </div>
                                    <div class="row form-group">
                                        <label class="col-sm-4 control-label">Background Color</label>
                                        <div class="col-sm-8">
                                            <p-colorPicker name="backgroundColor" [(ngModel)]="backgroundColor"
                                                [disabled]="disabled">
                                            </p-colorPicker>
                                        </div>
                                    </div>
                                    <div class="form-group center mt-2">
                                        <p-progressSpinner *ngIf="isLoading" [style]="{ width: '40px', height: '40px' }"
                                            strokeWidth="3">
                                        </p-progressSpinner>
                                        <button pButton pRipple type="button" label="Reset"
                                            class="p-button-text p-button-plain p-ml-2 p-mr-4"
                                            (click)="resetChatRoom()"></button>

                                        <button pButton pRipple type="button" class="p-button-raised "
                                            (click)="editChatRoom(chatButtonForm)" label="Save"
                                            [disabled]="disabled"></button>
                                    </div>
                                </form>
                            </fieldset>
                        </div>
                        <div class="col-md-6">
                            <fieldset>
                                <legend>Preview</legend>
                                <p-messages key="tr" [style]="{ 'font-size': '12px' }"></p-messages>
                                <div class="form-horizontal">
                                    <div>
                                        <div class="p-d-flex p-jc-between p-ai-center p-p-2"
                                            style="background: #2196F3;border-radius: 4px 4px 0px 0px;">
                                            <i class="pi pi-arrow-circle-left"
                                                style="font-size: 2rem;color: white;"></i>
                                            <div style="font-size: 1.3rem;color: white;font-weight: bold;">
                                                Chatstasy
                                            </div>
                                            <i class="pi pi-home" style="font-size: 2rem;color: white;"></i>
                                        </div>
                                        <div class="p-d-flex p-ai-center p-p-2"
                                            [ngStyle]="{'background-color': backgroundColor}">
                                            <div *ngIf="profileUrlNew != 'assets/images/picture.svg'" class="rectangle" [ngStyle]="style" mwlResizable
                                                [validateResize]="validate" [enableGhostResize]="true"
                                                [resizeSnapGrid]="{ left: 1, right: 1 }"
                                                (resizeEnd)="onResizeEnd($event)">
                                                <img [src]="profileUrlNew"
                                                    style="width: 100%;height: 100%;border-radius: 3px;object-fit: cover;"
                                                    alt="" />
                                                <div class="resize-handle-right" mwlResizeHandle
                                                    [resizeEdges]="{ right: true }"></div>
                                            </div>
                                            <div class="p-d-flex p-flex-column p-ml-3"
                                                style="font-weight: bolder;text-align: center;"
                                                [ngStyle]="{'color': textColor}">
                                                <div style="font-size: 18px;">
                                                    {{businessName}}
                                                </div>
                                                <div *ngIf="phone != ''" style="font-size: 14px;">+1 {{phone}}
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    <div class="form-group center mt-2">
                                        <p-progressSpinner *ngIf="isLoading" [style]="{ width: '40px', height: '40px' }"
                                            strokeWidth="3">
                                        </p-progressSpinner>
                                        <button pButton pRipple type="button" label="Publish"
                                            class="p-button-raised p-mb-2" (click)="confirmPublishDialog()"
                                            [disabled]="disabledPublish"></button>

                                        <small *ngIf="!disabledPublish" class="col-sm-12" style="color: #858585;">
                                            Publish your changes to customers in Chatstasy app.
                                        </small>
                                    </div>
                                </div>
                            </fieldset>
                        </div>

                    </div>

                </div>
            </p-card> -->
        </div>
    </p-scrollPanel>
</div>
<!-- REMOVE LOGO MODAL -->
<p-confirmDialog [baseZIndex]="10000" rejectButtonStyleClass="p-button-text" [style]="{width:'45vw'}">
</p-confirmDialog>
<!-- CROP MODAL -->
<app-image-cropper-dialog #renew [displayCropModal]="displayCropModal" (hideCropModal)="hideCropModal($event)"
    (croppedImage)="croppedImage($event)">
</app-image-cropper-dialog>

<!-- RESIZE CROP MODAL -->
<app-image-resize-cropper-dialog  #renew [displayCropModal]="displayCropModal2" (hideCropModal)="hideCropModal($event)" (croppedImage)="croppedImage($event)" [aspectRatio] = aspectRatio [ratioChanged] = aspectRatio [resizeToWidth]="resizeToWidth" [resizeToHeight]="resizeToHeight">
</app-image-resize-cropper-dialog>