import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { AuthenticationService } from '../authentication/authentication.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(
    private authenticationService: AuthenticationService,
    private router: Router,
  ) { }

  canActivate(route: ActivatedRouteSnapshot): boolean {
    if (this.checkActiveState('home', route)) {
      return this.canActivateForHome();
    } else {
      return this.canActiveForOthers();
    }
  }

  canActiveForOthers(): boolean {
    console.log('Auth Guard respo:', this.authenticationService.isSubscribed())
    if (this.authenticationService.isLoggedIn() && this.authenticationService.isSubscribed()) {
      return true;
    } else {
      this.router.navigate(['/home']);
      return false;
    }
  }

  canActivateForHome(): boolean {
    if (this.authenticationService.isLoggedIn()) {
      return true;
    } else {
      this.router.navigate(['/login']);
      return false;
    }
  }

  checkActiveState(givenLink: string, route: ActivatedRouteSnapshot): boolean {
    let value = false
    for (let i = 0; i < route.url.length; i++) {
      if (route.url[i].path === givenLink) {
        console.log(route.url[i].path);
        value = true;
      } else {
        value = false;
        break;
      }
    }
    return value;
  }
}
