export const environment = {
  production: true,
  API_URL_WITHOUT_PORT: "https://chatstasy.biz", //"http://45.79.178.215", 34.214.226.249, https://chatstasy.biz
  API_URL: "https://api.chatstasy.biz", //"http://45.79.178.215:8020", https://api.chatstasy.biz
  IMAGE_BASE_URL: "https://api.chatstasy.biz/api/anonymous/cdn/",//'http://45.79.178.215:8020/api/anonymous/cdn/',  
  MOBILEADMINPORTAL: "http://52.36.142.38/api/services",
  WEBSOCKET: "https://chatstasydev.app/websocket",
  ADMIN_API_PORTAL_URL: "https://adminapi.chatstasy.biz",
  // API_URL_WITHOUT_PORT: "http://localhost",
  // API_URL: "http://localhost:8020",
  // IMAGE_BASE_URL: "http://localhost:8020/api/anonymous/cdn/",
  // MOBILEADMINPORTAL: "http://localhost/api/services",
  // WEBSOCKET: "ws://localhost:4000",
  FRESHDESK_URL: "https://chatstasy.freshdesk.com",
  FRESHDESK_API_KEY: "1Q6zC8iEUlckfSasIRyB",
  FRESHDESK_TOKEN: 'MVE2ekM4aUVVbGNrZlNhc0lSeUI6WA==',
  SQUAREUP_APP_ID:'sandbox-sq0idb-oQkmVtiwJOacyhiPDMvxMg',//->>>Sandbox
  SQUAREUP_LOCATION_ID:'LSRM666P6036Y',//->>>Sandbox

  // SQUAREUP_APP_ID:'sq0idp-mb71si30eG3Obv2Qli3dZg',//->>>Production
  // SQUAREUP_LOCATION_ID:'LT9SN4E9HYC4W',//->>>Production


  // firebaseConfig: {
  //   apiKey: "AIzaSyDNX5alPwfqYnRtxzmF1d1nEHuQiqBBlxQ",
  //   authDomain: "chatstasy-52116.firebaseapp.com",
  //   projectId: "chatstasy-52116",
  //   storageBucket: "chatstasy-52116.appspot.com",
  //   messagingSenderId: "571243245038",
  //   appId: "1:571243245038:web:d03326223b71d589e672ff",
  //   databaseURL: "https://chatstasy-52116.firebaseio.com/",
  // },
  
  firebaseConfig:{
    apiKey: "AIzaSyAwQyXO9mVHlMd9zTlVzpzAomV9iXjP-g0",
    authDomain: "chatstasy-biztest.firebaseapp.com",
    projectId: "chatstasy-biztest",
    storageBucket: "chatstasy-biztest.appspot.com",
    messagingSenderId: "474166342732",
    appId: "1:474166342732:web:f2dce8e58ef87f978270e9",
    measurementId: "G-ZSHS5D6HHN",
    databaseURL: 'https://chatstasy-biztest-default-rtdb.firebaseio.com/'
  }
};
