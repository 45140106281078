import { Component, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { PrivilegeService } from 'src/app/services/privilege/privilege.service';
import { UtilsService } from 'src/app/services/utils/utils.service';

@Component({
  selector: 'app-message-credit-warning',
  templateUrl: './message-credit-warning.component.html',
  styleUrls: ['./message-credit-warning.component.css']
})
export class MessageCreditWarningComponent implements OnInit {
  showHideMsgCredit: boolean = false;
  showHideCloseBtn: boolean = false;
  severity: string = 'warn';
  msgText: string = ''
  constructor(
    private utilsService: UtilsService,
    private privilegeService: PrivilegeService,
  ) { }

  ngOnInit(): void {
    this.utilsService.creditCount.subscribe(
      (creditCount) => {
        if (parseInt(creditCount) == 0) {
          this.showHideCloseBtn = true;
          this.msgText = 'You have no message credit left.'
          this.severity = 'error'
        } else {
          this.showHideCloseBtn = false;
          this.msgText = 'You have ' + creditCount + ' message credit left.'
          this.severity = 'warn'
        }
        if (parseInt(creditCount) <= 10 && creditCount != 'null') {
          this.showHideMsgCredit = true;
        } else {
          this.showHideMsgCredit = false;
        }
      }
    )
  }

  ngAfterViewInit() {

  }


  openMsgModalCredit() {
    this.utilsService.setCreditModalShowHide(true)
  }

  hideMsgCredit() {
    this.showHideMsgCredit = false;
  }

  getPrivilege(id: any) {
    return this.privilegeService.getPrivileges(id);
  }
}
