<div class="container" id="smart-button-container" style="margin-top: 5%;">
    <div class="card justify-content-center">
        <div class="card-header">
            <h3 class="text-center">Checkout</h3>
        </div>
        <div class="card-body">
            <p-messages key="errMsg" [style]="{
                'font-size': '12px'
              }"></p-messages>
            <table class="table table-bordered">
                <thead>
                    <tr style="font-size: 1.2rem;">
                        <th>Subscription Type</th>
                        <th>Amount</th>
                    </tr>
                </thead>
                <tbody>
                    <tr style="text-align: center;font-size: 1rem;">
                        <td>{{subscriptionType}}</td>
                        <td>${{amount}}</td>
                    </tr>

                </tbody>
            </table>
        </div>
        <div class="row justify-content-center">
            <div class="col-md-6">

                <ngx-paypal [config]="payPalConfig"></ngx-paypal>
            </div>
        </div>
    </div>
</div><!-- TOAST -->
<p-toast position="bottom-right" key="br"></p-toast>