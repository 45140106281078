import { Component, HostListener, Input, OnInit, Output, ElementRef } from '@angular/core';
import { EventEmitter } from '@angular/core';
import { ICreateOrderRequest, IPayPalConfig } from 'ngx-paypal';
import { MessageService } from 'primeng/api';
import { Dialog } from 'primeng/dialog';
import { AppSettings } from 'src/app/Constant/AppSettings';
import { SubscriptionAndPaymentsService } from 'src/app/services/subscription-and-payments/subscription-and-payments.service';
import { SubscriptionService } from 'src/app/services/subscription/subscription.service';
import { UserService } from 'src/app/services/user/user.service';
import { CommonFunctions } from 'src/app/Utilities/CommonFunctions';
import { Router } from '@angular/router';
declare var Square: any;
import { environment } from 'src/environments/environment.prod';
import { UtilsService } from 'src/app/services/utils/utils.service';

@Component({
  selector: 'app-renew-modal',
  templateUrl: './renew-modal.component.html',
  styleUrls: ['./renew-modal.component.css'],
})
export class RenewModalComponent implements OnInit {
  @Input() from = '';
  @Output() hideModalRenewDialog: any = new EventEmitter();
  @Output() isSuccess: any = new EventEmitter();
  subscriptionPackageList: any[] = []
  stateOptions: any[] = [];
  isLoading: boolean = false;
  timeoutError = '';
  disabled: boolean = false;
  //Renew modal 
  titleText = '';
  currentPosition: string = '';
  packageBtnText: string = 'Proceed with FREE FOREVER';
  packageText: any = {};
  packageId: number = 0;
  subscriptionPlan: number = 0;
  stateValue: string = '';
  calculateInvoice: any[] = [];
  // Dialog display varaible
  @Input() displayRenewModal: boolean = false;
  isMaximize: boolean = false;
  public innerWidth: any;
  //Message Credit
  selectedMessageCredit: any = null;
  messageCreditList: any[] = [];

  //Payment
  public payPalConfig?: IPayPalConfig;
  amount: string = '0';
  subscriptionType: string = '';
  subscriptionId: any = '';
  userId: string = CommonFunctions.getUserId();
  showPaymentGatway : string = 'none';
  coupon: string = '';
  discountValid: boolean = false;
  discountId: string = '';
  SquareupToken: string = "";
  SelectedpaymentGateWay: string ="";
  cashAppPayDetails:any;
  validateUpgradePackageError: boolean = false;

  constructor(
    private userService: UserService,
    private subscriptionService: SubscriptionService,
    private subscriptionAndPaymentsService: SubscriptionAndPaymentsService,
    private messageService: MessageService,
    private router: Router,
    private elementRef:ElementRef,
    public utilsService: UtilsService,
  ) {
    this.utilsService.loadSubscriptionCal.subscribe(
      (isClicked) => {
        if (isClicked) {
          this.calculateSubscription();
        }
      }
    )
   }

  ngOnInit(): void {
    this.innerWidth = window.innerWidth;
    this.titleText = this.from == "message_credit" ? 'Buy Message Credits' : 'Select a Package to Proceed';
    this.currentPosition = this.from == "message_credit" ? 'message_credit' : 'package';
    this.stateValue = 'monthly';
    this.stateOptions = [{ label: 'Monthly', value: 'monthly' }, { label: 'Yearly', value: 'yearly' }];
    if (this.from != "message_credit") {
      this.subsPackageList();
    }
    this.initConfig()
    this.subscriptionType = CommonFunctions.getBusinessInfo().subscriptionType == null ? 'FREE FOREVER' : CommonFunctions.getBusinessInfo().subscriptionType;
    this.getMessageCreditPack()
    console.log(this.from)

    if(this.router.url.toString().toLocaleLowerCase() == '/registration/free'){
      this.packageId = 28;
      this.subscriptionPlan = 0;
      this.subscriptionId = 28;
      this.subscriptionType = "FREE FOREVER";
    }else if(this.router.url.toString().toLocaleLowerCase() == '/registration/basic'){
      this.packageId = 29;
      this.subscriptionPlan = 1;
      this.subscriptionId = 29;
      this.subscriptionType = "BASIC";
    }else if(this.router.url.toString().toLocaleLowerCase() == '/registration/standard'){
      this.packageId = 30;
      this.subscriptionPlan = 2;
      this.subscriptionId = 30;
      this.subscriptionType = "STANDARD";
    }else if(this.router.url.toString().toLocaleLowerCase() == '/registration/free-monthly'){
      this.packageId = 28;
      this.subscriptionPlan = 0;
      this.stateValue = 'monthly';
      this.subscriptionId = 28;
      this.subscriptionType = "FREE FOREVER";
    }else if(this.router.url.toString().toLocaleLowerCase() == '/registration/free-annual'){
      this.packageId = 28;
      this.subscriptionPlan = 0;
      this.stateValue = 'yearly';
      this.subscriptionId = 28;
      this.subscriptionType = "FREE FOREVER";
    }else if(this.router.url.toString().toLocaleLowerCase() == '/registration/basic-monthly'){
      this.packageId = 29;
      this.subscriptionPlan = 1;
      this.stateValue = 'monthly';
      this.subscriptionId = 29;
      this.subscriptionType = "BASIC";
    }else if(this.router.url.toString().toLocaleLowerCase() == '/registration/basic-yearly'){
      this.packageId = 29;
      this.subscriptionPlan = 1;
      this.stateValue = 'yearly';
      this.subscriptionId = 29;
      this.subscriptionType = "BASIC";
    }else if(this.router.url.toString().toLocaleLowerCase() == '/registration/standard-monthly'){
      this.packageId = 30;
      this.subscriptionPlan = 2;
      this.stateValue = 'monthly';
      this.subscriptionId = 30;
      this.subscriptionType = "STANDARD";
    }else if(this.router.url.toString().toLocaleLowerCase() == '/registration/standard-yearly'){
      this.packageId = 30;
      this.subscriptionPlan = 2;
      this.stateValue = 'yearly';
      this.subscriptionId = 30;
      this.subscriptionType = "STANDARD";
    }
    // else if(this.router.url.toString().toLocaleLowerCase() == '/registration/enterprise-monthly'){
    //   this.packageId = 31;
    //   this.subscriptionPlan = 3;
    //   this.stateValue = 'monthly';
    //   this.subscriptionId = 31;
    //   this.subscriptionType = "ENTERPRISE";
    // }else if(this.router.url.toString().toLocaleLowerCase() == '/registration/enterprise-annual'){
    //   this.packageId = 31;
    //   this.subscriptionPlan = 3;
    //   this.stateValue = 'yearly';
    //   this.subscriptionId = 31;
    //   this.subscriptionType = "ENTERPRISE";
    // }

    if(this.from == "subs&pay"){
      if(this.subscriptionType == "FREE FOREVER"){
        this.subscriptionPlan = 0;
        this.packageId = 28;
        this.subscriptionId = 28;
      }else if(this.subscriptionType == "BASIC"){
        this.subscriptionPlan = 1;       
        this.packageId = 29; 
        this.subscriptionId = 29;
      }else if(this.subscriptionType == "STANDARD"){
        this.subscriptionPlan = 2;
        this.packageId = 30;
        this.subscriptionId = 30;
      }else if(this.subscriptionType == "ENTERPRISE"){
        this.subscriptionPlan = 3;
      }
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.innerWidth = window.innerWidth;
  }

  private initConfig(): void {
    this.payPalConfig = {
      currency: "USD",
      clientId:
        "Acn1XRqCXTvV7RpH8TZqvgK1yLgXevuTBZPcu6HBW6qEEDL6buNQOSnJJ9mfxLLJIQgD5aaFafT77h6d",
      createOrderOnClient: (data) =>
        <ICreateOrderRequest>{
          intent: "CAPTURE",
          purchase_units: [
            {
              amount: {
                currency_code: "USD",
                value: this.amount,
                breakdown: {
                  item_total: {
                    currency_code: "USD",
                    value: this.amount,
                  },
                },
              },
              items: [
                {
                  name: this.from == "message_credit" ? this.selectedMessageCredit.name : this.subscriptionType,
                  quantity: "1",
                  category: "DIGITAL_GOODS",
                  unit_amount: {
                    currency_code: "USD",
                    value: this.amount,
                  },
                },
              ],
            },
          ],
        },
      advanced: {
        commit: "true",
        extraQueryParams: [ { name: "disable-funding", value:"credit,card"} ] 
      },
      style: {
        layout: "vertical",
        color: "blue",
        shape: "pill",
        label: "pay",
      },
      onApprove: (data, actions) => {
        console.log(
          "onApprove - transaction was approved, but not authorized",
          data,
          actions
        );
        actions.order.get().then((details: any) => {
          console.log(
            "onApprove - you can get full order details inside onApprove: ",
            details
          );
        });
      },
      onClientAuthorization: (data) => {
        console.log(
          "onClientAuthorization - you should probably inform your server about completed transaction at this point",
          data
        );
        if (data.status == "COMPLETED") {
          console.log("payment Reff no --->> " + data.id);
          this.callPaymentSuccess(data.id,'PAYPAL')

        } else {
          this.showErrorMessage("Oops! Payment failed, please try again.");
          this.hideProgressBar()
        }
      },
      onCancel: (data, actions) => {
        console.log("OnCancel", data, actions);
        this.hideProgressBar();
        // this.showErrorMessage("Oops! Payment failed, please try again.");
      },
      onError: (err) => {
        console.log("OnError", err);
        this.hideProgressBar()
      },
      onClick: (data, actions) => {
        console.log("onClick", data, actions);
        // this.showProgressBar()
      },
    };
  }


  subsPackageList() {
    this.showProgressBar()
    this.subscriptionService.listSubscription().subscribe(
      (response) => {
        var json = JSON.parse(JSON.stringify(response));
        console.log("Package List Response --->> " + JSON.stringify(json));
        if (json.response.status == 'SUCCESS') {
          this.subscriptionPackageList = json.data;
          if(this.packageId == 0){
            this.packageId = this.subscriptionPackageList[0].id
          }
          if(this.subscriptionId == ""){
            this.subscriptionId = this.subscriptionPackageList[0].id;
          }
          this.calculateSubscription();
        } else {
          this.showErrorMessage(json.response.displayMessage)
        }
        this.hideProgressBar();
      },
      (error) => {
        console.log(error);
        this.showErrorMessage(AppSettings.ERROR_MSG)
        this.hideProgressBar();
      }
    );
  }

  checkSingleSubsPackage(subs_id: string) {
    this.showProgressBar()
    this.subscriptionAndPaymentsService.checkSubscription(subs_id).subscribe(
      (response) => {
        var json = JSON.parse(JSON.stringify(response));
        console.log("Check Package Response --->> " + JSON.stringify(json));
        if (json.response.status == 'SUCCESS') {
          this.packageText = json.data;
        } else {
          this.showErrorMessage(json.response.displayMessage)
        }
        this.hideProgressBar();
      },
      (error) => {
        console.log(error);
        this.showErrorMessage(AppSettings.ERROR_MSG)
        this.hideProgressBar();
      }
    );
  }

  getMessageCreditPackage(type: string) {
    this.showProgressBar()
    this.messageCreditList = [];
    this.subscriptionAndPaymentsService.messageCreditPackageList(type).subscribe(
      (response) => {
        var json = JSON.parse(JSON.stringify(response));
        console.log("Message Credit Package Response --->> " + JSON.stringify(json));
        if (json.response.status == 'SUCCESS') {
          if (this.from != "message_credit") {
            this.messageCreditList.push({ description: 'I do not want to buy additional message credits now', id: 'default', name: '' })
          }
          json.data.forEach((element: any) => {
            this.messageCreditList.push(element);
          });
          this.selectedMessageCredit = this.messageCreditList[0];
          this.calculateSubscription();
        } else {
          this.showErrorMessage(json.response.displayMessage)
        }
        this.hideProgressBar();
      },
      (error) => {
        console.log(error);
        this.showErrorMessage(AppSettings.ERROR_MSG)
        this.hideProgressBar();
      }
    );
  }



  calculateSubscription() {
    if (this.from == "message_credit") {
      this.packageId = 0;
      this.stateValue = '';
    }
    let subscription = '';
    if (this.subscriptionType == 'FREE FOREVER') {
      subscription = 'FREE';
    } else {
      subscription = this.subscriptionType
    }

    if(this.from == 'home' && this.packageId == 28 && this.coupon !=''){
      this.showErrorMessage('Discount not applicable for free forever subscription plan');
      return;
    }
    
    this.showProgressBar()

    if(this.from == 'home' && this.packageId == 0){
      this.subsPackageList();
      return;
    }

    if(this.from == 'subs&pay'){
      this.validateUpgradePackageError = this.validateUpgradePackage();
    }

    this.subscriptionAndPaymentsService.calculateSubscription(this.packageId, this.stateValue, this.selectedMessageCredit?.name, subscription, this.coupon).subscribe(
      (response) => {
        var json = JSON.parse(JSON.stringify(response));
        console.log("Calculate Package Response --->> " + JSON.stringify(json));
        if (json.response.status == 'SUCCESS') {
          this.calculateInvoice = json.data;
          json.data.forEach((element: any) => {
            if (element.name == 'Total Amount') {
              this.amount = element.value;
            }
            if(this.coupon != ""){
              if (element.name == 'Discount Amount') {
                if(element.valid == "failure"){
                  this.showErrorMessage(element.value);
                }else{
                  this.discountId = element.id;
                  this.discountValid = true;
                }
              }
            }
          });
        } else {
          this.showErrorMessage(json.response.displayMessage)
        }
        // this.isSuccess.emit('SUCCESS')
        this.hideProgressBar();
      },
      (error) => {
        console.log(error);
        this.showErrorMessage(AppSettings.ERROR_MSG)
        this.hideProgressBar();
      }
    );
  }

  paymentSuccess(paymentRefNo: string, uSubType: string, PaymentGateway: string) {
    this.showProgressBar()
    let messageCreditPackage, tax, proratedCharge, setupFee = '';
    this.calculateInvoice.forEach((element: any) => {
      if (element.name == 'Tax (0%)') {
        tax = element.value;
      }
      if (element.name == 'Setup Fee') {
        setupFee = element.value;
      }
      if (element.name == 'Prorated Charge') {
        proratedCharge = element.value;
      }
    });

    this.messageCreditList.forEach((element: any) => {
      if (element.id == this.selectedMessageCredit.id) {
        messageCreditPackage = element.name;
      }
    });
    // let subscription = '';
    // if (this.subscriptionType == 'FREE FOREVER') {
    //   subscription = 'FREE';
    // } else {
    //   subscription = this.subscriptionType
    // }
    let data = {
      paymentRefNo: paymentRefNo,
      subscriptionId: this.subscriptionId,
      price: this.amount,
      subscriptionType: this.subscriptionType,
      duration: this.stateValue,
      messageCreditPackage: messageCreditPackage,
      tax: tax,
      proratedCharge: proratedCharge,
      setupFee: setupFee,
      uSubType: uSubType,
      PaymentGateway: PaymentGateway
    }

    if(uSubType == 'activation'){
      this.clearFormData();
      Object.assign(data, { discountId: this.discountId });
      this.isSuccess.emit(data);
      return;
    }

    this.subscriptionAndPaymentsService.paymentSuccess(this.userId, this.from, data)
      .subscribe(
        (response) => {
          var json = JSON.parse(JSON.stringify(response));
          console.log("Payment success Response --->> " + JSON.stringify(json));
          if (json.response.status == 'SUCCESS') {
            this.showSuccessMessage(json.response.status, json.response.message)
            this.displayRenewModal = false;
            this.clearFormData();
            if(this.from == "subs&pay" || this.from == "message_credit"){
              this.isSuccess.emit('SUCCESS');
            }
            //  this.isSuccess.emit('SUCCESS')
          } else {
            this.showErrorMessage(json.response.displayMessage)
          }

          this.hideProgressBar();
        },
        (error) => {
          console.log(error);
          this.showErrorMessage(AppSettings.ERROR_MSG)
          this.hideProgressBar();
        }
      );
  }

  renewUser(paymentRefNo: string) {
    this.showProgressBar()
    console.log("auid -> " + this.userId);
    console.log("ast -> " + this.subscriptionType);
    this.subscriptionAndPaymentsService
      .renewUser(
        this.userId,
        this.subscriptionType,
        this.subscriptionId,
        paymentRefNo,
        this.amount,
        this.stateValue
      )
      .subscribe(
        (response) => {
          var json = JSON.parse(JSON.stringify(response));
          console.log("Renew User Response --->> " + JSON.stringify(json));
          if (json.response.status == 'SUCCESS') {
            this.showSuccessMessage(json.response.status, json.response.message)
            this.displayRenewModal = false;
            this.clearFormData();
            this.isSuccess.emit('SUCCESS')
          } else {
            this.showErrorMessage(json.response.displayMessage)
          }

          this.hideProgressBar();
        },
        (error) => {
          console.log(error);
          this.showErrorMessage(AppSettings.ERROR_MSG)
          this.hideProgressBar();
        }
      );
  }

  activateUser(paymentRefNo: string) {
    this.userService
      .activateUser(this.userId, this.subscriptionType, this.subscriptionId, paymentRefNo, this.amount, this.stateValue)
      .subscribe(
        (response) => {
          var json = JSON.parse(JSON.stringify(response));
          console.log("Activate User Response --->> " + JSON.stringify(json));
          if (json.response.status == 'SUCCESS') {
            this.showSuccessMessage(json.response.status, json.response.message)
            this.displayRenewModal = false;
            this.clearFormData();
            this.isSuccess.emit('SUCCESS')
            this.subscriptionService.isPaymentSuccessful = true;
          } else {
            this.showErrorMessage(json.response.displayMessage)
          }
        },
        (error) => {
          console.log(error);
          this.showErrorMessage(AppSettings.ERROR_MSG)
          this.hideProgressBar();
        }
      );
  }

  onPackageSelection(itemSubs: any, from: string) {
    if(from != 'table'){
      itemSubs = this.subscriptionPackageList[itemSubs.target.value];
    }
    console.log('test data:' + JSON.stringify(itemSubs))
    let id = 0
    if (from == 'table') {
      id = itemSubs.coreSubscriptionId
    } else {
      id = itemSubs.id
    }
    if(this.from != 'home'){
      this.checkSingleSubsPackage(id.toString());
    }
    this.packageId = id;
    this.subscriptionId = id;
    console.log('subscriptionId:', id)
    this.subscriptionType = itemSubs.name;
    this.packageBtnText = 'Proceed with ' + itemSubs.name;
    this.getMessageCreditPack()
    console.log(this.subscriptionType)
    // this.calculateSubscription();
  }

  validateUpgradePackage(){
    if(this.from == 'subs&pay'){      
      var result;
      var subType = CommonFunctions.getBusinessInfo().subscriptionType == null ? 'FREE FOREVER' : CommonFunctions.getBusinessInfo().subscriptionType;

      for( var i = 0, len = this.subscriptionPackageList.length; i < len; i++ ) {
        console.log("Name: "+this.subscriptionPackageList[i].name);
        console.log("subType: "+subType);
        if( this.subscriptionPackageList[i].name === subType ) {
            this.subscriptionPackageList[i].setId = i;
            result = this.subscriptionPackageList[i];
            break;
        }
      }

      if(result.setId > this.subscriptionPlan){
        this.showErrorMessage('Could not downgrade the subscription package from the current package.');
        return false;
      }
    }
    return true;
  }

  goToNextAndPrevious(value: string) {
    this.currentPosition = value;
    switch (value) {
      case 'package':
        this.titleText = 'Select a Package to Proceed'
        break;
      case 'message_credit':
        this.titleText = 'Buy Message Credits'
        break;
      case 'review_pay':
        this.calculateSubscription();
        this.titleText = 'Review and Pay'
        break;
    }
  }


  getMessageCreditPack() {
    if (this.subscriptionType == 'FREE FOREVER') {
      this.getMessageCreditPackage('FREE')
    } else {
      this.getMessageCreditPackage(this.subscriptionType)
    }
  }

  callPaymentSuccess(id: string, PaymentGateway: string) {
    if (this.from == 'home') {
      this.paymentSuccess(id, 'activation', PaymentGateway)
    } else if (this.from == 'subs&pay') {
      this.paymentSuccess(id, 'renewal', PaymentGateway)
    } else if (this.from == 'message_credit') {
      this.paymentSuccess(id, '', PaymentGateway)
    }
  }



  clearFormData() {
    this.messageService.clear('errMsg');
    this.timeoutError = '';
    this.isMaximize = false;
    this.titleText = this.from == "message_credit" ? 'Buy Message Credits' : 'Select a Package to Proceed';
    this.currentPosition = this.from == "message_credit" ? 'message_credit' : 'package';
    this.stateValue = 'monthly';
    this.hideModalRenewDialog.emit(this.displayRenewModal)
  }

  twoDigitDecimal(value: string) {
    return parseFloat(value).toFixed(2);
  }

  showProgressBar() {
    this.messageService.clear();
    this.isLoading = true;
    this.disabled = true;
  }

  hideProgressBar() {
    this.isLoading = false;
    this.disabled = false;
  }

  showErrorMessage(msg: string) {
    this.messageService.add({
      key: 'errMsg',
      severity: 'error',
      detail: msg,
    });
  }

  showSuccessMessage(status: string, msg: string) {
    this.messageService.add({
      key: 'br',
      severity: 'success',
      summary: status,
      detail: msg,
    });
  }

  showDialogMaximized(event: any, dialog: Dialog) {
    if (this.innerWidth <= 640) {
      dialog.maximized = true;
    }

  }

  ProcessshowPaymentGatway(paymentGateWay:string){
    this.messageService.clear('errMsg');
    if(this.from == 'home' && this.packageId == 28 && (this.selectedMessageCredit.name == '' || this.selectedMessageCredit.name == '100_CREDITS')){ 
      this.showErrorMessage('FREE FOREVER Plan requires a minimum of 500 messages');
      return;   
    }
    if(this.from == 'home' && this.packageId == 28 && this.stateValue == "monthly") {
      this.showErrorMessage('Monthly payment plan not applicable for the FREE FOREVER Plan.');
      return;
    }
    // this.titleText = "Your Payable Amount "+ this.amount
    this.SelectedpaymentGateWay = paymentGateWay;

    // if(paymentGateWay == 'paypal') {
    //   var interval = setInterval(() => {
    //     let iframe : any = document.getElementsByClassName("component-frame visible")[0];
    //     if(iframe != undefined) {
    //       // let getIdVal: any = document.getElementById(iframe.id);
    //       clearInterval(interval);
    //       console.log(iframe.contentWindow.document.body.getElementsByClassName("paypal-button-number-0"));
    //       // getElementsByClassName('paypal-button-number-1')
    //     }
    //   },1000)
    // }

    this.currentPosition = 'review_pay';
    if(paymentGateWay == "squareupcard"){
      this.showProgressBar();
      setTimeout(() => {
        this.SquareupCardPay();        
        setTimeout(() => {
          this.hideProgressBar();
        }, 3000);

      }, 1000);
    }

    if(paymentGateWay == "squareupother"){

      this.showProgressBar();
      setTimeout(() => {
        this.SquareupGooglePay();
        // this.SquareupApplePay();
        this.SquareupACHPay();
        this.SquareupCashApp();
        
        setTimeout(() => {
          this.hideProgressBar();
        }, 3000);

      }, 1000);
    }
    this.validateUpgradePackageError = true;
    this.titleText = "Select Payment Method.";
  }  

  async SquareupCardPay(){
    let payments = Square.payments(environment.SQUAREUP_APP_ID, environment.SQUAREUP_LOCATION_ID);
    let card:any;
    try {
      card = await this.initializeCard(payments);
    } catch (e) {
      console.error('Initializing Card failed', e);
      return;
    }
    const cardButton:any = document.getElementById('card-button');
    cardButton.addEventListener('click', async (event:any) => {
      this.showProgressBar();
      await this.handlePaymentMethodSubmission(event, card);
    });
  }

  async initializeCard(payments:any) {
    const card = await payments.card();
    await card.attach('#card-container'); 
    return card; 
  }
  
  
  async SquareupGooglePay(){
    let payments = Square.payments(environment.SQUAREUP_APP_ID, environment.SQUAREUP_LOCATION_ID);
    let googlePay:any;
    try {
      googlePay = await this.initializeGooglePay(payments);
    } catch (e) {
      console.error('Initializing Google Pay failed', e);
    }

    if (googlePay !== undefined) {
      const googlePayButton:any = document.getElementById('google-pay-button');
      googlePayButton.addEventListener('click', async (event:any) => {
        this.showProgressBar();
        await this.handlePaymentMethodSubmission(event, googlePay);
      });
    }  
  }

  async initializeGooglePay(payments:any) {
    const paymentRequest = this.buildPaymentRequest(payments); 
    const googlePay = await payments.googlePay(paymentRequest);
    await googlePay.attach('#google-pay-button',{"buttonSizeMode": "fill","buttonType": "long"}); 
    return googlePay;
  }

  buildPaymentRequest(payments:any) {
    return payments.paymentRequest({
      countryCode: 'US',
      currencyCode: 'USD',
      total: {
        amount: this.amount,
        label: 'Total',
      },
    });
  }

  async SquareupApplePay(){
    let payments = Square.payments(environment.SQUAREUP_APP_ID, environment.SQUAREUP_LOCATION_ID);
    let applePay:any;
    try {
      applePay = await this.initializeApplePay(payments);
    } catch (e) {
      console.error('Initializing Apple Pay failed', e);
    }

    if (applePay !== undefined) {
      const applePayButton:any = document.getElementById('apple-pay-button');
      applePayButton.addEventListener('click', async (event:any) => {
        this.showProgressBar();
        await this.handlePaymentMethodSubmission(event, applePay);
      });
    }  
  }

  async initializeApplePay(payments:any) {
    const paymentRequest = this.buildPaymentRequest(payments)
    const applePay = await payments.applePay(paymentRequest);
    return applePay;
  }

  async SquareupACHPay(){
    let payments = Square.payments(environment.SQUAREUP_APP_ID, environment.SQUAREUP_LOCATION_ID);
    let ach:any;
    try {
      ach = await this.initializeACH(payments);
      const achButton:any = document.getElementById('ach-button');
      achButton.addEventListener('click', async (event:any) => {
        const paymentForm = document.getElementById('payment-form');
        const achOptions = this.getACHOptions(paymentForm);
        await this.tokenize(ach, achOptions);
        ach.addEventListener(`ontokenization`, async (event:any) => {
          const { tokenResult, error } = event.detail;
          if (error) {
          }
          else if (tokenResult.status === `OK`) {
            try {
              this.SquareupToken = tokenResult.token;
              this.handlePaymentMethodSubmission(event, ach);
            } catch (e) {
              alert('fail');
            }
          }
        });
      });     
    } catch (e) {
      console.error('Initializing Card failed', e);
      return;
    }  
  }

  async initializeACH(payments:any) {
    const ach = await payments.ach();
    return ach;
  }

  async tokenize(paymentMethod:any, options = {}) {
    paymentMethod.tokenize(options);
  }

  getACHOptions(form:any) {
    const billingContact = this.getBillingContact(form);
    const accountHolderName = `${billingContact.givenName} ${billingContact.familyName}`; 
    return { accountHolderName };
  }

  getBillingContact(form:any) {
    const formData = new FormData(form);
    return {
      givenName: formData.get('givenName'),
      familyName: formData.get('familyName'),
    };
  }


  async SquareupCashApp(){
    let payments = Square.payments(environment.SQUAREUP_APP_ID, environment.SQUAREUP_LOCATION_ID);
    let cashAppPay:any;
    try {
      cashAppPay = await this.initializeCashApp(payments);
      const AppPayButton:any = document.getElementById('cash-app-pay');
      AppPayButton.addEventListener('click', async (event:any) => {
        cashAppPay.addEventListener(`ontokenization`, async (event:any) => {
          const { tokenResult, error } = event.detail;
          if (error) {
          }
          else if (tokenResult.status === `OK`) {
            try {
              this.SquareupToken = tokenResult.token;
              this.handlePaymentMethodSubmission(event, cashAppPay);
            } catch (e) {
              alert('fail');
            }
          }
        });
      });
    } catch (e) {
      console.error('Initializing Cash App Pay failed', e);
    }    
  }
  
  async initializeCashApp(payments:any) {
    const paymentRequest = this.buildPaymentRequest(payments);
    if(this.cashAppPayDetails){
      this.cashAppPayDetails.destroy();
    }
    const cashAppPay = await payments.cashAppPay(paymentRequest,{
      redirectURL: 'https://my.website/checkout',
      referenceId: 'my-website-00000001'
    });
    await cashAppPay.attach('#cash-app-pay',{
      shape: 'semiround',
      width: 'full',
    });
    this.cashAppPayDetails = cashAppPay;
    return cashAppPay;    
  }
 

  async handlePaymentMethodSubmission(event:any, paymentMethod:any) {
    if(paymentMethod.methodType != "ACH" && paymentMethod.methodType != "Cash App Pay"){
      event.preventDefault();
    }
    this.showProgressBar();
    try {
      let tokenResult:any;
      if(paymentMethod.methodType != "ACH" && paymentMethod.methodType != "Cash App Pay"){
        tokenResult = await paymentMethod.tokenize();
        this.SquareupToken = tokenResult.token
      }
      this.subscriptionAndPaymentsService.SquareProcessPayment(this.SquareupToken,this.amount).subscribe(
        (response) => {
          var json = JSON.parse(JSON.stringify(response));
          console.log("Check Package Response --->> " + JSON.stringify(json));
          if (json.response.status == 'SUCCESS') {
            this.callPaymentSuccess(json.data,'SQUAREUP')
          } else {
            this.showErrorMessage(json.response.displayMessage)
          }
          this.hideProgressBar();
        },
        (error) => {
          console.log(error);
          this.showErrorMessage(AppSettings.ERROR_MSG)
          this.hideProgressBar();
        }
      );
    } catch (e:any) {
      console.error(e.message);
    }
  }

  backToReview(){
    this.currentPosition = '';
    this.titleText = this.from == "message_credit" ? 'Buy Message Credits' : 'Select a Package to Proceed';
  }

  removeCouponCode(){
    this.coupon = "";
    this.discountId = "";
    this.discountValid = false;
    this.calculateSubscription();
  }

}

