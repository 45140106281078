import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '../services/authentication/authentication.service';
import { UtilsService } from '../services/utils/utils.service';
import { CommonFunctions } from '../Utilities/CommonFunctions';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.css'],
})
export class LogoutComponent implements OnInit {
  constructor(
    private router: Router,
    private utilsService: UtilsService,
    public authenticationService: AuthenticationService
  ) { }

  ngOnInit(): void {
    this.logout();
  }

  logout() {
    this.authenticationService.logout().subscribe(
      (response) => {
        console.log('Logout Response --->> ' + JSON.stringify(response));
        CommonFunctions.removeToken()
        CommonFunctions.removeUser()
        CommonFunctions.removeBusinessInfo()
        this.utilsService.setIsLogin(false)
        CommonFunctions.removeClientChatList();
        this.router.navigate(['/login']);
      },
      (error) => {
        console.log('There was an error');
        console.log(error);
      }
    );
  }
}
