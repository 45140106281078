import { Component, ElementRef, HostListener, NgZone, OnInit, ViewChild, EventEmitter } from '@angular/core';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import * as moment from 'moment';
import { MenuItem, MessageService } from 'primeng/api';
import { Dialog } from 'primeng/dialog';
import { AppSettings } from '../Constant/AppSettings';
import { RenewModalComponent } from '../custom/renew-modal/renew-modal.component';
import { BusinessInfoService } from '../services/business-info/business-info.service';
import { SubscriptionAndPaymentsService } from '../services/subscription-and-payments/subscription-and-payments.service';
import { SubscriptionService } from '../services/subscription/subscription.service';
import { UtilsService } from '../services/utils/utils.service';
import { CommonFunctions } from '../Utilities/CommonFunctions';

interface Data {
  name: string,
  code: string
}
@Component({
  selector: 'app-subscription-and-payments',
  templateUrl: './subscription-and-payments.component.html',
  styleUrls: ['./subscription-and-payments.component.css']
})
export class SubscriptionAndPaymentsComponent implements OnInit {
  @ViewChild("parent") private parentRef?: ElementRef<HTMLElement>;
  @ViewChild("invoiceContent", { static: false }) private invoiceContent: ElementRef | undefined;
  // @ViewChild('invoiceContent') invoiceContent: ElementRef | undefined;
  items: MenuItem[] = [{ label: 'Subscription & Payments' }];
  home: MenuItem = { icon: 'pi pi-home', routerLink: '/home' };
  cols: any[] = [];
  colsMsgCredit: any[] = [];
  selectedAction?: Data;
  subscriptionList: any[] = [];
  subscriptionMsgCreditList: any[] = [];
  singlePackage: any = {};
  invoiceData: any = {};
  isLoading: boolean = false;
  timeoutError = '';
  disabled: boolean = false;
  //Invoice 
  businessName = '';
  businessAddress = '';
  businessAddressTwo = '';
  businessCity = '';
  businessStateAndZipcode = '';
  //Paginator variable
  pageSize: any = 0;
  currentPage: any = 1;
  totalCount: any = 0;

  //Paginator variable for message credit
  pageSizeCredit: any = 0;
  currentPageCredit: any = 1;
  totalCountCredit: any = 0;

  // Dialog display varaible
  displayRenewModal: boolean = false;
  displayViewModal: boolean = false;
  displayViewInvoiceModal: boolean = false;
  isMaximize: boolean = false;
  innerWidth: any;
  @ViewChild('renew') renew?: RenewModalComponent;
  subscriptionType = '';
  ee: EventEmitter<number> = new EventEmitter<number>();

  constructor(
    public utils: UtilsService,
    private subscriptionService: SubscriptionService,
    private subscriptionAndPaymentsService: SubscriptionAndPaymentsService,
    private messageService: MessageService,
    private businessInfoService: BusinessInfoService,
  ) { }

  ngOnInit(): void {
    this.cols = [
      { field: 'name', header: 'Subscription' },
      { field: 'status', header: 'Status' },
      { field: 'startDate', header: 'Activated On' },
      { field: 'expiry', header: 'Expiry On' },
      { field: 'subscribedOn', header: 'Paid On' },
      { field: 'totalAmount', header: 'Amount' },
    ]
    this.colsMsgCredit = [
      { field: 'description', header: 'Description' },
      { field: 'messageCredits', header: 'Credits' },
      { field: 'createdDate', header: 'Paid On' },
      { field: 'totalPrice', header: 'Amount' },
    ]
    this.listSubscription()
    this.getMessageCreditPaymentsList()
    this.getBusinessInfo()
    this.utils.buyMsgCreditReload.subscribe(
      (isClicked) => {
        if (isClicked) {
          this.getMessageCreditPaymentsList()
          this.utils.onReloadMsgCreditClick(false);
          this.updateBusinessInfo();
        }
      }
    )
  }


  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.innerWidth = window.innerWidth;

  }


  listSubscription() {
    this.showProgressBar()
    this.subscriptionList = [];
    this.subscriptionAndPaymentsService.subscriptionList(this.currentPage).subscribe(
      (response) => {
        var json = JSON.parse(JSON.stringify(response));
        console.log("Package Payment List Response --->> " + JSON.stringify(json));
        if (json.response.status == 'SUCCESS') {
          json.data.forEach((element: any) => {
            this.subscriptionList.push(
              {
                id: element.id,
                name: element.name,
                userId: element.userId,
                description: element.description,
                features: element.features,
                price: element.price,
                coreSubscriptionId: element.coreSubscriptionId,
                startDate: moment(element.startDate).format('Do MMMM YYYY'),
                subscribedOn: moment(element.subscribedOn).format('Do MMMM YYYY'),
                expiry: moment(element.expiry).format('Do MMMM YYYY'),
                paymentReference: element.paymentReference,
                tax: element.tax,
                proratedCharge: element.proratedCharge,
                messagingCredit: element.messagingCredit,
                totalAmount: '$ ' + element.totalAmount.toFixed(2),
                status: element.status,
                paymentMode: element.paymentMode
              }
            );
          });
          this.pageSize = json.additionalData.pageSize;
          this.totalCount = json.additionalData.totalCount;
        } else {
          this.showErrorMessage(json.response.displayMessage)
        }
        this.hideProgressBar();
      },
      (error) => {
        console.log(error);
        this.showErrorMessage(AppSettings.ERROR_MSG)
        this.hideProgressBar();
      }
    );
  }



  getMessageCreditPaymentsList() {
    this.showProgressBar()
    this.subscriptionMsgCreditList = [];
    this.subscriptionAndPaymentsService.getMessageCreditPaymentsList(this.currentPageCredit).subscribe(
      (response) => {
        var json = JSON.parse(JSON.stringify(response));
        console.log("MessageCreditPayments List Response --->> " + JSON.stringify(json));
        if (json.response.status == 'SUCCESS') {
          json.data.forEach((element: any) => {
            this.subscriptionMsgCreditList.push(
              {
                id: element.id,
                userId: element.userId,
                description: element.paymentLineItems[0].description,
                messageCredits: element.paymentLineItems[0].messageCredits,
                createdDate: moment(element.createdDate).format('Do MMMM YYYY'),
                paymentReference: element.paymentReference,
                tax: element.tax,
                proratedCharge: element.proratedCharge,
                totalPrice: '$ ' + element.totalPrice.toFixed(2),
                paymentMode: element.paymentMode
              }
            );
          });
          this.pageSizeCredit = json.additionalData.pageSize;
          this.totalCountCredit = json.additionalData.totalCount;
        } else {
          this.showErrorMessage(json.response.displayMessage)
        }
        this.hideProgressBar();
      },
      (error) => {
        console.log(error);
        this.showErrorMessage(AppSettings.ERROR_MSG)
        this.hideProgressBar();
      }
    );
  }


  getSingleSubsPackage(subs_id: string) {
    this.showProgressBar()
    this.subscriptionService.getSingleSubscription(subs_id).subscribe(
      (response) => {
        var json = JSON.parse(JSON.stringify(response));
        console.log("Package Single Response --->> " + JSON.stringify(json));
        if (json.response.status == 'SUCCESS') {
          this.singlePackage = json.data;
        } else {
          this.showErrorMessage(json.response.displayMessage)
        }
        this.hideProgressBar();
      },
      (error) => {
        console.log(error);
        this.showErrorMessage(AppSettings.ERROR_MSG)
        this.hideProgressBar();
      }
    );
  }



  getInvoice(item: any, label: string) {
    this.showProgressBar()
    this.subscriptionAndPaymentsService.getInvoice(item.id).subscribe(
      (response) => {
        var json = JSON.parse(JSON.stringify(response));
        console.log("Get Invoice Response --->> " + JSON.stringify(json));
        if (json.response.status == 'SUCCESS') {
          this.invoiceData = json.data;
          if (label == 'Download') {
            this.openPDF()
          }
        } else {
          this.showErrorMessage(json.response.displayMessage)
        }
        this.hideProgressBar();
      },
      (error) => {
        console.log(error);
        this.showErrorMessage(AppSettings.ERROR_MSG)
        this.hideProgressBar();
      }
    );
  }

  getInvoiceMsgCredit(item: any, label: string) {
    this.showProgressBar()
    this.subscriptionAndPaymentsService.getInvoiceMsgCredit(item.id).subscribe(
      (response) => {
        var json = JSON.parse(JSON.stringify(response));
        console.log("Get InvoiceMsgCredit Response --->> " + JSON.stringify(json));
        if (json.response.status == 'SUCCESS') {
          this.invoiceData = json.data;
          if (label == 'Download') {
            this.openPDF()
          }
        } else {
          this.showErrorMessage(json.response.displayMessage)
        }
        this.hideProgressBar();
      },
      (error) => {
        console.log(error);
        this.showErrorMessage(AppSettings.ERROR_MSG)
        this.hideProgressBar();
      }
    );
  }

  checkUpcoming(item: any) {
    this.showProgressBar()
    this.subscriptionAndPaymentsService.checkUpcoming(CommonFunctions.getUserId()).subscribe(
      (response) => {
        var json = JSON.parse(JSON.stringify(response));
        console.log("Check Upcoming Response --->> " + JSON.stringify(json));
        if (json.response.status == 'SUCCESS') {
          if (json.data.status == 'ERROR') {
            this.showErrorToastMessage(json.data.message);
          } else {
            this.renew?.onPackageSelection(item, 'table');
            this.showHideModalDialog('renew_subscription', ' ');
          }
        } else {
          this.showErrorMessage(json.response.displayMessage)
        }
        this.hideProgressBar();
      },
      (error) => {
        console.log(error);
        this.showErrorMessage(AppSettings.ERROR_MSG)
        this.hideProgressBar();
      }
    );
  }


  getBusinessInfo() {
    this.businessName = CommonFunctions.getBusinessInfo().name;
    this.businessAddress = CommonFunctions.getBusinessInfo().address;
    this.businessAddressTwo = CommonFunctions.getBusinessInfo().address2;
    this.businessCity = CommonFunctions.getBusinessInfo().city;
    this.businessStateAndZipcode = CommonFunctions.getBusinessInfo().businessState +" "+CommonFunctions.getBusinessInfo().zipcode;
  }



  getMenuItemsForItem(item: any): MenuItem[] {
    const context = item;
    if (item.status == 'ACTIVE') {
      return [
        { label: 'Upgrade', icon: 'pi-angle-double-up', command: e => this.openInvoice(e, context) },
        { label: 'Renew', icon: 'pi pi-fw pi-plus', command: e => this.openInvoice(e, context) },
        { label: 'View', icon: 'pi pi-fw pi-eye', command: e => this.openInvoice(e, context) },
        { label: 'Download', icon: 'pi pi-fw pi-download', command: e => this.openInvoice(e, context) }
      ]
    } else {
      return [
        { label: 'View', icon: 'pi pi-fw pi-eye', command: e => this.openInvoice(e, context) },
        { label: 'Download', icon: 'pi pi-fw pi-download', command: e => this.openInvoice(e, context) }
      ]
    }
  }

  openInvoice(label: any, item: any) {
    label = label.target.value
    // console.log('id:' + item + ', event:' + JSON.stringify(e))
    // if (e.item.label == 'View') {
    //   if (item.coreSubscriptionId) {
    //     this.getInvoice(item, e.item.label)
    //   } else {
    //     this.getInvoiceMsgCredit(item, e.item.label)
    //   }
    //   this.showHideModalDialog('view_subscription_invoice', '');
    // } else if (e.item.label == 'Renew') {
    //   // this.onPackageSelection(item, 'table')
    //   this.checkUpcoming(item)
    // } else if (e.item.label == 'Download') {
    //   if (item.coreSubscriptionId) {
    //     this.getInvoice(item, e.item.label)
    //   } else {
    //     this.getInvoiceMsgCredit(item, e.item.label)
    //   }
    //   this.showHideModalDialog('view_subscription_invoice', '');
    // }
    console.log('id:' + item + ', event:' + JSON.stringify(label))
    if (label == 'View') {
      if (item.coreSubscriptionId) {
        this.getInvoice(item, label)
      } else {
        this.getInvoiceMsgCredit(item, label)
      }
      this.showHideModalDialog('view_subscription_invoice', '');
    } else if (label == 'Renew') {
      // this.onPackageSelection(item, 'table')
      this.checkUpcoming(item)
    } else if (label == 'Download') {
      if (item.coreSubscriptionId) {
        this.getInvoice(item, label)
      } else {
        this.getInvoiceMsgCredit(item, label)
      }
      this.showHideModalDialog('view_subscription_invoice', '');
    }else if(label == 'Upgrade'){
      this.showHideModalDialog('upgrade_subscription', '');
      setTimeout(() => {
        this.utils.triggerSubscriptionCal(true);
      }, 1000);
    }
  }


  public openPDF(): void {
    // setTimeout(() => {
    //   let DATA = this.invoiceContent?.nativeElement;
    //   html2canvas(DATA).then(canvas => {
    //     var imgData = canvas.toDataURL('image/png');
    //     var pdf = new jsPDF('p', 'pt', [canvas.width, canvas.height]);
    //     var pdfWidth = pdf.internal.pageSize.getWidth();
    //     var pdfHeight = pdf.internal.pageSize.getHeight();
    //     pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
    //     pdf.save("mypdf.pdf");
    //   });
    // }, 2000);


    setTimeout(() => {
      let DATA = this.invoiceContent?.nativeElement;
      html2canvas(DATA).then(canvas => {
        const imgWidth = 208;
        const pageHeight = 295;
        const imgHeight = canvas.height * imgWidth / canvas.width;
        const heightLeft = imgHeight;
        console.log('imgHeight', imgHeight)
        const contentDataURL = canvas.toDataURL('image/png', 1.0);
        const pdf = new jsPDF('p', 'mm', 'a4');
        const position = 0;
        pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight);
        pdf.save('invoice.pdf');
      });
    }, 2000);

  }

  paginate(event: any, from: string) {
    // console.log(event)
    if (from == 'subscription') {
      this.currentPage = event.page + 1
      this.listSubscription()
    } else {
      this.currentPageCredit = event.page + 1
      this.getMessageCreditPaymentsList()
    }
  }

  clearFormData() {
    this.messageService.clear('errMsg');
    this.timeoutError = '';
    this.isMaximize = false;
  }
  twoDigitDecimal(value: string) {
    return parseFloat(value).toFixed(2);
  }

  showProgressBar() {
    this.messageService.clear();
    this.isLoading = true;
    this.disabled = true;
  }

  hideProgressBar() {
    this.isLoading = false;
    this.disabled = false;
  }

  showErrorMessage(msg: string) {
    this.messageService.add({
      key: 'errMsg',
      severity: 'error',
      detail: msg,
    });
  }

  showErrorToastMessage(msg: string) {
    this.messageService.add({
      key: 'br',
      severity: 'error',
      detail: msg,
    });
  }

  showDialogMaximized(event: any, dialog: Dialog) {
    if (this.innerWidth <= 640) {
      dialog.maximized = true;
    }

  }

  hideModalRenewDialog(displayRenewModal: any) {
    if (displayRenewModal !== undefined) {
      // console.log("displayRenewModal:" + displayRenewModal)
      this.displayRenewModal = displayRenewModal;
    }

  }

  isSuccess(isSuccess: any) {
    console.log(isSuccess)
    if (isSuccess != undefined) {
      console.log(isSuccess)
      if (isSuccess == 'SUCCESS') {
        this.listSubscription();
        this.updateBusinessInfo();
      }
    }
  }

  showHideModalDialog(type: string, id: string) {
    switch (type) {
      case 'view_subscription':
        this.clearFormData();
        this.getSingleSubsPackage(id);
        this.displayViewModal = !this.displayViewModal;
        break;
      case 'view_subscription_invoice':
        this.clearFormData();
        this.displayViewInvoiceModal = !this.displayViewInvoiceModal;
        break;
      case 'renew_subscription':
        this.clearFormData();
        this.displayRenewModal = !this.displayRenewModal;
        break;
      case 'upgrade_subscription':
        this.clearFormData();
        this.displayRenewModal = !this.displayRenewModal;
        break;
    }
  }

  get state(): string {
    return this.utils.state;
  }


  updateBusinessInfo() {
    this.businessInfoService.getBusinessInfo(CommonFunctions.getBusinessId()).subscribe(
      (response) => {
        var json = JSON.parse(JSON.stringify(response));
        console.log('Business info response --> ' + JSON.stringify(json));
        if (json.response.status == 'SUCCESS') {
          if (json.data != '' && json.data != 'null' && json.data != null) {
            CommonFunctions.setBusinessInfo(json.data)
            this.reloadComponent()
            // this.avatarName = json!.data!.name!.split(' ')[0]!.charAt(0) + json!.data!.name!.split(' ')[1]!.charAt(0);
          }
        } else {
          this.showErrorMessage(json.response.displayMessage)

        }
      },
      (error) => {
        console.log(error);
        this.showErrorMessage(AppSettings.ERROR_MSG)
        this.hideProgressBar();
      }
    );
  }

  reloadComponent() {
    this.utils.onReloadProfileClick(true);
  }

}
