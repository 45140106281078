<div style="width: 100%;">
    <div *ngIf="userName != ''" class="header">
        <div class="p-d-flex p-ai-center">
            <img *ngIf="preferenceType != 'chatstasy' && setLogo == true " src="assets/images/SMS_Logo.png"
            class="sidebar_profile_img" style="width: 32px;height: 32px;" alt="">
            <img *ngIf="preferenceType != 'chatstasy' && setLogo == false " src="assets/images/QuestionMark.png"
            class="sidebar_profile_img" style="width: 32px;height: 32px;" alt="">
            <!-- [label]="userName!.split(' ')[0]!.charAt(0) + userName!.split(' ')[1]!.charAt(0)" -->
            <img *ngIf="preferenceType == 'chatstasy'" src="assets/images/chatstasy_circle.png"
                style="width: 40px;height: 40px;" alt="">
            <!-- <img style="border-radius: 100%;
            height: 40px;
            width: 40px;" src="assets/images/avatar-7.jpg" alt=""> -->
            <div>
                <div class="header_profile_name">{{userName}}</div>
                <div class="header_profile_name" style="font-size: 12px;">
                    {{typingStatus}}</div>
            </div>
        </div>
    </div>
    <router-outlet (activate)="RoomData($event)"></router-outlet>
    <p-progressBar *ngIf="isLoading" [value]="percentage" [style]="{'height': '6px'}"></p-progressBar>
    <div *ngIf="userName != ''" class="footer p-p-2">
        <p-messages key="errMsg" [style]="{ 'font-size': '12px' }"></p-messages>
        <div class="p-d-flex p-jc-evenly p-ai-center">
            <input type="file" accept="*" (change)="selectFile($event)" style="display: none;" #file multiple>
            <button *ngIf="selectedFiles.length == 0" pButton pRipple type="button" icon="pi pi-paperclip"
                class="p-button-rounded p-button-text p-button-sm" style="font-size: 1.2rem"
                (click)="file.click()"></button>
            <i class="pi pi-bars" style="font-size: 1rem;padding: 0.4375rem 0.875rem; cursor: pointer;" (click)="showToolBar()"></i>
            <div *ngIf="selectedFiles.length != 0" class="p-d-flex p-ai-baseline">
                <button pButton pRipple type="button" icon="pi pi-times"
                    class="p-button-rounded p-button-text p-button-sm" style="font-size: 1.2rem"
                    (click)="clearFiles()"></button>
            </div>
            <div id="chatArea" style="border-top: 1px solid #ccc;width: 40em;">
                <!-- <div id="chatAreaInputBox"></div> -->
                <textarea id="editor" name="editor"></textarea>
            </div>
            <!-- <textarea [(ngModel)]="inputText" class="p-mr-2 p-ml-2" style="width: 100%;max-height: 66px;"
                placeholder="Type a message" [rows]="1" pInputTextarea [autoResize]="true"
                (input)="keyup($event)"></textarea> -->
            <!-- <button pButton pRipple type="button" label="Send" class="p-button-raised" style="font-size: 12px;"
                (click)="sendData()" [disabled]="disabled || bussinesInfo.state=='offline'">
            </button> -->
            <!-- <i class="pi pi-bars" style="font-size: 1rem;padding: 0.4375rem 0.875rem; cursor: pointer;" (click)="showToolBar()"></i> -->
            <button pButton pRipple type="button" label="Send" class="p-button-raised" style="font-size: 12px;"
                (click)="sendData()" [disabled]="disabled || bussinesInfo.state=='offline' && clientUserMode == 'LIVE' ">
            </button>
        </div>

        <div class="p-grid p-mt-2 p-ml-2 p-mr-2" *ngIf="selectedFiles.length">
            <div style="padding: 1px;" *ngFor="let file of selectedFiles; let i=index" [@animation]="'visible'">
                <p-chip [label]="file.name" [image]="file.type=='image/jpeg' || file.type=='image/png'?file.src:''"
                    [icon]="file.type!='image/jpeg' && file.type!='image/png'? 'pi pi-file':''" [removable]="true"
                    (onRemove)="removeToFileList(file)">
                </p-chip>
            </div>
        </div>
    </div>
</div>