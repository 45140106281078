import { Injectable } from '@angular/core';
import { Observable, Observer, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoginSocketService {
  private socket!: WebSocket;
  private connectionStatus: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private reconnectInterval: number = 5000; // Reconnect every 5 seconds
  private reconnectAttempts: number = 0;
  private maxReconnectAttempts: number = 10;

  constructor() { }

  connect(url: string): void {
    this.socket = new WebSocket(url);

    this.socket.onopen = () => {
      console.log('WebSocket connection established.');
      this.connectionStatus.next(true);
    };

    this.socket.onerror = (error) => {
      console.error('WebSocket error:', error);
      this.connectionStatus.next(false);
    };

    this.socket.onclose = () => {
      console.log('WebSocket connection closed.');
      this.connectionStatus.next(false);
      if (this.reconnectAttempts < this.maxReconnectAttempts) {
        setTimeout(() => {
          console.log('Attempting to reconnect...');
          this.reconnectAttempts++;
          this.connect(url); // Attempt to reconnect
        }, this.reconnectInterval);
      }
    };
  }

  disconnect(): void {
    if (this.socket) {
      this.socket.close();
    }
  }

  getStatus(): Observable<boolean> {
    return this.connectionStatus.asObservable();
  }

  sendMessage(message: string): void {
    if (this.socket && this.socket.readyState === WebSocket.OPEN) {
      this.socket.send(message);
    } else {
      console.error('WebSocket is not open. Ready state:', this.socket.readyState);
    }
  }

  onMessage(): Observable<any> {
    return new Observable((observer: Observer<any>) => {
      this.socket.onmessage = (event) => {
        observer.next(event.data);
      };
    });
  }
}
