<div class="p-d-flex p-jc-center p-ai-center" style="height: 100vh">
    <div class="card_width">
        <div class="p-d-flex p-jc-center p-ai-center p-mb-4">
            <img src="assets/images/ChatstasyCorpLogoAndSlogan-June2023-1.png" style="height: 30%;width: 70%;" alt="Chatstasy" />
        </div>
        <p-card>
            <form class="form-login" #signInForm="ngForm">
                <fieldset class="fieldset">
                    <legend>Forgot password</legend>
                    <p [ngClass]="currentPosition=='email'?'show':'hide'" style="font-size: 15px;">
                        Please enter your email to get OTP.
                    </p>
                    <p-messages key="errMsg" [style]="{ 'font-size': '12px' }"></p-messages>
                    <div [ngClass]="currentPosition=='email'?'show':'hide'">
                        <div class="p-fluid">
                            <div *ngIf="showBusinessName" class="p-field">
                                <span class="p-input-icon-left">
                                    <i class="pi pi-id-card" style="color: #007AFF;"></i>
                                    <input type="text" pInputText class="form-control" name="businessname"
                                        placeholder="Business name" autofocus [(ngModel)]="businessUrl"
                                        (blur)="getBusinessInfoByShortname()" />
                                </span>
                            </div>
                            <div class="p-field">
                                <span class="p-input-icon-left">
                                    <i class="pi pi-user" style="color: #007AFF;"></i>
                                    <input type="text" pInputText class="form-control" name="username"
                                        placeholder="Registered email" required autofocus [(ngModel)]="email" />
                                </span>
                            </div>

                        </div>
                    </div>
                    <div [ngClass]="currentPosition=='otp'?'show':'hide'">
                        <div class="p-d-flex p-jc-center p-ai-center" style="height: 200px;">
                            <div class="p-d-flex p-flex-column p-jc-center p-ai-center">
                                <div class="p-mb-2 center">We’ve sent a One Time Password (OTP) to your email <span
                                        style="font-weight: bold;">{{email}}</span>. Please
                                    enter it to complete verification.
                                </div>
                                <ng-otp-input #ngOtpInput (onInputChange)="onOtpChange($event)"
                                    [config]="{length:6,allowNumbersOnly:true}">
                                </ng-otp-input>
                                <div *ngIf="!showResendLayout" class="p-mb-2 p-mt-4 center">Please wait
                                    {{resendCountNumber}} second(s) before requesting a new One Time Password (OTP).
                                </div>
                                <div *ngIf="showResendLayout" class="p-mb-2 p-mt-4 center">Didn't get email ? <a
                                        href="javascript:void(0)" (click)="resendOtp()">Resend OTP</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div [ngClass]="currentPosition=='password'?'show':'hide'">
                        <form role="form" class="form-horizontal" (ngSubmit)="reset(resetPassword)" id="resetPassword"
                            #resetPassword="ngForm">
                            <div class="row form-group">
                                <label class="col-sm-4 control-label">Password<span
                                        class="symbol required"></span></label>
                                <div class="col-sm-8">
                                    <input class="form-control" type="password" name="profileNewPassword"
                                        pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$"
                                        placeholder="Enter Password" ngModel #profileNewPassword="ngModel"
                                        [disabled]="disabled" />

                                </div>
                                <small *ngIf="profileNewPassword.invalid" style="font-size: 13px;"
                                    class="col-sm-12 p-error p-ml-3 p-mt-1">Password should be
                                    of
                                    minimum
                                    8 character and contain
                                    atleast one
                                    uppercase letter, one number, one special character.
                                </small>
                            </div>
                            <div class="row form-group">
                                <label class="col-sm-4 control-label">Confirm Password<span
                                        class="symbol required"></span></label>
                                <div class="col-sm-8">
                                    <input type="password" name="profileConfirmPassword" class="form-control"
                                        placeholder="Re-enter Password" ngModel #profileConfirmPassword="ngModel"
                                        required pattern="{{ profileNewPassword.value }}" [disabled]="disabled" />
                                    <small *ngIf="profileConfirmPassword.errors?.pattern" class="p-error">
                                        Passwords do not match.
                                    </small>
                                </div>
                            </div>
                        </form>
                    </div>
                    <!-- footer -->
                    <div>
                        <div [ngClass]="currentPosition=='email'?'show':'hide'">
                            <div class="p-d-flex p-flex-column p-jc-center p-ai-center">
                                <p-button type="submit" label="Proceed" icon="pi pi-arrow-circle-right" iconPos="right"
                                    [disabled]="disabled" (click)="goToNextAndPrevious('otp')"></p-button>
                            </div>

                        </div>
                        <div [ngClass]="currentPosition=='otp'?'show':'hide'">
                            <div class="p-grid">
                                <div class="p-col p-d-flex p-ai-end" style="text-align: start;padding: 0;">
                                    <button pButton pRipple type="button" icon="pi pi-angle-left" label="Back"
                                        class="p-button-secondary p-button-text"
                                        (click)="goToNextAndPrevious('email')"></button>
                                </div>
                                <div class="p-col p-d-flex p-jc-end" style="padding: 0;">
                                    <button *ngIf="showRegisterLayout" pButton pRipple type="button"
                                        icon="pi pi-angle-right" iconPos="right" label="Next"
                                        class="p-button-secondary p-button-text"
                                        (click)="goToNextAndPrevious('password')"></button>
                                </div>
                            </div>
                        </div>
                        <div [ngClass]="currentPosition=='password'?'show':'hide'">
                            <div class="p-d-flex p-flex-column p-jc-center p-ai-center">
                                <p-button type="submit" label="Change Password" (click)="reset(resetPassword)"
                                    [disabled]="disabled"></p-button>
                            </div>
                            <div *ngIf="redirectingToLogin">
                                <div class="p-mb-2 p-mt-2 center">Redirecting to login in {{countNumber}} sec...
                                </div>
                            </div>
                        </div>
                        <div *ngIf="error" class="center mb-2">
                            <label style="color: red;">
                                {{error}}
                            </label>
                        </div>

                    </div>
                </fieldset>
                <div *ngIf="isLoading" class="progressLayout">
                    <div class="progressContainer">
                        <p-progressSpinner [style]="{ width: '40px', height: '40px' }" strokeWidth="3">
                        </p-progressSpinner>
                    </div>
                </div>
            </form>
            <div class="copy_right">
                &copy;
                <span class="current-year"></span>
                <span style="font-weight: bold !important;text-transform: uppercase !important;"> Chatstasy</span>.
                <span>All rights reserved</span>
            </div>
        </p-card>
    </div>

</div>