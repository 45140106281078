import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MenuItem, MessageService } from 'primeng/api';
import { map, take } from 'rxjs/operators';
import { Room } from '../models/room.model';
import firebase from 'firebase';
import { ClientcommunicationService } from '../services/clientcommunication/clientcommunication.service';
import { UtilsService } from '../services/utils/utils.service';
import { CommonFunctions } from '../Utilities/CommonFunctions';

@Component({
  selector: 'app-support-chat',
  templateUrl: './support-chat.component.html',
  styleUrls: ['./support-chat.component.css']
})
export class SupportChatComponent implements OnInit {
  items: MenuItem[] = [{ label: 'Chat with Support' }];
  home: MenuItem = { icon: 'pi pi-home', routerLink: '/home' };
  seedValue: string = '';
  clients: any[] = [];
  user: any;
  user_id: any;
  searchText = '';
  isLoading: boolean = false;
  visibleBtn: boolean = true;
  // Dialog display varaible
  displayAddModal: boolean = false;
  disabled: boolean = false;
  public innerWidth: any;
  businessParamValue: any = '';
  constructor(
    public utils: UtilsService,
    private clientcommunicationService: ClientcommunicationService,
    private router: Router,
    private messageService: MessageService
  ) {
    clientcommunicationService.setDbRef('sopport');
  }

  ngOnInit(): void {
    this.utils.pathParam.subscribe(value => {
      this.businessParamValue = value;
    });
    this.user = CommonFunctions.getUser();
    this.user_id = CommonFunctions.getUserId()
    this.addChatRoom()
  }

  createNewChat(room: Room) {
    this.clientcommunicationService.checkRoomExistForSupport(CommonFunctions.getBusinessInfoId().toString()).snapshotChanges().pipe(take(1))
      .pipe(
        map((changes: any) =>
          changes.map((c: any) =>
            ({ id: c.payload.doc.id, ...c.payload.doc.data() })
          )
        )
      ).subscribe((response: any) => {
        console.log(response);
        if (response.length > 0) {
          // alert('Sorry, room already present');
          console.log("Sorry, room already present");
          if (response[0] != null) {
            this.router.navigate([this.businessParamValue + '/support-chat/room/' + response[0]?.id]);
          }
        } else {
          console.log("Does not exist.");
          console.log("Room Added.")
          this.clientcommunicationService.createRoom(room).then((response: any) => {
            // console.log(response);
            if (response != null) {
              this.router.navigate([this.businessParamValue + '/support-chat/room/' + response?.id]);
            }
          });
        }
      });

  }


  addChatRoom() {
    let room: any = {}
    room.business_id = CommonFunctions.getBusinessInfoId().toString()  //company id
    room.business_logo = "";
    room.business_name = CommonFunctions.getBusinessInfo().name;

    room.recepient_id = '01';
    room.recepient_name = 'System Admin';
    room.recepient_image = "";
    room.last_message = "";
    room.is_typing = 'noOne';
    room.preference_type = 'chatstasy';
    room.timestamp = firebase.firestore.FieldValue.serverTimestamp()
    this.createNewChat(room)
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.innerWidth = window.innerWidth;
  }

  get state(): string {
    return this.utils.state;
  }

}
