import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { BehaviorSubject } from 'rxjs'
import { Room } from 'src/app/models/room.model';
import { CommonFunctions } from 'src/app/Utilities/CommonFunctions';

@Injectable({
  providedIn: 'root'
})
export class FirbaseMessagingService {
  messageCount = new BehaviorSubject('');
  notificationCount = new BehaviorSubject('');
  private dbPath = 'business_admin/chatrooms/' + CommonFunctions.getBusinessInfoId();
  private dbNotificationPath = 'business_admin/notifications/' + CommonFunctions.getBusinessInfoId();
  constructor(
    private db: AngularFirestore
  ) {
  }

  getAllUnreadRooms(): AngularFirestoreCollection<Room> {
    let itemsCollection = this.db.collection<Room>(this.dbPath, ref => ref.where('read_status', "==", CommonFunctions.getBusinessInfoId().toString()));
    return itemsCollection;
  }

  getAllUnreadNotifications(): AngularFirestoreCollection<Notification> {
    let itemsCollection = this.db.collection<Notification>(this.dbNotificationPath, ref => ref.where('readStatus', "==", 'unread'));
    return itemsCollection;
  }
  getAllNotifications(): AngularFirestoreCollection<Notification> {
    let itemsCollection = this.db.collection<Notification>(this.dbNotificationPath, ref => ref.orderBy('timestamp', 'desc'));
    return itemsCollection;
  }

  updateNotificationRead(id: any, object: any): Promise<void> {
    return this.db.collection(this.dbNotificationPath).doc(id).update(object);
  }

  setDbRef() {
    this.dbPath = 'business_admin/chatrooms/' + CommonFunctions.getBusinessInfoId();
    this.dbNotificationPath = 'business_admin/notifications/' + CommonFunctions.getBusinessInfoId();
  }
}

export class Notification {
  referenceId?: string;
  readStatus?: string;
  notificationType?: string;
  title?: string;
  description?: string;
  timestamp?: any
}