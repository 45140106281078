import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ConnectionService } from 'ng-connection-service';
import { MenuItem, MessageService } from 'primeng/api';
import { Dialog } from 'primeng/dialog';
import { environment } from 'src/environments/environment.prod';
import { FirbaseMessagingService } from '../services/firbase-messaging/firbase-messaging.service';
import { PrivilegeService } from '../services/privilege/privilege.service';
import { UtilsService } from '../services/utils/utils.service';
import { CommonFunctions } from '../Utilities/CommonFunctions';
import { SubscriptionAndPaymentsService } from '../services/subscription-and-payments/subscription-and-payments.service';
import { AppSettings } from '../Constant/AppSettings';
import * as moment from 'moment';
import { ElapsedService } from '../services/Elapsed/elapsed.service';
import { DatePipe } from '@angular/common';
import { BusinessHoursService } from '../services/business-hours/business-hours.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
})
export class HeaderComponent implements OnInit {
  firstName = '';
  lastName = '';
  avatarName = '';
  businessLogoUrl = '';
  bussinesInfo: any = {};
  profileUrl = '';
  imageInputError = false
  status = 'ONLINE';
  businessState = '';
  isConnected = true;
  noInternetConnection: boolean = false;
  items: MenuItem[] = [];
  msgItems: MenuItem[] = [];
  messageCount: string = '0';
  notificationCount: string = '0';
  innerWidth: any;
  //Message Credit
  messageCreditCount = 0;
  displayRenewModal: boolean = false;
  selectedCategory: any = null;
  categories: any[] = [{ name: 'I do not want to buy additional message credits now', key: 'default' }, { name: 'Marketing', key: 'M' }, { name: 'Production', key: 'P' }, { name: 'Research', key: 'R' }];
  subscriptionList: any[] = [];
  displayModalUpcoming: boolean = false;
  cols: any[] = [];
  hoursList: any[] = [];
  operatingStatus: boolean = false;

  constructor(
    private router: Router,
    private utilsService: UtilsService,
    private privilegeService: PrivilegeService,
    private connectionService: ConnectionService,
    private messagingService: FirbaseMessagingService,
    private messageService: MessageService,
    private subscriptionAndPaymentsService: SubscriptionAndPaymentsService,
    private elapsed: ElapsedService,
    private datePipe: DatePipe,
    private businessHoursService: BusinessHoursService,
  ) {
    this.connectionService.monitor().subscribe(isConnected => {
      this.isConnected = isConnected;
      if (this.isConnected) {
        this.status = "ONLINE";
        this.noInternetConnection = false;
      }
      else {
        this.status = "OFFLINE";
        this.noInternetConnection = true;
      }
    })
    this.messagingService.messageCount.subscribe(
      (messageCount) => {
        this.messageCount = messageCount
        // this.showSuccessToastMsg(newPayload?.notification?.title, newPayload?.notification?.body)
      }
    )
    this.messagingService.notificationCount.subscribe(
      (notificationCount) => {
        this.notificationCount = notificationCount
        // this.showSuccessToastMsg(newPayload?.notification?.title, newPayload?.notification?.body)
      }
    )
    this.utilsService.creditCount.subscribe(
      (creditCount) => {
        this.messageCreditCount = parseInt(creditCount)
      }
    )
    this.utilsService.reloadProfile.subscribe(
      (isClicked) => {
        if (isClicked) {
          this.getProfile()
        }
      }
    )
  }

  ngOnInit(): void {
    this.innerWidth = window.innerWidth;
    this.utilsService.modal.subscribe(
      (modal) => {
        this.displayRenewModal = modal;
      }
    )
    this.utilsService.creditCount.subscribe(
      (creditCount) => {
        this.messageCreditCount = parseInt(creditCount);
      }
    )
    this.getProfile()
    this.bussinesInfo = CommonFunctions.getBusinessInfo();
    this.items = [{
      label: 'My Profile',
      icon: 'pi pi-user',
      routerLink: ['/profile']
    },
    {
      label: 'Subscription & Payments',
      icon: 'pi pi-dollar',
      visible: this.getPrivilege('businessclient_billing_management'),
      routerLink: ['/subscription-and-payments']
    },
    {
      label: 'Buy Message Credits',
      icon: 'pi pi-inbox',
      visible: this.getPrivilege('businessclient_billing_management'),
      command: () => {
        this.openMsgCredit();
      }
    },
    {
      label: 'Log Out',
      icon: 'pi pi-power-off',
      routerLink: ['/logout']
    }];

    this.cols = [
      { field: 'name', header: 'Subscription' },
      { field: 'status', header: 'Status' },
      { field: 'startDate', header: 'Activated On' },
      { field: 'expiry', header: 'Expiry On' },
      { field: 'subscribedOn', header: 'Paid On' },
      { field: 'totalAmount', header: 'Amount' },
    ]

    this.listSubscription();
    this.getBusinessHoursList();
  }

  getProfile() {
    var user = CommonFunctions.getUser();
    console.log('Header response --->> ' + user.firstName);
    this.avatarName = user.firstName.charAt(0) + user.lastName.charAt(0);
    this.firstName = user.firstName;
    this.lastName = user.lastName;
    this.imageInputError = false;
    this.profileUrl = environment.API_URL + user.profileImage
    //Business
    this.businessState = CommonFunctions.getBusinessInfo().state;
    this.businessLogoUrl = environment.IMAGE_BASE_URL + CommonFunctions.getBusinessInfo().logo;
    this.bussinesInfo = CommonFunctions.getBusinessInfo();
    // console.log(this.businessLogoUrl)

    setInterval(() => {
      var weekday: any[] = [];
      weekday[0] =  "SUNDAY";
      weekday[1] = "MONDAY";
      weekday[2] = "TUESDAY";
      weekday[3] = "WEDNESDAY";
      weekday[4] = "THURSDAY";
      weekday[5] = "FRIDAY";
      weekday[6] = "SATURDAY";
      
      if(this.hoursList.length > 0){
        this.hoursList.forEach((element:any)=>{      
          if(weekday[new Date(this.elapsed.getCurrentTimeInTimezone(CommonFunctions.getBusinessInfo().timeZone)).getDay()] == element.nameOfDay){   
            
            var startTime = this.elapsed.getElapsedTime(this.datePipe.transform(this.elapsed.getCurrentTimeInTimezone(CommonFunctions.getBusinessInfo().timeZone), 'yyyy-MM-dd')+"T"+element.startTime, CommonFunctions.getBusinessInfo().timeZone);  
            
            var endTime = this.elapsed.getElapsedTime(this.datePipe.transform(this.elapsed.getCurrentTimeInTimezone(CommonFunctions.getBusinessInfo().timeZone), 'yyyy-MM-dd')+"T"+element.endTime, CommonFunctions.getBusinessInfo().timeZone);  

            if(Number(startTime.split(',')[0]) <= 0 && Number(startTime.split(',')[1]) <= 0 && Number(startTime.split(',')[2]) <= 0 && Number(startTime.split(',')[3]) <= 0){
              // if(!this.operatingStatus){
              //   this.operatingStatus = true;
              // }
              if(!this.bussinesInfo.operatingStatus){
                this.bussinesInfo.operatingStatus = true;
              }
            }

            if(Number(endTime.split(',')[0]) <= 0 && Number(endTime.split(',')[1]) <= 0 && Number(endTime.split(',')[2]) <= 0 && Number(endTime.split(',')[3]) <= 0){
              // if(this.operatingStatus){
              //   this.operatingStatus = false;
              // }
              if(this.bussinesInfo.operatingStatus){
                this.bussinesInfo.operatingStatus = false;
              }
            }
          }
        });
      } 
    }, 10000);

  }

  fileFailedToLoad(event: any) {
    console.log(event)
    this.imageInputError = true;
    this.profileUrl = 'assets/images/default-user.png'
  }

  fileFailedToLoadBusinessLogo(event: any) {
    console.log(event)
    this.businessLogoUrl = 'assets/images/business_2.png'
  }

  openClientCommunication() {
    if (this.router.url.split('/')[1] != "client-communication") {
      this.router.navigateByUrl('/client-communication')
    }
  }

  openMsgCredit() {
    this.displayRenewModal = !this.displayRenewModal;
  }

  hideModalRenewDialog(displayRenewModal: any) {
    if (displayRenewModal !== undefined) {
      this.displayRenewModal = displayRenewModal;
      this.setCreditModalShowHide(displayRenewModal)
    }
  }

  isSuccess(isSuccess: any) {
    if (isSuccess !== undefined) {
      if (isSuccess == 'SUCCESS') {
        this.utilsService.onReloadMsgCreditClick(true);
      }
    }
  }


  clearFormData() {
    this.messageService.clear('errMsg');
  }

  showErrorMessage(msg: string) {
    this.messageService.add({
      key: 'errMsg',
      severity: 'error',
      detail: msg,
    });
  }

  showDialogMaximized(event: any, dialog: Dialog) {
    if (this.innerWidth <= 640) {
      dialog.maximized = true;
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.innerWidth = window.innerWidth;
  }

  changeMobileMenuState() {
    this.utilsService.changeMobileMenuState(true)
  }

  changeState() {
    this.utilsService.changeState()
  }

  setIsVisibleSidebar(isVisible: boolean) {
    this.utilsService.setIsVisibleSidebar(isVisible);
  }

  changeCreditMsgState(state: string) {
    this.utilsService.changeCreditMsgState(state)
  }

  getCreditState() {
    return this.utilsService.creditMsgState;
  }

  setCreditModalShowHide(modal: boolean) {
    this.utilsService.setCreditModalShowHide(modal)
  }

  getPrivilege(id: any) {
    return this.privilegeService.getPrivileges(id);
  }

  listSubscription() {
    this.subscriptionList = [];
    this.subscriptionAndPaymentsService.subscriptionList(1).subscribe(
      (response) => {
        var json = JSON.parse(JSON.stringify(response));
        console.log("Package Payment List Response --->> " + JSON.stringify(json));
        if (json.response.status == 'SUCCESS') {
          json.data.forEach((element: any) => {
            if(element.status == "UPCOMING"){
              this.subscriptionList.push(
                {
                  id: element.id,
                  name: element.name,
                  userId: element.userId,
                  description: element.description,
                  features: element.features,
                  price: element.price,
                  coreSubscriptionId: element.coreSubscriptionId,
                  startDate: moment(element.startDate).format('Do MMMM YYYY'),
                  subscribedOn: moment(element.subscribedOn).format('Do MMMM YYYY'),
                  expiry: moment(element.expiry).format('Do MMMM YYYY'),
                  paymentReference: element.paymentReference,
                  tax: element.tax,
                  proratedCharge: element.proratedCharge,
                  messagingCredit: element.messagingCredit,
                  totalAmount: '$ ' + element.totalAmount.toFixed(2),
                  status: element.status,
                  paymentMode: element.paymentMode
                }
              );
            }
          });
        } else {
          this.showErrorMessage(json.response.displayMessage)
        }
      },
      (error) => {
        console.log(error);
        this.showErrorMessage(AppSettings.ERROR_MSG);
      }
    );
  }

  ShowUpcomingPlan(){
    this.displayModalUpcoming = true;
  }

  getBusinessHoursList() {
    // this.showProgressBar();
    this.businessHoursService.getListBusinessHours().subscribe(
      (response) => {
        var json = JSON.parse(JSON.stringify(response));
        console.log('Business Hours response --> ' + JSON.stringify(json));
        if (json.response.status == 'SUCCESS') {
          this.hoursList = json.data;
        } else {
          this.showErrorMessage(json.response.displayMessage)
        }
        // this.hideProgressBar()
      }, (error) => {
        console.log(error);
        this.showErrorMessage(AppSettings.ERROR_MSG)
        // this.hideProgressBar();
      })
  }
}
