import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { timeout } from 'rxjs/operators';
import { environment } from 'src/environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class SupportTicketsService {
  constructor(private http: HttpClient) { }

  filterTicketsListByClientId(pageNumber: any, filterData: any) {
    const url = environment.FRESHDESK_URL + "/api/v2/search/tickets?query=" + filterData + '&page=' + pageNumber;
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: 'Basic ' + environment.FRESHDESK_TOKEN,
        "Content-Type": "application/json",
      }),
    };
    return this.http.get(url, httpOptions).pipe(timeout(60000));
  }


  listTickets(pageNumber: any, perPage: any) {
    const url = environment.FRESHDESK_URL + "/api/v2/tickets?page=" + pageNumber + "&per_page=" + perPage;
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: 'Basic ' + environment.FRESHDESK_TOKEN,
        "Content-Type": "application/json",
      }),
    };
    return this.http.get(url, httpOptions).pipe(timeout(60000));
  }

  addTicket(json: any, file: any) {
    const url = environment.FRESHDESK_URL + "/api/v2/tickets";
    let httpOptions = {};
    if (file != null) {
      httpOptions = {
        headers: new HttpHeaders({
          Authorization: 'Basic ' + environment.FRESHDESK_TOKEN,
        }),
      }
      const formData = new FormData();
      formData.append('attachments[]', file);
      formData.append('description', json.description);
      formData.append('subject', json.subject);
      formData.append('email', json.email);
      formData.append('priority', json.priority);
      formData.append('status', json.status);
      formData.append('type', json.type);
      formData.append('custom_fields[cf_business_id]', json.custom_fields.cf_business_id);
      formData.append('responder_id', json.responder_id);
      formData.append('cc_emails[]', json.cc_emails);
      console.log('formData:', JSON.stringify(formData))
      return this.http.post(url, formData, httpOptions).pipe(timeout(60000));
    } else {
      httpOptions = {
        headers: new HttpHeaders({
          Authorization: 'Basic ' + environment.FRESHDESK_TOKEN,
          "Content-Type": "application/json",
        }),
      }
      return this.http.post(url, json, httpOptions).pipe(timeout(60000));
    }
  }

  editTicket(json: any, ticketId: string) {
    const url = environment.FRESHDESK_URL + "/api/v2/tickets/" + ticketId;
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: 'Basic ' + environment.FRESHDESK_TOKEN,
        "Content-Type": "application/json",
      }),
    };
    return this.http.put(url, json, httpOptions).pipe(timeout(60000));
  }


  getTicket(ticketId: string) {
    const url = environment.FRESHDESK_URL + "/api/v2/tickets/" + ticketId;
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: 'Basic ' + environment.FRESHDESK_TOKEN,
        "Content-Type": "application/json",
      }),
    };
    return this.http.get(url, httpOptions).pipe(timeout(60000));
  }

  listComments(ticket_id: any) {
    const url = environment.FRESHDESK_URL + "/api/v2/tickets/" + ticket_id + "/conversations";
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: 'Basic ' + environment.FRESHDESK_TOKEN,
        "Content-Type": "application/json",
      }),
    };
    return this.http.get(url, httpOptions).pipe(timeout(60000));
  }

  addComment(ticket_id: any, json: any, file: any) {
    const url = environment.FRESHDESK_URL + "/api/v2/tickets/" + ticket_id + "/reply";
    let httpOptions = {};
    if (file != null) {
      httpOptions = {
        headers: new HttpHeaders({
          Authorization: 'Basic ' + environment.FRESHDESK_TOKEN,
        }),
      }
      const formData = new FormData();
      formData.append('attachments[]', file);
      formData.append('body', json.body);
      return this.http.post(url, formData, httpOptions).pipe(timeout(60000));
    } else {
      httpOptions = {
        headers: new HttpHeaders({
          Authorization: 'Basic ' + environment.FRESHDESK_TOKEN,
          "Content-Type": "application/json",
        }),
      }
      return this.http.post(url, json, httpOptions).pipe(timeout(60000));
    }
  }

  downloadFile(link: any): any {
    return this.http.get(link).pipe(timeout(60000));
  }
}
