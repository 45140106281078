import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { MultiSelectModule } from 'primeng/multiselect';
import { ToastModule } from 'primeng/toast';
import { MessagesModule } from 'primeng/messages';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DialogModule } from 'primeng/dialog';
import { ButtonModule } from 'primeng/button';
import { PasswordModule } from 'primeng/password';
import { PaginatorModule } from 'primeng/paginator';
import { InputTextModule } from 'primeng/inputtext';
import { InputMaskModule } from 'primeng/inputmask';
import { TreeModule } from 'primeng/tree';
import { TooltipModule } from 'primeng/tooltip';
import { TableModule } from 'primeng/table';
import { CardModule } from 'primeng/card';
import { MenuModule } from 'primeng/menu';
import { AvatarModule } from 'primeng/avatar';
import { TabViewModule } from 'primeng/tabview';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { ListboxModule } from 'primeng/listbox';
import { DropdownModule } from 'primeng/dropdown';
import { ProgressBarModule } from 'primeng/progressbar';
import { ChipModule } from 'primeng/chip';
import { PanelMenuModule } from 'primeng/panelmenu';
import { SelectButtonModule } from 'primeng/selectbutton';
import { CheckboxModule } from 'primeng/checkbox';
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { NgxPayPalModule } from 'ngx-paypal';
import { FullCalendarModule } from '@fullcalendar/angular'; // the main connector. must go first
import dayGridPlugin from '@fullcalendar/daygrid'; // a plugin
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import interactionPlugin from '@fullcalendar/interaction'; // a plugin
import { InputSwitchModule } from 'primeng/inputswitch';
import { ContextMenuModule } from 'primeng/contextmenu';
import { CalendarModule } from 'primeng/calendar';
import { SidebarModule } from 'primeng/sidebar';
import { NgxShimmerLoadingModule } from 'ngx-shimmer-loading';
import { ColorPickerModule } from 'primeng/colorpicker';
import { ConfirmationService, MessageService } from 'primeng/api';
import { ImageCropperModule } from 'ngx-image-cropper';
import { TextInputAutocompleteModule } from 'angular-text-input-autocomplete';
import { PanelModule } from 'primeng/panel';
import { QuillModule } from 'ngx-quill';
import { TextareaAutosizeModule } from 'ngx-textarea-autosize';
import { ResizableModule } from 'angular-resizable-element';
import { RadioButtonModule } from 'primeng/radiobutton';
import { ChartModule } from 'primeng/chart';
import { NgOtpInputModule } from 'ng-otp-input';
import { SkeletonModule } from 'primeng/skeleton';
import { AccordionModule } from 'primeng/accordion';
import { DividerModule } from 'primeng/divider';
import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';

//Project's page Module
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { LoginComponent } from './login/login.component';
import { SignupComponent } from './signup/signup.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ProfileComponent } from './profile/profile.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { UserComponent } from './user/user.component';
import { ClientcommunicationComponent } from './clientcommunication/clientcommunication.component';
import { ClientDatamanagementComponent } from './client-datamanagement/client-datamanagement.component';
import { LogoutComponent } from './logout/logout.component';
import { RoleComponent } from './role/role.component';
import { AuthGuard } from './services/authguard/auth.guard';
import { environment } from 'src/environments/environment';
import { ChatAreaComponent } from './clientcommunication/chat-area/chat-area.component';
import { ChatSidebarComponent } from './clientcommunication/chat-sidebar/chat-sidebar.component';
import { ChatDefaultPageComponent } from './clientcommunication/chat-area/chat-default-page/chat-default-page.component';
import { ChatRoomComponent } from './clientcommunication/chat-area/chat-room/chat-room.component';
import { FilterPipe } from './pipe/filter.pipe';
import { SubscriptionComponent } from './subscription/subscription.component';
import { PaypalComponent } from './paypal/paypal.component';
import { BroadcastMessageComponent } from './clientcommunication/broadcast-message/broadcast-message.component';
import { BusinessInformationComponent } from './business-information/business-information.component';
import { MessageBotConfigurationComponent } from './message-bot-configuration/message-bot-configuration.component';
import { BusinessHoursComponent } from './business-hours/business-hours.component';
import { SubscriptionAndPaymentsComponent } from './subscription-and-payments/subscription-and-payments.component';
import { BusinessCalendarComponent } from './business-calendar/business-calendar.component';
import { ServiceTypeComponent } from './service-type/service-type.component';
import { ServiceProviderComponent } from './service-provider/service-provider.component';
import { RenewModalComponent } from './custom/renew-modal/renew-modal.component';
import { ChatButtonConfigurationComponent } from './chat-button-configuration/chat-button-configuration.component';
import { ChatRoomConfigurationComponent } from './chat-room-configuration/chat-room-configuration.component';
import { BusinessKeywordsComponent } from './business-keywords/business-keywords.component';
import { ImageCropperDialogComponent } from './custom/image-cropper-dialog/image-cropper-dialog.component';
import { MessagesTemplateComponent } from './messages-template/messages-template.component';
import { FrequentlyAskedQuestionsComponent } from './frequently-asked-questions/frequently-asked-questions.component';
import { SupportTicketsComponent } from './support-tickets/support-tickets.component';
import { HowToComponent } from './how-to/how-to.component';
import { MessageCreditWarningComponent } from './custom/message-credit-warning/message-credit-warning.component';
import { SupportChatComponent } from './support-chat/support-chat.component';
import { RegistrationComponent } from './registration/registration.component';
import { APP_BASE_HREF, DatePipe } from '@angular/common';
import { CommonFunctions } from './Utilities/CommonFunctions';
import { TwillioMessageComponent } from './custom/twillio-message/twillio-message.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { BlockCopyPasteDirective } from './directive/block-copy-paste.directive';
// import { NgxStripeModule } from 'ngx-stripe';
import { FileUploadModule } from 'primeng/fileupload';
import {SliderModule,} from 'primeng/slider';
import {InputNumberModule} from 'primeng/inputnumber';
import { GalleriaModule } from 'primeng/galleria';
import { ConfigurationWizardComponent } from './configuration-wizard/configuration-wizard.component';
import { ServiceGalleryComponent } from './service-gallery/service-gallery.component';
import { BusinessCalendarActionComponent } from './business-calendar-action/business-calendar-action.component';
import { ActionButtonComponent } from './action-button/action-button.component';
import { SafeHtmlPipe } from 'src/safe-html';
import { ClientSegmentationComponent } from './client-segmentation/client-segmentation.component';
import { PhonePreviewComponent } from './phone-preview/phone-preview.component';
import { ImageResizeCropperDialogComponent } from './custom/image-resize-cropper-dialog/image-resize-cropper-dialog.component';
import { ChatButtonPromotionComponent } from './chat-button-promotion/chat-button-promotion.component';
import { ClientFilterPipe } from './pipe/client-filter.pipe';
import { BusinessHolidaysComponent } from './business-holidays/business-holidays.component';
import { AppointmentConfirmationComponent } from './appointment-confirmation/appointment-confirmation.component';

FullCalendarModule.registerPlugins([ // register FullCalendar plugins
  dayGridPlugin,
  timeGridPlugin,
  listPlugin,
  interactionPlugin
]);


@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    SidebarComponent,
    LoginComponent,
    SignupComponent,
    DashboardComponent,
    ProfileComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    UserComponent,
    ClientcommunicationComponent,
    ClientDatamanagementComponent,
    LogoutComponent,
    RoleComponent,
    ChatAreaComponent,
    ChatSidebarComponent,
    ChatDefaultPageComponent,
    ChatRoomComponent,
    FilterPipe,
    SubscriptionComponent,
    PaypalComponent,
    BroadcastMessageComponent,
    BusinessInformationComponent,
    MessageBotConfigurationComponent,
    BusinessHoursComponent,
    SubscriptionAndPaymentsComponent,
    BusinessCalendarComponent,
    ServiceTypeComponent,
    ServiceProviderComponent,
    RenewModalComponent,
    ChatButtonConfigurationComponent,
    ChatRoomConfigurationComponent,
    BusinessKeywordsComponent,
    ImageCropperDialogComponent,
    MessagesTemplateComponent,
    FrequentlyAskedQuestionsComponent,
    SupportTicketsComponent,
    HowToComponent,
    MessageCreditWarningComponent,
    SupportChatComponent,
    RegistrationComponent,
    TwillioMessageComponent,
    PageNotFoundComponent,
    BlockCopyPasteDirective,
    ConfigurationWizardComponent,
    ServiceGalleryComponent,
    BusinessCalendarActionComponent,
    ActionButtonComponent,
    SafeHtmlPipe,
    ClientSegmentationComponent,
    PhonePreviewComponent,
    ImageResizeCropperDialogComponent,
    ChatButtonPromotionComponent,
    ClientFilterPipe,
    BusinessHolidaysComponent,
    AppointmentConfirmationComponent
  ],

  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    ProgressSpinnerModule,
    MultiSelectModule,
    ToastModule,
    MessagesModule,
    ConfirmDialogModule,
    DialogModule,
    ButtonModule,
    PasswordModule,
    PaginatorModule,
    InputTextModule,
    InputMaskModule,
    TreeModule,
    TooltipModule,
    TableModule,
    CardModule,
    MenuModule,
    AvatarModule,
    TabViewModule,
    BreadcrumbModule,
    InputTextareaModule,
    ScrollPanelModule,
    ListboxModule,
    DropdownModule,
    ProgressBarModule,
    ChipModule,
    PanelMenuModule,
    SelectButtonModule,
    CheckboxModule,
    FullCalendarModule, // register FullCalendar with you app
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFirestoreModule, // for firestore
    AngularFireStorageModule,
    NgxPayPalModule,
    InputSwitchModule,
    ContextMenuModule,
    CalendarModule,
    SidebarModule,
    NgxShimmerLoadingModule,
    ColorPickerModule,
    ImageCropperModule,
    TextInputAutocompleteModule,
    PanelModule,
    QuillModule.forRoot(),
    TextareaAutosizeModule,
    ResizableModule,
    RadioButtonModule,
    ChartModule,
    NgOtpInputModule,
    // NgxStripeModule.forRoot(environment.STRIPE_KEY),
    FileUploadModule,
    SliderModule,
    InputNumberModule,
    GalleriaModule,
    SkeletonModule,
    AccordionModule,
    DividerModule
  ],
  providers: [AuthGuard, MessageService, ConfirmationService,
    {
      provide: APP_BASE_HREF,
      useFactory: CommonFunctions.getBaseLocation
    }, DatePipe,
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule { }
