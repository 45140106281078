import { Component, Input, OnInit } from '@angular/core';
import firebase from 'firebase';
import { ClientcommunicationService } from 'src/app/services/clientcommunication/clientcommunication.service';
import { Observable, Subscription } from 'rxjs';
import { Room } from 'src/app/models/room.model';
import { CommonFunctions } from 'src/app/Utilities/CommonFunctions';
import { FileUpload } from 'src/app/models/FileUpload';
import { MessageService } from 'primeng/api';
import { AppSettings } from 'src/app/Constant/AppSettings';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { finalize } from 'rxjs/operators';
import { AngularFireStorageReference } from '@angular/fire/storage';
import { ClientDataService } from '../../services/client-data/client-data.service';
import Quill from 'quill';
import { MessagesTemplateService } from '../../services/messages-template/messages-template.service';
import { ActionButtonService } from 'src/app/services/action-button/action-button.service';
declare var CKEDITOR: any;

@Component({
  selector: 'app-chat-area',
  templateUrl: './chat-area.component.html',
  styleUrls: ['./chat-area.component.css'],
  animations: [
    trigger('animation', [
      state('visible', style({
        transform: 'translateX(0)',
        opacity: 1
      })),
      transition('void => *', [
        style({ transform: 'translateX(50%)', opacity: 0 }),
        animate('300ms ease-out')
      ]),
      transition('* => void', [
        animate(('250ms ease-in'), style({
          height: 0,
          opacity: 0,
          transform: 'translateX(50%)'
        }))
      ])
    ])
  ]
})
export class ChatAreaComponent implements OnInit {
  room: Room = new Room();
  inputText: string = '';
  subs?: Subscription;
  paramValue: string = '';
  preferenceType = '';
  userStatus: string = '';
  userName: string = '';
  @Input() from: any = '';
  typingTimer: any;                //timer identifier
  doneTypingInterval = 5000;
  typingStatus = '';
  bussinesInfo: any = {};
  //storage
  selectedFiles: any[] = [];
  finalSelectedFiles?: FileList;
  currentFileUpload?: FileUpload;
  downloadUrlList: any[] = [];
  percentage = 0;
  isLoading: boolean = false;
  disabled: boolean = false;
  clientUserMode: string = '';
  oldRoomId: string = '';
  arrayVariables: any[] = [''];
  chatAreaInputBoxQuill: any;
  displayToolbar: boolean = true;
  arrayActionButton: any[] = [''];
  setLogo: boolean = true;
  interval: any;

  constructor(
    private clientcommunicationService: ClientcommunicationService,
    private messageService: MessageService,
    private clientDataService: ClientDataService,
    private messagesTemplateService: MessagesTemplateService,
    private actionButtonService: ActionButtonService,
  ) { }

  ngOnInit(): void {
    this.subs = this.clientcommunicationService.pathParam.subscribe(value => {
      this.paramValue = value;
      console.log(this.paramValue);
    });
    this.bussinesInfo = CommonFunctions.getBusinessInfo();
    this.listMessagesVariables();
    this.listActionButton();
  }

  selectFile(event: any): void {
    // File Preview
    this.finalSelectedFiles = event.target.files;
    for (let index = 0; index < event.target.files.length; index++) {
      const file = event.target.files[index];
      var size = file.size;
      var name = file.name;
      var extension = name.substring(name.lastIndexOf('.') + 1);
      console.log('Name: ' + name + "\n" +
        'Size: ' + CommonFunctions.formatBytes(size));
      if (size > 5000000) {
        let finalName = name;
        if (name.length > 20) {
          finalName = name.substring(0, 20) + '...' + extension;
        }
        this.showErrorMessage(finalName + ' File should not be more than 5 MB!')
      }
      if (file.type == 'image/jpeg' || file.type == 'image/png') {
        const reader = new FileReader();
        reader.onload = () => {
          file.src = reader.result as string;
          this.selectedFiles.push(file);
        }
        reader.readAsDataURL(file)
      } else {
        this.selectedFiles.push(file);
      }

    }
    if (this.checkLargeFileExist(this.selectedFiles)) {
      this.disabled = true
    } else {
      this.disabled = false
    }
    console.log("Large file exist:", this.checkLargeFileExist(this.selectedFiles))
  }

  upload(i: number): void {
    this.showProgressBar()
    if (this.finalSelectedFiles) {
      const file: File | null = this.finalSelectedFiles.item(i);
      if (file) {
        this.currentFileUpload = new FileUpload(file);
        const { uploadTask, storageRef } = this.clientcommunicationService.pushFileToStorage(this.currentFileUpload)
        this.uploadToFireStorage(i, uploadTask, storageRef).subscribe(
          percentage => {
            this.percentage = Math.round(percentage ? percentage : 0);
          },
          error => {
            console.log(error);
            this.hideProgressBar()
            this.showErrorToastMsg(AppSettings.ERROR_MSG)
          }
        );
      }
    }

  }

  uploadToFireStorage(i: number, uploadTask: any, storageRef: AngularFireStorageReference): Observable<number | undefined> {
    uploadTask.snapshotChanges().pipe(
      finalize(() => { })
    ).subscribe((result: any) => {
      console.log("uploadTask:", result.state);
      if (result.state == 'success') {
        storageRef.getMetadata().subscribe(metaData => {
          console.log("metaDataSubscribe:", metaData)
          storageRef.getDownloadURL().subscribe(downloadURL => {
            console.log("downloadURLSubscribe:", downloadURL)
            this.downloadUrlList.push({ url: downloadURL, name: metaData.name || '' })
            if (result.state == 'success' && this.selectedFiles.length == this.downloadUrlList.length) {
              let thread = this.getPrepareChatMsg()
              thread.attachments = this.downloadUrlList;
              this.saveMessage(thread)
              this.hideProgressBar()
              console.log('File uploaded successfully.')
              // this.showSuccessMessage('File uploaded successfully.')
              this.clearFiles()
            }
          });
        });

      }
    });
    return uploadTask.percentageChanges();
  }


  saveFilesToStorage() {
    for (let index = 0; index < this.selectedFiles.length; index++) {
      this.upload(index)
    }
  }

  getPrepareChatMsg() {
    let thread: any = {
      message_channel_type: this.preferenceType == 'chatstasy' ? "chat" : "sms",
      sender_id: CommonFunctions.getBusinessInfo().businessId,
      sender_image: '',
      sender_name: CommonFunctions.getUser().firstName + ' ' + CommonFunctions.getUser().lastName,
      sender_type: "Business",
      text: this.inputText.replace(/&amp;/g, '&'),
      attachments: [],
      recepient_id: this.from == 'client' ? this.room.recepient_id?.toString() : '01',
      timestamp: firebase.firestore.FieldValue.serverTimestamp()
    }
    return thread
  }

  sendData() {
    // console.log(this.chatAreaInputBoxQuill.getText());
    // // console.log(this.chatAreaInputBoxQuill.getText());
    // return 
    // this.inputText = this.chatAreaInputBoxQuill.root.innerHTML;
    this.inputText = CKEDITOR.instances.editor.getData();
    if (this.selectedFiles.length != 0) {
      this.saveFilesToStorage()
    } else {
      if (this.inputText === '') {
        return;
      }
      let thread = this.getPrepareChatMsg()
      this.saveMessage(thread)
    }
  }

  saveMessage(thread: any): void {
    if (this.selectedFiles.length == 0 && this.preferenceType == 'nonchatstasy') {
      this.sendSMS(thread)
      this.clearData()
    } else if (this.selectedFiles.length != 0 && this.preferenceType == 'nonchatstasy') {
      this.sendSMS(thread)
      // this.saveFirebaseMessage(thread)
    } else {
      this.saveFirebaseMessage(thread)
    }
  }

  saveFirebaseMessage(thread: any): void {
    console.log(thread)
    this.clientcommunicationService.addMeassage(this.paramValue, thread).then(() => {
      console.log('Created new item successfully!');
      this.clientcommunicationService.updateRoom(this.paramValue, thread.text, thread.timestamp, this.room.recepient_id?.toString() || 'noOne').then(() => {
        console.log('Updated room successfully!');
        this.clearData()
      }).catch((error) => {
        console.log('Error: Getting document:', error.message)
        this.showErrorToastMsg(AppSettings.ERROR_MSG)
      });
      // this.submitted = true;
    });
  }

  sendSMS(thread: any) {
    const { timestamp, ...newThread } = thread;
    // tempThread = thread
    // delete tempThread.timestamp;
    // var data = { data: JSON.parse(JSON.stringify(roleData)) };
    console.log('ID --->> ' + JSON.stringify(newThread));
    this.clientcommunicationService.sendSMS(newThread, this.paramValue).subscribe(
      (response: any) => {
        var json = JSON.parse(JSON.stringify(response));
        console.log('Send SMS Post Response --->> ' + JSON.stringify(json));
        if (json.response.status == 'SUCCESS') {
          this.showSuccessMessage(json.response.displayMessage)
        } else {
          this.showErrorToastMsg(json.response.displayMessage)
        }
      },
      (error: any) => {
        console.log(error);
        this.showErrorToastMsg(AppSettings.ERROR_MSG)
      }
    );
  }

  setUserStatus(rv: any) {
    this.userName = rv.recepient_name;
    let typeStatus = false;
    if (this.from == 'client') {
      typeStatus = rv['is_typing_' + rv.recepient_id]
    } else {
      if (rv.is_typing == CommonFunctions.getUserId().toString() || rv.is_typing == 'noOne') {
        typeStatus = false
      } else {
        typeStatus = true
      }
    }

    if (typeStatus) {
      this.typingStatus = "Typing..."
    } else {
      this.typingStatus = "";
    }

    if (this.oldRoomId == '') {
      this.oldRoomId = this.paramValue;
      this.getClientById(rv.recepient_id);
    } else if (this.oldRoomId != this.paramValue) {
      this.getClientById(rv.recepient_id);
      this.oldRoomId = this.paramValue;
    }
    this.setLogo = rv.is_subscribed;

  }

  getClientById(recepientId: any) {
    this.clientDataService.getClientById(recepientId).subscribe((response) => {
      var json = JSON.parse(JSON.stringify(response));
      console.log('Get Client By ID --->> ' + JSON.stringify(json));
      if (json.response.status == "SUCCESS") {
        this.clientUserMode = json.data.userMode;
      } else {
        this.clientUserMode = "LIVE";
      }
    },
      (error) => {
        console.log(error);
        this.showErrorToastMsg(AppSettings.ERROR_MSG)
        this.hideProgressBar();
      });
  }

  setUserTyping(value: boolean) {
    var typeObject: any = {}
    if (this.from == 'client') {
      typeObject['is_typing_' + CommonFunctions.getUserId()] = value
    } else {
      if (value) {
        typeObject['is_typing'] = CommonFunctions.getUserId().toString()
      } else {
        typeObject['is_typing'] = 'noOne'
      }

    }

    this.clientcommunicationService.updateRoomForTypingOrRead(this.paramValue, typeObject).then(() => {
      console.log('updated type to:--' + value);
      if (!value) {
        this.index = 0;
      }
    }).catch((error) => {
      console.log('Error: Getting document:', error.message)
    });
  }

  index = 0;
  keyup(event: any) {
    if (this.index == 0) {
      this.index++;
      this.doneTyping(true);
    }
    clearTimeout(this.typingTimer);
    this.typingTimer = setTimeout(() => {
      this.doneTyping(false);

    }, 3000);
  }

  //user is "finished typing," do something
  doneTyping(value: boolean) {
    if (this.paramValue !== '') {
      this.setUserTyping(value)
    }
  }

  RoomData(ev: any): void {
    if (ev.RoomData !== undefined) {
      ev.RoomData.subscribe((room: Room) => {
        if (room != undefined) {
          this.room = room
          console.log("prefe:" + room.preference_type)
          this.preferenceType = room.preference_type || '';
          this.setUserStatus(room);
        }
      });
    }
  }
  clearData() {
    this.messageService.clear('errMsg');
    this.inputText = '';
    // this.chatAreaInputBoxQuill.root.innerHTML = '';
    CKEDITOR.instances.editor.setData('');
  }

  clearFiles() {
    this.selectedFiles = []
    this.finalSelectedFiles = undefined;
    this.downloadUrlList = [];
    this.messageService.clear('errMsg');
  }

  removeToFileList(file: any) {
    let index = this.selectedFiles.indexOf(file)
    if (index != -1) {
      this.selectedFiles.splice(index, 1)
    }
    if (this.selectedFiles.length == 0) {
      this.clearFiles()
    }
    if (this.checkLargeFileExist(this.selectedFiles)) {
      this.disabled = true
    } else {
      this.messageService.clear('errMsg');
      this.disabled = false
    }
  }

  checkLargeFileExist(array: any) {
    return array.some((file: any) => {
      return file.size > 5000000;
    });
  }


  showErrorToastMsg(msg: string) {
    this.messageService.clear('br');
    this.messageService.add({
      key: 'br',
      severity: 'error',
      detail: msg,
    });
  }

  showSuccessMessage(msg: string) {
    this.messageService.clear('br');
    this.messageService.add({
      key: 'br',
      severity: 'success',
      detail: msg,
    });
  }

  showErrorMessage(msg: string) {
    this.messageService.add({
      key: 'errMsg',
      severity: 'error',
      detail: msg,
    });
  }


  hideProgressBar() {
    this.isLoading = false;
    this.disabled = false;
  }

  showProgressBar() {
    this.messageService.clear('errMsg');
    this.isLoading = true;
    this.disabled = true;
  }

  getFontName(font: any) {
    return font.toLowerCase().replace(/\s/g, "-");
  }

  ngAfterViewInit() {
  this.interval = setInterval(() => {
    var checkElement = document.getElementById("editor");
    if (this.arrayVariables.length > 0 && checkElement != null) {
      clearInterval(this.interval);

      console.log("Array Variables: ", this.arrayVariables);
      console.log("Array Action Buttons: ", this.arrayActionButton);

      // CKEDITOR.config.strinsert_strings = this.arrayVariables;
      // CKEDITOR.config.strinsert_strings_two = this.arrayActionButton;

      const arrayVariables = this.arrayVariables;
      const arrayActionButton = this.arrayActionButton;

      CKEDITOR.replace('editor', {
        toolbar: [
          { name: 'paragraph', items: ['NumberedList', 'BulletedList', '-', 'JustifyLeft', 'JustifyCenter', 'JustifyRight', '-',] },
            { name: 'styles', items: ['Styles', 'Format', 'Font', 'FontSize'] },
            { name: 'basicstyles', items: ['Bold', 'Italic', 'Underline'] },
            { name: 'insert', items: ['Smiley'] },
            { name: 'links', items: [ 'Link', 'Unlink'] },
            { name: 'colors', items: ['TextColor', 'BGColor'] },
            { name: 'tools', items: ['my-combo', 'Maximize'] },
          ],
        height: "70px",
        removePlugins: 'elementspath',
        resize_enabled: false,
        stylesSet: [
          // Block styles
          { name: 'Italic Title', element: 'h2', styles: { 'font-style': 'italic' } },
          { name: 'Subtitle', element: 'h3', styles: { 'color': '#aaa', 'font-style': 'italic' } },
          { name: 'Special Container', element: 'div', styles: { 'padding': '5px 10px', 'background': '#eee', 'border': '1px solid #ccc' } },
          
          // Inline styles
          { name: 'Bold', element: 'strong' },
          { name: 'Italic', element: 'em' },
          { name: 'Underline', element: 'u' },
          { name: 'Strike', element: 'strike' },
          { name: 'Big', element: 'big' },
          { name: 'Small', element: 'small' },
          { name: 'Sample Text', element: 'samp' }
          // Other styles you want to keep can be added here
        ],
        format_tags: 'p;h1;h2;h3;h4;h5;h6',
        on: {
          pluginsLoaded: function () {
            var editor:any = this,
              config = editor.config;
            config.resize_maxHeight = 300;
            editor.ui.addRichCombo('my-combo', {
              label: 'Action Buttons / Variables',
              title: 'Action Buttons / Variables',
              toolbar: 'basicstyles,0',

              panel: {
                css: [CKEDITOR.skin.getPath('editor')].concat(config.contentsCss),
                multiSelect: false,
                attributes: { 'aria-label': 'My Dropdown Title' }
              },

              init: function () {
                // let selectVariable = CKEDITOR.config.strinsert_strings_two;
                // let selectActionButton = CKEDITOR.config.strinsert_strings;

                // console.log("Inside init: CKEDITOR.config.strinsert_strings_two: ", selectVariable);
                // console.log("Inside init: CKEDITOR.config.strinsert_strings: ", selectActionButton);

                // this.startGroup('Action Buttons');
                // selectVariable.forEach((element: any) => {
                //   this.add(element.rawHtmlLink, element.name);
                // });

                // this.startGroup('Variables');
                // selectActionButton.forEach((element: any) => {
                //   this.add(element, element);
                // });

                this.startGroup('Action Buttons');
                arrayActionButton.forEach((element: any) => {
                  this.add(element.rawHtmlLink, element.name);
                });

                this.startGroup('Variables');
                arrayVariables.forEach((element: any) => {
                  this.add(element, element);
                });

              },

              onClick: function (value: any) {
                editor.focus();
                editor.fire('saveSnapshot');
                  editor.insertHtml(value);
                //   editor.insertHtml(value);

                // editor.insertHtml(value);

                editor.fire('saveSnapshot');
              }
            });
          }
        },
      });

      let checkId = setInterval(() => {
        var toolbar: any = document.getElementsByClassName("cke_reset_all");
        if (toolbar[0] != null) {
          clearInterval(checkId);
          var toolbar: any = document.getElementsByClassName("cke_reset_all");
          toolbar[0].style.display = "none";
        }
      }, 100);
    }
  }, 1000);

    // var Intervalement = setInterval(()=>{
    //   var checkElement = document.getElementById("chatAreaInputBox");
    //   if(checkElement != null){
    //     clearInterval(Intervalement);
    //     // specify the fonts you would 
    //     var fonts = ['Arial', 'Verdana', 'Tahoma', 'Trebuchet', 'Times New Roman', 'Georgia', 'Garamond', 'Courier New', 'Helvetica'];
    //     var fontNames = fonts.map(font => this.getFontName(font));
    //     // add fonts to style
    //     var fontStyles = "";
    //     var foc = this;
    //     fonts.forEach(function(font) {
    //         var fontName = foc.getFontName(font);
    //         fontStyles += ".ql-snow .ql-picker.ql-font .ql-picker-label[data-value=" + fontName + "]::before, .ql-snow .ql-picker.ql-font .ql-picker-item[data-value=" + fontName + "]::before {" +
    //             "content: '" + font + "';" +
    //             "font-family: '" + font + "', sans-serif;" +
    //             "}" +
    //             ".ql-font-" + fontName + "{" +
    //             " font-family: '" + font + "', sans-serif;" +
    //             "}";
    //     });
    //     var node = document.createElement('style');
    //     node.innerHTML = fontStyles;
    //     document.body.appendChild(node);


    //     var linkdis = setInterval(() => {          
    //       var linkcols = document.querySelectorAll<HTMLElement>('.ql-link');
    //       if(linkcols.length > 0 ) {
    //         clearInterval(linkdis);
    //         linkcols[0].style.pointerEvents = 'none';
    //         linkcols[0].style.opacity = '0.4';
    //       }
    //     }, 1000);

    //     var imagedis = setInterval(() => {          
    //       var imagecols = document.querySelectorAll<HTMLElement>('.ql-image');
    //       if(imagecols.length > 0 ) {
    //         clearInterval(imagedis);
    //         imagecols[0].style.pointerEvents = 'none';
    //         imagecols[0].style.opacity = '0.4';
    //       }
    //     }, 1000);

    //     var videodis = setInterval(() => {          
    //       var videocols = document.querySelectorAll<HTMLElement>('.ql-video');
    //       if(videocols.length > 0 ) {
    //         clearInterval(videodis);
    //         videocols[videocols.length - 1].style.pointerEvents = 'none';
    //         videocols[videocols.length - 1].style.opacity = '0.4';
    //       }
    //     }, 1000);


    //     // Add fonts to whitelist
    //     var Font = Quill.import('formats/font');
    //     Font.whitelist = fontNames;
    //     Quill.register(Font, true);

    //     let btnCheck:any = this.arrayActionButton;
    //     let cutmBtnName:any = [];
    //     this.arrayActionButton.forEach((element:any) => {
    //       cutmBtnName.push(element.name);
    //     });

    //     var chatArea = new Quill('#chatAreaInputBox', {
    //       theme: 'snow',
    //       modules: {
    //         toolbar: {
    //           container:
    //           [
    //             [{ 'size': ['small', false, 'large', 'huge'] }],
    //             ['bold', 'italic', 'underline', 'strike'],
    //             [{ 'list': 'ordered' }, { 'list': 'bullet' }],
    //             [{ 'color': [] }, { 'background': [] }],
    //             [{ 'font': fontNames  }],
    //             [{ 'align': [] }],
    //             [ 'link', 'image', 'video' ],
    //             [{ 'placeholder': this.arrayVariables }],
    //             [{ 'custom_button': cutmBtnName}],
    //           ],
    //           handlers: {
    //             "placeholder": function (value:any) { 
    //                 if (value) {
    //                     var cursorPosition:any = chatArea.getSelection()?.index;
    //                     chatArea.insertText(cursorPosition, value);
    //                     chatArea.setSelection(cursorPosition + value.length);
    //                 }
    //               },
    //               "custom_button": function (value:any) { 
    //                 if (value) {
    //                     var cursorPosition:any = chatArea.getSelection()?.index;
    //                     btnCheck.forEach((el:any)=>{
    //                       if(el.name == value){
    //                         let setURL = el.link;
    //                         if(!CommonFunctions.validSecureURL(el.link)){
    //                           setURL = "https://"+el.link;
    //                         }

    //                         let setLink = '<p><a href="'+setURL+'" target="_blank"><img src="https://dabuttonfactory.com/button.png?t='+el.name+'&f=Open+Sans-Bold&ts=10&tc=fff&hp=10&vp=10&c='+el.buttonShape+'&bgt=unicolored&bgc='+el.backgroundColour+'"></a></p>';
    //                         chatArea.clipboard.dangerouslyPasteHTML(cursorPosition, setLink);
    //                         chatArea.setSelection(cursorPosition + setLink.length);
    //                       }
    //                     });
    //                 }
    //               }
    //             }
    //         },
    //       },
    //       placeholder: 'Type a message...',
    //     });

    //     this.chatAreaInputBoxQuill = chatArea;
    //     const placeholderPickerItems = Array.prototype.slice.call(document.querySelectorAll('#chatArea .ql-placeholder .ql-picker-item'));

    //     placeholderPickerItems.forEach(item => item.textContent = item.dataset.value);
    //     var checkDropdown:any = document.querySelector('#chatArea .ql-placeholder .ql-picker-label');
    //     var InsertVal:any = document.querySelector('#chatArea .ql-placeholder .ql-picker-label');
    //     if(checkDropdown){
    //       checkDropdown.style.paddingRight  = '20px';
    //       checkDropdown.innerHTML = 'Select Variable' + InsertVal.innerHTML;
    //     }

    //     const custombtnPickerItems = Array.prototype.slice.call(document.querySelectorAll('#chatArea .ql-custom_button .ql-picker-item'));  
    //     custombtnPickerItems.forEach(item => item.textContent = item.dataset.value);
    //     var checkcustombtn:any = document.querySelector('#chatArea .ql-custom_button .ql-picker-label');
    //     var InsertVal:any = document.querySelector('#chatArea .ql-custom_button .ql-picker-label');
    //     if(checkcustombtn){
    //       checkcustombtn.style.paddingRight  = '20px';
    //       checkcustombtn.innerHTML = 'Select Action Button' + InsertVal.innerHTML;
    //     }

    //     var toolbar:any = document.getElementsByClassName("ql-toolbar ql-snow");
    //     toolbar[0].style.display = "none";
    //   }
    // },100);
  }

  listMessagesVariables() {
    this.messagesTemplateService.listVariables().subscribe(
      (response: any) => {
        var json = JSON.parse(JSON.stringify(response));
        console.log('list Message Variables  Response --->> ' + JSON.stringify(json));
        if (json.response.status == 'SUCCESS') {
          this.arrayVariables = [];
          json.data.forEach((element: string) => {
            if (element.indexOf('BUSINESS_') != -1) {
              if ('$BUSINESS_URL' != element) {
                this.arrayVariables.push(element.replace('$', '${').concat('}'))
              }
            }
          });
        } else {
          this.showErrorMessage(json.response.displayMessage)
        }
      },
      (error: any) => {
        console.log(error);
        this.showErrorMessage(AppSettings.ERROR_MSG);
      }
    );
  }

  listActionButton() {
    this.actionButtonService.ActionButtonlistByBusinessIdAndStatus().subscribe(
      (response) => {
        var json = JSON.parse(JSON.stringify(response));
        console.log('Keyword Variables  Response --->> ' + JSON.stringify(json));
        if (json.response.status == 'SUCCESS') {
          this.arrayActionButton = json.data;
        } else {
          this.showErrorMessage(json.response.displayMessage)
        }
      },
      (error) => {
        console.log(error);
        this.showErrorMessage(AppSettings.ERROR_MSG)
        this.hideProgressBar()
      }
    );
  }

  showToolBar() {
    if (this.displayToolbar) {
      var toolbar: any = document.getElementsByClassName("cke_reset_all");
      toolbar[0].style.display = "block";
    } else {
      var toolbar: any = document.getElementsByClassName("cke_reset_all");
      toolbar[0].style.display = "none";
    }

    var findClass:any = document.getElementsByClassName("cke_combo_inlinelabel");
    findClass[findClass.length-1].style.width = '140px';
    this.displayToolbar = !this.displayToolbar;
  }

}
