<p-messages [style]="{'font-size': '12px'}"></p-messages>

<form role="form" class="form-horizontal" (ngSubmit)="reset(resetPassword)" id="resetPassword" #resetPassword="ngForm">

  <div class="row form-group">
    <label class="col-sm-4 control-label">Password<span class="symbol required"></span></label>
    <div class="col-sm-8">
      <input class="form-control" type="password" name="profileNewPassword"
        pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$" placeholder="Enter Password"
        ngModel #profileNewPassword="ngModel" [disabled]="disabled" />

    </div>
    <small *ngIf="profileNewPassword.invalid" style="font-size: 13px;" class="col-sm-12 p-error p-ml-3 p-mt-1">Password
      should be
      of
      minimum
      8 character and contain
      atleast one
      uppercase letter, one number, one special character.
    </small>
  </div>
  <div class="row form-group">
    <label class="col-sm-4 control-label">Confirm Password<span class="symbol required"></span></label>
    <div class="col-sm-8">
      <input type="password" name="profileConfirmPassword" class="form-control" placeholder="Re-enter Password" ngModel
        #profileConfirmPassword="ngModel" required pattern="{{ profileNewPassword.value }}" [disabled]="disabled" />
      <small *ngIf="profileConfirmPassword.errors?.pattern" class="p-error">
        Passwords do not match.
      </small>
    </div>
  </div>
  <div class="form-group center">
    <p-progressSpinner *ngIf="isLoading" [style]="{ width: '40px', height: '40px' }" strokeWidth="3" class="p-mr-4">
    </p-progressSpinner>
    <button pButton pRipple class="p-button-raised " type="submit" label="Reset Password"
      [disabled]="disabled || profileConfirmPassword.errors?.pattern || profileNewPassword.invalid"></button>
  </div>
</form>

<!-- TOAST -->
<p-toast position="bottom-right" key="br"></p-toast>