<div [ngClass]="state=='open' ? 'main_content_open' : 'main_content'">
  <p-scrollPanel [style]="{width: '100%', height: '95vh','margin-top':'7px','padding-bottom':'70px'}" #sc>
    <app-message-credit-warning></app-message-credit-warning>
    <app-twillio-message></app-twillio-message>
    <div class="p-d-flex p-jc-between p-ai-center p-pt-3 p-pb-3">
      <div class="page_title">
        Manage Users
      </div>
      <div>
        <p-breadcrumb [model]="items" [home]="home"></p-breadcrumb>
      </div>
    </div>
    <div class="p-mb-5">
      <p-card>
        <div class="p-mb-3 toolbar">
          <div class="p-grid p-ai-center">
            <div class="p-col p-md-3 p-lg-3">
              <div class="p-d-flex">
                <span class="p-input-icon-right p-fluid">
                  <i *ngIf="searchInputText !== ''" class="pi pi-times" style="cursor: pointer;"
                    (click)="resetUserList()"></i>
                  <!--searchInput.value=''-->
                  <input #searchInput pInputText type="text" [(ngModel)]="searchInputText" placeholder="Search User"
                    (keydown)="onEnterSearchUser($event,searchInput.value)" />
                  <!-- (input)="dt1.filterGlobal($any($event.target).value, 'contains')" -->
                </span>
                <div>
                  <button pButton pRipple type="button" icon="pi pi-search" [style]="{width:'2.1rem',height:'2.1rem'}"
                    class="p-button-rounded p-button-raised p-ml-2" (click)="listUsers(1,searchInput.value)"></button>
                </div>
              </div>
            </div>
            <div class="p-col p-md-3 p-lg-3 p-fluid">
              <p-dropdown [options]="sortBy" [(ngModel)]="selectedSortBy" optionLabel="name" optionValue="value"
                [showClear]="true" placeholder="Sort By" (onChange)="onFilterValueChange($event)">
              </p-dropdown>
            </div>
            <div class="p-col p-md-6 p-lg-6 p-jc-end d-none d-lg-flex d-xl-flex">
              <button *ngIf="getPrivilege('businessclient_user_create')" pButton pRipple type="button" label="Add User"
                class="p-button-sm p-button-raised" style="font-size: 12px;" icon="pi pi-plus"
                (click)="showHideModalDialog('add_user')" [disabled]="bussinesInfo?.subscriptionType != 'STANDARD'"></button>
            </div>
            <div class="p-col p-md-6 p-lg-6 d-flex p-jc-center d-lg-none">
              <button *ngIf="getPrivilege('businessclient_user_create')" pButton pRipple type="button" label="Add User"
                class="p-button-sm p-button-raised" style="font-size: 12px;" icon="pi pi-plus"
                (click)="showHideModalDialog('add_user')" [disabled]="bussinesInfo?.subscriptionType != 'STANDARD'"></button>
            </div>
          </div>
        </div>
        <p-table #dt1 [columns]="cols" [value]="users"
          styleClass="p-datatable-sm p-datatable-gridlines p-datatable-striped " [autoLayout]='true' [responsive]='true'
          [rowHover]="true">
          <!-- [globalFilterFields]="['firstName','lastName','mobile','email','country']" -->
          <ng-template pTemplate="header" let-columns>
            <tr style="font-size: small;">
              <th *ngFor="let col of columns">
                {{col.header}}
              </th>
              <th *ngIf="getPrivilege('businessclient_user_modify')">
                Action
              </th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-user let-columns="columns">
            <tr style="font-size: smaller;">
              <td *ngFor="let col of columns">
                <span class="p-column-title">{{col.header}}</span>
                {{user[col.field]}}
              </td>

              <td *ngIf="getPrivilege('businessclient_user_modify')" class="last_border">
                <span class="p-column-title">Action</span>
                <a *ngIf="getPrivilege('businessclient_user_modify') && user.canChangeRole; else canEdit"
                  href="javascript:void(0);" data-toggle="modal" (click)="getUser(user, 'edit_user')">
                  <i class="pi pi-pencil" style="font-size: 0.8rem;color:#007AFF"></i>
                </a>
                <ng-template #canEdit style="display:inline-block;">
                  <div *ngIf="getPrivilege('businessclient_user_modify')" pTooltip="You cannot edit this user"
                    tooltipPosition="left" style="display:inline-block;">
                    <a href="javascript:void(0);" data-toggle="modal" class="disabledClass">
                      <i class="pi pi-pencil" style="font-size: 0.8rem"></i>
                    </a>
                  </div>
                </ng-template>

                <!-- <a href="javascript:void(0);" data-toggle="modal">
                        <i class="ti-settings"></i>
                      </a>
                      <a href="javascript:void(0);" data-toggle="modal">
                        <i class="ti-pencil"></i>
                      </a>
                      <a href="javascript:void(0);" >
                        <i class="ti-settings"></i>
                      </a>  -->
                &nbsp;
                <a *ngIf="getPrivilege('businessclient_user_modify') && user.canChangeRole; else canReset"
                  href="javascript:void(0);" (click)="getUser(user, 'reset_password')">
                  <i class="pi pi-cog" style="font-size: 0.8rem;color:#007AFF"></i>
                </a>
                <ng-template #canReset style="display:inline-block;">
                  <div pTooltip="You cannot change password of this user" tooltipPosition="left"
                    style="display:inline-block;">
                    <a href="javascript:void(0);" class="disabledClass">
                      <i class="pi pi-cog" style="font-size: 0.8rem"></i>
                    </a>
                  </div>
                </ng-template>
                &nbsp;
                <a *ngIf="user.canDelete; else canDelete" href="javascript:void(0);"
                  (click)="confirmDeleteDialog(user)">
                  <i class="pi pi-trash" style="font-size: 0.8rem;color: #dc3545;"></i>
                </a>
                <ng-template #canDelete style="display:inline-block;">
                  <div pTooltip="You cannot delete this user" tooltipPosition="left" style="display:inline-block;">
                    <a href="javascript:void(0);" class="disabledClass">
                      <i class="pi pi-trash" style="font-size: 0.8rem"></i>
                    </a>
                  </div>
                </ng-template>
              </td>
            </tr>
          </ng-template>
          <ng-template pTemplate="emptymessage" let-columns>
            <tr>
              <td [attr.colspan]="columns.length+1">
                <div *ngIf="isLoading" class="alignCenter">
                  <p-progressSpinner [style]="{ width: '40px', height: '40px' }" strokeWidth="3">
                  </p-progressSpinner>
                </div>
                <p-messages *ngIf="users?.length == 0 && !isLoading" key="errMsg" class="alignCenter">
                  <ng-template pTemplate>
                    <i class="pi pi-copy" style="font-size: 2rem"></i>
                    <div class="p-ml-2">NO DATA FOUND</div>
                  </ng-template>
                </p-messages>
              </td>
            </tr>
          </ng-template>
        </p-table>
        <p-paginator #p [rows]="pageSize" [totalRecords]="totalCount" (onPageChange)="paginate($event)">
        </p-paginator>
      </p-card>
    </div>
  </p-scrollPanel>
</div>

<!-- ADD USER MODAL -->
<p-dialog header="Add User" [(visible)]="displayAddModal" [modal]="true" [baseZIndex]="10000" [draggable]="false"
  [resizable]="true" [maximizable]="true" (onHide)="clearFormData()" (onShow)="showDialogMaximized($event,addDialog)"
  #addDialog>
  <p-messages key="errMsg" [style]="{
      'font-size': '12px'
    }"></p-messages>
  <form role="form" (ngSubmit)="addUser(addUserForm)" #addUserForm="ngForm" style="min-width: 60vw;">
    <div class="row">
      <div class="form-group col-md-6">
        <label>First Name<span class="symbol required"></span></label>
        <input type="text" class="form-control border-black" name="firstName" placeholder="Enter First Name"
          [(ngModel)]="firstName" required />
      </div>
      <div class="form-group col-md-6">
        <label>Last Name<span class="symbol required"></span></label>
        <input type="text" class="form-control" name="lastName" placeholder=" Enter Last Name" [(ngModel)]="lastName"
          required />
      </div>
    </div>
    <div class="row">
      <div class="form-group col-md-4">
        <label class="control-label">Mobile<span class="symbol required"></span></label>
        <span class="input-icon"><input type="text" class="form-control" placeholder="Enter Mobile" name="mobile"
            [(ngModel)]="mobile" [disabled]="disabled" maxlength="10" (keypress)="validateDigitOnly($event)">
          <span class="number">+1</span></span>
        <!-- <div class="p-inputgroup">
          <span class="p-inputgroup-addon"><i class="pi pi-plus"></i></span>
          <input type="text" name="mobile" class="form-control" placeholder="Enter Mobile"
            (blur)="inputMobileValidator($event)" [(ngModel)]="mobile" required aria-describedby="mobile-error"
            aria-invalid="{!isValidMobile}" characterPattern="^[1-9]\d{1,14}$" />
        </div> -->
      </div>
      <div class="form-group col-md-4">
        <label>Email<span class="symbol required"></span></label>
        <input type="email" class="form-control" name="AddUserEmail" placeholder=" Enter email" [(ngModel)]="email"
          required />
      </div>
      <div class="form-group col-md-4">
        <label>Country<span class="symbol required"></span></label>
        <select [(ngModel)]="country" class="form-control" name="country" required style="height: 34px;">
          <option [ngValue]="null" disabled>Select Country</option>
          <option *ngFor="let country of countries" [value]="country.isoCode">
            {{ country.name }}
          </option>
        </select>
      </div>
    </div>
    <div class="row">
      <div class="form-group col-md-6">
        <label>New Password<span class="symbol required"></span></label>
        <input class="form-control" [ngClass]="newPassword.invalid ? 'ng-invalid ng-dirty' : ''" type="password"
          name="addNewPassword" pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$"
          placeholder="Enter New Password" [(ngModel)]="password" #addNewPassword="ngModel" />
        <small *ngIf="addNewPassword.invalid" class="p-error">Password should be of minimum 8 character and contain
          atleast one
          uppercase letter, one number, one special character.
        </small>
      </div>

      <div class="form-group col-md-6">
        <label>Confirm Password<span class="symbol required"></span></label>
        <input type="password" name="addConfirmPassword" class="form-control" placeholder="Enter Confirm Password"
          [(ngModel)]="confirmPassword" #addConfirmPassword="ngModel" required pattern="{{ newPassword.value }}" />
        <small *ngIf="addConfirmPassword.errors?.pattern" class="p-error">
          Passwords do not match.
        </small>
      </div>
    </div>
    <div class="row">
      <div class="form-group col-md-6">
        <label>Roles<span class="symbol required"></span></label><br>
        <p-multiSelect [options]="roles" styleClass="col-md-12" [(ngModel)]="addSelectedRoles"
          placeholder="Select roles" [displaySelectedLabel]="true" optionLabel="name" filterPlaceHolder="Search roles"
          optionValue="id" name="roles" appendTo="body" [style]="{ 'font-size': '14px','width':'100%'}"
          [baseZIndex]="10001">
          <ng-template let-value pTemplate="selectedItems">
            <div class="country-item country-item-value" *ngFor="let option of addSelectedRoles">
              <div>{{labelByValue(option)}}</div>
            </div>
            <div *ngIf="!addSelectedRoles || addSelectedRoles.length === 0" class="country-placeholder">
              Select roles
            </div>
          </ng-template>
        </p-multiSelect>
      </div>
    </div>

  </form>
  <ng-template pTemplate="footer">
    <p-button icon="pi pi-times" (click)="displayAddModal = false" label="Cancel"
      styleClass="p-button-danger p-button-text"></p-button>
    <p-button icon="pi pi-user-plus" (click)="addUser(addUserForm)" label="Add" [disabled]="disabled"></p-button>
  </ng-template>
  <div *ngIf="isLoading" class="progressLayout">
    <div class="progressContainer">
      <p-progressSpinner [style]="{ width: '40px', height: '40px' }" strokeWidth="3"></p-progressSpinner>
    </div>
  </div>
</p-dialog>

<!-- EDIT USER MODAL -->
<p-dialog #editDialog header="Edit User" [(visible)]="displayEditModal" [modal]="true" [baseZIndex]="10000"
  [draggable]="false" [resizable]="true" [maximizable]="true" (onShow)="showDialogMaximized($event,editDialog)">
  <p-messages key="errMsg" [style]="{
      'font-size': '12px'
    }"></p-messages>
  <form style="min-width: 60vw;" role="form" (ngSubmit)="editUser(userForm)" #userForm="ngForm">
    <div class="row">
      <div class="form-group col-md-6">
        <label>First Name<span class="symbol required"></span></label>
        <input type="text" class="form-control border-black" name="firstName" placeholder="Enter First Name"
          [(ngModel)]="firstName" />
      </div>
      <div class="form-group col-md-6">
        <label>Last Name<span class="symbol required"></span></label>
        <input type="text" class="form-control" name="lastName" placeholder=" Enter Last Name" [(ngModel)]="lastName" />
        <input type="hidden" value="id" [(ngModel)]="id" name="id" />
      </div>
    </div>
    <div class="row">
      <!-- [ngClass]="isValidMobile ? 'has-success' : 'has-error'" -->
      <div class="form-group col-md-4">
        <label class="control-label">Mobile<span class="symbol required"></span></label>
        <span class="input-icon"><input type="text" class="form-control" placeholder="Enter Mobile" name="mobile"
            [(ngModel)]="mobile" [disabled]="disabled" maxlength="10" (keypress)="validateDigitOnly($event)">
          <span class="number">+1</span></span>
      </div>
      <div class="form-group col-md-4">
        <label>Email<span class="symbol required"></span></label>
        <input type="text" class="form-control" name="email" placeholder=" Enter email" [(ngModel)]="email" />
      </div>
      <div class="form-group col-md-4">
        <label>Country<span class="symbol required"></span></label>
        <select [(ngModel)]="country" name="country" class="form-control" style="height: 34px;">
          <option [ngValue]="null" disabled>Select Country</option>
          <option *ngFor="let country of countries" [value]="country.isoCode">
            {{ country.name }}
          </option>
        </select>
      </div>
    </div>
    <div class="row">
      <div class="form-group col-md-6">
        <label>Roles<span class="symbol required"></span></label>
        <div>
          <p-multiSelect styleClass="col-md-12" [options]="roles" [(ngModel)]="selectedRoles" placeholder="Select roles"
            optionLabel="name" filterPlaceHolder="Search roles" [displaySelectedLabel]="true" optionValue="id"
            name="editRoles" [maxSelectedLabels]="3" appendTo="body" [style]="{ 'font-size': '14px','width':'100%'}"
            [baseZIndex]="10001">
            <!-- <span *ngFor="let c of selectedRoles" style="margin-right: 10px">
              {{labelByValue(c)}}
            </span> -->
            <ng-template let-value pTemplate="selectedItems">
              <div class="country-item country-item-value" *ngFor="let option of selectedRoles">
                <div>{{labelByValue(option)}}</div>
              </div>
              <div *ngIf="!selectedRoles || selectedRoles.length === 0" class="country-placeholder">
                Select roles
              </div>
            </ng-template>
          </p-multiSelect>
        </div>
      </div>
    </div>
  </form>
  <ng-template pTemplate="footer">
    <p-button icon="pi pi-times" (click)="displayEditModal = false" label="Cancel"
      styleClass="p-button-danger p-button-text"></p-button>
    <p-button icon="pi pi-save" (click)="editUser(userForm)" label="Save" [disabled]="disabled"></p-button>
  </ng-template>
  <div *ngIf="isLoading" class="progressLayout">
    <div class="progressContainer">
      <p-progressSpinner [style]="{ width: '40px', height: '40px' }" strokeWidth="3"></p-progressSpinner>
    </div>
  </div>
</p-dialog>

<!-- REMOVE USER MODAL -->
<p-confirmDialog [baseZIndex]="10000" rejectButtonStyleClass="p-button-text" [style]="{width:'45vw'}">
</p-confirmDialog>

<!-- RESET PASSWORD -->
<p-dialog #resetDialog header="Reset Password" [(visible)]="displayResetPasswordModal" [modal]="true"
  [baseZIndex]="10000" [draggable]="false" [resizable]="false" (onHide)="clearFormData()"
  (onShow)="showDialogMaximized($event,resetDialog)">
  <p-messages key="errMsg" [style]="{
      'font-size': '12px'
    }"></p-messages>
  <form style="min-width: 50vw;" (ngSubmit)="reset(resetPassword)" id="resetPassword" #resetPassword="ngForm">
    <div class="row form-group">
      <label class="col-sm-4 control-label">New Password<span class="symbol required"></span></label>
      <div class="col-sm-8">
        <input class="form-control" [ngClass]="newPassword.invalid ? 'ng-invalid ng-dirty' : ''" type="password"
          name="password" pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$"
          placeholder="Enter Password" [(ngModel)]="password" #newPassword="ngModel" />
        <small *ngIf="newPassword.invalid" class="p-error">Password should be of minimum 8 character and contain
          atleast one
          uppercase letter, one number, one special character.
        </small>
        <input type="hidden" name="id" value="{{ id }}" [(ngModel)]="id" />
      </div>
    </div>
    <div class="row form-group">
      <label class="col-sm-4 control-label">Confirm Password<span class="symbol required"></span></label>
      <div class="col-sm-8">
        <input type="password" name="confirm-password" class="form-control" placeholder="Re-enter Password"
          [(ngModel)]="confirmPassword" #confirmpassword="ngModel" required pattern="{{ newPassword.value }}" />
        <small *ngIf="confirmpassword.errors?.pattern" class="p-error">
          Passwords do not match.
        </small>
        <!-- <div
          *ngIf="confirmpassword.invalid || confirmpassword.touched"
          class="alert alert-danger"
        >
          <div *ngIf="confirmpassword.errors?.required">
            Confirm password is required.
          </div>

          <div *ngIf="confirmpassword.errors?.pattern">
            Password & Confirm Password does not match.
          </div>
        </div> -->
      </div>
    </div>
  </form>
  <ng-template pTemplate="footer">
    <p-button icon="pi pi-times" (click)="displayResetPasswordModal = false" label="Cancel"
      styleClass="p-button-danger p-button-text"></p-button>
    <p-button icon="pi pi-cog" (click)="reset(resetPassword)" label="Reset Password"
      [disabled]="disabled || !resetPassword.valid"></p-button>
  </ng-template>
  <div *ngIf="isLoading" class="progressLayout">
    <div class="progressContainer">
      <p-progressSpinner [style]="{ width: '40px', height: '40px' }" strokeWidth="3"></p-progressSpinner>
    </div>
  </div>
</p-dialog>