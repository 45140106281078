import { Component, OnInit, ViewChild, Input, Output } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ConfirmationService, MessageService } from 'primeng/api';
import { AppSettings } from '../Constant/AppSettings';
import { RegistrationService } from '../services/registration/registration.service';
import { Md5 } from 'ts-md5/dist/md5';
import { CommonFunctions } from '../Utilities/CommonFunctions';
import { SubscriptionAndPaymentsService } from 'src/app/services/subscription-and-payments/subscription-and-payments.service';
import { RenewModalComponent } from "../custom/renew-modal/renew-modal.component";

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.css']
})
export class RegistrationComponent implements OnInit {
  @ViewChild('ngOtpInput', { static: false }) ngOtpInput: any;
  //Business Url
  shortNameUrl: string = '';
  //Business info
  businessName: string = '';
  address = '';
  country = 'null';
  userCountry = 'null';
  countries: any[] = [];
  phone: string = '';
  fax: string = '';
  ein: string = '';
  websiteUrl: string = '';
  businessType: any[] = []
  timezone: string = 'null';
  timezoneList: string[] = [
    "(GMT -5:00) US/Eastern",
    "(GMT -6:00) US/Central",
    "(GMT -7:00) US/Mountain",
    "(GMT -8:00) US/Pacific",
    "(GMT -9:00) US/Alaska",
    "(GMT -10:00) US/Hawaii",
  ];
  selectedBusinessType: any = 'null';
  //Profile info
  firstName: string = '';
  lastName: string = '';
  mobile: string = '';
  email = '';
  profileNewPassword = '';
  profileConfirmPassword = '';
  //Add Services
  serivces: any[] = [{
    serviceName: '',
    autoSchedule: false
  }];
  disabledServiceAdd: boolean = false
  //
  titleText: string = 'Business Url';
  currentPosition: string = 'user_info';
  isLoading: boolean = false;
  disabledShortUrl: boolean = true;
  disabled: boolean = false;
  error = '';
  //otp
  receivedOtp: any;
  agreeValue: boolean = false
  showRegisterLayout: boolean = false;
  redirectingToLogin: boolean = false;
  countNumber = 5;
  resendCountNumber = 60;
  showResendLayout: boolean = false;
  disabledOtp: boolean = true;
  //File upload
  avatarName = '';
  imageInputError: boolean = false;
  businessProfileUrl = '';
  profileUrl = '';
  //image cropper
  croppedImageUrl: any = '';
  //Crop Dialog
  displayCropModal: boolean = false;
  address2: string = '';
  city: string = '';
  business_state: string = '';
  zipcode: any;
  paymentDetails: any[] = [];

  @ViewChild('renew') renew?: RenewModalComponent;
  // Dialog display varaible
  displayRenewModal: boolean = false;

  constructor(
    private registrationService: RegistrationService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private subscriptionAndPaymentsService: SubscriptionAndPaymentsService
  ) { }

  ngOnInit(): void {
    localStorage.clear();
    this.getCountries();
    this.getBusinessType();
    this.getTimezone();
  }

  checkBusinessUrl() {
    if (this.shortNameUrl != '') {
      this.checkBusinessShortname()
    }
  }
  checkBusinessShortname() {
    this.messageService.clear('errMsg');
    this.registrationService.checkBusinessShortname(this.shortNameUrl).subscribe(
      (response) => {
        var json = JSON.parse(JSON.stringify(response));
        console.log('Check Short Name Response --->> ' + JSON.stringify(json));
        if (json.response.status == 'SUCCESS') {
          this.disabledShortUrl = false
        } else {
          this.showErrorMessage(json.response.displayMessage)
          this.disabledShortUrl = true
        }
        if (this.shortNameUrl == '') {
          this.disabledShortUrl = true
        }
      },
      (error) => {
        console.log('There was an error');
        console.log(error);
        this.hideProgressBar();
      }
    );
  }

  checkDuplicateUser(): Promise<string> {
    return new Promise((resolve, reject) => {
      this.registrationService.checkDuplicateUser(this.mobile, this.email, this.shortNameUrl).subscribe(
        (response) => {
          var json = JSON.parse(JSON.stringify(response));
          console.log('Check Duplicate User Response --->> ' + JSON.stringify(json));
          if (json.response.status == 'SUCCESS') {
            resolve('success')
          } else {
            this.showErrorMessage(json.data.message)
            resolve('null')
          }
        },
        (error) => {
          console.log(error);
          this.showErrorMessage(AppSettings.ERROR_MSG)
          this.hideProgressBar();
          resolve('null')
        }
      );
    })
  }

  resendOtp() {
    this.sendOtp()
    this.showResendButtonAfterSixtySec()
  }

  sendOtp() {
    this.clearOtp();
    this.showProgressBar();
    this.registrationService.sendOTP(this.email).subscribe(
      (response) => {
        this.hideProgressBar();
        var json = JSON.parse(JSON.stringify(response));
        console.log('Send Otp Response --->> ' + JSON.stringify(json));
        if (json.response.status == 'SUCCESS') {
          this.receivedOtp = json.data;
        } else {
          this.showErrorMessage(json.data.message)
        }
      },
      (error) => {
        console.log(error);
        this.showErrorMessage(AppSettings.ERROR_MSG)
        this.hideProgressBar();
      }
    );
  }

  paymentProcess(){
    if (this.validateBusinessInfo() == 'null') {
      return;
    }
    this.showHideModalDialog('renew_subscription');
  }

  registerBusiness() {
    this.showProgressBar()
    let serviceTypeData: any[] = []
    this.serivces.forEach(element => {
      serviceTypeData.push({ name: element.serviceName, autoSchedule: false })
    })
    const businessData = {
      businessInfo: {
        name: this.businessName,
        shortName: this.shortNameUrl,
        businessType: {
          id: this.selectedBusinessType,
          name: this.businessType.find(element => element.id == this.selectedBusinessType).name
        },
        address: this.address,
        address2: this.address2,
        city: this.city,
        zipcode: this.zipcode,
        businessState: this.business_state,
        country: this.country,
        phone: '+1' + this.phone,
        fax: this.fax,
        logo: null,
        website: this.websiteUrl,
        timeZone: this.timezone,
        einNo: this.ein
      },
      // serviceType: serviceTypeData,
      user: {
        firstName: this.firstName,
        lastName: this.lastName,
        mobile: '+1' + this.mobile,
        email: this.email,
        country: this.userCountry,
        password: this.profileConfirmPassword
      }
    }
    var data = { data: JSON.parse(JSON.stringify(businessData)) };
    console.log('Business Data ---> ' + JSON.stringify(data));
    this.registrationService.registerBusiness(data).subscribe(
      (response) => {
        var json = JSON.parse(JSON.stringify(response));
        console.log('Business Register Response --->> ' + JSON.stringify(json));
        if (json.response.status == 'SUCCESS') {
          if (this.croppedImageUrl != '') {
            this.uploadBusinessLogo(json.data.id, json.response.displayMessage)
          } else {
            this.showSuccessMessage(json.response.displayMessage)
            // this.redirectingToLoginInFiveSec()
          }
          CommonFunctions.setBusinessInfo(json.data);

          this.subscriptionAndPaymentsService.paymentSuccess(json.data.id, 'home', this.paymentDetails)
            .subscribe(
              (response) => {
                var json = JSON.parse(JSON.stringify(response));
                console.log("Payment success Response --->> " + JSON.stringify(json));
                if (json.response.status == 'SUCCESS') {
                  this.PaymentshowSuccessMessage(json.response.status, json.response.message)
                  this.displayRenewModal = false;
                } else {
                  this.showErrorMessage(json.response.displayMessage)
                }

                this.hideProgressBar();
                this.redirectingToLoginInFiveSec()
              },
              (error) => {
                console.log(error);
                this.showErrorMessage(AppSettings.ERROR_MSG)
                this.hideProgressBar();
              }
            );
        } else {
          this.showErrorMessage(json.data.message)
        }
        this.hideProgressBar()
      },
      (error) => {
        console.log(error);
        this.showErrorMessage(AppSettings.ERROR_MSG)
        this.hideProgressBar();
      }
    );
  }

  uploadBusinessLogo(id: any, message: string) {
    this.showProgressBar()
    this.registrationService.uploadBusinessLogo(id, this.croppedImageUrl).subscribe(
      (res) => {
        console.log(res);
        var json = JSON.parse(JSON.stringify(res));
        if (json.response.status == 'SUCCESS') {
          this.redirectingToLoginInFiveSec()
          this.showSuccessMessage(message)
        } else {
          this.showErrorMessage(json.response.displayMessage)
        }
        this.hideProgressBar()
      },
      (err) => {
        console.log(err);
        this.showErrorMessage(AppSettings.ERROR_MSG)
      }
    );
  }


  getCountries() {
    this.registrationService.getCountries().subscribe(
      (response) => {
        var json = JSON.parse(JSON.stringify(response));
        console.log(json.data);
        this.countries = json.data;
      },
      (error) => {
        console.log(error);
        this.showErrorMessage(AppSettings.ERROR_MSG)
        this.hideProgressBar();
      }
    );
  }

  getBusinessType() {
    this.registrationService.getBusinessType().subscribe(
      (response) => {
        var json = JSON.parse(JSON.stringify(response));
        console.log(json.data);
        this.businessType = json.data;
        // json.data.forEach((element:any) =>{
        //   if(element.name == "DANCE STUDIO"){
        //     this.businessType.push({id: element.id, name: element.name});
        //   }
        //   if(element.name == "Non-Profit"){
        //     this.businessType.push({id: element.id, name: element.name.toUpperCase()});
        //   }
        // });
        this.businessType.sort((a,b) => a.name > b.name ? 1 : -1);
      },
      (error) => {
        console.log(error);
        this.showErrorMessage(AppSettings.ERROR_MSG)
        this.hideProgressBar();
      }
    );
  }

  getTimezone() {
    this.registrationService.getTimezone().subscribe(
      (response) => {
        var json = JSON.parse(JSON.stringify(response));
        console.log('Business timezone response --> ' + JSON.stringify(json.data));
        if (json.response.status == 'SUCCESS') {
          // Object.entries(json.data).forEach(([key, value]) => {
          //   if(key == '513' || key == '552' || key == '581' || key == '596' || key == '608' || key == '620'){
          //     this.timezoneList.push(json.data[key]);
          //   }
          // });
          // this.timezoneList = json.data;
          // this.timezoneList.push(json.data[590]);
          // this.timezoneList.push(json.data[588]);
          // this.timezoneList.push(json.data[594]);
          // this.timezoneList.push(json.data[595]);
          // this.timezoneList.push(json.data[585]);
          // this.timezoneList.push(json.data[591]);
        } else {
          this.showErrorMessage(json.response.displayMessage)
        }
      },
      (error) => {
        console.log(error);
        this.showErrorMessage(AppSettings.ERROR_MSG)
        this.hideProgressBar();
      }
    );
  }

  validateBusiness(): Promise<string> {
    const businessData = {
      name: this.businessName,
      shortName: this.shortNameUrl,
      businessType: {
        id: this.selectedBusinessType,
        name: this.businessType.find(element => element.id == this.selectedBusinessType).name
      },
      address: this.address,
      country: this.country,
      phone: '+1' + this.phone,
      fax: this.fax,
      logo: null,
      website: this.websiteUrl,
      einNo: this.ein
    }
    var data = { data: JSON.parse(JSON.stringify(businessData)) };
    console.log('Business Data ---> ' + JSON.stringify(data));

    return new Promise((resolve, reject) => {
      this.registrationService.validateBusiness(data).subscribe(
        (response) => {
          var json = JSON.parse(JSON.stringify(response));
          console.log('Validate Business response --> ' + JSON.stringify(json));
          if (json.response.status == 'SUCCESS') {
            resolve('success');
          } else {
            resolve('null')
            this.showErrorMessage(json.response.displayMessage)
          }

        },
        (error) => {
          console.log(error);
          this.showErrorMessage(AppSettings.ERROR_MSG)
          this.hideProgressBar();
          resolve('null')
        }
      );
    });
  }

  validateUser(): Promise<string> {
    const userData = {
      firstName: this.firstName,
      lastName: this.lastName,
      mobile: '+1' + this.mobile,
      email: this.email,
      country: this.userCountry,
      password: this.profileConfirmPassword
    }
    var data = { data: JSON.parse(JSON.stringify(userData)) };
    console.log('User Data ---> ' + JSON.stringify(data));
    return new Promise((resolve, reject) => {
      this.registrationService.validateUser(data).subscribe(
        (response) => {
          var json = JSON.parse(JSON.stringify(response));
          console.log('Validate User response --> ' + JSON.stringify(json));
          if (json.response.status == 'SUCCESS') {
            resolve('success');
          } else {
            resolve('null')
            this.showErrorMessage(json.response.displayMessage)
          }
        },
        (error) => {
          console.log(error);
          this.showErrorMessage(AppSettings.ERROR_MSG)
          this.hideProgressBar();
          resolve('null')
        }
      );
    })
  }

  onSubmit(signInForm: NgForm) {
    if (!signInForm.valid) {
      return;
    }
    // this.login(signInForm);
  }

  async goToNextAndPrevious(value: string) {
    switch (value) {
      case 'business_url':
        if (this.ValidateOTP() == 'null') {
          return;
        }
        this.messageService.clear('errMsg');
        this.titleText = 'Business Url'
        this.currentPosition = value;
        break;
      case 'business_info':
        if (this.validateShortName() == 'null') {
          return;
        }
        if(this.disabledShortUrl){
          return;
        } 
        this.titleText = 'Business Info'
        this.currentPosition = value;
        break;
      case 'user_info':
        if (this.validateUserInfo() == 'null') {
          return;
        } else {
          this.titleText = 'Business Admin User Information'
          this.currentPosition = value;
        }

        break;
      case 'otp':
        this.clearOtp()
        // if (this.validateAddServices() == 'null') {
        //   return;
        // }
        if (this.validateUserInfo() == 'null') {
          return;
        } else {
          await this.validateUser().then(async (resolve1: string) => {
            if (resolve1 == 'null') {
              return;
            } else {
              await this.checkDuplicateUser().then((resolve: string) => {
                if (resolve == 'null') {
                  return;
                } else {
                  // this.titleText = 'Add Services'
                  // this.currentPosition = value;

                  this.sendOtp()
                  this.titleText = 'OTP'
                  this.currentPosition = value;
                }
              })
            }
          })
        }
        break;
    }
  }

  validateShortName() {
    this.showProgressBar()
    if (this.shortNameUrl == '') {
      this.showErrorMessage('Business Url Field is empty.')
      this.hideProgressBar();
      return 'null';
    }

    this.hideProgressBar();
    return 'success';
  }

  validateBusinessInfo(): string {
    this.showProgressBar()
    if (this.businessName === '') {
      this.showErrorMessage('Business Name Field is empty.')
      this.hideProgressBar();
      return 'null';
    } if (this.address === '') {
      this.showErrorMessage('Address 1 Field is empty.')
      this.hideProgressBar();
      return 'null';
    } 
    
    // if(this.address2 === '' || this.address2 === 'null'){
    //   this.showErrorMessage('Address 2 Field is empty.')
    //   this.hideProgressBar();
    //   return 'null';
    // }

    // if(this.address2 === '' || this.address2 === 'null'){
    //   this.showErrorMessage('Address 2 Field is empty.')
    //   this.hideProgressBar();
    //   return 'null';
    // }

    if(this.city === '' || this.city === 'null'){
      this.showErrorMessage('City Field is empty.')
      this.hideProgressBar();
      return 'null';
    }

    if(this.business_state === '' || this.business_state === 'null'){
      this.showErrorMessage('State Field is empty.')
      this.hideProgressBar();
      return 'null';
    }

    if(this.zipcode === '' || this.zipcode === 'null' || this.zipcode === undefined){
      this.showErrorMessage('Zip Code Field is empty.')
      this.hideProgressBar();
      return 'null';
    }

    if(this.zipcode.length != 5){
      this.showErrorMessage('Zip Code should be 5 digits.')
      this.hideProgressBar();
      return 'null';
    }
    
    if (this.country === '' || this.country === 'null') {
      this.showErrorMessage('Country Field is empty.')
      this.hideProgressBar();
      return 'null';
    } if (this.phone === '') {
      this.showErrorMessage('Phone Field is empty.')
      this.hideProgressBar();
      return 'null';
    } if (this.phone.length <= 9) {
      this.showErrorMessage('Phone Number should be 10 digits.')
      this.hideProgressBar();
      return 'null';
    }
    if (!CommonFunctions.validateFax(this.fax)) {
      this.showErrorMessage('Fax Number should be minimum 8 digits.')
      this.hideProgressBar();
      return 'null';
    }
    if (this.ein === '') {
      this.showErrorMessage('Ein Field is empty.')
      this.hideProgressBar();
      return 'null';
    }

    if (!CommonFunctions.validateEin(this.ein)) {
      this.showErrorMessage('EIN Number should be minimum 9 digits.')
      this.hideProgressBar();
      return 'null';
    }
    if (this.websiteUrl != '' && !CommonFunctions.validURL(this.websiteUrl)) {
      this.showErrorMessage('website is invalid.')
      this.hideProgressBar();
      return 'null';
    } if (this.timezone === '' || this.timezone === 'null') {
      this.showErrorMessage('Timezone Field is empty.')
      this.hideProgressBar();
      return 'null';
    } if (this.selectedBusinessType === '' || this.selectedBusinessType === 'null') {
      this.showErrorMessage('Business Type Field is empty.')
      this.hideProgressBar();
      return 'null';
    } if (this.agreeValue == false) {
      this.showErrorMessage('Please agree to our Terms and conditions.')
      this.hideProgressBar();
      return 'null';
    }if(CommonFunctions.forbiddenCharsValidator(this.address)){
      this.showErrorMessage('Address please remove special characters and symbols like "&", "@", "#"');
      this.hideProgressBar();
      return 'null';
    }
    this.hideProgressBar();
    return 'success'
  }
  validateUserInfo(): string {
    this.showProgressBar()
    if (this.firstName === '') {
      this.showErrorMessage('First Name Field is empty.')
      this.hideProgressBar();
      return 'null';
    } if (this.lastName === '') {
      this.showErrorMessage('Last Name Field is empty.')
      this.hideProgressBar();
      return 'null';
    } if (this.email === '') {
      this.showErrorMessage('Email Field is empty.')
      this.hideProgressBar();
      return 'null';
    } if (this.mobile === '') {
      this.showErrorMessage('Mobile Field is empty.')
      this.hideProgressBar();
      return 'null';
    } if (this.mobile.length <= 9) {
      this.showErrorMessage('Mobile Number should be 10 digits.')
      this.hideProgressBar();
      return 'null';
    }
    // if (this.profileNewPassword.length === 0) {
    //   this.showErrorMessage('Please enter a new password.')
    //   this.hideProgressBar();
    //   return 'null';
    // } 
    if (this.profileNewPassword !== this.profileConfirmPassword) {
      this.showErrorMessage('Passwords do not match.')
      this.hideProgressBar();
      return 'null';
    } if (this.userCountry === '' || this.userCountry === 'null') {
      this.showErrorMessage('Country Field is empty.')
      this.hideProgressBar();
      return 'null';
    }
    this.hideProgressBar();
    return 'success'
  }

  validateAddServices() {
    this.showProgressBar()
    if (this.serivces[0].serviceName == '') {
      this.showErrorMessage('Add atleast one service.')
      this.hideProgressBar();
      return 'null';
    }
    if (this.serivces.some(obj => obj.serviceName == '')) {
      this.showErrorMessage('Service name should not be empty.')
      this.hideProgressBar();
      return 'null';
    }
    if (this.hasDuplicates(this.serivces)) {
      this.showErrorMessage('Duplicate service are not allowed.')
      this.hideProgressBar();
      return 'null';
    }

    this.hideProgressBar();
    return 'success';
  }

  hasDuplicates(array: any) {
    var valuesSoFar = Object.create(null);
    for (var i = 0; i < array.length; ++i) {
      var value = array[i].serviceName;
      if (value in valuesSoFar) {
        return true;
      }
      valuesSoFar[value] = true;
    }
    return false;
  }

  removeImageLogo() {
    this.imageInputError = true;
    this.croppedImageUrl = '';
    this.businessProfileUrl = 'assets/images/business_2.png'
  }

  fileFailedToLoad(event: any) {
    console.log(event)
    this.imageInputError = true;
    this.businessProfileUrl = 'assets/images/business_2.png'
    this.profileUrl = 'assets/images/default-user.png'
  }

  hideCropModal(displayCropModal: any) {
    if (displayCropModal !== undefined) {
      this.displayCropModal = displayCropModal;
    }

  }


  croppedImage(imageFile: any) {
    if (imageFile !== undefined) {
      this.croppedImageUrl = imageFile?.croppedImageUrl
      if (this.currentPosition == 'business_info') {
        this.businessProfileUrl = imageFile?.base64Value;
      } else {
        this.profileUrl = imageFile?.base64Value;
      }
      this.imageInputError = false
      // this.uploadBusinessLogo()
    }
  }

  addService() {
    if (this.serivces.length < 5) {
      this.serivces.push({
        serviceName: '',
      });
    }
    this.enableDisableAddService()
  }

  removeService(i: number) {
    if (this.serivces.length > 1) {
      this.serivces.splice(i, 1);
    }
    this.enableDisableAddService()
  }

  enableDisableAddService() {
    if (this.serivces.length == 5) {
      this.disabledServiceAdd = true
    } else {
      this.disabledServiceAdd = false
    }
  }

  onOtpChange(event: any) {
    // console.log(event, event.length)
    if (event.length == 6) {
      this.showProgressBar()
      this.ngOtpInput.otpForm.disable();
      const md5 = new Md5();
      let enterOtp = md5.appendStr(event).end();
      if (this.receivedOtp == enterOtp) {
        this.showRegisterLayout = true;
        this.showSuccessMessage('Otp verified successfully')
      } else {
        this.ngOtpInput.otpForm.enable();
        this.showErrorMessage('Invalid OTP. Please check your code and try again.')
      }
      this.hideProgressBar()
    }
  }

  confirmCancelDialog() {
    this.messageService.clear();
    this.confirmationService.confirm({
      message: 'Are you sure you want to abort the registration ?',
      header: 'Cancel Confirmation',
      icon: 'pi pi-times',
      accept: () => {
        window.location.href = 'http://chatstasy.com'
      }
    });
  }

  enableDisableRegister() {
    if (this.agreeValue && this.showRegisterLayout) {
      this.disabledOtp = false;
    } else {
      this.disabledOtp = true;
    }
  }

  toggleDisable() {
    if (this.ngOtpInput.otpForm) {
      if (this.ngOtpInput.otpForm.disabled) {
        this.ngOtpInput.otpForm.enable();
      } else {
        this.ngOtpInput.otpForm.disable();
      }
    }
  }

  redirectingToLoginInFiveSec() {
    this.redirectingToLogin = true
    let interval = setInterval(() => {
      this.countNumber = this.countNumber - 1
    }, 1000);
    setTimeout(() => {
      clearInterval(interval)
      CommonFunctions.setBaseUrl(this.shortNameUrl);
      window.location.href = this.shortNameUrl + '/login';
      // this.router.navigate(['/login'], { replaceUrl: true });
    }, 5000);
  }


  showResendButtonAfterSixtySec() {
    this.showResendLayout = false
    let interval = setInterval(() => {
      this.resendCountNumber = this.resendCountNumber - 1
    }, 1000);
    setTimeout(() => {
      clearInterval(interval)
      this.resendCountNumber = 60;
      this.showResendLayout = true
    }, 60000);
  }

  clearOtp() {
    this.messageService.clear('errMsg');
    this.ngOtpInput.otpForm.enable();
    this.ngOtpInput.setValue('');
    this.agreeValue = false;
    this.showRegisterLayout = false;
  }




  validateDigitOnly(event: any) {
    var inp = String.fromCharCode(event.keyCode);
    if (/[0-9]/.test(inp)) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  }




  alphaOnly(event: any) {
    var inp = String.fromCharCode(event.keyCode);
    // Allow alpahbets
    if (/[a-zA-Z]/.test(inp)) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  };

  keyPressAlphaNumericWithCharacters(event: any) {
    var inp = String.fromCharCode(event.keyCode);
    // Allow numbers, alpahbets
    if (/[a-zA-Z0-9]/.test(inp)) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  }

  showErrorMessage(msg: string) {
    this.messageService.add({
      key: 'errMsg',
      severity: 'error',
      detail: msg,
    });
  }

  showSuccessMessage(msg: string) {
    this.messageService.add({
      key: 'errMsg',
      severity: 'success',
      detail: msg,
    });
  }

  showProgressBar() {
    this.messageService.clear('errMsg');
    this.isLoading = true;
    this.disabled = true;
  }

  hideProgressBar() {
    this.isLoading = false;
    this.disabled = false;
  }
  
  Splitfunction(string:string, nb:any) {
    var array = string.split(' ');
    return array[nb];
  }

  ValidateOTP(){
    this.showProgressBar()
    if(this.showRegisterLayout == false){
      this.showErrorMessage('Invalid OTP. Please check your code and try again.')
      this.hideProgressBar();
      return 'null';
    }else{
      this.hideProgressBar();
      return 'success';
    }
  }

  showHideModalDialog(type: string) {
    switch (type) {
      case 'renew_subscription':
        this.displayRenewModal = !this.displayRenewModal;
        break;
    }
  }

  hideModalRenewDialog(displayRenewModal: any) {
      this.displayRenewModal = displayRenewModal;
  }

  isSuccess(isSuccess: any) {
    if (isSuccess !== undefined) {
        this.registerBusiness();
        this.paymentDetails = isSuccess;
    }
  }

  PaymentshowSuccessMessage(status: string, msg: string) {
    this.messageService.add({
      key: 'br',
      severity: 'success',
      summary: status,
      detail: msg,
    });
  }

}
