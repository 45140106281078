import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { timeout } from 'rxjs/operators';
import { CommonFunctions } from 'src/app/Utilities/CommonFunctions';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class RoleService {
  constructor(private router: Router, private http: HttpClient) { }

  listPrivilegeGroups() {
    const url = environment.API_URL + '/api/privilegeGroups';
    const TOKEN = CommonFunctions.getToken();
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + TOKEN,
      }),
    };
    return this.http.get(url, httpOptions).pipe(timeout(60000));
  }

  listRoles() {
    const url = environment.API_URL + '/api/role';
    const TOKEN = CommonFunctions.getToken();
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + TOKEN,
        'BusinessId': CommonFunctions.getBusinessId().toString()
      }),
    };
    return this.http.get(url, httpOptions).pipe(timeout(60000));
  }

  addRole(role: any) {
    console.log('Add Role Service Data ---> ' + JSON.stringify(role));
    const url = environment.API_URL + '/api/role/add';
    const TOKEN = CommonFunctions.getToken();
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + TOKEN,
        'Content-Type': 'application/json',
        'BusinessId': CommonFunctions.getBusinessId().toString()
      }),
    };
    return this.http.post(url, role, httpOptions).pipe(timeout(60000));
  }

  editRole(role: any, id: any) {
    console.log('Edit Role Service Data ---> ' + JSON.stringify(role));
    const url = environment.API_URL + '/api/role/' + id + '/edit';
    const TOKEN = CommonFunctions.getToken();
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + TOKEN,
        'Content-Type': 'application/json',
        'BusinessId': CommonFunctions.getBusinessId().toString()
      }),
    };
    return this.http.post(url, role, httpOptions).pipe(timeout(60000));
  }

  getRole(id: any) {
    const TOKEN = CommonFunctions.getToken();
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + TOKEN,
        'Content-Type': 'application/json',
        'BusinessId': CommonFunctions.getBusinessId().toString()
      }),
    };
    const url = environment.API_URL + '/api/role/' + id;
    return this.http.get(url, httpOptions).pipe(timeout(60000));
  }

  removeRole(id: any) {
    console.log('Remove Id ---> ' + id);
    const TOKEN = CommonFunctions.getToken();
    console.log(TOKEN);
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + TOKEN,
        'Content-Type': 'application/json',
        'BusinessId': CommonFunctions.getBusinessId().toString()
      }),
    };
    const url = environment.API_URL + '/api/role/' + id + '/remove';
    return this.http.post(url, null, httpOptions).pipe(timeout(60000));
  }
}
