<div [ngClass]="state=='open' ? 'main_content_open' : 'main_content'">
    <p-scrollPanel [style]="{width: '100%',height: '95vh','margin-top':'7px','padding-bottom':'70px'}" #sc>
        <app-message-credit-warning></app-message-credit-warning>
        <app-twillio-message></app-twillio-message>
        <div class="p-d-flex p-jc-between p-ai-center p-pt-3 p-pb-3">
            <div class="page_title">
                Manage Services
            </div>
            <div>
                <p-breadcrumb [model]="items" [home]="home"></p-breadcrumb>
            </div>
        </div>
        <div class="p-mb-5">
            <p-card>
                <div class="toolbar">
                    <div class="p-grid p-ai-center">
                        <div class="p-col p-md-12 p-lg-12 p-jc-end d-flex d-lg-flex d-xl-flex">
                            <button pButton pRipple type="button" label="Add New Service" class="p-button-raised"
                                icon="pi pi-plus" style="font-size: 12px;"
                                (click)="showHideModalDialog('add_service')"></button>
                        </div>
                    </div>
                </div>
            </p-card>
            <p-card styleClass="p-mt-3">
                <div class="row form-group">
                    <div class="col-sm-12">
                        <p-table [columns]="cols" [value]="serviceTypeList"
                            styleClass="p-datatable-sm p-datatable-gridlines p-datatable-striped " [autoLayout]='true'
                            [responsive]='true' [rowHover]="true">

                            <ng-template pTemplate="header" let-columns>
                                <tr style="font-size: small;">
                                    <th *ngFor="let col of columns" [style.width]="getColumnWidth(col.field)">
                                        {{col.header}}
                                    </th>
                                    <th style="width: 145px;">
                                        Service Image/Video
                                    </th>
                                    <th  style="width: auto;">
                                        Action
                                    </th>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-service let-columns="columns">
                                <tr style="font-size: smaller;">
                                    <td *ngFor="let col of columns">
                                        <span class="p-column-title">{{col.header}}</span>
                                        {{service[col.field]}}
                                    </td>

                                    <td style="text-align: center;">
                                        <a href="javascript:void(0);" data-toggle="modal" (click)="getServiceType(service, 'show_image_files')" [ngClass]="service.serviceImage.length =='0' ? 'disabledClass': '' ">
                                            <i class="pi pi-image" style="font-size: 0.8rem;" [ngStyle]="service.serviceImage.length =='0' ? {'color': '#dddddd'} : {'color': '#007AFF'}"></i>
                                        </a>
                                        &nbsp;
                                        <!-- <a href="javascript:void(0);" data-toggle="modal" (click)="getServiceType(service, 'show_video_files')" [ngClass]="service.serviceVideo.length =='0' ? 'disabledClass': '' ">
                                            <i class="pi pi-video" style="font-size: 0.8rem;" [ngStyle]="service.serviceVideo.length =='0' ? {'color': '#dddddd'} : {'color': '#007AFF'}"></i>
                                        </a> -->
                                        <a (click)="goCNN(service.serviceVideoUrl)" [ngClass]="service.serviceVideoUrl == null ? 'disabledClass': '' ">
                                            <i class="pi pi-video" style="font-size: 0.8rem;" [ngStyle]="service.serviceVideoUrl == null ? {'color': '#dddddd'} : {'color': '#007AFF'}"></i>
                                        </a>
                                        &nbsp;
                                        <a (click)="goCNN(service.serviceUrl)" [ngClass]="service.serviceUrl == null ? 'disabledClass': '' ">
                                            <i class="pi pi-link" style="font-size: 0.8rem;" [ngStyle]="service.serviceUrl == null ? {'color': '#dddddd'} : {'color': '#007AFF'}"></i>
                                        </a>
                                    </td>

                                    <td class="last_border">
                                        <span class="p-column-title">Action</span>
                                        <a href="javascript:void(0);" data-toggle="modal"
                                            (click)="getServiceType(service, 'edit_service')">
                                            <i class="pi pi-pencil" style="font-size: 0.8rem;color:#007AFF"></i>
                                        </a>
                                        <!-- <ng-template #canEdit style="display:inline-block;">
                                            <div pTooltip="You cannot edit this service" tooltipPosition="left"
                                                style="display:inline-block;">
                                                <a href="javascript:void(0);" data-toggle="modal" class="disabledClass">
                                                    <i class="pi pi-pencil" style="font-size: 0.8rem"></i>
                                                </a>
                                            </div>
                                        </ng-template> -->
                                        &nbsp;
                                        <a *ngIf="service.canDelete; else canDelete" href="javascript:void(0);"
                                            data-toggle="modal" (click)="confirmDeleteDialog(service)">
                                            <i class="pi pi-trash" style="font-size: 0.8rem;color: #dc3545;"></i>
                                        </a>
                                        <ng-template #canDelete style="display:inline-block;">
                                            <div pTooltip="You cannot delete this service" tooltipPosition="left"
                                                style="display:inline-block;">
                                                <a href="javascript:void(0);" data-toggle="modal" class="disabledClass">
                                                    <i class="pi pi-trash" style="font-size: 0.8rem"></i>
                                                </a>
                                            </div>
                                        </ng-template>
                                    </td>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="emptymessage" let-columns>
                                <tr>
                                    <td [attr.colspan]="columns.length+2">
                                        <div *ngIf="isLoading" class="alignCenter">
                                            <p-progressSpinner [style]="{ width: '40px', height: '40px' }"
                                                strokeWidth="3">
                                            </p-progressSpinner>
                                        </div>
                                        <p-messages key="errMsg" *ngIf="serviceTypeList?.length == 0 && !isLoading"
                                            class="alignCenter">
                                            <ng-template pTemplate>
                                                <i class="pi pi-copy" style="font-size: 2rem"></i>
                                                <div class="p-ml-2">NO DATA FOUND</div>
                                            </ng-template>
                                        </p-messages>
                                    </td>
                                </tr>
                            </ng-template>
                        </p-table>
                        <p-paginator #p [rows]="pageSize" [totalRecords]="totalCount" (onPageChange)="paginate($event)">
                        </p-paginator>
                    </div>
                </div>
            </p-card>
        </div>
    </p-scrollPanel>
</div>



<!-- ADD/EDIT MODAL -->
<p-dialog #addDialog [header]="headerName" [(visible)]="displayAddEditModal" [modal]="true" [baseZIndex]="10000" [draggable]="false" [resizable]="true" [maximizable]="true" styleClass="p-fluid" (onHide)="clearFormData()" [style]="{width: '70vw'}">
    <p-messages key="errMsg" [style]="{
      'font-size': '12px'
    }"></p-messages>
    <!-- <form style="min-width:60vw;" role="form" #addServiceForm="ngForm">
        <div class="row">
            <div class="form-group col-md-6">
                <label>Service Name<span class="symbol required"></span></label>
                <input type="text" class="form-control" name="serviceName" placeholder="Enter Service Name"
                    [(ngModel)]="serviceName" required />
            </div>
            <div class="form-group col-md-6">
                <label>Service Duration(HH:mm)<span class="symbol required"></span></label>
                <p-inputMask [(ngModel)]="serviceDuration" name="serviceDuration" styleClass="form-control"
                    placeholder=" Enter Service Duration" mask="99:99" [autoClear]="false">
                </p-inputMask>
            </div>
        </div>
        <div class="row">
            <div class="form-group col-md-4">
                <label>Special Instructions</label>
                <input type="text" class="form-control" name="specialInstructions"
                    placeholder="Enter Special Instructions" [(ngModel)]="specialInstructions" required />
            </div>
            <div class="form-group col-md-4">
                <label>Reminder Day<span class="symbol required"></span></label>
                <p-dropdown name="reminderDay" [options]="reminderDayList" [(ngModel)]="reminderDay" optionLabel="name"
                    optionValue="value" (onChange)="onFilterValueChange($event)" [panelStyle]="{'font-size':'14px'}"
                    placeholder="Select Reminder Day" appendTo="body"
                    [style]="{'height': '34px', 'font-size': '14px','width':'100%'}" [baseZIndex]="10001">
                </p-dropdown>
            </div>
            
        </div>
        <div #container class="row" style="position:relative">
            <div class="p-d-flex p-flex-column col-md-12">
                <label>Auto Schedule</label>
                <div class="p-d-flex p-ai-center">
                    <p-inputSwitch [(ngModel)]="autoSchedule" name="autoSchedule"
                        (onChange)="onToggleAutoSchedule($event)">
                    </p-inputSwitch>
                    <div class="p-ml-2" style="font-size: 12px;color:#858585">{{tooltipMsg}}</div>
                </div>

            </div>

        </div> -->
        <!-- <div class="row">
            <div class="p-d-flex p-flex-column col-md-2">
                <label>Recurring</label>
                <p-inputSwitch [(ngModel)]="recurring" name="recurring"></p-inputSwitch>
            </div>
            <div *ngIf="recurring" class="col-md-5">
                <label>Recurrent Type</label>
                <select [(ngModel)]="recurrent" name="recurrent" class="form-control" style="height: 34px;"
                    [ngClass]="!recurring? 'disabledSelect':''" [disabled]="!recurring">
                    <option [value]="null" disabled>Select Recurrent Type</option>
                    <option *ngFor="let recurr of recurrentType" [value]="recurr.value">
                        {{ recurr.name }}
                    </option>
                </select>
            </div>
            <div *ngIf="recurring" class="col-md-5">
                <label>Number Of Recurrence</label>
                <input type="number" class="form-control" name="noOfRecurrence"
                    placeholder=" Enter Number Of Recurrence" [(ngModel)]="noOfRecurrence" required
                    [readonly]="!recurring" />
            </div>
        </div> -->
    <!-- </form> -->

    <form style="min-width:60vw;" role="form" #addServiceForm="ngForm">
        <div class="row">            
            <div class="form-group" style="width: 50%; float: right;padding: 10px;">
                <label>Service Name<span class="symbol required"></span></label>
                <input type="text" class="form-control" name="serviceName" placeholder="Enter Service Name"
                    [(ngModel)]="serviceName" required autocomplete="off"/>
            </div>

            <div class="form-group" style="width: 50%; float: right;padding: 10px;">
                <label>Service Location</label>
                <input type="text" class="form-control" name="location" placeholder="Enter Service Location" [(ngModel)]="location" required autocomplete="off"/>
            </div>

            <div class="form-group" style="width: 50%; float: right;padding: 10px;">
                <label>Service Description<span class="symbol required"></span></label>
                <textarea pInputTextarea type="text" name="serviceDescription" class="form-control" placeholder="Enter Service Description" [(ngModel)]="serviceDescription">
                </textarea>
            </div>

            <div class="form-group" style="width: 50%; float: right;padding: 10px;">
                <label>Service Special Instructions</label>
                <textarea pInputTextarea type="text" name="specialInstructions" class="form-control" placeholder="Enter Service Special Instructions" [(ngModel)]="specialInstructions">
                </textarea>
            </div>
        </div>

        <div class="row">
            <div class="form-group p-field p-grid" style="width: 25%; float: right;padding: 10px;">
                <label for="Service Cost">Service Cost</label>
                <div style="width: 60%; padding-left: 5px;">
                    <p-inputNumber [(ngModel)]="serviceCost" inputId="currency-us" mode="currency" currency="USD" locale="en-US" [minFractionDigits]="2" [ngModelOptions]="{standalone: true}" [maxlength]="9">
                    </p-inputNumber>
                </div>
            </div>

            <div class="form-group p-field p-grid" style="width: 40%; float: right;padding: 10px;">
                <label style="width: 30%;">Service Duration</label>
                <div style=" width: 70%; padding-left: 5px;">
                    <span style=" float: left; margin: 8px; ">HH:</span>
                    <select [(ngModel)]="serviceHours" name="serviceHours" class="form-control"  placeholder="Select a Hours" required style="width: 30%; float: left;">
                        <option *ngFor="let item of [].constructor(9); let i = index" value="{{ i | number :'2.0-0'}}">{{ i | number :'2.0-0'}}</option>
                    </select>
                    <span style=" float: left; margin: 8px; ">MM:</span>
                    <select [(ngModel)]="serviceMints" name="serviceMints" class="form-control"  placeholder="Select a Hours" required style="width: 30%;">
                        <option *ngFor="let item of [].constructor(60); let i = index" value="{{ i | number :'2.0-0'}}"> {{ i | number :'2.0-0'}}</option>
                    </select>
                </div>
            </div>

            <div class="form-group p-field p-grid" style="width: 30%; float: right;padding: 10px;">
                <label style="width: 45%;">Service Category<span class="symbol required"></span></label>
                <div style="width: 55%; padding-left: 5px;">
                    <select [(ngModel)]="serviceCategory" name="serviceCategory" class="form-control"  placeholder="Select a Category" style="height: 34px;" required (change)="onChangeServiceCategory($event)">
                        <option selected value="Recurring Daily">Recurring Daily</option>
                        <option selected value="Recurring Weekly">Recurring Weekly</option>
                        <option selected value="Recurring Bi-Weekly">Recurring Bi-Weekly</option>
                        <option selected value="Recurring Monthly">Recurring Monthly</option>
                        <option selected value="Recurring Custom">Recurring Custom</option>
                        <option value="Non-Recurring">Non-Recurring</option>
                    </select>
                </div>
            </div>
        </div>
        
        <div class="row">
            <div class="form-group p-field p-grid" style="width: 25%; float: right;padding: 10px;">
                <label>Service Start Time<span class="symbol required"></span></label>
                <div style=" width: 40%; padding-left: 5px;">
                    <select [(ngModel)]="serviceTime" name="serviceTime" class="form-control"  placeholder="Service Start Time" style="height: 34px;" required>
                        <option selected value="true">Default</option>
                        <option value="false">Custom</option>
                    </select>
                </div>
            </div>

            <div class="form-group p-field p-grid" style="width: 35%; float: right;padding: 10px;">
                <label [ngClass]="serviceTime == 'true' ? 'disabledbutton': '' ">Custom Service Start Time<span class="symbol required"></span></label> 
                <div [ngClass]="serviceTime == 'true' ? 'disabledbutton': '' " style=" width: 40%; padding-left: 5px;" >
                    <p-calendar [(ngModel)]="customStartTime" [timeOnly]="true" inputId="timeonly" name="startTime" hourFormat="12" [readonlyInput]="true" inputId="multiple" #startTime [showIcon]="true"></p-calendar>
                </div>
            </div>

            <div class="form-group p-field p-grid" style="width: 40%; float: right;padding: 10px;">
                <label *ngIf="serviceCategory == 'Recurring Daily'" [ngClass]="serviceCategory == 'Recurring Daily' ?'disabledbutton':'' " style=" width: 55%; padding-left: 10px; ">{{serviceCategory}} <span class="symbol required"></span></label>      
                <label *ngIf="serviceCategory != 'Recurring Daily'" style=" width: 55%; padding-left: 10px; ">{{serviceCategory}} <span class="symbol required"></span></label>         
                <div style="width: 45%; padding-left: 5px;">                    
                    <div [ngClass]="serviceCategory == 'Recurring Daily' ?'disabledbutton':'' " *ngIf="serviceCategory == 'Recurring Daily'">
                        <p-multiSelect></p-multiSelect>
                    </div>                
                    
                    <div *ngIf="serviceCategory == 'Recurring Weekly' || serviceCategory == 'Recurring Bi-Weekly'">
                        <p-multiSelect [options]="weekList" [ngModelOptions]="{standalone: true}" [(ngModel)]="selectedWeek" optionLabel="weekName" placeholder="Select {{serviceCategory}}" [autoZIndex]="true"></p-multiSelect>
                    </div>
    
                    <div [ngClass]="serviceCategory == 'Recurring Monthly'?'show':'hide'">
                        <p-calendar name="calendarMonthly" [touchUI]="true" selectionMode="multiple" #calendarMonthly [showIcon]="true" (onSelect)="validateRecurringMonth()" [(ngModel)]="selectedMonth" [readonlyInput]="true" inputId="touchui" [minDate]="minDate" placeholder="Enter {{serviceCategory}}">
                            <p-footer>
                                <button pButton type="button" label="Close" 
                                   (click)="calendarMonthlyClose()">
                                </button>
                            </p-footer>
                        </p-calendar>
                    </div>
                    
                    <div [ngClass]="serviceCategory == 'Recurring Custom'?'show':'hide'">
                        <p-calendar name="calendarCustom" [touchUI]="true" selectionMode="multiple" [readonlyInput]="true" inputId="multiple" #calendarCustom [showIcon]="true" [minDate]="minDate" placeholder="Enter {{serviceCategory}}" [(ngModel)]="selectedMonth">
                            <p-footer>
                                <button pButton type="button" label="Close" 
                                   (click)="calendarCustom.hideOverlay()">
                                </button>
                            </p-footer>
                        </p-calendar>
                    </div>
    
                    <div [ngClass]="serviceCategory == 'Non-Recurring'?'show':'hide'">
                        <p-calendar name="calendarNonRecurring" [touchUI]="true" #calendarNonRecurring [showIcon]="true" [(ngModel)]="selectedDate" [readonlyInput]="true" inputId="touchui" [minDate]="minDate" placeholder="Enter {{serviceCategory}}">
                            <p-footer>
                                <button pButton type="button" label="Close" 
                                   (click)="calendarNonRecurring.hideOverlay()">
                                </button>
                            </p-footer>
                        </p-calendar>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="form-group p-field p-grid" style="width: 35%; float: right;padding: 10px;">
                <label>Number of Appointments per Day<span class="symbol required"></span></label>
                <div style="padding-left: 5px;">
                    <select [(ngModel)]="appointmentsPerDay" name="appointmentsPerDay" class="form-control"  placeholder="Number of Appointments per Day" style="height: 34px;" required>
                        <option selected value="true">As Many</option>
                        <option value="false">Custom</option>
                    </select>
                </div>
            </div>

            <div class="form-group p-field p-grid" style="width: 35%; float: right;padding: 10px;" [ngClass]="appointmentsPerDay == 'true' ? 'disabledbutton': '' ">
                <label>Custom Appointments per Day<span class="symbol required"></span></label>
                <div style="padding-left: 5px;">
                    <p-dropdown [options]="options" [(ngModel)]="customAppointmentPerDay" name="customAppointmentPerDay"></p-dropdown>
                </div>
            </div>

            <div class="form-group p-field p-grid" style="width: 30%; float: right;padding: 10px;" [ngClass]="concurrency == 'true' ?'':'disabledbutton' ">
                <div *ngIf="serviceCategory == 'Recurring Monthly' || serviceCategory == 'Recurring Custom'">
                    <div style=" padding-left: 29px; ">
                        <span><strong>Dates</strong></span>
                        <span style=" padding-left: 164px; "><strong>Concurrent Seats</strong></span>
                    </div>
                    <div class="col-md-6">
                        <div *ngFor="let selectedMonths of selectedMonth" style=" padding: 8px; ">
                            <div>
                                {{ selectedMonths | date: 'MM/dd/yyyy' }}
                            </div>
                        </div>
                    </div>
    
                    <div class="col-md-6">
                        <div *ngFor="let selectedMonths of selectedMonth; let i = index" style="padding-bottom: 2px;">
                            <select name="concurrencyArray{{[i]}}" class="form-control" style="height: 34px;"
                                [ngClass]="!recurring? 'disabledSelect':''" [(ngModel)]="groupConcurrencyArray[i]">
                                <option *ngFor="let counts of groupConcurrencyCountArray" [value]="counts">
                                    {{ counts }}
                                </option>
                            </select>
                        </div>
                    </div>
                </div>

                <div *ngIf="serviceCategory == 'Recurring Weekly' || serviceCategory == 'Recurring Bi-Weekly'">
                    <div style=" padding-left: 29px; ">
                        <span><strong>Weekday</strong></span>
                        <span style=" padding-left: 141px; "><strong>Concurrent Seats</strong></span>
                    </div>
                    <div class="col-md-6">
                        <div *ngFor="let weeks of selectedWeek" style=" padding: 8px; ">
                            <div>
                                {{ weeks.weekName }}
                            </div>
                        </div>
                    </div>
    
                    <div class="col-md-6">
                        <div *ngFor="let weeks of selectedWeek; let i = index" style="padding-bottom: 2px;">
                            <select name="concurrencyArray{{[i]}}" class="form-control" style="height: 34px;"
                                [ngClass]="!recurring? 'disabledSelect':''" [(ngModel)]="groupConcurrencyArray[i]">
                                <option *ngFor="let counts of groupConcurrencyCountArray" [value]="counts">
                                    {{ counts }}
                                </option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row" style="width: 60%;">            
            <div class="form-group p-field p-grid" style="width: 50%; float: right;padding: 10px;">
                <label style=" padding-left: 10px; ">Service Type<span class="symbol required"></span></label>
                <div style=" padding-left: 10px;">
                    <select [(ngModel)]="serviceType" name="serviceType" class="form-control"  placeholder="Select a category" style="height: 34px;" required>
                        <option selected value="One-On-One">One-On-One</option>
                        <option value="One-To-Many">One-To-Many</option>
                    </select>                
                </div>
            </div>

            <div class="form-group p-field p-grid" style="width: 50%; float: right; padding: 10px;">
                <label>Max participants<span class="symbol required"></span></label>
                <div style="padding-left: 10px;" [ngClass]="serviceType != 'One-To-Many' ?'disabledbutton':'' ">
                    <p-dropdown [options]="getRangeValue()" [(ngModel)]="serviceRangeValue" name="serviceRangeValue"></p-dropdown>

                </div>
            </div>            
        </div>

        <div class="row" style="width: 60%;"> 
            <div class="form-group p-field p-grid" style="width: 50%; float: right; padding: 10px;">    
                <label style=" width: 40%; padding-left: 10px; ">Concurrency<span class="symbol required"></span></label>
                <div class="p-field-radiobutton" style=" width: 25%; float: left; margin-top: 7px; padding-left: 10px;">
                    <p-radioButton name="concurrency" value="true" [(ngModel)]="concurrency" inputId="true" (onClick)="setConcurrency()"></p-radioButton>
                    <label for="true">Yes</label>
                </div>
                <div class="p-field-radiobutton" style=" width: 25%; margin-top: 7px; padding-left: 10px;">
                    <p-radioButton name="concurrency" value="false" [(ngModel)]="concurrency" inputId="false" (onClick)="setConcurrency()"></p-radioButton>
                    <label for="false">No</label>
                </div>
            </div>

            <div class="form-group p-field p-grid" style="width: 50%; float: right; padding: 10px;">    
                <label>Concurrency Seats<span class="symbol required"></span></label>
                <div style=" width: 30%; float: left; margin-top: 7px; padding-left: 10px;">
                <select name="concurrencyCountArray" class="form-control" style="height: 34px;"
                [ngClass]="concurrency == 'false' ?'disabledbutton':'' " [(ngModel)]="concurrencySelectedNumber" (change)="concurrencyOnChange($event)">
                    <option *ngFor="let concurrencyCounts of concurrencyCountArray.slice(1)" [value]="concurrencyCounts">
                        {{ concurrencyCounts }}
                    </option>
                </select>
                </div>            
            </div>

            <div class="form-group col-md-12">
                <!-- <div class="form-group col-md-3">
                    <div style="padding-left: 25px; padding-right: 25px;" [ngClass]="serviceType != 'One-To-Many' ?'disabledbutton':'' ">
                        <h5 style=" font-weight: 600; ">Range: {{serviceRangeValue[0] + ' - ' + serviceRangeValue[1]}}</h5>
                        <p-slider [ngModelOptions]="{standalone: true}" [(ngModel)]="serviceRangeValue" [range]="true" [min]="2" [max]="100"></p-slider>
                    </div>
                </div> -->
                

                <div class="form-group col-md-6">
                    <!-- <div class="col-md-6">
    
                        <label>Concurrency<span class="symbol required"></span></label>
                        <br>
                        <div class="p-field-radiobutton" style=" width: 30%; float: left; margin-top: 7px;">
                            <p-radioButton name="concurrency" value="true" [(ngModel)]="concurrency" inputId="true" (onClick)="setConcurrency()"></p-radioButton>
                            <label for="true">Yes</label>
                        </div>
                        <div class="p-field-radiobutton" style=" width: 30%; margin-top: 7px;">
                            <p-radioButton name="concurrency" value="false" [(ngModel)]="concurrency" inputId="false" (onClick)="setConcurrency()"></p-radioButton>
                            <label for="false">No</label>
                        </div>
                    </div> -->
    
                    <!-- <div class="col-md-6">
    
                        <label>Concurrency Seats<span class="symbol required"></span></label>
                        <br>
                        <select name="concurrencyCountArray" class="form-control" style="height: 34px;"
                        [ngClass]="concurrency == 'false' ?'disabledbutton':'' " [(ngModel)]="concurrencySelectedNumber" (change)="concurrencyOnChange($event)">
                            <option *ngFor="let concurrencyCounts of concurrencyCountArray.slice(1)" [value]="concurrencyCounts">
                                {{ concurrencyCounts }}
                            </option>
                        </select>                
                    </div> -->
                </div>                
            </div>
            
        </div>
        <div class="row"> 
            <div class="form-group p-field p-grid" style="width: 38%;float: right;padding: 10px;">
                <label>Service Appointment Reminder<span class="symbol required"></span></label>
                <div class="p-field-radiobutton" style=" padding-left: 10px; float: left; margin-top: 7px;">
                    <p-radioButton name="serviceAppointmentReminder" value="true" [(ngModel)]="serviceAppointmentReminder" inputId="true"></p-radioButton>
                    <label for="true">Yes</label>
                </div>
                <div class="p-field-radiobutton" style=" padding-left: 10px; margin-top: 7px;">
                    <p-radioButton name="serviceAppointmentReminder" value="false" [(ngModel)]="serviceAppointmentReminder" inputId="false"></p-radioButton>
                    <label for="false">No</label>
                </div>
            </div>

            <div class="form-group p-field p-grid" style="width: 22%;float: right;padding: 10px;">
                <label style="height: 34px;"
                [ngClass]="serviceAppointmentReminder == 'false' ?'disabledbutton':'' " >No. Of days before<span class="symbol required"></span></label>
                <div style=" padding-left: 10px; ">
                    <select name="reminderDays" class="form-control" style="height: 34px;"
                    [ngClass]="serviceAppointmentReminder == 'false' ?'disabledbutton':'' " [(ngModel)]="reminderDays">
                        <option *ngFor="let days of appointmentReminderDaysNumber" [value]="days">
                            {{ days }}
                        </option>
                    </select> 
                </div>
            </div>
        </div>
        <div class="row">

            <div class="form-group p-field p-grid" style="width: 30%;float: right;padding: 10px;">
                <label>Service Activation<span class="symbol required"></span></label>
                <div style=" padding-left: 10px; ">
                    <select name="serviceActivation" class="form-control" [(ngModel)]="serviceActivation">
                        <option value="immediate">Immediate</option>
                        <option value="custom">custom</option>
                    </select> 
                </div>
            </div>

            <div class="form-group p-field p-grid" style="width: 50%;float: right;padding: 10px;" [ngClass]="serviceActivation == 'immediate' ?'disabledbutton':'' ">
                <label>Activation Date<span class="symbol required"> &nbsp;</span></label>
                <p-calendar name="serviceDate" [touchUI]="true" #serviceDate [showIcon]="true" [(ngModel)]="serviceStartDate" [readonlyInput]="true" inputId="touchui" [minDate]="minDate" placeholder="Enter Start Date">
                    <p-footer>
                        <button pButton type="button" label="Close" 
                           (click)="serviceDate.hideOverlay()">
                        </button>
                    </p-footer>
                </p-calendar>
            </div>
        </div>

        <div class="row">
            <div class="form-group p-field p-grid" style="padding: 10px;">
                <label>Appointment Confirmation<span class="symbol required"></span></label>                
                <div class="p-field-radiobutton" style="padding-left: 10px;">
                    <p-radioButton name="serviceClinetConfirmation" value="true" [(ngModel)]="serviceClinetConfirmation" inputId="true"></p-radioButton>
                    <label for="true">Yes</label>
                </div>
                <div class="p-field-radiobutton" style=" padding-left: 10px;">
                    <p-radioButton name="serviceClinetConfirmation" value="false" [(ngModel)]="serviceClinetConfirmation" inputId="false"></p-radioButton>
                    <label for="false">No</label>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="form-group p-field p-grid" style="padding: 10px;">
                <label>Service Appointment Scheduling<span class="symbol required"></span></label>
                <br>
                <div class="p-field-radiobutton" style="padding-left: 10px;">
                    <p-radioButton name="serviceAppointmentScheduling" value="By Client" [(ngModel)]="serviceAppointmentScheduling" inputId="By Client"></p-radioButton>
                    <label for="By Client">By Client</label>
                </div>
                <div class="p-field-radiobutton" style="padding-left: 10px;">
                    <p-radioButton name="serviceAppointmentScheduling" value="By Admin" [(ngModel)]="serviceAppointmentScheduling" inputId="By  Admin"></p-radioButton>
                    <label for="By Admin">By Admin</label>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="form-group p-field p-grid" style="padding: 10px;">
                <label>Client Appointment Rescheduling<span class="symbol required"></span></label>
                <div class="p-field-radiobutton" style="padding-left: 10px;">
                    <p-radioButton name="clientAppointmentRescheduling" value="true" [(ngModel)]="clientAppointmentRescheduling" inputId="true"></p-radioButton>
                    <label for="true">Yes</label>
                </div>
                <div class="p-field-radiobutton" style="padding-left: 10px;">
                    <p-radioButton name="clientAppointmentRescheduling" value="false" [(ngModel)]="clientAppointmentRescheduling" inputId="By  Admin"></p-radioButton>
                    <label for="false">No</label>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="form-group p-field p-grid" style="width: 30%; padding: 10px;">
                <label>Pre-Payment<span class="symbol required"></span></label>
                <div class="p-field-radiobutton" style="padding-left: 10px; margin-top: 7px;">
                    <p-radioButton name="prePayment" value="true" [(ngModel)]="prePayment" inputId="true"></p-radioButton>
                    <label for="true">Yes</label>
                </div>
                <div class="p-field-radiobutton" style="padding-left: 10px; margin-top: 7px;">
                    <p-radioButton name="prePayment" value="false" [(ngModel)]="prePayment" inputId="false"></p-radioButton>
                    <label for="false">No</label>
                </div>
            </div>
            <div class="form-group p-field p-grid" style="width: 30%;padding: 10px;">
                <div [ngClass]="prePayment == 'false' ?'disabledbutton':'' " style="width: 100%; padding-top: 10px;">
                    <div class="p-field-radiobutton" style=" width: 25%; float: left; margin-top: 1px;">
                        <p-radioButton name="prePaymentType" value="URL" [(ngModel)]="prePaymentType" inputId="URL"></p-radioButton>
                        <label for="true">URL&nbsp;</label>
                    </div>
                    <div class="p-field-radiobutton" style="width: 75%; padding-left: 10px; margin-top: 1px;">
                        <p-radioButton name="prePaymentType" value="Action Button" [(ngModel)]="prePaymentType" inputId="Action Button"></p-radioButton>
                        <label for="false">Action Button</label>
                    </div>
                </div>
            </div>
            <div class="form-group p-field p-grid" style="width: 40%; padding: 10px;">                  
                <label *ngIf="prePayment == 'true' && prePaymentType == 'URL'">Payment URL<span class="symbol required"></span></label>
                <label *ngIf="prePayment == 'true' && prePaymentType == 'Action Button'">Select Action Button.<span class="symbol required"></span></label>
                <div>
                    <input type="text" *ngIf="prePayment == 'true' && prePaymentType == 'URL'" class="form-control" name="prePaymentUrl" placeholder="Enter URL" [(ngModel)]="prePaymentUrl" autocomplete="off"/>
                    <p-dropdown *ngIf="prePayment == 'true' && prePaymentType == 'Action Button'"  [options]="arrayActionButton" [(ngModel)]="selectedActionButton" optionLabel="name" name="actionButton" placeholder="Select Action Button"></p-dropdown>
                </div>
            </div>

            <div class="form-group col-md-6">
                <label>URL</label>
                <input type="text" class="form-control" name="serviceUrl" placeholder="Enter URL"
                    [(ngModel)]="serviceUrl" autocomplete="off"/>
            </div>

            <div class="form-group col-md-6">
                <label>Service Video URL</label>
                <input type="text" class="form-control" name="serviceVideoUrl" placeholder="Enter Service URL"
                    [(ngModel)]="serviceVideoUrl" autocomplete="off"/>
            </div>

            <div class="form-group col-md-12">
                <label>Service Image</label>

                <div class="form-group col-md-12">
                    <div class="col-md-12" style="background-color: #F8F9FA;height: 80px;border: 1px solid #DEE2E6;border-radius: 2px;">
                        <span class="btn btn-primary btn-file" icon="pi pi-plus" style=" margin: 20px; ">
                            <i class="pi pi-plus" style="margin-right: 0.5rem;"></i>Choose<input type="file" accept="image/*" (change)="serviceFileChangeEvent($event, 'image')" multiple>
                        </span>
                    </div>
                    <div style="overflow: auto;width: 100%;height: 200px;border: 1px solid #DEE2E6">                    
                        <div *ngFor="let image of serviceImage; let index = index;" class="col-md-12" style="padding: 10px;">
                            <div class="col-md-3 center">
                                <img [src]="image.objectURL" alt="image.name" width="50" height="50" class="lazyload">
                            </div>
                            <div class="col-md-3 center" style=" word-wrap: break-word; ">
                                {{image.name}}
                            </div>
                            <div class="col-md-3 center">
                                {{niceBytes(image.size)}}
                            </div>
                            <div class="col-md-3 center">
                                <span class="closes" title="Delete" (click)="removeSelectFile(image.name,'image')" style="cursor: pointer;"><i class="pi pi-trash" style="font-size: 0.8rem;color: #dc3545;"></i></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- <div class="form-group col-md-12">
                <div class="form-group col-md-12" *ngIf="canDelete; else canChangeProvider" >
                    <label for="binary" class="p-ml-3 p-mb-0">Enable default service provider:  &nbsp; </label>
                    <p-checkbox name="defaultServiceProvider" [binary]="true" inputId="binary" [(ngModel)]="defaultServiceProvider"></p-checkbox>
                </div>

                <ng-template #canChangeProvider style="display:inline-block;">
                    <div pTooltip="You cannot delete this service" tooltipPosition="left"
                        style="display:inline-block;">
                        <label for="binary" class="p-ml-3 p-mb-0" class="disabledClass">Enable default service provider:  &nbsp; </label>
                        <p-checkbox name="defaultServiceProvider" class="disabledClass" [binary]="true" inputId="binary" [(ngModel)]="defaultServiceProvider"></p-checkbox>
                    </div>
                </ng-template>
            </div>
 -->
        </div>

        <div class="row" [ngClass]="currentPosition == 'appointment_details'?'show':'hide'">
            <div class="form-group col-md-12" style=" padding: 0px; ">
                <!-- <div class="col-md-6">
                    <label>Service Appointment Reminder<span class="symbol required"></span></label>
                    <br>
                    <div class="p-field-radiobutton" style=" width: 20%; float: left; margin-top: 7px;">
                        <p-radioButton name="serviceAppointmentReminder" value="true" [(ngModel)]="serviceAppointmentReminder" inputId="true"></p-radioButton>
                        <label for="true">Yes</label>
                    </div>
                    <div class="p-field-radiobutton" style=" width: 20%; margin-top: 7px;">
                        <p-radioButton name="serviceAppointmentReminder" value="false" [(ngModel)]="serviceAppointmentReminder" inputId="false"></p-radioButton>
                        <label for="false">No</label>
                    </div>
                </div> -->
    
                <!-- <div class="col-md-6">
                    <label style="height: 34px;"
                    [ngClass]="serviceAppointmentReminder == 'false' ?'disabledbutton':'' " >Appointment Reminder Days<span class="symbol required"></span></label>
                    <select name="reminderDays" class="form-control" style="height: 34px;"
                    [ngClass]="serviceAppointmentReminder == 'false' ?'disabledbutton':'' " [(ngModel)]="reminderDays">
                        <option *ngFor="let days of appointmentReminderDaysNumber" [value]="days">
                            {{ days }}
                        </option>
                    </select> 
                </div> -->
            </div>

            <!-- <div class="form-group col-md-6">
                <label>Appointment Confirmation<span class="symbol required"></span></label>
                <br>
                <div class="p-field-radiobutton" style=" width: 20%; float: left; margin-top: 7px;">
                    <p-radioButton name="serviceClinetConfirmation" value="true" [(ngModel)]="serviceClinetConfirmation" inputId="true"></p-radioButton>
                    <label for="true">Yes</label>
                </div>
                <div class="p-field-radiobutton" style=" width: 20%; margin-top: 7px;">
                    <p-radioButton name="serviceClinetConfirmation" value="false" [(ngModel)]="serviceClinetConfirmation" inputId="false"></p-radioButton>
                    <label for="false">No</label>
                </div>
            </div> -->

            <!-- <div class="form-group col-md-6">
                <label>Service Appointment Scheduling<span class="symbol required"></span></label>
                <br>
                <div class="p-field-radiobutton" style=" width: 30%;float: left;margin-top: 7px;">
                    <p-radioButton name="serviceAppointmentScheduling" value="By Client" [(ngModel)]="serviceAppointmentScheduling" inputId="By Client"></p-radioButton>
                    <label for="By Client">By Client</label>
                </div>
                <div class="p-field-radiobutton" style=" width: 30%; margin-top: 7px;">
                    <p-radioButton name="serviceAppointmentScheduling" value="By Admin" [(ngModel)]="serviceAppointmentScheduling" inputId="By  Admin"></p-radioButton>
                    <label for="By Admin">By Admin</label>
                </div>
            </div> -->

            <!-- <div class="form-group col-md-6">
                <label>Client Appointment Rescheduling<span class="symbol required"></span></label>
                <br>
                <div class="p-field-radiobutton" style=" width: 20%;float: left;margin-top: 7px;">
                    <p-radioButton name="clientAppointmentRescheduling" value="true" [(ngModel)]="clientAppointmentRescheduling" inputId="true"></p-radioButton>
                    <label for="true">Yes</label>
                </div>
                <div class="p-field-radiobutton" style=" width: 20%; margin-top: 7px;">
                    <p-radioButton name="clientAppointmentRescheduling" value="false" [(ngModel)]="clientAppointmentRescheduling" inputId="By  Admin"></p-radioButton>
                    <label for="false">No</label>
                </div>
            </div> -->

            <!-- <div class="form-group col-md-6">
                <label>Appointment Waiting list Allowed<span class="symbol required"></span></label>
                <br>
                <div class="p-field-radiobutton" style=" width: 20%;float: left;margin-top: 7px;">
                    <p-radioButton name="appointmentWaitinglistAllowed" value="true" [(ngModel)]="appointmentWaitinglistAllowed" inputId="true"></p-radioButton>
                    <label for="true">Yes</label>
                </div>
                <div class="p-field-radiobutton" style=" width: 20%; margin-top: 7px;">
                    <p-radioButton name="appointmentWaitinglistAllowed" value="false" [(ngModel)]="appointmentWaitinglistAllowed" inputId="false"></p-radioButton>
                    <label for="false">No</label>
                </div>
            </div> -->

            <!-- <div class="form-group col-md-6">
                <label>Walk-Ins Allowed<span class="symbol required"></span></label>
                <br>
                <div class="p-field-radiobutton" style=" width: 20%;float: left;margin-top: 7px;">
                    <p-radioButton name="walkInsAllowed" value="true" [(ngModel)]="walkInsAllowed" inputId="true"></p-radioButton>
                    <label for="true">Yes</label>
                </div>
                <div class="p-field-radiobutton" style=" width: 20%; margin-top: 7px;">
                    <p-radioButton name="walkInsAllowed" value="false" [(ngModel)]="walkInsAllowed" inputId="false"></p-radioButton>
                    <label for="false">No</label>
                </div>
            </div> -->

            <!-- <div class="form-group col-md-6">
                <label>Pre-Payment<span class="symbol required"></span></label>
                <br>
                <div class="p-field-radiobutton" style=" width: 20%;float: left;margin-top: 7px;">
                    <p-radioButton name="prePayment" value="true" [(ngModel)]="prePayment" inputId="true"></p-radioButton>
                    <label for="true">Yes</label>
                </div>
                <div class="p-field-radiobutton" style=" width: 20%; margin-top: 7px;">
                    <p-radioButton name="prePayment" value="false" [(ngModel)]="prePayment" inputId="false"></p-radioButton>
                    <label for="false">No</label>
                </div>
                <div *ngIf="prePayment == 'true'">
                    <div class="p-field-radiobutton" style=" width: 20%;float: left;margin-top: 1px;">
                        <p-radioButton name="prePaymentType" value="URL" [(ngModel)]="prePaymentType" inputId="URL"></p-radioButton>
                        <label for="true">URL</label>
                    </div>
                    <div class="p-field-radiobutton" style="margin-top: 25px;">
                        <p-radioButton name="prePaymentType" value="Action Button" [(ngModel)]="prePaymentType" inputId="Action Button"></p-radioButton>
                        <label for="false">Action Button</label>
                    </div>
                </div>
                <div class="col-md-12">                    
                    <label *ngIf="prePayment == 'true' && prePaymentType == 'URL'">Payment URL<span class="symbol required"></span></label>
                    <label *ngIf="prePayment == 'true' && prePaymentType == 'Action Button'">Select Action Button.<span class="symbol required"></span></label>
                    <br>
                    <input type="text" *ngIf="prePayment == 'true' && prePaymentType == 'URL'" class="form-control" name="prePaymentUrl" placeholder="Enter URL" [(ngModel)]="prePaymentUrl" autocomplete="off"/>
                    <p-dropdown *ngIf="prePayment == 'true' && prePaymentType == 'Action Button'"  [options]="arrayActionButton" [(ngModel)]="selectedActionButton" optionLabel="name" name="actionButton" placeholder="Select Action Button"></p-dropdown>
                </div>
            </div> -->
        </div>

        <div class="row" [ngClass]="currentPosition == 'media_details'?'show':'hide'">
            <div class="form-group col-md-6">
                <label>URL</label>
                <input type="text" class="form-control" name="serviceUrl" placeholder="Enter URL"
                    [(ngModel)]="serviceUrl" autocomplete="off"/>
            </div>

            <div class="form-group col-md-6">
                <label>Service Video URL</label>
                <input type="text" class="form-control" name="serviceVideoUrl" placeholder="Enter Service URL"
                    [(ngModel)]="serviceVideoUrl" autocomplete="off"/>
            </div>

            <div class="form-group col-md-12">
                <label>Service Image</label>

                <div class="form-group col-md-12">
                    <div class="col-md-12" style="background-color: #F8F9FA;height: 80px;border: 1px solid #DEE2E6;border-radius: 2px;">
                        <span class="btn btn-primary btn-file" icon="pi pi-plus" style=" margin: 20px; ">
                            <i class="pi pi-plus" style="margin-right: 0.5rem;"></i>Choose<input type="file" accept="image/*" (change)="serviceFileChangeEvent($event, 'image')" multiple>
                        </span>
                    </div>
                    <div style="overflow: auto;width: 100%;height: 200px;border: 1px solid #DEE2E6">                    
                        <div *ngFor="let image of serviceImage; let index = index;" class="col-md-12" style="padding: 10px;">
                            <div class="col-md-3 center">
                                <img [src]="image.objectURL" alt="image.name" width="50" height="50" class="lazyload">
                            </div>
                            <div class="col-md-3 center" style=" word-wrap: break-word; ">
                                {{image.name}}
                            </div>
                            <div class="col-md-3 center">
                                {{niceBytes(image.size)}}
                            </div>
                            <div class="col-md-3 center">
                                <span class="closes" title="Delete" (click)="removeSelectFile(image.name,'image')" style="cursor: pointer;"><i class="pi pi-trash" style="font-size: 0.8rem;color: #dc3545;"></i></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- <div class="form-group col-md-12">
                <label>Service Video</label>
                <div class="form-group col-md-12">
                    <div class="col-md-12" style="background-color: #F8F9FA;height: 80px;border: 1px solid #DEE2E6;border-radius: 2px;">
                        <span class="btn btn-primary btn-file" icon="pi pi-plus" style=" margin: 20px; ">
                            <i class="pi pi-plus" style="margin-right: 0.5rem;"></i>Choose<input type="file" accept="video/*" (change)="serviceFileChangeEvent($event, 'video')">
                        </span>
                    </div>
                    <div style="height: 150px; border: 1px solid #DEE2E6">                    
                        <div *ngFor="let video of serviceVideo; let index = index;" class="col-md-12" style="padding: 10px;">
                            <div class="col-md-3 center">
                                <i class="pi pi-video" style="margin-right: 0.5rem;"></i>
                            </div>
                            <div class="col-md-3 center" style=" word-wrap: break-word; ">
                                {{video.name}}
                            </div>
                            <div class="col-md-3 center">
                                {{niceBytes(video.size)}}
                            </div>
                            <div class="col-md-3 center">
                                <span class="closes" title="Delete" (click)="removeSelectFile(video.name,'video')" style="cursor: pointer;"><i class="pi pi-trash" style="font-size: 0.8rem;color: #dc3545;"></i></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div> -->

        </div>
    </form>

    <ng-template pTemplate="footer">
        <!-- <div [ngClass]="currentPosition == 'service_details'?'show':'hide'">
            <div class="p-grid">
                <div class="p-col p-d-flex p-ai-end" style="text-align: start;padding: 0;">
                </div>
                <div class="p-col p-d-flex p-jc-end" style="padding: 0;">
                    <button pButton pRipple type="button" icon="pi pi-angle-right" iconPos="right"
                        label="Next" class="p-button-secondary p-button-text"
                        (click)="goToNextAndPrevious('appointment_details')"></button>
                </div>
            </div>
        </div>

        <div [ngClass]="currentPosition == 'appointment_details'?'show':'hide'">                            
            <div class="p-grid">
                <div class="p-col p-d-flex p-ai-end" style="text-align: start;padding: 0;">
                    <button pButton pRipple type="button" icon="pi pi-angle-left" label="Back" class="p-button-secondary p-button-text" (click)="goToNextAndPrevious('service_details')"></button>
                </div>
                <div class="p-col p-d-flex p-jc-end" style="padding: 0;">
                    <button pButton pRipple type="button" icon="pi pi-angle-right" iconPos="right" label="Next" class="p-button-secondary p-button-text" (click)="goToNextAndPrevious('media_details')"></button>
                </div>
            </div>
        </div>

        <div [ngClass]="currentPosition == 'media_details'?'show':'hide'">                            
            <div class="p-grid">
                <div class="p-col p-d-flex p-ai-end" style="text-align: start;padding: 0;">
                    <button pButton pRipple type="button" icon="pi pi-angle-left" label="Back" class="p-button-secondary p-button-text" (click)="goToNextAndPrevious('appointment_details')"></button>
                </div>
                <div class="p-col p-d-flex p-jc-end" style="padding: 0;">
                    <p-button icon="pi pi-times" (click)="displayAddEditModal = false" label="Cancel"
                    styleClass="p-button-danger p-button-text"></p-button>
                    <p-button *ngIf="headerName=='Manage Services'" icon="pi pi-plus" (click)="addService(addServiceForm)"
                        label="Add" [disabled]=" disabled">
                    </p-button>
                    <p-button *ngIf="headerName=='Edit Manage Services'" icon="pi pi-save" (click)="editService(addServiceForm)"
                        label="Save" [disabled]="disabled">
                    </p-button>
                </div>
            </div>
        </div> -->

        <div class="p-col p-d-flex p-jc-end" style="padding: 0;">
            <p-button icon="pi pi-times" (click)="displayAddEditModal = false" label="Cancel"
            styleClass="p-button-danger p-button-text"></p-button>
            <p-button *ngIf="headerName=='Manage Services'" icon="pi pi-plus" (click)="addService(addServiceForm)"
                label="Add" [disabled]=" disabled">
            </p-button>
            <p-button *ngIf="headerName=='Edit Manage Services'" icon="pi pi-save" (click)="editService(addServiceForm)"
                label="Save" [disabled]="disabled">
            </p-button>
        </div>

    </ng-template>
    <div *ngIf="isLoading" class="progressLayout">
        <div class="progressContainer">
            <p-progressSpinner [style]="{ width: '40px', height: '40px' }" strokeWidth="3"></p-progressSpinner>
        </div>
    </div>
</p-dialog>


<!-- Image View/ Delete MODAL -->
<p-dialog #addDialogFiles [header]="headerName" [(visible)]="displayFilesModal" [modal]="true" [draggable]="false" [resizable]="true" [maximizable]="true" styleClass="p-fluid" (onHide)="clearFormData()" [style]="{width: '70vw'}">

    <div class="col-md-12" *ngIf="headerName == 'Service Images' ">
        <div class="row">
            <div *ngFor="let image of serviceImageFile; let index = index;" class="img-wraps" key="index">
                <div style="width: 200px; height: 220px; background-color: #f9f9f9; display: flex;justify-content: center; align-items: center; margin: 10px; border-radius: 10px;">
                    <span class="closes" title="Delete" (click)="serviceTypeFileDeleteConfirmDialog(image.id)"><i class="pi pi-trash"></i></span>
                    <img [src]="image.thumbnailFileName" [alt]="image.fileName" style="cursor: pointer;" class="img-thumbnail lazyload" (click)="ShowImagePopUp(image)"/>
                </div>
            </div>
        </div>
        <div *ngIf="serviceImageFile.length == 0" class="row">
            <div class="col-md-12 text-center">NO DATA FOUND</div>
        </div>
    </div>
    
    <div class="col-md-10 col-md-offset-1" *ngIf="headerName == 'Service Video'">
        <div align="center" class="embed-responsive embed-responsive-16by9 img-wraps" *ngIf="serviceVideoFile.length > 0 ">
            <span class="closes" title="Delete" (click)="serviceTypeFileDeleteConfirmDialog(serviceVideoFile[0].id)"><i class="pi pi-trash"></i></span>
            <video controls class="embed-responsive-item">
                <source src="{{serviceVideoFile[0].fileName}}" type="video/mp4">
            </video>
            
        </div>
        <div *ngIf="serviceVideo.length == 0" class="row">
            <div class="col-md-12 text-center">NO DATA FOUND</div>
        </div>
    </div>
</p-dialog>

<div #largeModal id="myModal" class="modal">
    <span class="close" (click)="CloseImagePopUp()">×</span>
    <img class="modal-content lazyload" src="{{selectImage}}">
    <div id="caption"></div>
</div>

<!-- REMOVE MODAL -->
<p-confirmDialog [baseZIndex]="10000" rejectButtonStyleClass="p-button-text" [style]="{width:'45vw'}">
</p-confirmDialog>
<!-- TOAST -->
<p-toast position=" bottom-right" key="br">
</p-toast>