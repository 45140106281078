import { Component, OnInit } from '@angular/core';
import { UtilsService } from '../services/utils/utils.service';
import { ConfirmationService, MenuItem, MessageService } from 'primeng/api';
import { ClientSegmentationService } from '../services/client-segmentation/client-segmentation.service';
import { AppSettings } from '../Constant/AppSettings';
import { ChatButtonPromotionService } from '../services/chat-button-promotion/chat-button-promotion.service';
import { environment } from 'src/environments/environment.prod';
import { CommonFunctions } from '../Utilities/CommonFunctions';

@Component({
  selector: 'app-chat-button-promotion',
  templateUrl: './chat-button-promotion.component.html',
  styleUrls: ['./chat-button-promotion.component.css']
})
export class ChatButtonPromotionComponent implements OnInit {

  home: MenuItem = { icon: 'pi pi-home', routerLink: '/home' };
  items: MenuItem[] = [{ label: 'Chat Button Configuration' }];
  chatButtonImageBase64 = '';
  imageInputError: boolean = false;
  // profileUrlNew = '';
  croppedImageUrl: any = '';
  displayCropModal: boolean = false;
  displayCropModal2: boolean = false;
  promotionDuration: number = 1;
  promotionGraphicImageUrl = '';
  promotionGraphicImageBase64 = '';
  graphicImageInputError: boolean = false;
  croppedImageWidht: number = 0;
  clientSegments: any[] = [];
  resizeToWidth: number = 0; 
  resizeToHeight: number = 0; 
  aspectRatio: number = 4/4;
  headerShow: string = 'chatButton';
  clientSegmentationList: any[] = [];
  isLoading: boolean = false;
  checkExistingPromotion: boolean = false;
  chatButtonPromotionId: number = 0; 
  disabledPublish: boolean = false;
  imageActionType: string = 'No Action';
  imageAction: string = '';

  constructor(
    private utils: UtilsService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private clientsegmentationservice: ClientSegmentationService,
    private chatButtonPromotionService: ChatButtonPromotionService
  ) { }

  ngOnInit(): void {
    this.getClientSegmentationByBusinessId();
    this.getChatButtonPromotionByBusinessId();
  }

  chatButtonFileFailedToLoad(event: any) {
    this.imageInputError = true;
    this.chatButtonImageBase64 = 'assets/images/picture.svg';
    // this.profileUrlNew = 'assets/images/picture.svg';
    this.croppedImageUrl = "";
  }

  promotionGraphicFileFailedToLoad(event: any) {
    this.graphicImageInputError = true;
    this.promotionGraphicImageBase64 = 'assets/images/picture.svg';
    // this.profileUrlNew = 'assets/images/picture.svg';
    this.croppedImageUrl = "";
  }

  croppedImage(imageFile: any) {
    if (imageFile !== undefined) {
      if(this.aspectRatio == 4/4){
        this.croppedImageUrl = imageFile?.croppedImageUrl
        this.chatButtonImageBase64 = imageFile?.base64Value;
        this.imageInputError = false;
        this.croppedImageWidht = imageFile.width;
      }else if(this.aspectRatio == 12/19.5){
        this.promotionGraphicImageUrl = imageFile?.croppedImageUrl
        this.promotionGraphicImageBase64 = imageFile?.base64Value;
        this.imageInputError = false;
        this.croppedImageWidht = imageFile.width;
      }
      
    }
  }

  hideCropModal(displayCropModal: any) {
    if (displayCropModal !== undefined) {
      this.displayCropModal = displayCropModal;
    }
    this.displayCropModal2 = false;
  }

  get state(): string {
    return this.utils.state;
  }

  confirmDeleteDialog() {
    this.messageService.clear();
    this.confirmationService.confirm({
      message: 'Do you want to delete this image ?',
      header: 'Delete Confirmation',
      icon: 'pi pi-trash',
      accept: () => {
        this.chatButtonFileFailedToLoad({})
      }
    });
  }

  chatButtonCropModel(){
    this.resizeToWidth = 0; 
    this.resizeToHeight = 0;
    this.aspectRatio = 4/4;
    this.displayCropModal = true;
  }

  promotionGraphicCropModel(){
    this.resizeToWidth = 0; 
    this.resizeToHeight = 0;
    this.aspectRatio = 12/19.5;
    this.displayCropModal = true;
  }

  getClientSegmentationByBusinessId(){
    this.clientsegmentationservice.getClientSegmentationListByActive().subscribe(
      (response) => {
        var json = JSON.parse(JSON.stringify(response));
        console.log('Get Client Segmentation Response --->> ' + JSON.stringify(json));
        if (json.response.status == 'SUCCESS') {
          this.clientSegmentationList = json.data;
        } else {
          this.showErrorMessage(json.response.displayMessage)
        }
        this.hideProgressBar();
      },
      (error) => {
        console.log(error);
        this.showErrorMessage(AppSettings.ERROR_MSG)
        this.hideProgressBar();
      }
    );
  }

  showProgressBar() {
    this.messageService.clear();
    this.isLoading = true;
  }

  hideProgressBar() {
    this.isLoading = false;
  }

  showErrorMessage(msg: string) {
    this.messageService.add({
      key: 'errMsg',
      severity: 'error',
      detail: msg,
    });
  }

  showErrorToastMsg(msg: string) {
    this.messageService.add({
      key: 'br',
      severity: 'error',
      summary: 'Error',
      detail: msg,
    });
  }

  showSuccessToastMsg(msg: string) {
    this.messageService.add({
      key: 'br',
      severity: 'success',
      summary: 'Success',
      detail: msg,
    });
  }

  getChatButtonPromotionByBusinessId(){
    this.chatButtonPromotionService.getChatButtonPromotion().subscribe(
      (response) => {
        var json = JSON.parse(JSON.stringify(response));
        console.log('Get Chat Button Promotion Response --->> ' + JSON.stringify(json));
        if (json.response.status == 'SUCCESS') {
          if(json.data != null){
            this.setData(json.data);
            this.checkExistingPromotion = true;
          }
        } else {
          this.showErrorToastMsg(json.response.displayMessage)
        }
        this.hideProgressBar();
      },
      (error) => {
        console.log(error);
        this.showErrorToastMsg(AppSettings.ERROR_MSG)
        this.hideProgressBar();
      }
    );
  }

  setData(data: any){
    this.chatButtonImageBase64 = environment.IMAGE_BASE_URL + data.chatButtonImage;
    this.promotionDuration = data.promotionDuration;
    this.promotionGraphicImageBase64 = environment.IMAGE_BASE_URL + data.promotionGraphic;
    this.chatButtonPromotionId = data.id;

    this.clientSegments = [];
    this.clientSegmentationList.forEach((element)=>{
      data.clientSegmentsId.split(",").forEach((elem:any)=>{
        if(element.id == elem){
          this.clientSegments.push(element);
        }
      })
    });
    this.disabledPublish = data.isPublished;
    this.imageActionType = data.imageActionType;
    this.imageAction = data.imageAction;
  }

  editChatButtonPromotion(){
    
    this.showProgressBar();
    //Validate form data
    if(this.chatButtonImageBase64 == 'assets/images/picture.svg' || this.chatButtonImageBase64 == ''){
      this.showErrorMessage('Chat button image is empty.')
      this.hideProgressBar();
      return;
    }

    if(this.clientSegments.length == 0){
      this.showErrorMessage('Client segments is empty.')
      this.hideProgressBar();
      return;
    }

    if(this.promotionGraphicImageBase64 == 'assets/images/picture.svg' || this.promotionGraphicImageBase64 == ''){
      this.showErrorMessage('Promotion graphic image is empty.')
      this.hideProgressBar();
      return;
    }

    if (this.imageActionType == 'URL' && this.imageAction != '' && !CommonFunctions.validURL(this.imageAction)) {
      this.showErrorMessage('URL is invalid.')
      this.hideProgressBar();
      return 'null';
    }
    
    // let actionString = this.imageAction;

    // if(this.imageActionType != 'URL'){
    //   actionString = "";
    // }

    //Split the data from the client segment
    let clientSegmentsId: any[] = [];
    this.clientSegments.forEach((element: any) => {
      clientSegmentsId.push(element.id)
    });

    const Data = {
      promotionDuration: this.promotionDuration,
      clientSegmentsId : clientSegmentsId.toString(),
      imageActionType : this.imageActionType,
      imageAction : this.imageAction
    }
    var data = { data: JSON.parse(JSON.stringify(Data)) };

    if(!this.checkExistingPromotion){
      this.chatButtonPromotionService.addChatButtonPromotion(data).subscribe(
        async (response) => {
          var json = JSON.parse(JSON.stringify(response));
          console.log('Edit Chat Button Promotion Post Response --->> ' + JSON.stringify(json));
          console.log('Status --->> ' + json.response.status);
          if (json.response.status == 'SUCCESS') {
            this.showSuccessMessage(json.response.status, json.response.message);
            if (this.croppedImageUrl != '') {
              await this.uploadChatButtonImage();
            }        
            if (this.promotionGraphicImageUrl != '') {
              this.uploadPromotionGraphicImage();
            }
          } else {
            this.showErrorToastMsg(json.response.displayMessage)
          }
          this.hideProgressBar();
        },
        (error) => {
          console.log(error);
          this.showErrorMessage(AppSettings.ERROR_MSG)
          this.hideProgressBar();
        }
      );
    }else{

      this.chatButtonPromotionService.updateChatButtonPromotion(data, this.chatButtonPromotionId).subscribe(
        async (response) => {
          var json = JSON.parse(JSON.stringify(response));
          console.log('Edit Chat Button Promotion Post Response --->> ' + JSON.stringify(json));
          console.log('Status --->> ' + json.response.status);
          if (json.response.status == 'SUCCESS') {
            this.showSuccessMessage(json.response.status, json.response.message);

            if (this.croppedImageUrl != '') {
              await this.uploadChatButtonImage();
            }        
            if (this.promotionGraphicImageUrl != '') {
              this.uploadPromotionGraphicImage();
            }

          } else {
            this.showErrorToastMsg(json.response.displayMessage)
          }
          this.hideProgressBar();
          this.disabledPublish = false;
        },
        (error) => {
          console.log(error);
          this.showErrorMessage(AppSettings.ERROR_MSG)
          this.hideProgressBar();
        }
      );
    }
    return null;
  }

  showSuccessMessage(status: string, msg: string) {
    this.messageService.add({
      key: 'br',
      severity: 'success',
      summary: status,
      detail: msg,
    });
  }

  resetChatButtonPromotion(){
    this.ngOnInit();
  }

  async uploadChatButtonImage(): Promise<void> {
    this.showProgressBar()
    // Perform asynchronous operations here
    return new Promise<void>((resolve, reject) => {
      this.chatButtonPromotionService.uploadChatButtonImage(this.croppedImageUrl).subscribe(
        (res) => {
          resolve();
          console.log(res);
          var json = JSON.parse(JSON.stringify(res));
          if (json.response.status == 'SUCCESS') {
            this.imageInputError = false;
            this.showSuccessMessage(json.response.status, json.response.message);
          } else {
            this.showErrorMessage(json.response.displayMessage)
          }
          this.hideProgressBar()
        },
        (err) => {
          console.log(err);
          this.showErrorMessage(AppSettings.ERROR_MSG)
        }
      );
    });
  }

  uploadPromotionGraphicImage() {
    this.showProgressBar()
    this.chatButtonPromotionService.uploadPromotionGraphicImage(this.promotionGraphicImageUrl).subscribe(
      (res) => {
        console.log(res);
        var json = JSON.parse(JSON.stringify(res));
        if (json.response.status == 'SUCCESS') {
          this.imageInputError = false;
          this.showSuccessMessage(json.response.status, json.response.message);
        } else {
          this.showErrorMessage(json.response.displayMessage)
        }
        this.hideProgressBar()
      },
      (err) => {
        console.log(err);
        this.showErrorMessage(AppSettings.ERROR_MSG)
      }
    );
  }

  confirmPublishDialog() {
    this.messageService.clear();
    this.confirmationService.confirm({
      message: 'Are you sure you want to start this promotion? Your changes will be immediately visible to your clients.',
      header: 'Publish Confirmation',
      icon: 'pi pi-upload',
      accept: () => {
        this.publishChatRoom(true)
      }
    });
  }

  publishChatRoom(value : any) {
    this.showProgressBar()
    this.chatButtonPromotionService.publishChatRoom(value).subscribe(
      (response) => {
        var json = JSON.parse(JSON.stringify(response));
        console.log('Publish Chat Room response --> ' + JSON.stringify(json));
        if (json.response.status == 'SUCCESS') {
          this.showSuccessMessage(json.response.status, json.response.displayMessage)
          this.getChatButtonPromotionByBusinessId()
        } else {
          this.showErrorMessage(json.response.displayMessage)
        }
        this.hideProgressBar()
      },
      (error) => {
        console.log(error);
        this.showErrorMessage(AppSettings.ERROR_MSG)
        this.hideProgressBar();
      }
      );
    }

  onSizeChange() {
    const minValue = 1;
    const maxValue = 365;
    // if (this.promotionDuration != null) {
    //   const minValue = 1;
    //   const maxValue = 28;
    //   if (this.promotionDuration < minValue) {
    //     this.promotionDuration = minValue;
    //   } else if (this.promotionDuration > maxValue) {
    //   this.promotionDuration = maxValue;
    //   }
    // }

    this.promotionDuration = Math.min(Math.max(this.promotionDuration, minValue), maxValue);
  }

  confirmStopPublishDialog() {
    this.messageService.clear();
    this.confirmationService.confirm({
      message: 'Are you sure you want to stop this promotion? Your changes will be immediately visible to your clients.',
      header: 'Unpublish Confirmation',
      icon: 'pi pi-times-circle',
      accept: () => {
        this.publishChatRoom(false)
      }
    });
  }
}
