<div [ngClass]="state=='open' ? 'main_content_open' : 'main_content'">
    <p-scrollPanel [style]="{width: '100%',height: '95vh','margin-top':'7px','padding-bottom':'70px'}" #sc>
        <app-message-credit-warning></app-message-credit-warning>
        <app-twillio-message></app-twillio-message>
        <div class="p-d-flex p-jc-between p-ai-center p-pt-3 p-pb-3">
            <div class="page_title">
                Business Information
            </div>
            <div>
                <p-breadcrumb [model]="items" [home]="home"></p-breadcrumb>
            </div>
        </div>
        <div class="p-mb-5">
            <p-card>
                <div class="fileinput fileinput-new text-center" data-provides="fileinput">
                    <div class="user-image mt-2">
                        <div class="fileinput-new img-thumbnail">
                            <img style="width: 100px;height: 100px;object-fit: contain;" [src]="profileUrl" alt=""
                                (error)="fileFailedToLoad($event)" />
                            <!-- <p-avatar *ngIf="imageInputError" [label]="avatarName"
                                        [style]="{width: '100px',height: '100px'}" size="xlarge">
                                    </p-avatar> -->
                        </div>
                        <div class="user-image-buttons">
                            <span class="btn btn-azure btn-file btn-sm" (click)="displayCropModal = true">
                                <span class="fileinput-new">
                                    <i class="fa fa-pencil"></i>
                                </span>
                            </span>
                            <a *ngIf="!imageInputError" href="javascript:void(0)" (click)="confirmDeleteDialog()"
                                class="btn fileinput-exists btn-red btn-sm">
                                <i class="fa fa-times"></i>
                            </a>
                        </div>
                    </div>
                </div>
                <div class="container-fluid bg-white">
                    <div class="row">
                        <div class="col-md-6">
                            <fieldset>
                                <legend>Change Business Information</legend>
                                <p-messages key="errMsg" [style]="{ 'font-size': '12px' }"></p-messages>
                                <form role="form" class="form-horizontal"
                                    (ngSubmit)="editBusinessInfo(businessInfoForm)" id="updateProfile"
                                    #businessInfoForm="ngForm">

                                    <div class="row form-group">
                                        <label class="col-sm-4 control-label">Business Name<span
                                                class="symbol required"></span></label>
                                        <div class="col-sm-8">
                                            <input type="text" name="businessName" class="form-control"
                                                placeholder="Enter Business Name" [(ngModel)]="businessName"
                                                [disabled]="disabled" required />
                                        </div>
                                    </div>
                                    <div class="row form-group">
                                        <label class="col-sm-4 control-label">Address 1<span
                                                class="symbol required"></span></label>
                                        <div class="col-sm-8">
                                            <textarea pInputTextarea type="text" name="address" class="form-control"
                                                placeholder="Enter Address" [(ngModel)]="address" [disabled]="disabled"
                                                required></textarea>
                                        </div>
                                    </div>

                                    <div class="row form-group">
                                        <label class="col-sm-4 control-label">Address 2 &nbsp;</label>
                                        <div class="col-sm-8">
                                            <textarea pInputTextarea type="text" name="address2" class="form-control"
                                                placeholder="Enter Address 2" [(ngModel)]="address2"
                                                [disabled]="disabled"></textarea>
                                        </div>
                                    </div>

                                    <div class="row form-group">
                                        <label class="col-sm-4 control-label">City<span
                                                class="symbol required"></span></label>
                                        <div class="col-sm-8">
                                            <input type="text" name="city" class="form-control" placeholder="Enter city"
                                                [(ngModel)]="city" [disabled]="disabled" />
                                        </div>
                                    </div>

                                    <div class="row form-group">
                                        <label class="col-sm-4 control-label">State<span
                                                class="symbol required"></span></label>
                                        <div class="col-sm-8">
                                            <input type="text" name="business_state" class="form-control"
                                                placeholder="Enter state" [(ngModel)]="business_state"
                                                [disabled]="disabled" autocomplete="off" maxlength="2"
                                                value="{{business_state | uppercase}}" />
                                        </div>
                                    </div>

                                    <div class="row form-group">
                                        <label class="col-sm-4 control-label">Zip Code<span
                                                class="symbol required"></span></label>
                                        <div class="col-sm-8">
                                            <input type="text" name="zipcode" class="form-control"
                                                placeholder="Enter Zip Code" [(ngModel)]="zipcode" [disabled]="disabled"
                                                autocomplete="off" maxlength="5"
                                                (keypress)="validateDigitOnly($event)" />
                                        </div>
                                    </div>
                                    <div class="row form-group">
                                        <label class="col-sm-4 control-label">Country<span
                                                class="symbol required"></span></label>
                                        <div class="col-sm-8">
                                            <select [(ngModel)]="country" name="country" class="form-control"
                                                placeholder="Select a country" style="height: 34px;" required>
                                                <option [value]="null" selected disabled>Select Country</option>
                                                <!-- <option [ngValue]="null">Choose Country</option> -->
                                                <option *ngFor="let country of countries" [ngValue]="country.isoCode">
                                                    {{ country.name }}
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="row form-group">
                                        <label class="col-sm-4 control-label">Phone<span
                                                class="symbol required"></span></label>
                                        <div class="col-sm-8">
                                            <span class="input-icon"><input type="text" class="form-control"
                                                    placeholder="Enter Mobile" name="phone" [(ngModel)]="phone"
                                                    [disabled]="disabled" maxlength="10"
                                                    (keypress)="validateDigitOnly($event)">
                                                <span class="number">+1</span></span>

                                            <!-- <div class="p-inputgroup">
                                                        <span class="p-inputgroup-addon"><i class="pi pi-plus"></i></span>
                                                        <input type="text" name="phone" class="form-control"
                                                            placeholder="Enter Phone" [(ngModel)]="phone" required />
                                                    </div> -->
                                        </div>
                                    </div>
                                    <div class="row form-group">
                                        <label class="col-sm-4 control-label">Fax</label>
                                        <div class="col-sm-8">
                                            <input type="text" name="fax" class="form-control" placeholder="Enter Fax"
                                                [(ngModel)]="fax" (keypress)="validateDigitOnly($event)"
                                                [disabled]="disabled" />
                                        </div>
                                    </div>
                                    <div class="row form-group">
                                        <label class="col-sm-4 control-label">EIN<span
                                                class="symbol required"></span></label>
                                        <div class="col-sm-8">
                                            <input type="text" name="ein" class="form-control" placeholder="Enter EIN"
                                                [(ngModel)]="ein" (keypress)="validateDigitOnly($event)"
                                                [disabled]="disabled" />
                                        </div>
                                    </div>
                                    <div class="row form-group">
                                        <label class="col-sm-4 control-label">Website</label>
                                        <div class="col-sm-8">
                                            <input type="text" name="website" class="form-control"
                                                placeholder="Enter Website Url" [(ngModel)]="websiteUrl"
                                                [disabled]="disabled" />
                                        </div>
                                    </div>
                                    <div class="row form-group">
                                        <label class="col-sm-4 control-label">Timezone<span
                                                class="symbol required"></span></label>
                                        <div class="col-sm-8">
                                            <!-- <input type="text" name="timezone" class="form-control"
                                                        placeholder="Enter timezone" [(ngModel)]="timezone" disabled /> -->
                                            <select [(ngModel)]="timezone" name="timezone" class="form-control"
                                                placeholder="Select a timezone" style="height: 34px;" required>
                                                <option [value]="null" disabled>Select a timezone</option>
                                                <option *ngFor="let data of timezoneList" [ngValue]="data">
                                                    {{ Splitfunction(data,2) }} {{ Splitfunction(data,0) }} {{
                                                    Splitfunction(data,1) }}
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="row form-group">
                                        <label class="col-sm-4 control-label">Business Type<span
                                                class="symbol required"></span></label>
                                        <div class="col-sm-8">
                                            <select [(ngModel)]="selectedBusinessType" name="businessType"
                                                class="form-control" style="height: 34px;"
                                                [ngClass]="disabled? 'select_disabled':''" [disabled]="disabled">
                                                <option [value]="null" disabled>Select business type</option>
                                                <!-- <option [ngValue]="null">Choose Country</option> -->
                                                <option *ngFor="let business of businessType" [value]="business.id">
                                                    {{ business.name }}
                                                </option>
                                            </select>
                                        </div>
                                    </div>

                                    <div class="form-group center mt-2">
                                        <p-progressSpinner *ngIf="isLoading" [style]="{ width: '40px', height: '40px' }"
                                            strokeWidth="3" class="p-mr-4">
                                        </p-progressSpinner>
                                        <button pButton pRipple type="button" label="Reset"
                                            class="p-button-text p-button-plain  p-mr-4"
                                            (click)="resetBusinessInfo()"></button>

                                        <button pButton pRipple type="button" class="p-button-raised "
                                            (click)="editBusinessInfo(businessInfoForm)" label="Save"
                                            [disabled]="disabled"></button>
                                    </div>
                                </form>
                            </fieldset>
                        </div>
                        <div class="col-md-6">
                            <div class="row-md-6">
                                <fieldset>
                                    <legend>SMS number</legend>
                                    <div class="row form-group">
                                        <label class="col-sm-4 control-label">SMS number</label>
                                        <div class="col-sm-8">
                                            <input pInputText type="text" name="twillioNumber"
                                                style="border-color: #c8c7cc !important;" [(ngModel)]="twillioNumber"
                                                placeholder="In progress" disabled required />
                                        </div>

                                    </div>
                                    <div class="form-group center mt-2">
                                        <p-progressSpinner *ngIf="isLoading" [style]="{ width: '40px', height: '40px' }"
                                            strokeWidth="3" class="p-mr-4">
                                        </p-progressSpinner>
                                    </div>

                                </fieldset>
                            </div>
                            <div class="row-md-6">
                                <fieldset>
                                    <legend>Business Status</legend>
                                    <div class="row form-group">
                                        <label class="col-sm-4 control-label">Business Status</label>
                                        <div class="col-sm-8 p-d-flex p-flex-column" *ngIf="businessStatusDate == null">
                                            <p-inputSwitch [(ngModel)]="businessStatus" name="businessStatus"
                                                (onChange)="onToggle($event)" [disabled]="true">
                                            </p-inputSwitch>
                                        </div>
                                        <div class="col-sm-8 p-d-flex p-flex-column" *ngIf="businessStatusDate != null">
                                            <p-inputSwitch [(ngModel)]="businessStatus" name="businessStatus"
                                                (onChange)="onToggle($event)">
                                            </p-inputSwitch>
                                        </div>
                                        <small class="col-sm-12" style="color: #858585;">
                                            When you are online, your customers can see you in the mobile
                                            app
                                        </small>
                                    </div>
                                    <div class="form-group center mt-2">
                                        <p-progressSpinner *ngIf="isLoading" [style]="{ width: '40px', height: '40px' }"
                                            strokeWidth="3" class="p-mr-4">
                                        </p-progressSpinner>
                                    </div>

                                </fieldset>
                            </div>
                            <div class="row-md-6">
                                <fieldset>
                                    <!-- <legend>Change URL</legend> -->
                                    <legend>Business Portal URL</legend>
                                    <p-messages key="tr" [style]="{ 'font-size': '12px' }"></p-messages>
                                    <form role="form" class="form-horizontal" #profileForm="ngForm">
                                        <div class="row form-group">
                                            <label class="col-sm-3 control-label">Short Name<span
                                                    class="symbol required"></span></label>
                                            <div class="col-sm-9">
                                                <div class="p-inputgroup">
                                                    <span class="p-inputgroup-addon">https://chatstasy.com/</span>
                                                    <input pInputText type="text" name="url" [(ngModel)]="shortNameUrl"
                                                        placeholder="Enter url"
                                                        (keypress)="keyPressAlphaNumericWithCharacters($event)"
                                                        (blur)="checkBusinessShortname()" required />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group center mt-2">
                                            <p-progressSpinner *ngIf="isLoading"
                                                [style]="{ width: '40px', height: '40px' }" strokeWidth="3"
                                                class="p-mr-4">
                                            </p-progressSpinner>
                                            <button pButton pRipple type="button" label="Reset"
                                                class="p-button-text p-button-plain  p-mr-4"
                                                (click)="getShortName()"></button>

                                            <button pButton pRipple type="button" class="p-button-raised"
                                                (click)="confirmChangeUrlDialog()" label="Save"
                                                [disabled]="disabledShortUrl"></button>
                                        </div>
                                    </form>

                                </fieldset>
                            </div>

                            <div class="row-md-6">
                                <p-progressSpinner *ngIf="isLoading" [style]="{ width: '40px', height: '40px' }" strokeWidth="3" class="p-mr-4">
                                </p-progressSpinner>
                                <fieldset>
                                    <legend>Backup/Restore Business Configuration Data</legend>
                                    <p-messages key="tr" [style]="{ 'font-size': '12px' }"></p-messages>
                                    <div class="col-md-6">
                                        <button pButton pRipple type="button" class="p-button-raised"
                                            (click)="confirmBackupFileDialog()" label="BACKUP"
                                            [disabled]="disabledBackupBtn"></button>
                                    </div>
                                    <div class="col-md-6">
                                        <button pButton pRipple type="button" class="p-button-raised p-button-success"
                                            (click)="listBackupFile()" label="RESTORE"
                                            [disabled]="disabledBackupBtn"></button>
                                    </div>

                                </fieldset>
                            </div>

                            <div class="row-md-6">
                                <fieldset>
                                    <legend>Open/Close Business</legend>
                                    <div class="row form-group">
                                        <label class="col-sm-4 control-label">OPEN/CLOSE</label>
                                        <div class="col-sm-8 p-d-flex p-flex-column">
                                            <p-inputSwitch [(ngModel)]="operatingStatus" name="operatingStatus"
                                                (onChange)="onToggleOperatingStatus($event)" [ngClass]="{'switch-on': operatingStatus, 'switch-off': !operatingStatus}">
                                            </p-inputSwitch>
                                        </div>
                                        <small class="col-sm-12" style="color: #858585;">
                                            You can digitally open or close your business.
                                        </small>
                                    </div>
                                    <div class="form-group center mt-2">
                                        <p-progressSpinner *ngIf="isLoading" [style]="{ width: '40px', height: '40px' }"
                                            strokeWidth="3" class="p-mr-4">
                                        </p-progressSpinner>
                                    </div>

                                </fieldset>
                            </div>

                        </div>

                    </div>

                </div>
            </p-card>
        </div>
    </p-scrollPanel>
</div>

<!-- REMOVE LOGO MODAL -->
<p-confirmDialog [baseZIndex]="10000" rejectButtonStyleClass="p-button-text" [style]="{width:'45vw'}">
</p-confirmDialog>

<!-- CROP MODAL -->
<app-image-cropper-dialog #renew [displayCropModal]="displayCropModal" (hideCropModal)="hideCropModal($event)"
    (croppedImage)="croppedImage($event)">
</app-image-cropper-dialog>
<!-- TOAST -->
<p-toast position="bottom-right" key="br"></p-toast>

<!-- ADD ROLE MODAL -->
<p-dialog #addDialog header="Restore Configuration Data" [(visible)]="displayBackupFile" [modal]="true" [baseZIndex]="10000"
    [draggable]="false" [resizable]="true" [maximizable]="true" styleClass="p-fluid" (onHide)="clearFormData()"
    (onMaximize)="onMaximizeBackup(isMaximizeBackup)" (onShow)="showDialogMaximized($event,addDialog)" [style]="{width: '50vw'}">
    <p-messages key="errMsg" [style]="{
      'font-size': '12px'
    }"></p-messages>

    <p-table [value]="backupList" [scrollable]="true" scrollHeight="200px">
        <ng-template pTemplate="header">
            <tr>
                <th style="width: 80%">File Name</th>
                <th>Choose</th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-product>
            <tr>
                <td style="width: 80%">{{product.backupName}}</td>
                <td><p-radioButton name="backupId" value="{{product.id}}" [(ngModel)]="selectedBackup"></p-radioButton></td>
            </tr>
        </ng-template>
    </p-table>    

    <div *ngIf="isLoading" class="progressLayout">
        <div class="progressContainer">
            <p-progressSpinner [style]="{ width: '40px', height: '40px' }" strokeWidth="3"></p-progressSpinner>
        </div>
    </div>

    <ng-template pTemplate="footer">
        <p-button icon="pi pi-times" (click)="displayBackupFile = false" label="Cancel"
            styleClass="p-button-danger p-button-text"></p-button>
        <p-button icon="pi pi-save" (click)="confirmImportFileDialog()" label="Import" [disabled]="disabledBackupBtn">
        </p-button>
    </ng-template>
</p-dialog>