import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { timeout } from 'rxjs/operators';
import { CommonFunctions } from 'src/app/Utilities/CommonFunctions';
import { environment } from 'src/environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  constructor(
    private http: HttpClient
  ) { }

  getClientChannel() {
    const url = environment.API_URL + '/api/stats/clientChannel';
    const TOKEN = CommonFunctions.getToken();
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + TOKEN,
        'Content-Type': 'application/json',
        'BusinessId': CommonFunctions.getBusinessId().toString()
      }),
    };
    return this.http.get(url, httpOptions).pipe(timeout(60000));
  }
  getMessageUsage() {
    const url = environment.API_URL + '/api/stats/messageUsage';
    const TOKEN = CommonFunctions.getToken();
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + TOKEN,
        'Content-Type': 'application/json',
        'BusinessId': CommonFunctions.getBusinessId().toString()
      }),
    };
    return this.http.get(url, httpOptions).pipe(timeout(60000));
  }
  getClientChannelAdoption() {
    const url = environment.API_URL + '/api/stats/clientChannelAdoption';
    const TOKEN = CommonFunctions.getToken();
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + TOKEN,
        'Content-Type': 'application/json',
        'BusinessId': CommonFunctions.getBusinessId().toString()
      }),
    };
    return this.http.get(url, httpOptions).pipe(timeout(60000));
  }
}
