<!-- <div class="p-d-flex p-jc-center p-ai-center" style="height: 100vh">
    <div class="row">
        <div class="col-md-12">
            <div class="card_width">
                <div class="p-d-flex p-jc-center p-ai-center p-mb-4">
                    <img src="assets/images/ChatstasyCorpLogoAndSlogan-June2023-1.png" style="height: 30%;width: 70%;" alt="Chatstasy" />
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-4">
            <p-card>
                <div class="copy_right">
                    &copy;
                    <span class="current-year"></span>
                    <span style="font-weight: bold !important;text-transform: uppercase !important;"> Chatstasy</span>.
                    <span>All rights reserved</span>
                </div>
            </p-card>
        </div>
    </div>
</div> -->

<div class="container">
    <div class="row">
        <div class="col-md-12">
            <div>
                <div class="p-d-flex p-jc-center p-ai-center p-mb-4">
                    <img src="assets/images/ChatstasyCorpLogoAndSlogan-June2023-1.png" style="width: 30%;margin-top: 15px;" alt="Chatstasy" />
                </div>
            </div>
        </div>
    </div>
      
    <div class="row" style="overflow:auto; height:400px;">
        <div style="width: 100%;">
            <p-card>
                <ng-template pTemplate="header">
                    <div class="text-center" style=" text-transform: uppercase;font-size: 20px;padding-top: 15px; ">
                        {{serviceName}}
                    </div>
                </ng-template>
                <div class="row">
                    <div *ngFor="let image of serviceTypeImages; let index = index;" class="img-wraps" key="index">
                        <div style="width: 200px; height: 220px; background-color: #f9f9f9; display: flex;justify-content: center; align-items: center; margin: 10px; border-radius: 10px;">
                            <img [src]="BaseURL+image.thumbnailFileName" [alt]="image.fileName" style="cursor: pointer;" class="img-thumbnail lazyload" (click)="ShowImagePopUp(image)"/>
                        </div>
                    </div>
                    <div *ngIf="serviceTypeImages.length == 0" class="col-12">
                        <div class="text-center">
                            <i class="pi pi-copy" style="font-size: 2rem"></i>
                            <div class="p-ml-2">NO DATA FOUND</div>
                        </div>
                    </div>
                </div>
                <ng-template pTemplate="footer">
                    <div class="copy_right">
                        &copy;
                        <span class="current-year"></span>
                        <span style="font-weight: bold !important;text-transform: uppercase !important;"> Chatstasy</span>.
                        <span>All rights reserved</span>
                    </div>
                </ng-template>
            </p-card>
        </div>
    </div>
    <div #largeModal id="myModal" class="modal">
        <span class="close" (click)="CloseImagePopUp()" style=" color: white; ">×</span>
        <img class="modal-content lazyload" src="{{selectImage}}">
        <div id="caption"></div>
    </div>
</div>
<p-toast position="bottom-right" key="errMsg" [baseZIndex]="10000"></p-toast>