import { Component, OnInit } from '@angular/core';
import { UtilsService } from '../services/utils/utils.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  constructor(
    public utils: UtilsService,
  ) { }

  ngOnInit(): void {
  }
  get state(): string {
    return this.utils.state;
  }
}
