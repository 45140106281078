<div [ngClass]="state=='open' ? 'main_content_open' : 'main_content'">
  <p-scrollPanel [style]="{width: '100%',height: '95vh','margin-top':'7px','padding-bottom':'70px'}" #sc>
    <app-message-credit-warning></app-message-credit-warning>
    <app-twillio-message></app-twillio-message>
    <div class="p-d-flex p-jc-between p-ai-center p-pt-3 p-pb-3">
      <div class="page_title">
        Client Segmentation
      </div>
      <div>
        <p-breadcrumb [model]="items" [home]="home"></p-breadcrumb>
      </div>
    </div>
    <div class="p-mb-5">
      <p-card>
        <div class="toolbar">
          <div class="p-grid p-ai-center">
            <div class="p-col p-md-12 p-lg-12 p-jc-end d-flex d-lg-flex d-xl-flex">
              <button pButton pRipple type="button" label="Add" class="p-button-raised" icon="pi pi-plus"
                style="font-size: 12px;" (click)="showHideModalDialog('add_clientsegmentation')" [disabled]="bussinesInfo?.subscriptionType != 'STANDARD'"></button>
            </div>
          </div>

          <div class="row form-group">
            <div class="col-sm-12">
              <p-table [columns]="cols" [value]="clientSegmentationList"
                styleClass="p-datatable-sm p-datatable-gridlines p-datatable-striped " [autoLayout]='true'
                [responsive]='true' [rowHover]="true">

                <ng-template pTemplate="header" let-columns>
                  <tr style="font-size: small;">
                    <th *ngFor="let col of columns">
                      {{col.header}}
                    </th>
                    <th>
                      Active/Inactive
                    </th>
                    <th>
                      Action
                    </th>
                  </tr>
                </ng-template>
                <ng-template pTemplate="body" let-clientSegmentation let-columns="columns">
                  <tr style="font-size: smaller;">
                    <td *ngFor="let col of columns">
                      <span class="p-column-title">{{col.header}}</span>
                      {{clientSegmentation[col.field]}}
                    </td>
                    <td>
                      <span class="p-column-title">Active/Inactive</span>
                      <p-inputSwitch [(ngModel)]="clientSegmentation.isEnabled"
                        name="enableDisable" (onChange)="onToggle($event,clientSegmentation)">
                      </p-inputSwitch>
                      &nbsp;
                    </td>
                    <td class="last_border">
                      <span class="p-column-title">Action</span>

                      <a data-toggle="modal" (click)="getOneClientSegmentation(clientSegmentation)"
                        style=" padding: 5px; ">
                        <i class="pi pi-pencil" style="font-size: 0.8rem;color:#007AFF"></i>
                      </a>
                      <a *ngIf="clientSegmentation.canDelete; else canDelete" data-toggle="modal"
                        (click)="confirmDeleteDialog(clientSegmentation)" style=" padding: 5px; ">
                        <i class="pi pi-trash" style="font-size: 0.8rem;color: #dc3545;"></i>
                      </a>
                      &nbsp;
                      <ng-template #canDelete style="display:inline-block;">
                        <div pTooltip="You cannot delete this client segmentation" tooltipPosition="left"
                          style="display:inline-block;">
                          <a href="javascript:void(0);" data-toggle="modal" class="disabledClass"
                            (click)="confirmDeleteDialog(clientSegmentation)" style=" padding: 5px; ">
                            <i class="pi pi-trash" style="font-size: 0.8rem"></i>
                          </a>
                        </div>
                      </ng-template>
                    </td>
                  </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage" let-columns>
                  <tr>
                    <td [attr.colspan]="columns.length+2">
                      <div *ngIf="isLoading" class="alignCenter">
                        <p-progressSpinner [style]="{ width: '40px', height: '40px' }" strokeWidth="3">
                        </p-progressSpinner>
                      </div>
                      <p-messages key="errMsg" *ngIf="clientSegmentationList?.length == 0 && !isLoading"
                        class="alignCenter">
                        <ng-template pTemplate>
                          <i class="pi pi-copy" style="font-size: 2rem"></i>
                          <div class="p-ml-2">NO DATA FOUND</div>
                        </ng-template>
                      </p-messages>
                    </td>
                  </tr>
                </ng-template>
              </p-table>

            </div>
          </div>
        </div>
      </p-card>
    </div>
  </p-scrollPanel>
</div>

<!-- ADD ClientSegmentation MODAL -->
<p-dialog header="{{headerName}}" [(visible)]="displayAddEditModal" [modal]="true" [baseZIndex]="10000"
  [draggable]="false" [resizable]="true" [maximizable]="true" (onHide)="clearFormData()"
  (onShow)="showDialogMaximized($event,addDialog)" #addDialog>
  <p-messages key="errMsg" [style]="{
      'font-size': '12px'
    }"></p-messages>
  <form role="form" #addClientSegmentationForm="ngForm" style="min-width: 60vw;">
    <div class="row">
      <div class="form-group col-md-6">
        <label>Name<span class="symbol required"></span></label>
        <input type="text" class="form-control border-black" name="name" placeholder="Enter Name" [(ngModel)]="name"
          required autocomplete="off" />
      </div>
      <div class="form-group col-md-6">
        <label>Description<span class="symbol required"></span></label>
        <textarea pInputTextarea type="text" class="form-control" placeholder="Enter Description" [rows]="5" [cols]="30"
          [(ngModel)]="description" name="Description" required></textarea>
      </div>
    </div>
  </form>
  <ng-template pTemplate="footer">
    <p-button icon="pi pi-times" (click)="displayAddEditModal = false" label="Cancel"
      styleClass="p-button-danger p-button-text"></p-button>
    <p-button icon="pi pi-save" (click)="addAndUpdateClientSegmentation('add')" label="Add" [disabled]="disabled"
      *ngIf="headerName == 'Add Client Segmentation'"></p-button>
    <p-button icon="pi pi-save" (click)="addAndUpdateClientSegmentation('update')" label="Update" [disabled]="disabled"
      *ngIf="headerName == 'Edit Client Segmentation'"></p-button>
  </ng-template>
  <div *ngIf="isLoading" class="progressLayout">
    <div class="progressContainer">
      <p-progressSpinner [style]="{ width: '40px', height: '40px' }" strokeWidth="3"></p-progressSpinner>
    </div>
  </div>
</p-dialog>

<!-- REMOVE USER MODAL -->
<p-confirmDialog [baseZIndex]="10000" [style]="{'min-width':'40vw'}" rejectButtonStyleClass="p-button-text">
</p-confirmDialog>