<div style=" border-right: 1px solid #dee2e6; height: 100%;">
    <div class="header">
        <div class="sidebar_search p-fluid">
            <div class="p-d-flex">
                <span class="p-input-icon-right ">
                    <i *ngIf="searchText !== ''" class="pi pi-times" style="cursor: pointer;"
                        (click)="searchText=''"></i>
                    <input #searchInput pInputText type="text" [(ngModel)]="searchText" placeholder="Search Client"
                        [style]="{'padding-right':'0'}" />
                </span>
                <div>
                    <button pButton pRipple type="button" icon="pi pi-search" [style]="{width:'2.1rem',height:'2.1rem'}"
                        class="p-button-rounded p-button-raised p-button-sm p-ml-2"></button>
                </div>
            </div>
        </div>
    </div>
    <p-scrollPanel [style]="{width: '100%',height: '385px'}" #sc>
        <ul class="sidebar_ul">
            <!-- <li class="list_sidebar">
                <a class="sidebar_container" style="text-align: center;" (click)="createNewChat()">
                    <div class="sidebar_info">
                        <h4 style="text-align: center; align-self: center;margin-top: 4px;">Create new chat</h4>
                    </div>
                </a>
            </li> -->
            <li class="list_sidebar" *ngFor="let room of rooms | filter: searchText">
                <a [ngClass]="room.id == paramValue? 'sidebar_container_selected' : 'sidebar_container'"
                    routerLink="room/{{ room.id }}">
                    <img *ngIf="room.preference_type == 'chatstasy'" src="assets/images/chatstasy_circle.png"
                        class="sidebar_profile_img" alt="">
                    <img *ngIf="room.preference_type != 'chatstasy' && room.hasOwnProperty('is_subscribed') == true  && room.is_subscribed == true" src="assets/images/SMS_Logo.png"  class="sidebar_profile_img" style="width: 32px;height: 32px;" alt="">
                    <img *ngIf="room.preference_type != 'chatstasy' && room.hasOwnProperty('is_subscribed') == true && room.is_subscribed == false" src="assets/images/QuestionMark.png"  class="sidebar_profile_img" style="width: 32px;height: 32px;" alt="">
                    <img *ngIf="room.preference_type != 'chatstasy' && room.hasOwnProperty('is_subscribed') == false" src="assets/images/SMS_Logo.png"  class="sidebar_profile_img" style="width: 32px;height: 32px;" alt="">
                    <div [ngClass]="room.read_status==businessId?'sidebar_info_unread':'sidebar_info'">
                        <h4 class="sidebar_info_title">{{room.recepient_name}}</h4>
                        <div class="sidebar_text_msg">
                            {{removeHTMLTag(room.last_message)}}
                            <!-- <div [innerHTML]="room.last_message | safeHtml"></div> -->
                            <div class="sidebar_text_msg">{{room.timeTag}}</div>
                        </div>
                    </div>
                </a>
            </li>
        </ul>
        <div *ngIf="rooms?.length == 0" class="no_chat_msg">
            <h1 style="font-size: 18px;">Click on Add Chat to start a new
                chat</h1>
        </div>
    </p-scrollPanel>
</div>