<section class="pricing py-2">
    <div class="pricing-header  text-center">
        <h1 class="display-4" style="font-size: 2rem;margin:0.5rem 0 1.5rem 0;">Select a Package to Proceed</h1>
    </div>
    <div class="container">
        <div class="row">
            <div *ngIf="!isLoading" class="progressLayout">
                <div class="progressContainer">
                    <p-progressSpinner [style]="{ width: '40px', height: '40px' }" strokeWidth="3">
                    </p-progressSpinner>
                </div>
            </div>
            <!-- Free Tier -->
            <div class="col-lg-4" *ngFor="let subs of subscriptionList">
                <div class="card mb-5 mb-lg-0">
                    
                    <div class="card-body">
                        <h5 class="card-title text-muted text-uppercase text-center">{{subs.name}}</h5>
                        <h6 class="card-price text-center">${{subs.price}}<span class="period">/month</span></h6>
                        <p class="hidden">{{subs.id}}</p>
                        <hr>

                        
                        <ul class="fa-ul" style="padding-bottom: 70px;">
                            <li *ngFor="let feature of subs.features"><span class="fa-li"><i
                                        class="pi pi-check"></i></span><strong>{{feature.amount}}</strong>
                                {{feature.description}}</li>
                            <!-- <li><span class="fa-li"><i class="pi pi-check"></i></span>5GB Storage</li>
                            <li><span class="fa-li"><i class="pi pi-check"></i></span>Unlimited Public Projects</li>
                            <li><span class="fa-li"><i class="pi pi-check"></i></span>Community Access</li>
                            <li class="text-muted"><span class="fa-li"><i class="pi pi-times"></i></span>Unlimited
                                Private Projects</li>
                            <li class="text-muted"><span class="fa-li"><i class="pi pi-times"></i></span>Dedicated
                                Phone Support</li>
                            <li class="text-muted"><span class="fa-li"><i class="pi pi-times"></i></span>Free Subdomain
                            </li>
                            <li class="text-muted"><span class="fa-li"><i class="pi pi-times"></i></span>Monthly Status
                                Reports</li> -->
                        </ul>
                        <!-- <div class="p-d-flex">
                            <p-button icon="pi pi-check" label="BUY"></p-button>
                        </div> -->
                        <a href="javascript:void(0)" (click)="onClickBuy(subs.price,subs.name,subs.id)"
                            class="btn btn-block btn-primary text-uppercase" id="buyBtn">Buy Now</a>
                    </div>
                </div>
            </div>
            <!-- Plus Tier -->
            <!-- <div class="col-lg-4">
                <div class="card mb-5 mb-lg-0">
                    <div class="card-body">
                        <h5 class="card-title text-muted text-uppercase text-center">Plus</h5>
                        <h6 class="card-price text-center">$9<span class="period">/month</span></h6>
                        <hr>
                        <ul class="fa-ul">
                            <li><span class="fa-li"><i class="pi pi-check"></i></span><strong>5 Users</strong></li>
                            <li><span class="fa-li"><i class="pi pi-check"></i></span>50GB Storage</li>
                            <li><span class="fa-li"><i class="pi pi-check"></i></span>Unlimited Public Projects</li>
                            <li><span class="fa-li"><i class="pi pi-check"></i></span>Community Access</li>
                            <li><span class="fa-li"><i class="pi pi-check"></i></span>Unlimited Private Projects</li>
                            <li><span class="fa-li"><i class="pi pi-check"></i></span>Dedicated Phone Support</li>
                            <li><span class="fa-li"><i class="pi pi-check"></i></span>Free Subdomain</li>
                            <li class="text-muted"><span class="fa-li"><i class="pi pi-times"></i></span>Monthly Status
                                Reports</li>
                        </ul>
                        <a href="#" class="btn btn-block btn-primary text-uppercase">Button</a>
                    </div>
                </div>
            </div> -->
            <!-- Pro Tier -->
            <!-- <div class="col-lg-4">
                <div class="card">
                    <div class="card-body">
                        <h5 class="card-title text-muted text-uppercase text-center">Pro</h5>
                        <h6 class="card-price text-center">$49<span class="period">/month</span></h6>
                        <hr>
                        <ul class="fa-ul">
                            <li><span class="fa-li"><i class="pi pi-check"></i></span><strong>Unlimited Users</strong>
                            </li>
                            <li><span class="fa-li"><i class="pi pi-check"></i></span>150GB Storage</li>
                            <li><span class="fa-li"><i class="pi pi-check"></i></span>Unlimited Public Projects</li>
                            <li><span class="fa-li"><i class="pi pi-check"></i></span>Community Access</li>
                            <li><span class="fa-li"><i class="pi pi-check"></i></span>Unlimited Private Projects</li>
                            <li><span class="fa-li"><i class="pi pi-check"></i></span>Dedicated Phone Support</li>
                            <li><span class="fa-li"><i class="pi pi-check"></i></span><strong>Unlimited</strong> Free
                                Subdomains</li>
                            <li><span class="fa-li"><i class="pi pi-check"></i></span>Monthly Status Reports</li>
                        </ul>
                        <a href="#" class="btn btn-block btn-primary text-uppercase">Button</a>
                    </div>
                </div>
            </div> -->
        </div>
    </div>
</section>