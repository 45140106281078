<div [ngClass]="state=='open' ? 'main_content_open' : 'main_content'">
    <p-scrollPanel [style]="{width: '100%',height: '95vh','margin-top':'7px','padding-bottom':'70px'}" #sc>
        <app-message-credit-warning></app-message-credit-warning>
        <app-twillio-message></app-twillio-message>
        <div class="p-d-flex p-jc-between p-ai-center p-pt-3 p-pb-3">
            <div class="page_title">
                Manage Service Provider
            </div>
            <div>
                <p-breadcrumb [model]="items" [home]="home"></p-breadcrumb>
            </div>
        </div>
        <div class="p-mb-5">
            <p-card>
                <div class="toolbar">
                    <div class="p-grid p-ai-center">
                        <div class="p-col p-md-12 p-lg-12 p-jc-end d-flex d-lg-flex d-xl-flex">
                            <button pButton pRipple type="button" label="Add Service Provider" class="p-button-raised"
                                icon="pi pi-plus" style="font-size: 12px;"
                                (click)="showHideModalDialog('add_service')"></button>
                        </div>
                    </div>
                </div>
            </p-card>
            <p-card styleClass="p-mt-3">
                <div class="row form-group">
                    <div class="col-sm-12">
                        <p-table [columns]="cols" [value]="serviceProviderList"
                            styleClass="p-datatable-sm p-datatable-gridlines p-datatable-striped " [autoLayout]='true'
                            [responsive]='true' [rowHover]="true">

                            <ng-template pTemplate="header" let-columns>
                                <tr style="font-size: small;">
                                    <th *ngFor="let col of columns">
                                        {{col.header}}
                                    </th>
                                    <th>
                                        Action
                                    </th>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-service let-columns="columns">
                                <tr style="font-size: smaller;">
                                    <td *ngFor="let col of columns">
                                        <span class="p-column-title">{{col.header}}</span>
                                        {{service[col.field]}}
                                    </td>

                                    <td class="last_border">
                                        <span class="p-column-title">Action</span>
                                        <a *ngIf="!service.canChangeRole; else canEdit" href="javascript:void(0);"
                                            data-toggle="modal" (click)="getServiceProvider(service, 'edit_service')">
                                            <i class="pi pi-pencil" style="font-size: 0.8rem;color:#007AFF"></i>
                                        </a>
                                        <ng-template #canEdit style="display:inline-block;">
                                            <div pTooltip="You cannot edit this serive" tooltipPosition="left"
                                                style="display:inline-block;">
                                                <a href="javascript:void(0);" data-toggle="modal" class="disabledClass">
                                                    <i class="pi pi-pencil" style="font-size: 0.8rem"></i>
                                                </a>
                                            </div>
                                        </ng-template>
                                        &nbsp;
                                        <a *ngIf="!service.canDelete; else canDelete" href="javascript:void(0);"
                                            data-toggle="modal" (click)="confirmDeleteDialog(service)">
                                            <i class="pi pi-trash" style="font-size: 0.8rem;color: #dc3545;"></i>
                                        </a>
                                        <ng-template #canDelete style="display:inline-block;">
                                            <div pTooltip="You cannot delete this service" tooltipPosition="left"
                                                style="display:inline-block;">
                                                <a href="javascript:void(0);" data-toggle="modal" class="disabledClass">
                                                    <i class="pi pi-trash" style="font-size: 0.8rem"></i>
                                                </a>
                                            </div>
                                        </ng-template>
                                    </td>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="emptymessage" let-columns>
                                <tr>
                                    <td [attr.colspan]="columns.length+1">
                                        <div *ngIf="isLoading" class="alignCenter">
                                            <p-progressSpinner [style]="{ width: '40px', height: '40px' }"
                                                strokeWidth="3">
                                            </p-progressSpinner>
                                        </div>
                                        <p-messages key="errMsg" *ngIf="serviceProviderList?.length == 0 && !isLoading"
                                            class="alignCenter">
                                            <ng-template pTemplate>
                                                <i class="pi pi-copy" style="font-size: 2rem"></i>
                                                <div class="p-ml-2">NO DATA FOUND</div>
                                            </ng-template>
                                        </p-messages>
                                    </td>
                                </tr>
                            </ng-template>
                        </p-table>
                        <p-paginator #p [rows]="pageSize" [totalRecords]="totalCount" (onPageChange)="paginate($event)">
                        </p-paginator>
                    </div>
                </div>
            </p-card>
        </div>
    </p-scrollPanel>
</div>



<!-- ADD /EDIT MODAL -->
<p-dialog #addDialog [header]="headerName" [(visible)]="displayAddEditModal" [modal]="true" [baseZIndex]="10000"
    [draggable]="false" [resizable]="true" [maximizable]="true" styleClass="p-fluid" (onHide)="clearFormData()"
    [style]="{width:'60vw'}" (onShow)="showDialogMaximized($event,addDialog)" [contentStyle]="{'overflow':'visible'}">
    <p-messages key="errMsg" [style]="{
      'font-size': '12px'
    }"></p-messages>
    <form role="form" #addServiceForm="ngForm">
        <div class="row">
            <div class="form-group col-md-6" [ngClass]="headerName=='Edit Service Provider' && serviceProviderName =='Any Provider' ? 'disabledClass' : ''">
                <label>Service Provider Name<span class="symbol required"></span></label>
                <input type="text" class="form-control" name="serviceProviderName"
                    placeholder=" Enter Service Provider Name" [(ngModel)]="serviceProviderName" required />
            </div>
            <div #myDiv class="form-group col-md-6">
                <label>Applicable Services<span class="symbol required"></span></label>
                <!-- <input type="number" class="form-control" name="reminderDay" placeholder=" Enter Reminder Day"
                    [(ngModel)]="reminderDay" required /> -->
                <p-multiSelect name="selectedApplicableServices" [(ngModel)]="selectedApplicableServices"
                    [options]="applicableServices" optionLabel="name" [style]="{height:'34px'}"
                    defaultLabel="Select applicable services">
                    <!-- <ng-template pTemplate="header">
                        Select All
                    </ng-template> -->
                </p-multiSelect>
            </div>
        </div>


    </form>

    <ng-template pTemplate="footer">
        <p-button icon="pi pi-times" (click)="displayAddEditModal = false" label="Cancel"
            styleClass="p-button-danger p-button-text"></p-button>
        <p-button *ngIf="headerName=='Add Service Provider'" icon="pi pi-plus" (click)="addService(addServiceForm)"
            label="Add" [disabled]=" disabled">
        </p-button>
        <p-button *ngIf="headerName=='Edit Service Provider'" icon="pi pi-save" (click)="editService(addServiceForm)"
            label="Save" [disabled]="disabled">
        </p-button>
    </ng-template>
    <div *ngIf="isLoading" class="progressLayout">
        <div class="progressContainer">
            <p-progressSpinner [style]="{ width: '40px', height: '40px' }" strokeWidth="3"></p-progressSpinner>
        </div>
    </div>
</p-dialog>

<!-- REMOVE MODAL -->
<p-confirmDialog [baseZIndex]="10000" rejectButtonStyleClass="p-button-text" [style]="{width:'45vw'}">
</p-confirmDialog>

<!-- TOAST -->
<p-toast position=" bottom-right" key="br"></p-toast>