import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthenticationService } from '../services/authentication/authentication.service';
import { Profile } from '../model/profile';
import { ProfileService } from '../services/profile/profile.service';
import { ConfirmationService, MenuItem, MessageService } from 'primeng/api';
import { AppSettings } from '../Constant/AppSettings';
import { UtilsService } from '../services/utils/utils.service';
import { environment } from 'src/environments/environment.prod';
import { CommonFunctions } from '../Utilities/CommonFunctions';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css'],
})
export class ProfileComponent implements OnInit {
  items: MenuItem[] = [{ label: 'Profile' }];
  home: MenuItem = { icon: 'pi pi-home', routerLink: '/home' };
  firstName: string = '';
  lastName: string = '';
  mobile: string = '';
  email = '';
  country = '';
  countries: any[] = [];
  timeoutError = '';
  isLoading: boolean = false;
  disabled: boolean = false;
  isFormValid = false;
  //File upload
  avatarName = '';
  imageInputError: boolean = false;
  profileUrl = '';
  //image cropper
  croppedImageUrl: any = '';
  //Crop Dialog
  displayCropModal: boolean = false

  constructor(
    public utils: UtilsService,
    private profileService: ProfileService,
    private router: Router,
    private messageService: MessageService,
    private confirmationService: ConfirmationService
  ) { }

  ngOnInit(): void {
    this.resetProfile();
    this.getCountries();
  }


  editProfile(profileForm: NgForm) {
    this.showProgressBar();
    if (profileForm.value.firstName === '') {
      this.showErrorBannerMsg('First Name Field is empty.')
      this.hideProgressBar();
      return;
    } if (profileForm.value.lastName === '') {
      this.showErrorBannerMsg('Last Name Field is empty.')
      this.hideProgressBar();
      return;
    } if (profileForm.value.mobile === '') {
      this.showErrorBannerMsg('Mobile Field is empty.')
      this.hideProgressBar();
      return;
    }
    if (this.mobile.length <= 9) {
      this.showErrorBannerMsg('Mobile Number should be 10 digits.')
      this.hideProgressBar();
      return;
    }
    if (profileForm.value.email === '') {
      this.showErrorBannerMsg('Email Field is empty.')
      this.hideProgressBar();
      return;
    } if (profileForm.value.country === '' || profileForm.value.country === 'null') {
      this.showErrorBannerMsg('Country Field is empty.')
      this.hideProgressBar();
      return;
    }
    console.log('Country -->> ' + profileForm.value.country);

    var mob = '+1' + this.mobile;

    console.log('+1', this.mobile)
    // console.log('Mobile --->> ' + mob);
    const profileData = new Profile(
      profileForm.value.firstName,
      profileForm.value.lastName,
      profileForm.value.email,
      mob,
      profileForm.value.country
    );
    var data = { data: JSON.parse(JSON.stringify(profileData)) };
    console.log('Profile Data ---> ' + JSON.stringify(data));
    this.profileService.editProfile(data).subscribe(
      (response) => {
        var json = JSON.parse(JSON.stringify(response));
        console.log('Edit Profile Post Response --->> ' + JSON.stringify(json));
        console.log('Status --->> ' + json.response.status);
        if (json.response.status == 'SUCCESS') {
          this.showSuccessMessage(json.response.status, json.response.displayMessage)
          CommonFunctions.setUser(json.data)
          this.setProfile(json.data)
          this.reloadComponent()
        } else {
          this.showErrorToastMsg(json.response.displayMessage)
        }
        this.hideProgressBar();
      },
      (error) => {
        console.log(error);
        this.timeoutError = error;
        this.showErrorToastMsg(AppSettings.ERROR_MSG)
        this.hideProgressBar();
      }
    );
  }


  getProfile() {
    this.profileService.getProfile().subscribe(
      (response) => {
        var json = JSON.parse(JSON.stringify(response));
        console.log(json.data);
        if (json.response.status == 'SUCCESS') {
          CommonFunctions.setUser(json.data);
          this.setProfile(json.data)
          this.reloadComponent()
        }
      },
      (error) => {
        console.log(error);
        this.timeoutError = error;
        this.showErrorToastMsg(AppSettings.ERROR_MSG)
        this.hideProgressBar();
      }
    );
  }


  getCountries() {
    this.profileService.getCountries().subscribe(
      (response) => {
        var json = JSON.parse(JSON.stringify(response));
        console.log(json.data);
        this.countries = json.data;
      },
      (error) => {
        console.log(error);
        this.timeoutError = error;
        this.showErrorToastMsg(AppSettings.ERROR_MSG)
        this.hideProgressBar();
      }
    );
  }

  uploadProfileLogo() {
    console.log("check");
    console.log(this.croppedImageUrl);
    this.showProgressBar()
    this.profileService.editProfileImage(this.croppedImageUrl).subscribe(
      (res) => {
        console.log(res);
        var json = JSON.parse(JSON.stringify(res));
        if (json.response.status == 'SUCCESS') {
          this.showSuccessMessage(json.response.status, json.response.message)
          this.getProfile()
        } else {
          this.showErrorToastMsg(json.response.displayMessage)
        }
        this.hideProgressBar()
      },
      (err) => {
        console.log(err);
        this.showErrorToastMsg(AppSettings.ERROR_MSG)
        this.hideProgressBar()
      }
    );
  }

  deleteProfileImage() {
    this.showProgressBar()
    this.profileService.deleteProfileImage().subscribe(
      (response) => {
        var json = JSON.parse(JSON.stringify(response));
        console.log('Delete Profile response --> ' + JSON.stringify(json));
        if (json.response.status == 'SUCCESS') {
          this.showSuccessMessage(json.response.status, json.response.displayMessage)
          this.getProfile()
        } else {
          this.showErrorToastMsg(json.response.displayMessage)
        }
        this.hideProgressBar()
      },
      (error) => {
        console.log(error);
        this.showErrorToastMsg(AppSettings.ERROR_MSG)
        this.hideProgressBar();
      }
    );
  }


  confirmDeleteDialog() {
    this.messageService.clear();
    this.confirmationService.confirm({
      message: 'Do you want to delete this image ?',
      header: 'Delete Confirmation',
      icon: 'pi pi-trash',
      accept: () => {
        this.deleteProfileImage();
      }
    });
  }

  resetProfile() {
    var user = CommonFunctions.getUser();
    this.setProfile(user)
  }

  setProfile(profileData: any) {
    var user = profileData;
    console.log(user);
    this.avatarName = user.firstName.charAt(0) + user.lastName.charAt(0);
    this.firstName = user.firstName;
    this.lastName = user.lastName;
    this.mobile = user.mobile.replace('+1', '');
    this.email = user.email;
    this.country = user.country;
    this.profileUrl = environment.API_URL + user.profileImage
    this.imageInputError = false;
  }

  reloadComponent() {
    this.utils.onReloadProfileClick(true);
  }


  fileFailedToLoad(event: any) {
    console.log(event)
    this.imageInputError = true;
    this.profileUrl = 'assets/images/default-user.png'
  }

  hideCropModal(displayCropModal: any) {
    if (displayCropModal !== undefined) {
      this.displayCropModal = displayCropModal;
    }

  }


  croppedImage(imageFile: any) {
    if (imageFile !== undefined) {
      this.croppedImageUrl = imageFile?.croppedImageUrl
      this.profileUrl = imageFile?.base64Value;
      this.imageInputError = false
      this.uploadProfileLogo()
    }
  }

  showErrorBannerMsg(msg: string) {
    this.messageService.add({
      key: 'errMsg',
      severity: 'error',
      detail: msg,
    });
  }


  showErrorToastMsg(msg: string) {
    this.messageService.add({
      key: 'br',
      severity: 'error',
      detail: msg,
    });
  }

  showSuccessMessage(status: string, msg: string) {
    this.messageService.add({
      key: 'br',
      severity: 'success',
      summary: status,
      detail: msg,
    });
  }

  hideProgressBar() {
    this.isLoading = false;
    this.disabled = false;
  }

  showProgressBar() {
    this.messageService.clear();
    this.isLoading = true;
    this.disabled = true;
  }

  validateDigitOnly(event: any) {
    var inp = String.fromCharCode(event.keyCode);
    if (/[0-9]/.test(inp)) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  }

  get state(): string {
    return this.utils.state;
  }
}
