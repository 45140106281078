import { Component, OnInit } from '@angular/core';
import { MenuItem, MessageService, ConfirmationService } from 'primeng/api';
import { ActionButtonService } from '../services/action-button/action-button.service';
import { UtilsService } from '../services/utils/utils.service';
import { AppSettings } from '../Constant/AppSettings';
import { CommonFunctions } from '../Utilities/CommonFunctions';
import { Dialog } from 'primeng/dialog';

@Component({
  selector: 'app-action-button',
  templateUrl: './action-button.component.html',
  styleUrls: ['./action-button.component.css']
})
export class ActionButtonComponent implements OnInit {

  items: MenuItem[] = [{ label: 'Action Buttons' }];
  home: MenuItem = { icon: 'pi pi-home', routerLink: '/home' };
  arrayActionButton: any[] = [''];
  disabled: boolean = false;
  isLoading: boolean = false;
  cols: any[] = [];
  bussinesInfo: any = {};
  headerName: string = '';
  ActionButtonCreateAndUpdate: boolean = false;
  Name: string ='';
  Description: string ='';
  Link: string = '';
  id: number = 0;
  public innerWidth: any;
  button_opitions: any[] = [];
  btntColor: string = '#41b70e';
  ButtonShape: any;

  constructor(
    private utils: UtilsService,
    private actionButtonService: ActionButtonService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
  ) { }

  ngOnInit(): void {
    this.innerWidth = window.innerWidth;
    this.listActionButton();
    this.cols = [
      { field: 'name', header: 'Action Button Name' },
      { field: 'link', header: 'Link' },
      { field: 'description', header: 'Description' },
    ];
    this.bussinesInfo = CommonFunctions.getBusinessInfo();
    this.button_opitions = [
      { name: 'Rounded Rectangle', value: '3' },
      { name: 'Fully Curved Sides', value: 'round' },
    ];
  }

  listActionButton() {
    this.actionButtonService.ActionButtonlistByBusinessId().subscribe(
      (response) => {
        var json = JSON.parse(JSON.stringify(response));
        console.log('Keyword Variables  Response --->> ' + JSON.stringify(json));
        if (json.response.status == 'SUCCESS') {
          this.arrayActionButton = json.data;
        } else {
          this.showErrorMessage(json.response.displayMessage)
        }
      },
      (error) => {
        console.log(error);
        this.showErrorMessage(AppSettings.ERROR_MSG)
        this.hideProgressBar()
      }
    );
  }

  showHideModalActionButtonDialog(type: string) {
    switch (type) {
      case 'add_action_button':
        this.headerName = 'Add Action Button';
        this.ActionButtonCreateAndUpdate = !this.ActionButtonCreateAndUpdate;
        this.Link = '';
        this.Name = '';
        this.Description = '';
        this.ButtonShape = { name: 'Rounded Rectangle', value: '3' };
        this.btntColor = '#41b70e';
        break;      
      case 'update_action_button':
        this.ActionButtonCreateAndUpdate = !this.ActionButtonCreateAndUpdate;
      break;
      }
  }

  getActionButton(act_button: any, type: string) {
    this.headerName = 'Edit Action Button';
    this.Link = act_button.link;
    this.id = act_button.id
    this.Name = act_button.name;
    this.Description = act_button.description;
    this.btntColor = '#'+act_button.backgroundColour;
    if(act_button.buttonShape == 3){
      this.ButtonShape = { name: 'Rounded Rectangle', value: '3' };
    }else{
      this.ButtonShape = { name: 'Fully Curved Sides', value: 'round' }
    }
    this.showHideModalActionButtonDialog(type)
  }

  
  AddAndUpdateActionButtonKeyword(type:any){
    
    this.messageService.clear();
    
    if(this.Name == '' || this.Name == undefined){
      this.showErrorMessage("Name is required.");
      return;
    }
    
    if(this.Link == '' || this.Link == undefined){
      this.showErrorMessage("Link is required.");
      return;
    }

    if(!CommonFunctions.validURL(this.Link)) {
      this.showErrorMessage("Link is invalid.");
      return;
    }

    if(this.Description == '' || this.Description == undefined){
      this.showErrorMessage("Description is required.");
      return;
    }

    this.showProgressBar();
    var shapeSet = this.ButtonShape?.value ? this.ButtonShape?.value : 3;

    let setURL = this.Link;
    if(!CommonFunctions.validSecureURL(this.Link)){
      setURL = "https://"+this.Link;
    }
    var Data = {
      link: this.Link,
      name: this.Name.toUpperCase(),
      description: this.Description,
      rawHtmlLink: '<a href="'+setURL+'" rel="noopener noreferrer" target="_blank"><img src="https://dabuttonfactory.com/button.png?t='+this.Name.toUpperCase()+'&f=Open+Sans-Bold&ts=10&tc=fff&hp=10&vp=10&c='+shapeSet+'&bgt=unicolored&bgc='+this.btntColor.substring(1)+'"></a>',
      backgroundColour: this.btntColor.substring(1),
      buttonShape: this.ButtonShape?.value ? this.ButtonShape?.value : 3
    }
    var data = { data: JSON.parse(JSON.stringify(Data)) };
    if(type == "add_action_button") {

      console.log('Create Form Data --->> ' + JSON.stringify(data));
      this.actionButtonService.addActionButton(data).subscribe(
        (response) => {
          var json = JSON.parse(JSON.stringify(response));
          if (json.response.status == 'SUCCESS') {
            this.showHideModalActionButtonDialog('add_action_button')
            this.listActionButton();
            this.showSuccessMessage(json.response.status, json.response.message)
          } else {
            this.showErrorMessage(json.response.displayMessage)
          }
          this.hideProgressBar();
        },
        (error) => {
          this.showErrorMessage(AppSettings.ERROR_MSG)
          this.hideProgressBar();
        }
        );
      }
      
      if(type == "update_action_button") {
      console.log('Update Form Data --->> ' + JSON.stringify(data));
      this.actionButtonService.editActionButton(this.id, data).subscribe(
        (response) => {
          var json = JSON.parse(JSON.stringify(response));
          if (json.response.status == 'SUCCESS') {
            this.showHideModalActionButtonDialog('add_action_button')
            this.listActionButton();
            this.showSuccessMessage(json.response.status, json.response.message)
          } else {
            this.showErrorMessage(json.response.displayMessage)
          }
          this.hideProgressBar();
        },
        (error) => {
          this.showErrorMessage(AppSettings.ERROR_MSG)
          this.hideProgressBar();
        }
      );
    }
    
  }

  editEnableDisable(value: any, keyword: any){
    this.showProgressBar();
    console.log(value, ',', keyword.id)
    this.actionButtonService.enableDisable(keyword.id, value).subscribe(
      (response) => {
        var json = JSON.parse(JSON.stringify(response));
        console.log('Enable/Disable Keyword Response --->> ' + JSON.stringify(json));
        console.log('Status --->> ' + json.response.status);
        if (json.response.status == 'SUCCESS') {
          this.showSuccessMessage(json.response.status, json.response.message)
        } else {
          this.showErrorMessage(json.response.displayMessage)
        }
        this.hideProgressBar();
      },
      (error) => {
        console.log(error);
        this.showErrorMessage(AppSettings.ERROR_MSG)
        this.hideProgressBar();
      }
    );
  }

  removeActionButtonConfirmDialog(id: any) {
    this.messageService.clear();
    this.confirmationService.confirm({
      message: 'Do you want to delete this action button?',
      header: 'Delete Confirmation',
      icon: 'pi pi-trash',
      accept: () => {
        this.removeActionButton(id);
      }
    });
  }

  removeActionButton(id:any){
    this.actionButtonService.removeActionButton(id).subscribe(
      (response) => {
        var json = JSON.parse(JSON.stringify(response));
        console.log('Enable/Disable Keyword Response --->> ' + JSON.stringify(json));
        console.log('Status --->> ' + json.response.status);
        if (json.response.status == 'SUCCESS') {
          this.showSuccessMessage(json.response.status, json.response.message);
          this.listActionButton();
        } else {
          this.showErrorMessage(json.response.displayMessage)
        }
        this.hideProgressBar();
      },
      (error) => {
        console.log(error);
        this.showErrorMessage(AppSettings.ERROR_MSG)
        this.hideProgressBar();
      }
    );
  }

  showDialogMaximized(event: any, dialog: Dialog) {
    if (this.innerWidth <= 640) {
      dialog.maximized = true;
    }
  }


  showProgressBar() {
    this.messageService.clear();
    this.isLoading = true;
    this.disabled = true;
  }

  hideProgressBar() {
    this.isLoading = false;
    this.disabled = false;
  }


  showErrorMessage(msg: string) {
    this.messageService.add({
      key: 'errMsg',
      severity: 'error',
      detail: msg,
    });
  }

  showErrorToastMsg(msg: string) {
    this.messageService.add({
      key: 'br',
      severity: 'error',
      detail: msg,
    });
  }

  showSuccessMessage(status: string, msg: string) {
    this.messageService.add({
      key: 'br',
      severity: 'success',
      summary: status,
      detail: msg,
    });
  }

  onToggle(event: any, keyword: any) {
    this.editEnableDisable(event.checked, keyword);
  }

  clearFormData() {
    this.messageService.clear('errMsg');
  }

  get state(): string {
    return this.utils.state;
  }

}
