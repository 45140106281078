<div [ngClass]="state=='open' ? 'main_content_open' : 'main_content'">
    <p-scrollPanel [style]="{width: '100%',height: '95vh','margin-top':'7px','padding-bottom':'70px'}" #sc>
        <app-message-credit-warning></app-message-credit-warning>
        <app-twillio-message></app-twillio-message>
        <div class="p-d-flex p-jc-between p-ai-center p-pt-3 p-pb-3">
            <div class="page_title">
                Message Bot Configuration
            </div>
            <div>
                <p-breadcrumb [model]="items" [home]="home"></p-breadcrumb>
            </div>
        </div>
        <div class="p-mb-5">
            <p-card header="After-hours Message"
                subheader="Your clients will receive this automated message outside of your business hours.">
                <!-- header="After Hour Message" -->
                <!-- subheader="Your customers will receive below message when client communication is deactivated." -->
                <form role="form" (ngSubmit)="saveMessage('AFTER_HOUR',editAfterHourMsgForm)"
                    #editAfterHourMsgForm="ngForm">
                    <div class="p-grid">
                        <div class="p-col-8">
                            <textarea pInputTextarea type="text" class="form-control" placeholder="Default Text"
                                [rows]="5" [cols]="30" [(ngModel)]="afterHourMsg" [disabled]="disabledAfterHour"
                                name="Msg" required></textarea>
                        </div>
                        <div class="p-col-4">
                            <div class="p-d-flex p-flex-column p-ai-center">
                                <p-selectButton [options]="stateOptions" [(ngModel)]="afterHourValue"
                                    optionLabel="label" optionValue="value" name="Value"
                                    (onChange)="activateDeactivateMessage(afterHourValue,'AFTER_HOUR')">
                                </p-selectButton>
                                <!-- <div *ngIf="!disabledAfterHour" style="text-align: center;margin-top: 8px;">
                                    Your customers will receive this message in off hours.
                                </div>
                                <div *ngIf="disabledAfterHour" style="text-align: center;margin-top: 8px;">
                                    Your customers will not receive any message in off hours.
                                </div> -->
                            </div>

                        </div>

                    </div>

                </form>
                <ng-template pTemplate="footer">
                    <p-progressSpinner *ngIf="isLoadingAfterHour" styleClass="p-mr-4"
                        [style]="{ width: '35px', height: '35px' }" strokeWidth="3">
                    </p-progressSpinner>
                    <button pButton pRipple label="Reset to Default" class="p-button-text p-button-plain"
                        (click)="resetMessage('AFTER_HOUR')" [disabled]="disabledAfterHour"></button>
                    <button pButton pRipple label="Save" class="p-button-raised" [style]="{'margin-left': '.5em'}"
                        (click)="saveMessage('AFTER_HOUR',editAfterHourMsgForm)"
                        [disabled]="disabledAfterHour"></button>
                </ng-template>

            </p-card>
            <div class="p-mt-4">
                <p-card header="Special Occasion Message"
                    subheader="When activated, your clients will receive this automated message in response to their message.">
                    <!-- header="Special Occassion Message" -->
                    <!-- subheader="Your customers will receive below message for special occassions instead of after hour message." -->
                    <form role="form" (ngSubmit)="saveMessage('SPECIAL_OCCASION',editSpecialOccassionMsgForm)"
                        #editSpecialOccassionMsgForm="ngForm">
                        <div class="p-grid">
                            <div class="p-col-8">
                                <textarea pInputTextarea type="text" class="form-control" placeholder="Default Text"
                                    [rows]="5" [cols]="30" [(ngModel)]="specialOccassionMsg"
                                    [disabled]="disabledSpecialOccassion" name="Msg" required></textarea>
                            </div>
                            <div class="p-col-4">
                                <div class="p-d-flex p-flex-column p-ai-center">
                                    <p-selectButton [options]="stateOptions" [(ngModel)]="specialOccassionValue"
                                        optionLabel="label" optionValue="value" name="Value"
                                        (onChange)="activateDeactivateMessage(specialOccassionValue,'SPECIAL_OCCASION')">
                                    </p-selectButton>
                                    <div *ngIf="!disabledSpecialOccassion" style="text-align: center;margin-top: 8px;">
                                        Your customers will receive this message when they send you any message
                                        till
                                        this is active.
                                    </div>
                                </div>

                            </div>

                        </div>
                    </form>
                    <ng-template pTemplate="footer">
                        <p-progressSpinner *ngIf="isLoadingSpecialOccassion" styleClass="p-mr-4"
                            [style]="{ width: '35px', height: '35px' }" strokeWidth="3">
                        </p-progressSpinner>
                        <button pButton pRipple label="Reset to Default" class="p-button-text p-button-plain"
                            (click)="resetMessage('SPECIAL_OCCASION')" [disabled]="disabledSpecialOccassion"></button>
                        <button pButton pRipple label="Save" class="p-button-raised" [style]="{'margin-left': '.5em'}"
                            (click)="saveMessage('SPECIAL_OCCASION',editSpecialOccassionMsgForm)"
                            [disabled]="disabledSpecialOccassion"></button>
                    </ng-template>

                </p-card>
            </div>

            <div class="p-mt-4">
                <p-card header="Unknown Client Message"
                    subheader="Messages from clients who are new to your business will receive this message as a response.">
                    <!-- header="Unknow Client" -->
                    <!-- subheader="Your customers will receive below message for when unknow client sent message to you." -->
                    <form role="form" (ngSubmit)="saveMessage('UNKNOWN_CLIENT',editUnknowClientMsgForm)"
                        #editUnknowClientMsgForm="ngForm">
                        <div class="p-grid">
                            <div class="p-col-8">
                                <textarea pInputTextarea type="text" class="form-control" placeholder="Default Text"
                                    [rows]="5" [cols]="30" [(ngModel)]="unknowclientMsg"
                                    [disabled]="disabledUnknowClient" name="Msg" required></textarea>
                            </div>
                            <div class="p-col-4">
                                <div class="p-d-flex p-flex-column p-ai-center">
                                    <p-selectButton [options]="stateOptions" [(ngModel)]="unknowclientValue"
                                        optionLabel="label" optionValue="value" name="Value"
                                        (onChange)="activateDeactivateMessage(unknowclientValue,'UNKNOWN_CLIENT')">
                                    </p-selectButton>
                                    <div *ngIf="!disabledUnknowClient" style="text-align: center;margin-top: 8px;">
                                        Unknown client will receive this message when they send you any message till this is active.
                                    </div>
                                </div>

                            </div>

                        </div>
                    </form>
                    <ng-template pTemplate="footer">
                        <p-progressSpinner *ngIf="isLoadingUnknowClient" styleClass="p-mr-4"
                            [style]="{ width: '35px', height: '35px' }" strokeWidth="3">
                        </p-progressSpinner>
                        <button pButton pRipple label="Reset to Default" class="p-button-text p-button-plain"
                            (click)="resetMessage('UNKNOWN_CLIENT')" [disabled]="disabledUnknowClient"></button>
                        <button pButton pRipple label="Save" class="p-button-raised" [style]="{'margin-left': '.5em'}"
                            (click)="saveMessage('UNKNOWN_CLIENT',editUnknowClientMsgForm)"
                            [disabled]="disabledUnknowClient"></button>
                    </ng-template>

                </p-card>
            </div>
        </div>
    </p-scrollPanel>
</div>

<!-- TOAST -->
<p-toast position="bottom-right" key="br"></p-toast>