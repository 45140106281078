import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { MessageService } from 'primeng/api';
import { Dialog } from 'primeng/dialog';
import { CommonFunctions } from 'src/app/Utilities/CommonFunctions';

@Component({
  selector: 'app-image-cropper-dialog',
  templateUrl: './image-cropper-dialog.component.html',
  styleUrls: ['./image-cropper-dialog.component.css']
})
export class ImageCropperDialogComponent implements OnInit {
  @ViewChild('imageInput') imageInput!: ElementRef;
  @Input() displayCropModal: boolean = false;
  @Output() hideCropModal: any = new EventEmitter();
  @Output() croppedImage: any = new EventEmitter();
  //image cropper
  maintainAspectRatio: boolean = true;
  disabled: boolean = false
  imageChangedEvent: any = '';
  croppedImageUrl: any = '';
  imageFile: any = undefined;
  innerWidth: any;

  constructor(
    private router: Router,
    private messageService: MessageService,
  ) { }

  ngOnInit(): void {
    this.innerWidth = window.innerWidth;
    if (this.router.url == '/chat-room-configuration' || this.router.url == '/registration' || this.router.url == '/business-information') {
      this.maintainAspectRatio = false;
    }
  }

  isFileValid(fileInput: any) {
    const file = fileInput.target.files[0];
    var size = file.size;
    var name = file.name;
    var extension = name.substring(name.lastIndexOf('.') + 1);
    if (extension == 'jpg' || extension == 'jpeg' || extension == 'png') {
      if (size > 2000000) {
        let finalName = name;
        if (name.length > 20) {
          finalName = name.substring(0, 20) + '...' + extension;
        }
        this.showErrorMessage(finalName + ' File should not be more than 2 MB!')
        return false
      } else {
        return true
      }
    } else {
      this.showErrorMessage('Invalid file, please select only jpeg, jpg, png')
      return false
    }
  }
  selectFile(imageInput: any): void {
    this.messageService.clear('errMsgCropper');
    if (this.isFileValid(imageInput)) {
      this.imageChangedEvent = imageInput;
      this.disabled = false;
    } else {
      this.disabled = true;
      this.imageInput!.nativeElement.value = "";
    }
  }
  imageCropped(event: ImageCroppedEvent) {
    // this.profileUrl = event.base64 || '';
    // Preview
    this.croppedImageUrl = CommonFunctions.base64ToFile(
      event.base64,
      this.imageChangedEvent.target.files[0].name,
    )
    this.imageFile = {
      base64Value: event.base64 || '',
      croppedImageUrl: this.croppedImageUrl,
      width: event.width,
      height: event.height
    };
  }
  imageLoaded() {
    // show cropper
  }
  cropperReady() {
    // cropper ready
  }
  loadImageFailed() {
    // show message
  }

  clearFormData() {
    this.messageService.clear('errMsgCropper');
    this.imageChangedEvent = null
    this.imageInput!.nativeElement.value = "";
    this.hideCropModal.emit(this.displayCropModal)
  }

  setImageToProfile() {
    this.croppedImage.emit(this.imageFile)
    this.displayCropModal = false
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.innerWidth = window.innerWidth;
  }

  showErrorMessage(msg: string) {
    this.messageService.add({
      key: 'errMsgCropper',
      severity: 'error',
      detail: msg,
    });
  }

  showDialogMaximized(event: any, dialog: Dialog) {
    if (this.innerWidth <= 640) {
      dialog.maximized = true;
    }

  }
}
