<div [ngClass]="state=='open' ? 'main_content_open' : 'main_content'">
  <p-scrollPanel [style]="{width: '100%',height: '95vh','margin-top':'7px','padding-bottom':'70px'}" #sc>
    <app-message-credit-warning></app-message-credit-warning>
    <app-twillio-message></app-twillio-message>
    <div class="p-d-flex p-jc-between p-ai-center p-pt-3 p-pb-3">
      <div class="page_title">
        Profile
      </div>
      <div>
        <p-breadcrumb [model]="items" [home]="home"></p-breadcrumb>
      </div>
    </div>
    <div class="p-mb-5">
      <p-card>
        <div class="fileinput fileinput-new text-center" data-provides="fileinput">
          <div class="user-image mt-2">
            <div class="fileinput-new img-thumbnail">
              <img style="width: 100px;height: 100px;" [src]="profileUrl" alt="" (error)="fileFailedToLoad($event)" />
              <!-- <p-avatar *ngIf="imageInputError" [label]="avatarName" [style]="{width: '100px',height: '100px'}"
                    size="xlarge">
                  </p-avatar> -->
            </div>
            <div class=" user-image-buttons">
              <span class="btn btn-azure btn-file btn-sm" (click)="displayCropModal = true">
                <span class="fileinput-new">
                  <i class="fa fa-pencil"></i>
                </span>
              </span>
              <a *ngIf="!imageInputError" href="javascript:void(0)" (click)="confirmDeleteDialog()"
                class="btn fileinput-exists btn-red btn-sm">
                <i class="fa fa-times"></i>
              </a>
            </div>
          </div>
        </div>
        <div class="container-fluid bg-white">
          <div class="row">
            <div class="col-md-6">
              <fieldset>
                <legend>Personal Information</legend>
                <p-messages key="errMsg" [style]="{ 'font-size': '12px' }"></p-messages>
                <form role="form" class="form-horizontal" (ngSubmit)="editProfile(profileForm)" id="updateProfile"
                  #profileForm="ngForm">

                  <div class="row form-group">
                    <label class="col-sm-4 control-label">First Name<span class="symbol required"></span></label>
                    <div class="col-sm-8">
                      <input type="text" name="firstName" class="form-control" placeholder="Enter First Name"
                        [(ngModel)]="firstName" [disabled]="disabled" required />
                    </div>
                  </div>
                  <div class="row form-group">
                    <label class="col-sm-4 control-label">Last Name<span class="symbol required"></span></label>
                    <div class="col-sm-8">
                      <input type="text" name="lastName" class="form-control" placeholder="Enter Last Name"
                        [(ngModel)]="lastName" [disabled]="disabled" required />
                    </div>
                  </div>
                  <div class="row form-group">
                    <label class="col-sm-4 control-label">Email<span class="symbol required"></span></label>
                    <div class="col-sm-8">
                      <input type="email" placeholder="Enter Email" id="email" name="email" class="form-control"
                        [(ngModel)]="email" [disabled]="disabled" required />
                    </div>
                  </div>
                  <div class="row form-group">
                    <label class="col-sm-4 control-label">Mobile<span class="symbol required"></span></label>
                    <div class="col-sm-8">
                      <span class="input-icon"><input type="text" class="form-control" placeholder="Enter Mobile"
                          name="mobile" [(ngModel)]="mobile" [disabled]="disabled" maxlength="10"
                          (keypress)="validateDigitOnly($event)">
                        <span class="number">+1</span></span>
                    </div>
                  </div>
                  <div class="row form-group">
                    <label class="col-sm-4 control-label">Country<span class="symbol required"></span></label>
                    <div class="col-sm-8">
                      <select [(ngModel)]="country" name="country" class="form-control" style="height: 34px;"
                        [disabled]="disabled">
                        <option [value]="null" disabled>Select Country<span class="symbol required"></span></option>
                        <option *ngFor="let country of countries" [value]="country.isoCode">
                          {{ country.name }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="form-group center mt-2">
                    <p-progressSpinner *ngIf="isLoading" [style]="{ width: '40px', height: '40px' }" strokeWidth="3"
                      class="p-mr-4">
                    </p-progressSpinner>
                    <button pButton pRipple type="button" label="Reset" class="p-button-text p-button-plain  p-mr-4"
                      (click)="resetProfile()"></button>

                    <button pButton pRipple type="button" class="p-button-raised " (click)="editProfile(profileForm)"
                      label="Save" [disabled]="disabled"></button>
                  </div>
                </form>
              </fieldset>
            </div>
            <div class="col-md-6">
              <fieldset>
                <legend>Choose a password</legend>
                <app-reset-password></app-reset-password>
              </fieldset>
            </div>
          </div>
        </div>
      </p-card>
    </div>
  </p-scrollPanel>
</div>

<!-- REMOVE PROFILE MODAL -->
<p-confirmDialog [baseZIndex]="10000" rejectButtonStyleClass="p-button-text" [style]="{width:'45vw'}">
</p-confirmDialog>

<!-- CROP MODAL -->
<app-image-cropper-dialog #renew [displayCropModal]="displayCropModal" (hideCropModal)="hideCropModal($event)"
  (croppedImage)="croppedImage($event)">
</app-image-cropper-dialog>