import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { ProfileService } from '../services/profile/profile.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css'],
  providers: [MessageService],
})
export class ResetPasswordComponent implements OnInit {
  constructor(
    private profileService: ProfileService,
    private router: Router,
    private messageService: MessageService
  ) { }
  isLoading: boolean = false;
  disabled: boolean = false;

  ngOnInit(): void { }
  timeoutError = '';

  reset(resetPasswordForm: NgForm) {
    this.showProgressBar();
    if (resetPasswordForm.value.profileNewPassword.length === 0) {
      this.messageService.add({
        severity: 'error',
        detail: 'Please enter a new password.',
      });
      this.hideProgressBar();
      return;
    } else if (
      resetPasswordForm.value.profileNewPassword !== resetPasswordForm.value.profileConfirmPassword
    ) {
      this.messageService.add({
        severity: 'error',
        detail: 'Passwords do not match.',
      });
      this.hideProgressBar();
      return;
    }
    const pass = new Object(resetPasswordForm.value.profileConfirmPassword);
    console.log(pass.toString);
    var passObj = { password: pass };
    var data = { data: JSON.parse(JSON.stringify(passObj)) };
    this.profileService.resetPassword(data).subscribe(
      (response) => {
        var json = JSON.parse(JSON.stringify(response));
        console.log(json);
        if (json.response.status == 'SUCCESS') {
          this.messageService.add({
            key: 'br',
            severity: 'success',
            summary: json.response.status,
            detail: json.response.message,
          });
          resetPasswordForm.reset();
          this.router.navigate(['/profile']);
        } else {
          this.messageService.add({
            severity: 'error',
            detail: json.response.displayMessage,
          });
        }
        this.hideProgressBar();
      },
      (error) => {
        console.log('There was an error');
        console.log(error);
        this.timeoutError = error;
      }
    );
  }

  hideProgressBar() {
    this.isLoading = false;
    this.disabled = false;
  }

  showProgressBar() {
    this.messageService.clear();
    this.isLoading = true;
    this.disabled = true;
  }
}
