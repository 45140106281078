import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { timeout } from 'rxjs/operators';
import { CommonFunctions } from 'src/app/Utilities/CommonFunctions';
import { environment } from 'src/environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class AppointmentService {

  constructor(
    private http: HttpClient
  ) { }

  listAppointment(beginOfWeek: any, endOfWeek: any, serviceType: any, serviceProvider: any) {
    let params = new HttpParams()
      .set('scheduledOn', beginOfWeek + ',' + endOfWeek);
    if (serviceType != null && serviceType != '') {
      params = params.set('serviceType', serviceType);
    }
    if (serviceProvider != null && serviceProvider != '') {
      params = params.set('serviceProvider', serviceProvider);
    }

    const url = environment.API_URL + '/api/appointment';
    const TOKEN = CommonFunctions.getToken();
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + TOKEN,
        'Content-Type': 'application/json',
        'BusinessId': CommonFunctions.getBusinessId().toString()
      }),
      params: params,
    };
    return this.http.get(url, httpOptions).pipe(timeout(60000));
  }



  getAppointmentFilterOptions() {
    const url = environment.API_URL + '/api/appointment/filterOption';
    const TOKEN = CommonFunctions.getToken();
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + TOKEN,
        'Content-Type': 'application/json',
        'BusinessId': CommonFunctions.getBusinessId().toString()
      }),
    };
    return this.http.get(url, httpOptions).pipe(timeout(60000));
  }

  getAppointment(appointment_id: string) {
    const url = environment.API_URL + '/api/appointment/' + appointment_id;
    const TOKEN = CommonFunctions.getToken();
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + TOKEN,
        'Content-Type': 'application/json',
        'BusinessId': CommonFunctions.getBusinessId().toString()
      }),
    };
    return this.http.get(url, httpOptions).pipe(timeout(60000));
  }


  addAppointment(data: any, notify: boolean) {
    const url = environment.API_URL + '/api/appointment/add?notify=' + notify;
    // console.log('Appointment add Data ---> ' + JSON.stringify(businessHour));
    const TOKEN = CommonFunctions.getToken();
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + TOKEN,
        'Content-Type': 'application/json',
        'BusinessId': CommonFunctions.getBusinessId().toString()
      }),
    };
    return this.http.post(url, data, httpOptions).pipe(timeout(60000));
  }

  editAppointment(appointment_id: any, data: any, notify: boolean) {
    const url = environment.API_URL + '/api/appointment/' + appointment_id + '/update?notify=' + notify;
    // console.log('Appointment edit Data ---> ' + JSON.stringify(businessHour));
    const TOKEN = CommonFunctions.getToken();
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + TOKEN,
        'Content-Type': 'application/json',
        'BusinessId': CommonFunctions.getBusinessId().toString()
      }),
    };
    return this.http.post(url, data, httpOptions).pipe(timeout(60000));
  }

  cancelAppointment(appointment_id: any, notify: boolean) {
    const url = environment.API_URL + '/api/appointment/' + appointment_id + '/cancel?notify=' + notify;
    const TOKEN = CommonFunctions.getToken();
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + TOKEN,
        'Content-Type': 'application/json',
        'BusinessId': CommonFunctions.getBusinessId().toString()
      }),
    };
    return this.http.post(url, null, httpOptions).pipe(timeout(60000));
  }

  listBlockedSlots() {
    const url = environment.API_URL + '/api/blockSlots/';
    const TOKEN = CommonFunctions.getToken();
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + TOKEN,
        'Content-Type': 'application/json',
        'BusinessId': CommonFunctions.getBusinessId().toString()
      }),
    };
    return this.http.get(url, httpOptions).pipe(timeout(60000));
  }

  getBlockSlot(slot_id: any) {
    const url = environment.API_URL + '/api/blockSlots/' + slot_id;
    const TOKEN = CommonFunctions.getToken();
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + TOKEN,
        'Content-Type': 'application/json',
        'BusinessId': CommonFunctions.getBusinessId().toString()
      }),
    };
    return this.http.get(url, httpOptions).pipe(timeout(60000));
  }

  addBlockSlot(data: any) {
    const url = environment.API_URL + '/api/blockSlots/add';
    const TOKEN = CommonFunctions.getToken();
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + TOKEN,
        'Content-Type': 'application/json',
        'BusinessId': CommonFunctions.getBusinessId().toString()
      }),
    };
    return this.http.post(url, data, httpOptions).pipe(timeout(60000));
  }

  unblockSlot(slot_id: any) {
    const url = environment.API_URL + '/api/blockSlots/' + slot_id + '/unblock';
    const TOKEN = CommonFunctions.getToken();
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + TOKEN,
        'Content-Type': 'application/json',
        'BusinessId': CommonFunctions.getBusinessId().toString()
      }),
    };
    return this.http.post(url, null, httpOptions).pipe(timeout(60000));
  }

  checkConflict(startDate: any, endDate: any, serviceType: any, serviceProvider: any) {
    let params = new HttpParams()
      .set('startDate', startDate);
    params = params.set('endDate', endDate);
    params = params.set('serviceType', serviceType);
    params = params.set('serviceProvider', serviceProvider);
    const url = environment.API_URL + '/api/appointment/checkConflict';
    const TOKEN = CommonFunctions.getToken();
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + TOKEN,
        'Content-Type': 'application/json',
        'BusinessId': CommonFunctions.getBusinessId().toString()
      }),
      params: params
    };
    return this.http.post(url, null, httpOptions).pipe(timeout(60000));
  }

  cancelAllAppointment(startDate: any, endDate: any, notify: boolean) {
    let params = new HttpParams()
      .set('startDate', startDate);
    params = params.set('endDate', endDate);
    params = params.set('notify', notify.toString());
    const url = environment.API_URL + '/api/appointment/cancelAll';
    const TOKEN = CommonFunctions.getToken();
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + TOKEN,
        'Content-Type': 'application/json',
        'BusinessId': CommonFunctions.getBusinessId().toString()
      }),
      params: params
    };
    return this.http.post(url, null, httpOptions).pipe(timeout(60000));
  }

  sendReminder(appointment_id: any) {
    let params = new HttpParams()
      .set('id', appointment_id);
    const url = environment.API_URL + '/api/appointment/sendReminder';
    const TOKEN = CommonFunctions.getToken();
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + TOKEN,
        'Content-Type': 'application/json',
        'BusinessId': CommonFunctions.getBusinessId().toString()
      }),
      params: params
    };
    return this.http.post(url, null, httpOptions).pipe(timeout(60000));
  }

  ListTestAppointment(){
    const url = environment.API_URL + '/api/appointment/listByTestAppointment';
    const TOKEN = CommonFunctions.getToken();
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + TOKEN,
        'Content-Type': 'application/json',
        'BusinessId': CommonFunctions.getBusinessId().toString()
      })
    };
    return this.http.get(url, httpOptions).pipe(timeout(60000));
  }

  removeTestAppointment(){
    const url = environment.API_URL + '/api/appointment/removeTestAppointment';
    const TOKEN = CommonFunctions.getToken();
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + TOKEN,
        'Content-Type': 'application/json',
        'BusinessId': CommonFunctions.getBusinessId().toString()
      })
    };
    return this.http.get(url, httpOptions).pipe(timeout(60000));
  }

  getAppointmentDetailsByID(appointmentID: any) {
    const url = environment.API_URL + '/api/appointment/' + appointmentID + '/getbyappointmentid';
    const TOKEN = CommonFunctions.getToken();
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + TOKEN,
        'Content-Type': 'application/json'
      }),
    };
    return this.http.get(url, httpOptions).pipe(timeout(60000));
  }

  confirmAppointment(appointment_id: any, notify: boolean, actionType: any) {
    const url = environment.API_URL + '/api/appointment/' + appointment_id + '/confirmappointment?notify=' + notify+'&actionType=' +actionType;
    const TOKEN = CommonFunctions.getToken();
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + TOKEN,
        'Content-Type': 'application/json',
        'BusinessId': CommonFunctions.getBusinessId().toString()
      }),
    };
    return this.http.get(url, httpOptions).pipe(timeout(60000));
  }

}
