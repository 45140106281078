import { Component, OnInit } from '@angular/core';
import { MenuItem, MessageService, ConfirmationService } from 'primeng/api';
import { Dialog } from 'primeng/dialog';
import { exit } from 'process';
import { AppSettings } from '../Constant/AppSettings';
import { BusinessKeywordsService } from '../services/business-keywords/business-keywords.service';
import { UtilsService } from '../services/utils/utils.service';
import { CommonFunctions } from '../Utilities/CommonFunctions';
import { element } from 'protractor';

@Component({
  selector: 'app-business-keywords',
  templateUrl: './business-keywords.component.html',
  styleUrls: ['./business-keywords.component.css']
})
export class BusinessKeywordsComponent implements OnInit {
  items: MenuItem[] = [{ label: 'Business Keywords' }];
  home: MenuItem = { icon: 'pi pi-home', routerLink: '/home' };
  disabled: boolean = false;
  isLoading: boolean = false;
  enableDisable: boolean = false
  keywordsList: any[] = []
  cols: any[] = [];
  //Dialog
  id: number = 0
  displayAddEditModal: boolean = false;
  headerName: string = '';

  disabled_AUTOMATIC_Msg: boolean = false;
  AUTOMATIC_Msg: string = '';
  error_AUTOMATIC_Msg: boolean = false;
  arrayVariables: any[] = [''];

  public innerWidth: any;
  keywordCreateAndUpdate: boolean = false;
  Name: string ='';
  Description: string ='';
  bussinesInfo: any = {};
  keywordExistingList: any[] = [
    'KEYWORDS',
    'NEW APPOINTMENT',
    'MY APPOINTMENTS',
    'CHANGE APPOINTMENT',
    'CANCEL APPOINTMENT',
    'ADDRESS',
    'LOCATION',
    'DIRECTION',
    'HOURS',
    'SERVICES',
    'SUBSCRIBE'
  ];

  constructor(
    private utils: UtilsService,
    private messageService: MessageService,
    private businessKeywordsService: BusinessKeywordsService,
    private confirmationService: ConfirmationService
  ) { }

  ngOnInit(): void {
    this.innerWidth = window.innerWidth;
    this.cols = [
      { field: 'name', header: 'Keyword Name' },
      { field: 'description', header: 'Description' },
    ]
    this.listKeywords()
    this.listMessagesVariables();
    this.bussinesInfo = CommonFunctions.getBusinessInfo();
  }

  editAutoMsg() {
    this.showProgressBar();
    var Data = {
      message: this.AUTOMATIC_Msg
    }
    var data = { data: JSON.parse(JSON.stringify(Data)) };
    console.log('Data --->> ' + JSON.stringify(data));
    this.businessKeywordsService.editKeywordMsg(this.id, data).subscribe(
      (response) => {
        var json = JSON.parse(JSON.stringify(response));
        console.log('Edit Keyword Response --->> ' + JSON.stringify(json));
        console.log('Status --->> ' + json.response.status);
        if (json.response.status == 'SUCCESS') {
          this.showHideModalDialog('edit_msg')
          this.listKeywords()
          this.showSuccessMessage(json.response.status, json.response.message)
        } else {
          this.showErrorMessage(json.response.displayMessage)
        }
        this.hideProgressBar();
      },
      (error) => {
        console.log(error);
        this.showErrorMessage(AppSettings.ERROR_MSG)
        this.hideProgressBar();
      }
    );
  }

  editEnableDisable(value: any, keyword: any) {
    this.showProgressBar();
    console.log(value, ',', keyword.id)
    this.businessKeywordsService.enableDisable(keyword.id, value).subscribe(
      (response) => {
        var json = JSON.parse(JSON.stringify(response));
        console.log('Enable/Disable Keyword Response --->> ' + JSON.stringify(json));
        console.log('Status --->> ' + json.response.status);
        if (json.response.status == 'SUCCESS') {
          this.showSuccessMessage(json.response.status, json.response.message)
        } else {
          this.showErrorMessage(json.response.displayMessage)
        }
        this.hideProgressBar();
      },
      (error) => {
        console.log(error);
        this.showErrorMessage(AppSettings.ERROR_MSG)
        this.hideProgressBar();
      }
    );
  }

  listMessagesVariables() {
    this.businessKeywordsService.listVariables().subscribe(
      (response) => {
        var json = JSON.parse(JSON.stringify(response));
        console.log('Keyword Variables  Response --->> ' + JSON.stringify(json));
        if (json.response.status == 'SUCCESS') {
          this.arrayVariables = [];
          json.data.forEach((element: string) => {
            this.arrayVariables.push(element.replace('$', ''))
          });
        } else {
          this.showErrorMessage(json.response.displayMessage)
        }
      },
      (error) => {
        console.log(error);
        this.showErrorMessage(AppSettings.ERROR_MSG)
        this.hideProgressBar()
      }
    );
  }


  getKeyword(keyword: any, type: string) {
    this.headerName = keyword.name;
    this.AUTOMATIC_Msg = keyword.message;
    this.id = keyword.id
    this.showHideModalDialog(type)
  }

  listKeywords() {
    this.showProgressBar()
    this.businessKeywordsService.listKeywords().subscribe(
      (response) => {
        var json = JSON.parse(JSON.stringify(response));
        console.log('List Keyword response --> ' + JSON.stringify(json.data));
        if (json.response.status == 'SUCCESS') {
          this.keywordsList = json.data;
          this.keywordsList.forEach((element: any) => {
            element.checkExistingKeyword = this.isCheckboxDisabled(element.name);
          });

          console.log(this.keywordsList);

        } else {
          this.showErrorMessage(json.response.displayMessage)
        }
        this.hideProgressBar()
      },
      (error) => {
        console.log(error);
        this.showErrorMessage(AppSettings.ERROR_MSG)
        this.hideProgressBar();
      }
    );
  }

  clearFormData() {
    this.messageService.clear('errMsg');

  }

  findChoicesIn(list: any[]) {
    return (searchText: any) =>
      list.filter(item => item.toLowerCase().includes(searchText.toLowerCase()));
  };

  getChoiceLabel(choice: string) {
    return '${' + `${choice}}`;
  }


  showHideModalDialog(type: string) {
    switch (type) {
      case 'edit_msg':
        this.displayAddEditModal = !this.displayAddEditModal;
        break;
    }
  }

  showDialogMaximized(event: any, dialog: Dialog) {
    if (this.innerWidth <= 640) {
      dialog.maximized = true;
    }
  }



  showProgressBar() {
    this.messageService.clear();
    this.isLoading = true;
    this.disabled = true;
  }

  hideProgressBar() {
    this.isLoading = false;
    this.disabled = false;
  }


  showErrorMessage(msg: string) {
    this.messageService.add({
      key: 'errMsg',
      severity: 'error',
      detail: msg,
    });
  }

  showErrorToastMsg(msg: string) {
    this.messageService.add({
      key: 'br',
      severity: 'error',
      detail: msg,
    });
  }

  showSuccessMessage(status: string, msg: string) {
    this.messageService.add({
      key: 'br',
      severity: 'success',
      summary: status,
      detail: msg,
    });
  }

  onToggle(event: any, keyword: any) {
    this.editEnableDisable(event.checked, keyword);
  }

  get state(): string {
    return this.utils.state;
  }

  showHideModalKeywordDialog(type: string) {
    switch (type) {
      case 'add_keyword':
        this.headerName = 'Add keyword';
        this.keywordCreateAndUpdate = !this.keywordCreateAndUpdate;
        this.AUTOMATIC_Msg = '';
        this.Name = '';
        this.Description = '';
        break;    }
  }

  AddKeyword(){
    if(this.Name == '' || this.Name == undefined){
      this.showErrorMessage("Name is required.");
      return;
    }
    
    if(this.Description == '' || this.Description == undefined){
      this.showErrorMessage("Description is required.");
      return;
    }
    
    if(this.AUTOMATIC_Msg == '' || this.AUTOMATIC_Msg == undefined){
      this.showErrorMessage("AUTOMATIC_Msg is required.");
      return;
    }

    this.showProgressBar();
    var Data = {
      message: this.AUTOMATIC_Msg,
      name: this.Name.toUpperCase(),
      description: this.Description
    }
    var data = { data: JSON.parse(JSON.stringify(Data)) };
    this.businessKeywordsService.addKeywordMsg(this.id, data).subscribe(
      (response) => {
        var json = JSON.parse(JSON.stringify(response));
        if (json.response.status == 'SUCCESS') {
          this.showHideModalKeywordDialog('add_keyword')
          this.listKeywords()
          this.showSuccessMessage(json.response.status, json.response.message)
        } else {
          this.showErrorMessage(json.response.displayMessage)
        }
        this.hideProgressBar();
      },
      (error) => {
        this.showErrorMessage(AppSettings.ERROR_MSG)
        this.hideProgressBar();
      }
    );
  }

  confirmDeleteDialog(keywords: any) {
    this.messageService.clear();
    this.confirmationService.confirm({
      message: 'Do you want to delete this business keyword?',
      header: 'Delete Confirmation',
      icon: 'pi pi-trash',
      accept: () => {
        this.removeBusinessKeyword(keywords);
      }
    });
  }

  removeBusinessKeyword(keywords:any){
    console.log(keywords);
    this.businessKeywordsService.removeBusinessKeywords(keywords.id).subscribe(
      (response) => {
        var json = JSON.parse(JSON.stringify(response));
        if (json.response.status == 'SUCCESS') {
          setTimeout(() => {
            this.showSuccessMessage(json.response.status, json.response.displayMessage);
          }, 1000);
          this.listKeywords();
        } else {
          this.showErrorToastMsg(json.response.displayMessage)
        }
        console.log('Remove Keywords Response --->> ' + JSON.stringify(json));
      },
      (error) => {
        console.log(error);
        this.showErrorToastMsg(AppSettings.ERROR_MSG)
      }
    );
  }


  isCheckboxDisabled(name:any){
    if (this.keywordExistingList.includes(name)) {
      return true;
    } else {
      return false;
    }
  }
}
