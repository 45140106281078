import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { timeout } from 'rxjs/operators';
import { CommonFunctions } from 'src/app/Utilities/CommonFunctions';
import { environment } from 'src/environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class BusinessHolidaysService {

  constructor(
    private http: HttpClient
  ) { }


  getListBusinessHolidays() {
    const url = environment.API_URL + '/api/businessHolidays/list';
    const TOKEN = CommonFunctions.getToken();
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + TOKEN,
        'Content-Type': 'application/json',
        'BusinessId': CommonFunctions.getBusinessId().toString()
      }),
    };
    return this.http.get(url, httpOptions).pipe(timeout(60000));
  }


  getApprovedHolidaysList() {
    const url = environment.API_URL + '/api/businessHolidays/approved/list';
    const TOKEN = CommonFunctions.getToken();
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + TOKEN,
        'Content-Type': 'application/json',
        'BusinessId': CommonFunctions.getBusinessId().toString()
      }),
    };
    return this.http.get(url, httpOptions).pipe(timeout(60000));
  }

  UpdateBusinessHolidays(data:any) {
    const url = environment.API_URL + '/api/businessHolidays/update/approvel?holidaysId='+data;
    const TOKEN = CommonFunctions.getToken();
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + TOKEN,
        'Content-Type': 'application/json',
        'BusinessId': CommonFunctions.getBusinessId().toString()
      }),
    };
    return this.http.post(url, data, httpOptions).pipe(timeout(60000));
  }


  getConfirmHolidaysList() {
    const url = environment.API_URL + '/api/businessHolidays/confirm/list';
    const TOKEN = CommonFunctions.getToken();
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + TOKEN,
        'Content-Type': 'application/json',
        'BusinessId': CommonFunctions.getBusinessId().toString()
      }),
    };
    return this.http.get(url, httpOptions).pipe(timeout(60000));
  }

  addCustomHolidays(data:any) {
    const url = environment.API_URL + '/api/businessHolidays/custom/add';
    const TOKEN = CommonFunctions.getToken();
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + TOKEN,
        'Content-Type': 'application/json',
        'BusinessId': CommonFunctions.getBusinessId().toString()
      }),
    };
    return this.http.post(url, data, httpOptions).pipe(timeout(60000));
  }

  removeCustomHolidays(id:any) {
    const url = environment.API_URL + '/api/businessHolidays/custom/'+id+'/remove';
    const TOKEN = CommonFunctions.getToken();
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + TOKEN,
        'Content-Type': 'application/json',
        'BusinessId': CommonFunctions.getBusinessId().toString()
      }),
    };
    return this.http.get(url, httpOptions).pipe(timeout(60000));
  }
}
