import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from '../services/authentication/authentication.service';
import { SignInData } from '../model/signInData';
import { PrivilegeService } from '../services/privilege/privilege.service';
import { RoleService } from '../services/role/role.service';
import { AppSettings } from '../Constant/AppSettings';
import { CommonFunctions } from '../Utilities/CommonFunctions';
import { BusinessInfoService } from '../services/business-info/business-info.service';
import { UtilsService } from '../services/utils/utils.service';
import { LocationStrategy } from '@angular/common';
import { MessageService } from "primeng/api";
import { LoginSocketService } from '../services/socket/login-socket.service';
import { Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent implements OnInit {
  isFormValid = false;
  areCredentialsInvalid = false;
  isLoading: boolean = false;
  disabled: boolean = true;
  businessUrl = '';
  showBusinessName: boolean = false;
  error = '';
  baseUrl = '';
  adminBusinessName: string = "";
  private statusSubscription!: Subscription;
  isConnected: boolean = false;
  userStatus: boolean = true;
  showTwoFactorAuth: boolean = false;

  constructor(
    private router: Router,
    private locationStrategy: LocationStrategy,
    private authenticationService: AuthenticationService,
    private privilegeService: PrivilegeService,
    private roleService: RoleService,
    private route: ActivatedRoute,
    private utilsService: UtilsService,
    private businessInfoService: BusinessInfoService,
    private messageService: MessageService,
    private loginSocketService: LoginSocketService
  ) { }

  ngOnInit() {
    this.baseUrl = CommonFunctions.replaceAll(this.locationStrategy.getBaseHref(), '/', '')
    // console.log('base url:', this.businessUrl);
    CommonFunctions.setBaseUrl('');
    if (this.baseUrl == '' || this.baseUrl == 'login') {
      this.showBusinessName = true;
    } else {
      this.showBusinessName = false;
      this.getBusinessInfoByShortname()
    }
    this.getAdminLogin();

    // Replace 'ws://localhost:4000' with your WebSocket server URL
    this.loginSocketService.connect(environment.WEBSOCKET);
    
    this.statusSubscription = this.loginSocketService.getStatus().subscribe(
      (status: boolean) => {
        this.isConnected = status;
      }
    );

    this.loginSocketService.onMessage().subscribe(
      (data) => {        
        const jSONdata = JSON.parse(data);
        console.log('Business ID:', jSONdata.businessId);
        console.log('Status:', jSONdata.status);
      },
      (error) => {
        console.error('Error receiving data', error);
      }
    );
  }

  ngOnDestroy(): void {
    if (this.statusSubscription) {
      this.statusSubscription.unsubscribe();
    }
    this.loginSocketService.disconnect();
  }

  getBusinessInfoByShortname() {
    this.showProgressBar()
    let shortName = '';
    if (this.showBusinessName) {
      shortName = this.businessUrl
    } else {
      shortName = this.baseUrl
    }

    this.authenticationService.getBusinessInfoByShortname(shortName).subscribe(
      (response) => {
        var json = JSON.parse(JSON.stringify(response));
        console.log('Get Business Info Response --->> ' + JSON.stringify(json));
        if (json.response.status == 'SUCCESS') {
          if (json.data != '' && json.data != 'null' && json.data != null) {
            CommonFunctions.setBusinessInfo(json.data)
            this.disabled = false;
          } else {
            this.disabled = true;
          }
        }
        this.getAdminLogin();
        this.hideProgressBar()
      },
      (error) => {
        console.log('There was an error');
        console.log(error);
        this.hideProgressBar();
      }
    );
  }

  //LOGIN
  onSubmit(signInForm: NgForm) {
    if (!signInForm.valid) {
      this.isFormValid = true;
      this.areCredentialsInvalid = false;
      return;
    }
    this.login(signInForm);
  }

  private login(signInForm: NgForm) {
    this.showProgressBar()
    const signInData = new SignInData(
      signInForm.value.username,
      signInForm.value.password
    );
    var data = { data: JSON.parse(JSON.stringify(signInData)) };
    this.authenticationService.authenticate(data).subscribe(
      (response) => {
        var json = JSON.parse(JSON.stringify(response));
        console.log('Token --->> ' + json.data.token);
        console.log('Login API Response --->> ' + JSON.stringify(json));
        if (json.response.status == 'SUCCESS') {
          this.showTwoFactorAuth = true;
          this.loginSocketService.onMessage().subscribe(
            (data) => {        
              const jSONdata = JSON.parse(data);
              // console.log('Business ID:', jSONdata.businessId);
              // console.log('Status:', jSONdata.status);
              // console.log(CommonFunctions.getBusinessInfo()?.businessId);
              
              const commonBusinessId = String(CommonFunctions.getBusinessInfo()?.businessId);

              if(commonBusinessId.trim() === jSONdata.businessId.trim() && jSONdata.status == 'true'){
                this.showTwoFactorAuth = false;
                CommonFunctions.setUser(json.data.user)
                CommonFunctions.setToken(json.data.token)
                this.authenticationService.TOKEN = json.data.token;
                this.utilsService.setIsLogin(true)
                this.listPrivilegeGroups(json.data.user)
                let bussinesInfo = CommonFunctions.getBusinessInfo();
                this.checkTwilioAssign();
              }else if(commonBusinessId.trim() === jSONdata.businessId.trim() && jSONdata.status == 'false'){
                // this.showTwoFactorAuth = false;
                this.userStatus = false;
              }

            },
            (error) => {
              console.error('Error receiving data', error);
            }
          );
          
        } else {
          if(this.adminBusinessName == ""){
            console.log(json);
            this.error = json.response.displayMessage;
            console.log(this.error);
          }
          
          if(this.adminBusinessName != ""){
            this.showProgressBar();
            const formData = {
              email: signInForm.value.username,
              password: signInForm.value.password,
              businessName: this.adminBusinessName
            }
            var data = { data: JSON.parse(JSON.stringify(formData)) };
            this.authenticationService.adminAuthenticate(data).subscribe(
              (response) => {
                this.error = "";
                var json = JSON.parse(JSON.stringify(response));
                console.log('Login API Response --->> ' + JSON.stringify(json));
                if (json.response.status == 'SUCCESS') {
                  this.hideProgressBar();
                  window.location.href = json.data.user.url;
                }else{
                  console.log(json);
                  this.error = json.response.displayMessage;
                  console.log(this.error);
                }
              });
          }
          // this.router.navigate(['/login']);
        }
        this.hideProgressBar()
      },
      (error) => {
        console.log('There was an error');
        console.log(error);
        this.error = AppSettings.ERROR_MSG;
        this.hideProgressBar();
      }
    );
  }

  listPrivilegeGroups(user: any) {
    this.isLoading = true;
    this.roleService.listPrivilegeGroups().subscribe(
      (response) => {
        var json = JSON.parse(JSON.stringify(response));
        console.log('Privileges --->> ' + JSON.stringify(json));
        if (json.response.status == 'SUCCESS') {
          let temp: any[] = [];
          for (let index = 0; index < json.data.length; index++) {
            let privilegeGroup = json.data[index];
            // console.log(privilegeGroup)
            for (let indexj = 0; indexj < privilegeGroup.privileges.length; indexj++) {
              let privilege = privilegeGroup.privileges[indexj];
              temp.push({
                id: privilege.id,
                name: privilege.name,
                value: false
              })
            }

          }

          this.privilegeService.privileges = temp;
          // if (user.roles.length > 0) {
          //   for (let index = 0; index < user.roles.length; index++) {
          //     if (user.roles[index].name === 'SUPER_ADMIN') {
          //       this.privilegeService.setPrivileges(user.role[0].id)
          //     } else {

          //     }
          //   }
          // }

          this.privilegeService.setPrivileges(user.privileges)
          if (this.showBusinessName) {
            window.location.href = this.businessUrl + '/home';
          } else {
            // window.location.href = this.baseUrl + '/home';
            this.router.navigate(['/home']);
          }
        }


        this.hideProgressBar();
      },
      (error) => {
        console.log(AppSettings.ERROR_MSG);
        console.log(error);
        this.hideProgressBar();
      }
    );
  }

  getBusinessInfo() {
    this.businessInfoService.getBusinessInfo(CommonFunctions.getUserId()).subscribe(
      (response) => {
        var json = JSON.parse(JSON.stringify(response));
        console.log('Business info response --> ' + JSON.stringify(json));
        if (json.response.status == 'SUCCESS') {
          if (json.data != '' && json.data != 'null' && json.data != null) {
            CommonFunctions.setBusinessInfo(json.data)
            this.utilsService.setIsLogin(true)
          }
          // window.location.reload();
        } else {
          // this.showErrorMessage(json.response.message)

        }
      },
      (error) => {
        console.log(error);
        this.hideProgressBar();
      }
    );
  }

  goToRegistration() {
    // this.router.navigate(['/registration']);
    window.location.href = '/registration'
  }

  showProgressBar() {
    this.isLoading = true;
    this.disabled = true;
  }

  hideProgressBar() {
    this.isLoading = false;
    this.disabled = false;
  }

  // ngOnDestroy() {
  //   CommonFunctions.removeBusinessInfo()
  // }

  getAdminLogin(){
    this.isLoading = true;

    this.businessInfoService.getAdminLoginBusinessList().subscribe(
      (response) => {
        var json = JSON.parse(JSON.stringify(response));
        console.log('Admin business list info response --> ' + JSON.stringify(json));

        if (json.response.status == 'SUCCESS') {
          if (json.data != '' && json.data != 'null' && json.data != null) {
            json.data.forEach((element:any) => {
              if (this.showBusinessName) {
                if(element.businessName == this.businessUrl){
                  this.adminBusinessName = element.businessName;
                }
              } else {
                if(element.businessName == this.baseUrl){
                  this.adminBusinessName = element.businessName;
                }
              }
            });
          }
        }
        this.isLoading = false;
      });
  }

  checkTwilioAssign(){
    this.businessInfoService.assignTwilioConfig().subscribe(
      (response) => {
        var json = JSON.parse(JSON.stringify(response));
        console.log('Business info response --> ' + JSON.stringify(json));
        if (json.response.status == 'SUCCESS') {
          if (json.data != '' && json.data != 'null' && json.data != null) {
          }
        }else{
          setTimeout(() => {
            this.showErrorMessage(json.response.displayMessage)
          }, 1000);
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }

  showErrorMessage(msg: string) {
    this.messageService.add({
      key: 'br',
      severity: 'error',
      detail: msg,
    });
  }

}
