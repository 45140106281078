import { Component, HostListener, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ConfirmationService, MenuItem, MessageService } from 'primeng/api';
import { Observable } from 'rxjs';
import { AppSettings } from '../Constant/AppSettings';
import { ImageSnippet } from '../Constant/ImageSnippet';
import { ChatButtonService } from '../services/chat-button/chat-button.service';
import { UtilsService } from '../services/utils/utils.service';
import { environment } from '../../environments/environment.prod';

@Component({
  selector: 'app-chat-button-configuration',
  templateUrl: './chat-button-configuration.component.html',
  styleUrls: ['./chat-button-configuration.component.css']
})
export class ChatButtonConfigurationComponent implements OnInit {
  items: MenuItem[] = [{ label: 'Chat Button Configuration' }];
  home: MenuItem = { icon: 'pi pi-home', routerLink: '/home' };
  businessName: string = '';
  phone: string = '';
  chatButtonCategory: string = 'null';
  chatButtonCategoryList: any[] = []
  isLoading: boolean = false;
  isLoadingUrl: boolean = false;
  disabled: boolean = false;
  disabledPublish: boolean = false
  //File upload
  avatarName = '';
  imageInputError: boolean = false;
  profileUrl = '';
  //image cropper
  croppedImageUrl: any = '';
  //Crop Dialog
  displayCropModal: boolean = false;
  headerShow: string = 'chatButtonConfig'
  
  constructor(
    private utils: UtilsService,
    private messageService: MessageService,
    private chatButtonService: ChatButtonService,
    private confirmationService: ConfirmationService
  ) { }

  ngOnInit(): void {
    this.getChatButton()
  }


  editChatButton(chatButtonForm: NgForm) {
    this.showProgressBar();
    if (this.businessName == '') {
      this.showErrorBannerMsg('Business Name Field is empty.')
      this.hideProgressBar();
      return;
    }
    if (this.phone == '') {
      this.showErrorBannerMsg('Mobile Field is empty.')
      this.hideProgressBar();
      return;
    }
    if (this.phone.length <= 9) {
      this.showErrorMessage('Phone Number should be 10 digits.')
      this.hideProgressBar();
      return;
    }
    let mob = '+1' + this.phone;
    const Data = {
      name: this.businessName,
      phoneNumber: mob,
      //   "chatButtonCategory": {
      //     "id": "299"
      // }
    }

    var data = { data: JSON.parse(JSON.stringify(Data)) };
    this.chatButtonService.editChatButton(data).subscribe(
      (response) => {
        var json = JSON.parse(JSON.stringify(response));
        console.log('Edit Chat Button Post Response --->> ' + JSON.stringify(json));
        console.log('Status --->> ' + json.response.status);
        if (json.response.status == 'SUCCESS') {
          this.showSuccessMessage(json.response.status, json.response.message)
          if (this.croppedImageUrl != '') {
            this.uploadChatButtonLogo();
          }
          this.disabledPublish = json.data.isPublished
        } else {
          this.showErrorMessage(json.response.displayMessage)
        }
        this.hideProgressBar();
      },
      (error) => {
        console.log(error);
        this.showErrorMessage(AppSettings.ERROR_MSG)
        this.hideProgressBar();
      }
    );
  }


  uploadChatButtonLogo() {
    this.showProgressBar()
    this.chatButtonService.uploadChatButtonLogo(this.croppedImageUrl).subscribe(
      (res) => {
        console.log(res);
        var json = JSON.parse(JSON.stringify(res));
        if (json.response.status == 'SUCCESS') {
          this.imageInputError = false
          this.showSuccessMessage(json.response.status, json.response.message)
        } else {
          this.showErrorMessage(json.response.displayMessage)
        }
        this.hideProgressBar()
      },
      (err) => {
        console.log(err);
        this.showErrorMessage(AppSettings.ERROR_MSG)
      }
    );
  }

  getChatButton() {
    this.showProgressBar()
    this.chatButtonService.getChatButton().subscribe(
      (response) => {
        var json = JSON.parse(JSON.stringify(response));
        console.log('Chat button response --> ' + JSON.stringify(json.data));
        if (json.response.status == 'SUCCESS') {
          this.setData(json.data)
        } else {
          this.showErrorMessage(json.response.displayMessage)
        }
        this.hideProgressBar()
      },
      (error) => {
        console.log(error);
        this.showErrorMessage(AppSettings.ERROR_MSG)
        this.hideProgressBar();
      }
    );
  }

  resetChatButton() {
    this.showProgressBar()
    this.chatButtonService.resetChatButton().subscribe(
      (response) => {
        var json = JSON.parse(JSON.stringify(response));
        console.log('Reset Chat Button response --> ' + JSON.stringify(json));
        if (json.response.status == 'SUCCESS') {
          this.showSuccessMessage(json.response.status, json.response.displayMessage)
          this.setData(json.data)
        } else {
          this.showErrorMessage(json.response.displayMessage)
        }
        this.hideProgressBar()
      },
      (error) => {
        console.log(error);
        this.showErrorMessage(AppSettings.ERROR_MSG)
        this.hideProgressBar();
      }
    );
  }


  deleteImageLogo() {
    this.showProgressBar()
    this.chatButtonService.deleteImageLogo().subscribe(
      (response) => {
        var json = JSON.parse(JSON.stringify(response));
        console.log('Delete Chat Button Logo response --> ' + JSON.stringify(json));
        if (json.response.status == 'SUCCESS') {
          this.showSuccessMessage(json.response.status, json.response.displayMessage)
          this.getChatButton()
        } else {
          this.showErrorMessage(json.response.displayMessage)
        }
        this.hideProgressBar()
      },
      (error) => {
        console.log(error);
        this.showErrorMessage(AppSettings.ERROR_MSG)
        this.hideProgressBar();
      }
    );
  }

  publishChatButton() {
    this.showProgressBar()
    this.chatButtonService.publishChatButton().subscribe(
      (response) => {
        var json = JSON.parse(JSON.stringify(response));
        console.log('Publish Chat Button response --> ' + JSON.stringify(json));
        if (json.response.status == 'SUCCESS') {
          this.showSuccessMessage(json.response.status, json.response.displayMessage)
          this.getChatButton()
        } else {
          this.showErrorMessage(json.response.displayMessage)
        }
        this.hideProgressBar()
      },
      (error) => {
        console.log(error);
        this.showErrorMessage(AppSettings.ERROR_MSG)
        this.hideProgressBar();
      }
    );
  }



  confirmDeleteDialog() {
    this.messageService.clear();
    this.confirmationService.confirm({
      message: 'Do you want to delete this image ?',
      header: 'Delete Confirmation',
      icon: 'pi pi-trash',
      accept: () => {
        this.deleteImageLogo();
      }
    });
  }

  confirmPublishDialog() {
    this.messageService.clear();
    this.confirmationService.confirm({
      message: 'Are you sure you want to publish your changes ? Your changes will be visible to your customers in Chatstasy App.',
      header: 'Publish Confirmation',
      icon: 'pi pi-upload',
      accept: () => {
        this.publishChatButton()
      }
    });
  }

  setData(data: any) {
    this.businessName = data.name;
    this.phone = data.phoneNumber.replace('+1', '');
    this.disabledPublish = data.isPublished
    this.imageInputError = false;
    this.profileUrl = environment.IMAGE_BASE_URL + data.image;
    // this.avatarName = data!.name!.split(' ')[0]!.charAt(0) + data!.name!.split(' ')[1]!.charAt(0);
  }

  fileFailedToLoad(event: any) {
    console.log(event)
    this.imageInputError = true;
    this.profileUrl = 'assets/images/business_2.png'
  }

  hideCropModal(displayCropModal: any) {
    if (displayCropModal !== undefined) {
      this.displayCropModal = displayCropModal;
    }

  }

  croppedImage(imageFile: any) {
    if (imageFile !== undefined) {
      this.croppedImageUrl = imageFile?.croppedImageUrl
      this.profileUrl = imageFile?.base64Value;
    }
  }

  validateDigitOnly(event: any) {
    var inp = String.fromCharCode(event.keyCode);
    if (/[0-9]/.test(inp)) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  }
  // Only AlphaNumeric with Some Characters [-_ ]
  keyPressAlphaNumericWithCharacters(event: any) {
    var inp = String.fromCharCode(event.keyCode);
    // Allow numbers, alpahbets, space, underscore
    if (/[a-zA-Z0-9]/.test(inp)) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  }

  showErrorBannerMsg(msg: string) {
    this.messageService.add({
      key: 'errMsg',
      severity: 'error',
      detail: msg,
    });
  }

  showErrorMessage(msg: string) {
    this.messageService.add({
      key: 'br',
      severity: 'error',
      detail: msg,
    });
  }

  showSuccessMessage(status: string, msg: string) {
    this.messageService.add({
      key: 'br',
      severity: 'success',
      summary: status,
      detail: msg,
    });
  }

  hideProgressBar() {
    this.isLoading = false;
    this.disabled = false;
  }

  showProgressBar() {
    this.messageService.clear('errMsg');
    this.isLoading = true;
    this.disabled = true;
  }

  get state(): string {
    return this.utils.state;
  }

}
