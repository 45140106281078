<div id="app">
  <app-sidebar *ngIf="showMasterLayout" from="desktop"></app-sidebar>
  <div class="app-content">
    <app-header *ngIf="showMasterLayout"></app-header>
    <router-outlet></router-outlet>
    <app-footer *ngIf="showMasterLayout"></app-footer>
  </div>
</div>
<!-- NOTIFICATION SIDEBAR -->
<p-sidebar [(visible)]="isVisible" position="right" [baseZIndex]="5000" [style]="{width:'20em'}">
  <h5 class="sidebar_title">notifications </h5>
  <p-scrollPanel [style]="{width: '100%', height: '95vh','padding-bottom':'40px'}" #sc>
    <ul class="sidebar_ul">
      <li class="list_sidebar" *ngFor="let msg of notifications">
        <a class="sidebar_container" (click)="onNotificationClick(msg)">
          <i *ngIf="msg.readStatus=='unread'" class="fa fa-circle status_online" style="right: 25px;"></i>
          <div [ngClass]="msg.readStatus=='unread'?'sidebar_info_unread':'sidebar_info'">
            <h4 class="sidebar_info_title">{{msg.title}}</h4><span style="color: #919191">{{msg.description}}</span>
          </div>
        </a>
      </li>
    </ul>
    <div class="alignCenter" style="margin-top: 1rem;" *ngIf="notifications?.length == 0 && !isLoading">
      NO NOTIFICATIONS FOUND
    </div>
    <!-- <p-button type="button" (click)="setIsVisibleSidebar(false)" label="Save" styleClass="p-button-success"></p-button>
  <p-button type="button" (click)="setIsVisibleSidebar(false)" label="Cancel" styleClass="p-button-secondary">
  </p-button> -->
  </p-scrollPanel>
</p-sidebar>

<!-- MENU FOR MOBILE VIEW -->
<p-sidebar [(visible)]="menuSidebar" position="left" [baseZIndex]="5000" [style]="{width:'20em'}"
  [showCloseIcon]="false" (onHide)="closeMenu()">
  <app-sidebar *ngIf="showMasterLayout" from="mobile"></app-sidebar>
</p-sidebar>

<p-dialog header="Account Deactivated" [(visible)]="displayModalDisable" [closable]="false" [modal]="true"
  [style]="{width: '50vw'}" [draggable]="false" [resizable]="false" [baseZIndex]="10000">
  <p class="p-m-0"> Your account is deactivated. Please contact Chatstasy support if you have any query.</p>
  <ng-template pTemplate="footer">
    <p-button icon="pi pi-check" (click)="logout()" label="Ok" styleClass="p-button-text"></p-button>
  </ng-template>
</p-dialog>
<!-- TOAST -->
<p-toast position="bottom-right" key="br" [baseZIndex]="10000"></p-toast>