import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { timeout } from 'rxjs/operators';
import { CommonFunctions } from 'src/app/Utilities/CommonFunctions';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ProfileService {
  constructor(private router: Router, private http: HttpClient) { }
  getProfile() {
    const url = environment.API_URL + '/api/me/profile';
    const TOKEN = CommonFunctions.getToken();
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + TOKEN,
        'Content-Type': 'application/json',
        'BusinessId': CommonFunctions.getBusinessId().toString()
      }),
    };
    return this.http.get(url, httpOptions).pipe(timeout(60000));
  }

  editProfile(profile: any) {
    const TOKEN = CommonFunctions.getToken();
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + TOKEN,
        'Content-Type': 'application/json',
        'BusinessId': CommonFunctions.getBusinessId().toString()
      }),
    };
    const url = environment.API_URL + '/api/me/profile/update';
    return this.http.post(url, profile, httpOptions).pipe(timeout(60000));
  }

  resetPassword(password: any) {
    console.log('Reset Password --->> ' + JSON.stringify(password));

    const url = environment.API_URL + '/api/me/resetPassword';
    const TOKEN = CommonFunctions.getToken();
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + TOKEN,
        'Content-Type': 'application/json',
        'BusinessId': CommonFunctions.getBusinessId().toString()
      }),
    };
    return this.http.post(url, password, httpOptions).pipe(timeout(60000));
  }

  getCountries() {
    const TOKEN = CommonFunctions.getToken();
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + TOKEN,
        'Content-Type': 'application/json',
        'BusinessId': CommonFunctions.getBusinessId().toString()
      }),
    };
    const url = environment.API_URL + '/api/global/country';
    return this.http.get(url, httpOptions).pipe(timeout(60000));
  }

  editProfileImage(file: any) {
    // console.log('Profile Swrevice Data ---> ' + JSON.stringify(file));
    const url = environment.API_URL + '/api/me/profileImage/update';
    const TOKEN = CommonFunctions.getToken();
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + TOKEN,
        'BusinessId': CommonFunctions.getBusinessId().toString()
      }),
    };
    // Create form data
    const formData = new FormData();
    formData.append('file', file, file.name);
    return this.http.post(url, formData, httpOptions).pipe(timeout(60000));
  }

  deleteProfileImage() {
    const url = environment.API_URL + '/api/me/profileImage/delete';
    const TOKEN = CommonFunctions.getToken();
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + TOKEN,
        'Content-Type': 'application/json',
        'BusinessId': CommonFunctions.getBusinessId().toString()
      }),
    };
    return this.http.post(url, null, httpOptions).pipe(timeout(60000));
  }
}
