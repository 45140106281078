import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { timeout } from 'rxjs/operators';
import { CommonFunctions } from 'src/app/Utilities/CommonFunctions';
import { environment } from 'src/environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class ChatButtonPromotionService {

  constructor(
    private http: HttpClient
  ) { }


  getChatButtonPromotion(){
    const url = environment.API_URL + '/api/chat-button-promotion';
    const TOKEN = CommonFunctions.getToken();
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + TOKEN,
        'Content-Type': 'application/json',
        'BusinessId': CommonFunctions.getBusinessId().toString()
      })
    };
    return this.http.get(url, httpOptions).pipe(timeout(60000));
  }

  addChatButtonPromotion(data: any){
    const url = environment.API_URL + '/api/chat-button-promotion/add';
    const TOKEN = CommonFunctions.getToken();
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + TOKEN,
        'Content-Type': 'application/json',
        'BusinessId': CommonFunctions.getBusinessId().toString()
      }),
    };
    return this.http.post(url, data, httpOptions).pipe(timeout(60000));
  }

  updateChatButtonPromotion(data: any, id: any){
    const url = environment.API_URL + '/api/chat-button-promotion/' + id + '/update';;
    const TOKEN = CommonFunctions.getToken();
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + TOKEN,
        'Content-Type': 'application/json',
        'BusinessId': CommonFunctions.getBusinessId().toString()
      }),
    };
    return this.http.post(url, data, httpOptions).pipe(timeout(60000));
  }

  uploadChatButtonImage(file: any) {
    const url = environment.API_URL + '/api/anonymous/cdn/chatButtonPromotion/chatButtonImage';
    const TOKEN = CommonFunctions.getToken();
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + TOKEN,
        'BusinessId': CommonFunctions.getBusinessId().toString()
      }),
    };
    const formData = new FormData();
    formData.append('file', file, file.name);
    return this.http.post(url, formData, httpOptions).pipe(timeout(60000));
  }

  uploadPromotionGraphicImage(file: any) {
    const url = environment.API_URL + '/api/anonymous/cdn/chatButtonPromotion/promotionGraphicImage';
    const TOKEN = CommonFunctions.getToken();
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + TOKEN,
        'BusinessId': CommonFunctions.getBusinessId().toString()
      }),
    };
    const formData = new FormData();
    formData.append('file', file, file.name);
    return this.http.post(url, formData, httpOptions).pipe(timeout(60000));
  }

  publishChatRoom(data:any) {
    const url = environment.API_URL + '/api/chat-button-promotion/publish?publish='+data;
    const TOKEN = CommonFunctions.getToken();
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + TOKEN,
        'Content-Type': 'application/json',
        'BusinessId': CommonFunctions.getBusinessId().toString()
      }),
    };
    return this.http.post(url, null, httpOptions).pipe(timeout(60000));
  }

}
