<div [ngClass]="state=='open' ? 'main_content_open' : 'main_content'">
    <p-scrollPanel [style]="{width: '100%', height: '95vh','margin-top':'7px','padding-bottom':'70px'}" #sc>
        <app-message-credit-warning></app-message-credit-warning>
        <app-twillio-message></app-twillio-message>
        <div class="p-d-flex p-jc-between p-ai-center p-pt-3 p-pb-3">
            <div class="page_title">
                Dashboard
            </div>
            <div>
                <!-- <p-breadcrumb [model]="items" [home]="home"></p-breadcrumb> -->
            </div>
        </div>
        <div class="container-fluid container-fullw bg-white">
            <div class="row">
                <div class="col-sm-3" style="padding: 0.5rem !important;">
                    <div class="panel panel-white no-radius">
                        <div class="panel-heading border-light">
                            <h4 class="panel-title">Client Channels</h4>
                            <ul class="panel-heading-tabs border-light">
                                <li class="panel-tools">
                                    <a class="btn btn-transparent btn-sm panel-refresh" (click)="getClientChannel()"><i
                                            class="ti-reload"></i></a>
                                </li>
                            </ul>
                        </div>
                        <div class="panel-wrapper">
                            <div class="panel-body" style="padding: 0;">
                                <div class="height-250">
                                    <p-chart *ngIf="!isLoadingClientChannel && !noClientChannelData" type="doughnut"
                                        [data]="clientChannelData" [options]="options" [plugins]="plugin"
                                        height="230px">
                                    </p-chart>
                                    <div *ngIf="!isLoadingClientChannel && noClientChannelData"
                                        class="p-d-flex p-ai-center p-jc-center" style="height: 100%;">
                                        <h4 class="panel-title">No customer yet</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="isLoadingClientChannel" class="progressLayout">
                            <div class="progressContainer">
                                <p-progressSpinner [style]="{ width: '40px', height: '40px' }" strokeWidth="3">
                                </p-progressSpinner>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-sm-3" style="padding: 0.5rem !important;">
                    <div class="panel panel-white no-radius">
                        <div class="panel-heading border-light">
                            <h4 class="panel-title">Chatstasy Adoption</h4>
                            <ul class="panel-heading-tabs border-light">
                                <li class="panel-tools">
                                    <a class="btn btn-transparent btn-sm panel-refresh"
                                        (click)="getClientChannelAdoption()"><i class="ti-reload"></i></a>
                                </li>
                            </ul>
                        </div>
                        <div class="panel-wrapper">
                            <div class="panel-body" style="padding: 0;">
                                <div class="height-250">
                                    <p-chart *ngIf="!isLoadingClientChannelAdoption && !noClientChannelAdoptionData"
                                        type="pie" [data]="clientChannelAdoptionData" [options]="options"
                                        [plugins]="plugin" height="230px">
                                    </p-chart>
                                    <div *ngIf="!isLoadingClientChannelAdoption && noClientChannelAdoptionData"
                                        class="p-d-flex p-ai-center p-jc-center" style="height: 100%;">
                                        <h4 class="panel-title">No
                                            message sent yet</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="isLoadingClientChannelAdoption" class="progressLayout">
                            <div class="progressContainer">
                                <p-progressSpinner [style]="{ width: '40px', height: '40px' }" strokeWidth="3">
                                </p-progressSpinner>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-sm-6" style="padding: 0.5rem !important;">
                    <div class="panel panel-white no-radius">
                        <div class="panel-heading border-light">
                            <h4 class="panel-title">Message Usage</h4>
                            <ul class="panel-heading-tabs border-light">
                                <li class="panel-tools">
                                    <a class="btn btn-transparent btn-sm panel-refresh" (click)="getMessageUsage()"><i
                                            class="ti-reload"></i></a>
                                </li>
                            </ul>
                        </div>
                        <div class="panel-wrapper">
                            <div class="panel-body" style="padding: 0;">
                                <div class="height-250">
                                    <p-chart *ngIf="!isLoadingMessageUsage && !noMessageUsageData" type="bar"
                                        [data]="messageUsageData" [options]="basicOptions" [plugins]="plugin"
                                        height="230px">
                                    </p-chart>
                                    <div *ngIf="!isLoadingMessageUsage && noMessageUsageData"
                                        class="p-d-flex p-ai-center p-jc-center" style="height: 100%;">
                                        <h4 class="panel-title">No
                                            message sent yet</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="isLoadingMessageUsage" class="progressLayout">
                            <div class="progressContainer">
                                <p-progressSpinner [style]="{ width: '40px', height: '40px' }" strokeWidth="3">
                                </p-progressSpinner>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </p-scrollPanel>
</div>

<app-renew-modal #renew [displayRenewModal]="displayRenewModal" from="home"
    (hideModalRenewDialog)="hideModalRenewDialog($event)" (isSuccess)='isSuccess($event)'>
</app-renew-modal>

<p-toast position="bottom-right" key="br"></p-toast>