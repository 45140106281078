export class User {
  firstName: string;
  lastName: string;
  email: string;
  mobile: string;
  country: string;
  password: string;
  roles: any[];
  constructor(
    firstName: string,
    lastName: string,
    email: string,
    mobile: string,
    country: string,
    password: string,
    roles: any[]
  ) {
    this.firstName = firstName;
    this.lastName = lastName;
    this.email = email;
    this.mobile = mobile;
    this.country = country;
    this.password = password;
    this.roles = roles;
  }

  getFirstName(): string {
    return this.firstName;
  }

  getLastName(): string {
    return this.lastName;
  }

  getEmail(): string {
    return this.email;
  }

  getMobile(): string {
    return this.mobile;
  }

  getCountry(): string {
    return this.country;
  }

  getPassword(): string {
    return this.password;
  }

  getRoles(): any[] {
    return this.roles;
  }
}