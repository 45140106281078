import { Component, HostListener, OnInit } from '@angular/core';
import { UtilsService } from '../services/utils/utils.service';
import { ConfirmationService, MenuItem, MessageService } from 'primeng/api';
import { BusinessHolidaysService } from '../services/business-holidays/business-holidays.service';
import * as moment from 'moment';
import 'moment-timezone';
import { DatePipe } from '@angular/common';
import { Dialog } from 'primeng/dialog';
import { AppSettings } from '../Constant/AppSettings';

interface YearLabel {
  label: string;
}

@Component({
  selector: 'app-business-holidays',
  templateUrl: './business-holidays.component.html',
  styleUrls: ['./business-holidays.component.css']
})
export class BusinessHolidaysComponent implements OnInit {

  home: MenuItem = { icon: 'pi pi-home', routerLink: '/home' };
  isLoading: boolean = false;
  disabled: boolean = false;
  items: MenuItem[] = [{ label: 'Business Holidays' }];
  uniqueYears:any = [];
  selectedYear!: { year: number; };
  businessHolidayList:any = [];
  businessHolidayFilter:any = [];
  selectedHolidaysValues: string[] = [];
  unSelectedHolidaysValues: string[] = [];
  allCheckboxValues: string[] = [];
  detectChanges:Boolean = false;
  displayAddEditModal: boolean = false;
  id: any = '';
  holidayName: string = '';
  holidayDate!: Date;
  roleDialogMax: string = '278px';
  isMaximizeAddRole: boolean = false;
  public innerWidth: any;
  invalidDates: any[] = [];

  constructor(
    public utils: UtilsService,
    public businessHolidaysService: BusinessHolidaysService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private datePipe: DatePipe
  ) { }

  ngOnInit(): void {
    this.innerWidth = window.innerWidth;
    this.getBusinessHolidaysList();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.innerWidth = window.innerWidth;
  }

  showDialogMaximized(event: any, dialog: Dialog) {
    if (this.innerWidth <= 640) {
      dialog.maximized = true;
    }
  }

  onMaximizeAddHoliday(isMaximize: boolean) {
    console.log(isMaximize);
    if (isMaximize) {
      this.roleDialogMax = '278px';
      this.isMaximizeAddRole = !this.isMaximizeAddRole;
    } else {
      this.roleDialogMax = '350px';
      this.isMaximizeAddRole = !this.isMaximizeAddRole;
    }
  }

  get state(): string {
    return this.utils.state;
  }

  getBusinessHolidaysList() {
      this.businessHolidaysService.getListBusinessHolidays().subscribe(
        (response) => {
          var json = JSON.parse(JSON.stringify(response));
          console.log('Business Holidays response --> ' + JSON.stringify(json));
          if (json.response.status == 'SUCCESS') {
            // json.data.forEach((ele:any)=>{
            //   const date = new Date(ele.date + 'T00:00:00Z'); 
            //   ele.date = this.datePipe.transform(date, 'yyyy-MM-dd HH:mm:ss', 'UTC');
            // });
            this.uniqueYears = this.extractUniqueYears(json.data);
            this.businessHolidayList = json.data;
            this.filterDatesByYear(json.data, this.uniqueYears[0].year);
          }
      });
  }

  extractUniqueYears(dates: any[]): YearLabel[] {
    const uniqueYearsSet = new Set<number>();
    dates.forEach(dateObj => {
      const year = new Date(dateObj.date).getFullYear();
      uniqueYearsSet.add(year);
    });
  
    // Create an array of objects containing year and label
    return Array.from(uniqueYearsSet).map(year => ({ year, label: year.toString() }));
  }

  confirmDialogYearChange(dates: any[], year: number) {
    this.messageService.clear();
    this.confirmationService.confirm({
      message: 'Changes are detected please save before changing the years.',
      header: 'Unsave Data',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.detectChanges = false;
        this.filterDatesByYear(dates,year);
      }
    });
  }

  filterDatesByYear(dates: any[], year: number) {
    if(this.detectChanges){
      this.confirmDialogYearChange(dates,year);
      return;
    }
    this.businessHolidayFilter = dates.filter(dateObj => new Date(dateObj.date).getFullYear() === year);
    this.businessHolidayFilter.forEach((element:any) => {
      this.allCheckboxValues.push(String(element.id));
    });
    this.checkHolidaysConflict();
  }

  getUniqueYearsAsString(): { label: string, value: number }[] {
    return this.uniqueYears.map((year: number) => ({ label: year.toString(), value: year }));
  }

  saveBusinessHolidays(){
    
  }
  

  showErrorMessage(msg: string) {
    this.messageService.add({
      key: 'br',
      severity: 'error',
      detail: msg,
    });
  }

  showSuccessMessage(msg: string) {
    this.messageService.add({
      key: 'br',
      severity: 'success',
      detail: msg,
    });
  }

  hideProgressBar() {
    this.isLoading = false;
    this.disabled = false;
  }

  showProgressBar() {
    this.messageService.clear();
    this.isLoading = true;
    this.disabled = true;
  }

  checkHolidaysConflict(){
    this.businessHolidaysService.getApprovedHolidaysList().subscribe(
      (response) => {
        var json = JSON.parse(JSON.stringify(response));
        console.log('Business Holidays response --> ' + JSON.stringify(json));
        if (json.response.status == 'SUCCESS') {
          this.selectedHolidaysValues = this.businessHolidayFilter.map((holiday: { id: any; }) => String(holiday.id));
          if(json.data != null){
            json.data.forEach((element:any) => {
              this.selectedHolidaysValues = this.selectedHolidaysValues.filter(item => item !== element.holidayId.toString());
              this.unSelectedHolidaysValues.push(element.holidayId);
            });
          }       
        }
        console.log(this.selectedHolidaysValues);
    });
  }

  confimSaveUnCheckData(){
    this.messageService.clear();
    this.confirmationService.confirm({
      message: 'Do you want to save this changes?',
      header: 'Save Confirmation',
      icon: 'pi pi-save',
      accept: () => {
        this.detectChanges = false;
        this.saveUnCheckData();
      }
    });
  }

  saveUnCheckData(){
    this.showProgressBar();
    this.unSelectedHolidaysValues = Array.from(new Set(this.unSelectedHolidaysValues));
    this.businessHolidaysService.UpdateBusinessHolidays(this.unSelectedHolidaysValues.toString() ? this.unSelectedHolidaysValues.toString() : null).subscribe(
      (response) => {
        var json = JSON.parse(JSON.stringify(response));
        console.log('Business holidays saved response --> ' + JSON.stringify(json));
        if (json.response.status == 'SUCCESS') {
          this.showSuccessMessage(json.response.message)
        }else{
          this.showErrorMessage(json.response.displayMessage)
        }
        this.hideProgressBar();
    });
  }

  onCheckboxChange(event: any, id: any){
    this.detectChanges = true;
    if(!event.checked){      
      this.unSelectedHolidaysValues.push(id.toString());
    }
    const uncheckedValues = this.allCheckboxValues.filter(value => !this.selectedHolidaysValues.includes(value));    

    this.unSelectedHolidaysValues = uncheckedValues;
  }

  showHideModalDialog(type: string) {
    switch (type) {
      case 'edit_role':
        this.clearFormData();
        this.displayAddEditModal = !this.displayAddEditModal;
        break;
      case 'add_holiday':
        this.clearFormData();
        this.displayAddEditModal = !this.displayAddEditModal;
        break;
    }
  }

  clearFormData() {
    this.messageService.clear('errMsg');
  }

  addHoliday(){
    this.messageService.clear();    
    if(this.holidayName == '' || this.holidayName == undefined){
      this.showErrorMessage("Name is required.");
      return;
    }

    this.showProgressBar();
    var Data = {
      name: this.holidayName,
      date: this.datePipe.transform(this.holidayDate, 'yyyy-MM-ddT00:00:00')
    }

    var data = { data: JSON.parse(JSON.stringify(Data)) };
    this.businessHolidaysService.addCustomHolidays(data).subscribe(
      (response) => {
        var json = JSON.parse(JSON.stringify(response));
        if (json.response.status == 'SUCCESS') {
          this.showSuccessMessage(json.response.message);
          this.displayAddEditModal = !this.displayAddEditModal;
          this.getBusinessHolidaysList();
        } else {
          this.showErrorMessage(json.response.displayMessage)
        }
        this.hideProgressBar();
      },
      (error) => {
        this.showErrorMessage(AppSettings.ERROR_MSG)
        this.hideProgressBar();
      }
      );    
  }

  onDateChange(event: any, type: string) {

  }

   // Only AlphaNumeric with Some Characters [-_ ]
   keyPressAlphaNumericWithCharacters(event: any) {

    var inp = String.fromCharCode(event.keyCode);
    // Allow numbers, alpahbets, space, underscore
    if (/^[a-zA-Z0-9 ]$/.test(inp)) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  }

  confirmDeleteDialog(customHolidayId: any) {
    this.messageService.clear();
    this.confirmationService.confirm({
      message: 'Do you want to delete this custom holiday?',
      header: 'Delete Confirmation',
      icon: 'pi pi-trash',
      accept: () => {
        this.removeCustomHolidays(customHolidayId);
      }
    });
  }

  removeCustomHolidays(customHolidayId:any){
    this.businessHolidaysService.removeCustomHolidays(customHolidayId).subscribe(
      (response) => {
        var json = JSON.parse(JSON.stringify(response));
        if (json.response.status == 'SUCCESS') {
          this.showSuccessMessage(json.response.message);
          this.getBusinessHolidaysList();

          this.filterDatesByYear(this.businessHolidayList, this.uniqueYears[0].year);
        } else {
          this.showErrorMessage(json.response.displayMessage)
        }
        this.hideProgressBar();
      },
      (error) => {
        this.showErrorMessage(AppSettings.ERROR_MSG)
        this.hideProgressBar();
      }); 
  }

}
