import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ConfirmationService, MenuItem, MessageService } from 'primeng/api';
import { Dialog } from 'primeng/dialog';
import { Paginator } from 'primeng/paginator';
import { AppSettings } from '../Constant/AppSettings';
import { ServiceProviderService } from '../services/service-provider/service-provider.service';
import { ServiceTypeService } from '../services/service-type/service-type.service';
import { UtilsService } from '../services/utils/utils.service';

@Component({
  selector: 'app-service-provider',
  templateUrl: './service-provider.component.html',
  styleUrls: ['./service-provider.component.css'],
})
export class ServiceProviderComponent implements OnInit {
  @ViewChild('p', { static: false }) paginator?: Paginator;
  items: MenuItem[] = [{ label: 'Manage Service Provider' }];
  home: MenuItem = { icon: 'pi pi-home', routerLink: '/home' };
  isLoading: boolean = false;
  serviceProviderList: any[] = []
  cols: any[] = [];
  //form variable
  serviceId = '';
  serviceProviderName: string = '';
  applicableServices: any[] = [];
  selectedApplicableServices: any;
  //Paginator variable
  pageSize: any = 0;
  currentPage: any = 1;
  totalCount: any = 0;
  // Dialog display varaible
  headerName: string = '';
  displayAddEditModal: boolean = false;
  disabled: boolean = false;
  public innerWidth: any;
  constructor(
    public utils: UtilsService,
    private serviceProviderService: ServiceProviderService,
    private serviceTypeService: ServiceTypeService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
  ) { }

  ngOnInit(): void {
    this.innerWidth = window.innerWidth;
    this.cols = [
      { field: 'name', header: 'Service Provider Name' },
      { field: 'serviceTypes', header: 'Applicable Services' }
    ]

    this.listServiceType()
    this.listServiceProvider()
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.innerWidth = window.innerWidth;
  }


  addService(serviceForm: NgForm) {
    this.showProgressBar();
    if (serviceForm.value.serviceProviderName == '') {
      this.showErrorMessage('Service Provider Name Field is empty.')
      this.hideProgressBar();
      return;
    } if (this.selectedApplicableServices == '') {
      this.showErrorMessage('Please select service type.')
      this.hideProgressBar();
      return;
    }
    let tempService: any[] = [];
    this.selectedApplicableServices.forEach((element: any) => {
      tempService.push({ id: element.code })
    });
    const serviceData = {
      name: this.serviceProviderName,
      serviceTypes: tempService
    }
    var data = { data: JSON.parse(JSON.stringify(serviceData)) };
    console.log('Add Service Form Data --->> ' + JSON.stringify(data));
    this.serviceProviderService.addServiceProvider(data).subscribe(
      (response) => {
        var json = JSON.parse(JSON.stringify(response));
        console.log('Add Service Provider Post Response --->> ' + JSON.stringify(json));
        console.log('Status --->> ' + json.response.status);
        if (json.response.status == 'SUCCESS') {
          this.showHideModalDialog('add_service');
          this.showSuccessMessage(json.response.status, json.response.displayMessage)
          serviceForm.reset();
          this.listServiceProvider();
        } else {
          this.showErrorMessage(json.response.displayMessage)
        }
        this.hideProgressBar();
      },
      (error) => {
        console.log(error);
        this.showErrorMessage(AppSettings.ERROR_MSG)
        this.hideProgressBar();
      }
    );
  }

  editService(serviceForm: NgForm) {
    this.showProgressBar();
    if (serviceForm.value.serviceProviderName == '') {
      this.showErrorMessage('Service Provider Name Field is empty.')
      this.hideProgressBar();
      return;
    } if (this.selectedApplicableServices == '') {
      if(serviceForm.value.serviceProviderName != "Any Provider"){
        this.showErrorMessage('Please select service type.')
        this.hideProgressBar();
        return;
      }
    }
    let tempService: any[] = [];
    this.selectedApplicableServices.forEach((element: any) => {
      tempService.push({ id: element.code })
    });
    const serviceData = {
      name: this.serviceProviderName,
      serviceTypes: tempService
    }
    var data = { data: JSON.parse(JSON.stringify(serviceData)) };
    console.log('Edit Service Form Data --->> ' + JSON.stringify(data));
    this.serviceProviderService.editServiceProvider(this.serviceId, data).subscribe(
      (response) => {
        var json = JSON.parse(JSON.stringify(response));
        console.log('Edit Service Provider Post Response --->> ' + JSON.stringify(json));
        console.log('Status --->> ' + json.response.status);
        if (json.response.status == 'SUCCESS') {
          this.showHideModalDialog('edit_service');
          this.showSuccessMessage(json.response.status, json.response.displayMessage)
          serviceForm.reset();
          this.listServiceProvider();
        } else {
          this.showErrorMessage(json.response.displayMessage)
        }
        this.hideProgressBar();
      },
      (error) => {
        console.log(error);
        this.showErrorMessage(AppSettings.ERROR_MSG)
        this.hideProgressBar();
      }
    );
  }

  listServiceProvider() {
    this.showProgressBar();
    this.serviceProviderService.serviceProviderList().subscribe(
      (response) => {
        var json = JSON.parse(JSON.stringify(response));
        console.log('Get Service Provider List Response --->> ' + JSON.stringify(json));
        if (json.response.status == 'SUCCESS') {
          this.serviceProviderList = [];
          json.data.forEach((element: any) => {
            let serviceType = '';
            element.serviceTypes.forEach((service: any) => {
              serviceType += service.name + ','
            });
            this.serviceProviderList.push({ id: element.id, name: element.name, serviceTypes: serviceType, canDelete: element.canDelete, canChangeRole: element.canEdit })
          });
          // this.serviceProviderList = json.data;
          this.pageSize = json.additionalData.pageSize;
          this.totalCount = json.additionalData.totalCount;
        } else {
          this.showErrorToastMsg(json.response.displayMessage)
        }
        this.hideProgressBar();
      },
      (error) => {
        console.log(error);
        this.showErrorToastMsg(AppSettings.ERROR_MSG)
        this.hideProgressBar();
      }
    );
  }


  listServiceType() {
    this.showProgressBar();
    this.serviceTypeService.serviceTypeList().subscribe(
      (response) => {
        var json = JSON.parse(JSON.stringify(response));
        console.log('Get ServiceType List Response --->> ' + JSON.stringify(json));
        if (json.response.status == 'SUCCESS') {
          this.applicableServices = json.data;
          this.applicableServices = []
          json.data.forEach((element: any) => {
            this.applicableServices.push({ name: element.name, code: element.id })
          });
        } else {
          this.showErrorMessage(json.response.displayMessage)
        }
        this.hideProgressBar();
      },
      (error) => {
        console.log(error);
        this.showErrorToastMsg(AppSettings.ERROR_MSG)
        this.hideProgressBar();
      }
    );
  }

  removeServiceProvider(service: any) {
    this.serviceProviderService.removeServiceProvider(service.id).subscribe(
      (response) => {
        var json = JSON.parse(JSON.stringify(response));
        if (json.response.status == 'SUCCESS') {
          this.showSuccessMessage(json.response.status, json.response.displayMessage)
          this.listServiceProvider();
        } else {
          this.showErrorToastMsg(json.response.displayMessage)
        }
        console.log('Remove Provider Response --->> ' + JSON.stringify(json));
      },
      (error) => {
        console.log(error);
        this.showErrorToastMsg(AppSettings.ERROR_MSG)
      }
    );
  }

  getServiceProvider(service: any, type: string) {
    this.showHideModalDialog(type);
    if (type === 'edit_service') {
      this.showProgressBar();
      this.serviceProviderService.getSingleServiceProvider(service.id).subscribe(
        (response) => {
          var json = JSON.parse(JSON.stringify(response));
          console.log('Get Service Response --->> ' + JSON.stringify(json));
          if (json.response.status == 'SUCCESS') {
            this.serviceId = json.data.id
            this.serviceProviderName = json.data.name;
            this.selectedApplicableServices = [];
            json.data.serviceTypes.forEach((element: any) => {
              this.selectedApplicableServices.push({ name: element.name, code: element.id })
            });
          } else {
            this.showErrorMessage(json.response.displayMessage)
          }
          this.hideProgressBar();
        },
        (error) => {
          console.log(error);
          this.showErrorMessage(AppSettings.ERROR_MSG)
          this.hideProgressBar();
        }
      );
    }
  }

  confirmDeleteDialog(service: any) {
    this.messageService.clear();
    this.confirmationService.confirm({
      message: 'Do you want to delete this service provider?',
      header: 'Delete Confirmation',
      icon: 'pi pi-trash',
      accept: () => {
        this.removeServiceProvider(service);
      }
    });
  }


  showHideModalDialog(type: string) {
    switch (type) {
      case 'edit_service':
        this.headerName = 'Edit Service Provider';
        this.displayAddEditModal = !this.displayAddEditModal;
        break;
      case 'add_service':
        this.clearFormData();
        this.headerName = 'Add Service Provider';
        this.displayAddEditModal = !this.displayAddEditModal;
        break;
    }
  }



  showDialogMaximized(event: any, dialog: Dialog) {
    if (this.innerWidth <= 640) {
      dialog.maximized = true;
    }

  }


  showProgressBar() {
    // this.messageService.clear();
    this.isLoading = true;
    this.disabled = true;
  }

  hideProgressBar() {
    this.isLoading = false;
    this.disabled = false;
  }


  showErrorMessage(msg: string) {
    this.messageService.add({
      key: 'errMsg',
      severity: 'error',
      detail: msg,
    });
  }

  showErrorToastMsg(msg: string) {
    this.messageService.add({
      key: 'br',
      severity: 'error',
      detail: msg,
    });
  }

  showSuccessMessage(status: string, msg: string) {
    this.messageService.add({
      key: 'br',
      severity: 'success',
      summary: status,
      detail: msg,
    });
  }

  clearFormData() {
    this.messageService.clear('errMsg');
    this.serviceProviderName = '';
    this.selectedApplicableServices = '';
  }
  paginate(event: any) {
    console.log(event)
    // this.listUsers(event.page + 1, this.searchInputText)
  }
  get state(): string {
    return this.utils.state;
  }
}
